/* eslint-disable class-methods-use-this */
import { observable, action, computed } from "mobx";
import { message } from "antd";
import DriverService from "../../../services/api/drivers";
import BaseForm from "../base-form";

class RemoveDriverForm extends BaseForm {
  @observable driverId = null;

  @observable driver = null;

  @observable isRemovingSuccess = false;

  @computed
  get shouldBeOpened() {
    return !!this.driverId;
  }

  @action
  delete() {
    const values = {
      id: this.driverId,
      ...this.values()
    };
    return DriverService.delete(values)
      .then(({ data }) => {
        this.isRemovingSuccess = true;
        this.resetState();
        return data;
      })
      .catch(e => {
        if (
          e.response &&
          (e.response.status === 422 || e.response.status === 500)
        ) {
          message.error(e.response.data && e.response.data.message);
        } else {
          message.warning(e.message);
        }
      });
  }

  @action
  initState({ id, driver }) {
    this.driverId = id;
    this.driver = driver;
  }

  resetState() {
    this.driverId = null;
    this.driver = null;
    this.isRemovingSuccess = false;
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.delete();
      }
    };
  }
}

export default new RemoveDriverForm();
