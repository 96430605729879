import moment from "moment";
import momentTz from "moment-timezone";

(() => {
  momentTz.tz.setDefault("Africa/Lagos");

  moment.updateLocale("en", {
    relativeTime: {
      hh: "%dh"
    }
  });

  moment.fn.isWorkingDay = function isWorkingDay() {
    return ![6, 7].includes(this.isoWeekday());
  };

  moment.fn.workingAdd = function workingAdd(number, period) {
    const day = this.clone();

    if (!day.isValid()) {
      return day;
    }

    if (number < 0) {
      number = Math.round(-1 * number) * -1;
    } else {
      number = Math.round(number);
    }

    const signal = number < 0 ? -1 : 1;

    period = typeof period !== "undefined" ? period : "days";

    let remaining = Math.abs(number);

    while (remaining > 0) {
      day.add(signal, period);

      if (day.isWorkingDay()) {
        remaining -= 1;
      }
    }

    return day;
  };
})();
