import { observable, action, reaction, computed } from "mobx";
import moment from "moment";
import BaseStore from "./base-manager";
import DriverService from "../../../services/api/drivers";
import {
  STATUS_DEFAULT,
  PAGE_DEFAULT,
  LINK_DRIVER_MAX_TRUCKS,
  DATE_PICKER_PROPS,
  DRIVER_STATUSES_CODES,
  DRIVER_STATUSES
} from "../../../config/constants";
import OptionsService from "../../../services/api/options";
import { formatPhoneNumber } from "../../../utils/format";

class DriversStore extends BaseStore {
  loadFunction = DriverService.get;

  @observable tag = STATUS_DEFAULT;

  @observable licenseClassesLoaded = false;

  @observable licenseClasses = null;

  @observable onlySingleSelection = true;

  constructor() {
    super();
    reaction(
      () => this.tag,
      () => {
        this.setSortedInfo();
      }
    );
  }

  loadOptions() {
    if (!this.licenseClassesLoaded) {
      OptionsService.getLicenseClasses()
        .then(classes => {
          this.licenseClasses = Object.keys(classes).map(item => ({
            value: item,
            label: classes[item]
          }));
        })
        .finally(() => {
          this.licenseClassesLoaded = true;
        });
    }
  }

  @action
  setTag(status) {
    this.tag = status;
    this.setPage(PAGE_DEFAULT);
  }

  @computed
  get selectedDriverCouldBeLinked() {
    if (!this.paginator.data) {
      return true;
    }
    return (
      this.paginator.data.filter(driver => {
        return (
          this.selectedRowKeys.includes(driver.id) &&
          [
            DRIVER_STATUSES_CODES.APPROVED,
            DRIVER_STATUSES_CODES.PENDING_RECERTIFICATION
          ].includes(driver.status) &&
          moment(driver.license_expiry_date).isAfter(moment())
        );
      }).length === this.selectedRowKeys.length
    );
  }

  // eslint-disable-next-line class-methods-use-this
  get tags() {
    return Object.entries(DRIVER_STATUSES).map(entry => {
      const [code, descriptor] = entry;

      return {
        code,
        name: descriptor.name
      };
    });
  }

  @computed
  get getSelectedDriverEntity() {
    if (!this.paginator.data) {
      return null;
    }

    const selectedKey = this.selectedRowKeys[0];

    if (!selectedKey) {
      return null;
    }

    return this.paginator.data.find(driver => driver.id === selectedKey);
  }

  @computed
  get canAddDrivers() {
    return this.selectedRowKeys.length <= LINK_DRIVER_MAX_TRUCKS;
  }

  @computed
  get specificArguments() {
    return {
      status: this.tag
    };
  }

  formatDriverName = ({ first_name, last_name, middle_name }) => {
    return [
      first_name && first_name,
      middle_name && middle_name,
      last_name && last_name
    ]
      .filter(Boolean)
      .join(" ");
  };

  formatLicenseExpiryDate = ({ license_expiry_date }) =>
    license_expiry_date &&
    moment(license_expiry_date).format(DATE_PICKER_PROPS.format);

  formatPhone = phoneNumber => formatPhoneNumber(phoneNumber);

  isLicenseExpired = ({ license_expiry_date }) => {
    const now = moment();
    return moment(license_expiry_date).isBefore(now);
  };
}

export default new DriversStore();
