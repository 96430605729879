/* eslint-disable class-methods-use-this */
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import Field from "../field";
import BaseForm from "../base-form";

class LoginForm extends BaseForm {
  setup() {
    return {
      fields: [
        {
          name: "email",
          type: "text",
          rules: "required|email_ext|string|max:255",
          guard: "spaces"
        },
        {
          name: "password",
          type: "password",
          rules: "required|max:255"
        }
      ]
    };
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs
      })
    };
  }

  options() {
    return {
      validateOnReset: false,
      showErrorsOnReset: false,
      validationDebounceWait: 100
    };
  }

  reset() {
    this.$("email").reset();
    this.$("password").reset();
  }

  makeField(props) {
    return new Field(props);
  }
}

export default new LoginForm();
