/* eslint-disable class-methods-use-this */
import React from "react";
import { observable, action, computed } from "mobx";
import { message } from "antd";
import deepCleaner from "deep-cleaner";
import ShipmentsService from "../../../../services/api/shipments";
import BaseForm from "../../base-form";
import Shipment from "../../../shipment";
import {
  SEND_QUOTE_COMMENT_LENGTH,
  SHIPMENT_ACTIVE_STATUS_CODES
} from "../../../../config/constants";
import OptionsService from "../../../../services/api/options";
import AuthStore from "../../../auth";
import { formatNumber } from "../../../../utils/format";

class CancelActiveShipmentForm extends BaseForm {
  @observable shipment = null;

  @observable isCancelSuccess = false;

  @observable isOptionsLoaded = false;

  @observable options = { reasons: [] };

  @computed
  get shouldBeOpened() {
    return !!this.shipment && !!this.shipment.id;
  }

  loadOptions() {
    if (!this.isOptionsLoaded) {
      OptionsService.getShipmentCancelReasons()
        .then(reasons => {
          this.options.reasons = reasons;
        })
        .finally(() => {
          this.isOptionsLoaded = true;
        });
    }
  }

  calculateAmountCancelShipment(id) {
    if (id) {
      return ShipmentsService.getAmountCancelShipment(id).then(
        ({ data }) => data
      );
    }
    return null;
  }

  cancelMessage() {
    const truckOwnerMessage = () => (
      <>
        VH Admin Charge including VAT{" "}
        <span className="font-bolder">
          ₦ {this.shipment && formatNumber(this.shipment.amount, true)}
        </span>{" "}
        will be saved in buffer and deducted from your next payout if you cancel{" "}
        this shipment. Proceed with cancellation?
      </>
    );
    const shipperAgentMessage = () => (
      <>
        You will be refunded{" "}
        <span className="font-bolder">
          ₦ {this.shipment && formatNumber(this.shipment.amount, true)}
        </span>{" "}
        if you cancel this shipment. Proceed with cancellation?
      </>
    );

    if (AuthStore.isTruckOwner) {
      return truckOwnerMessage();
    }
    if (AuthStore.isAgent || AuthStore.isShipper) {
      return shipperAgentMessage();
    }
    return null;
  }

  /**
   * @param shipment {Shipment}
   * @returns {boolean}
   */
  isNotAvailableToCancel(shipment) {
    // * Note: Shipment and associated Quote status must be the same!
    const isSameStatus = shipment.isQuoteAndShipmentStateIsSameForStatus(
      shipment.status
    );

    const isShipmentOrQuoteArePickedUp = !!(
      shipment.deliveryHistory.find(
        state =>
          state.status === SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP &&
          state.updatedDate !== null
      ) ||
      shipment.activeQuoteDeliveryHistory.find(
        state =>
          state.status === SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP &&
          state.updatedDate !== null
      )
    );

    // todo: after Pick Up date

    // if SH: Active and QU: Truck at Origin

    const shipmentIsActiveAndTruckAtOrigin =
      shipment.status === SHIPMENT_ACTIVE_STATUS_CODES.ACTIVE &&
      shipment.activeQuote.status ===
        SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN;

    // if SH: Truck at Origin and QU: Active

    const truckAtOriginAndQuoteIsActive =
      shipment.status === SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN &&
      shipment.activeQuote.status === SHIPMENT_ACTIVE_STATUS_CODES.ACTIVE;

    return (
      !isSameStatus ||
      shipmentIsActiveAndTruckAtOrigin ||
      truckAtOriginAndQuoteIsActive ||
      isShipmentOrQuoteArePickedUp
    );
  }

  @computed
  get commentRemainingChars() {
    const currentText = this.$("comment").value || "";
    if (currentText.length > SEND_QUOTE_COMMENT_LENGTH) {
      return 0;
    }
    return SEND_QUOTE_COMMENT_LENGTH - currentText.length;
  }

  @action
  async setShipment(shipment) {
    const amount = await this.calculateAmountCancelShipment(shipment.id);
    this.shipment = new Shipment(shipment);
    this.shipment = {
      ...this.shipment,
      ...amount
    };
    return this;
  }

  @action
  initState(shipment) {
    this.setShipment(shipment);
    this.loadOptions();
  }

  resetState() {
    this.$("reason_id").clear();
    this.$("reason_id").resetValidation();
    this.reset();
  }

  @action
  closeForm() {
    this.resetState();
    this.shipment = null;
  }

  @action
  delete() {
    const values = {
      ...this.values(),
      id: this.shipment.id
    };
    return ShipmentsService.delete(deepCleaner(values))
      .then(({ data }) => {
        this.isCancelSuccess = true;
        setTimeout(() => {
          message.success(`Shipment ${values.id} canceled successfully.`);
        }, 2000);
        this.closeForm();
        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          const {
            data: { errors }
          } = e.response;
          if (errors) {
            Object.entries(errors).forEach(entry => {
              const [fieldName, errorMessage] = entry;
              if (this.has(fieldName)) {
                this.$(fieldName).invalidate(errorMessage);
              }
            });
          } else {
            message.error(e.response.data.message && e.response.data.message);
          }
        } else {
          message.warning(e.message);
        }
      })
      .finally(() => {
        this.isCancelSuccess = false;
      });
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.delete();
      },
      onInit: () => {
        this.$("comment").intercept(({ change }) => {
          if (
            typeof change.newValue === "string" &&
            change.newValue.length > SEND_QUOTE_COMMENT_LENGTH
          ) {
            return false;
          }
          return change;
        });
      }
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "reason_id",
          type: "select",
          rules: "required",
          label: "Specify Reason for Cancellation Shipment",
          placeholder: "Select a Reason"
        },
        {
          name: "comment",
          type: "textarea",
          rules: "max:500",
          label: "Comment",
          placeholder: "Some words about the Reason for Cancel Shipment"
        }
      ]
    };
  }

  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }
}

export default new CancelActiveShipmentForm();
