const data = {
  created_at: "2019-11-25 11:12:00",
  updated_at: "2019-11-27 13:50:15",
  parent_shipment_id: null,
  status: "quote_selected",
  origin_country_id: 1,
  origin_state_id: 1,
  origin_city_id: 1,
  origin_area_id: 1,
  origin_street_address: "Pavlovskogo 21",
  origin_house_number: "1",
  destination_country_id: 1,
  destination_state_id: 1,
  destination_city_id: 2,
  destination_area_id: 22,
  destination_street_address: "Pavlovskogo 21",
  destination_house_number: "2",
  commodity_type_id: 42,
  truck_type_id: 7,
  packaging_id: 5,
  weight: "200",
  length: "10",
  volume: null,
  cargo_value: "1000",
  pickup_date_start: "2019-11-29T13:58:45.000000Z",
  pickup_date_end: "2019-12-05T13:58:45.000000Z",
  pickup_time_start: "2019-11-27T08:00:00.000000Z",
  pickup_time_end: "2019-11-27T09:00:00.000000Z",
  delivery_date: "2019-12-06T13:58:45.000000Z",
  shipper_contact_name: "Aleksey Danilovich a",
  contact_phone_number: "+234-8029787600",
  shipper_id: null,
  shipper_email: null,
  owner_id: 161,
  is_shared_consignment: true,
  origin_country: { id: 1, iso_code: "NG", name: "NIGERIA" },
  origin_state: { id: 1, name: "ABIA", country_id: 1 },
  origin_city: { id: 1, name: "ABA", state_id: 1 },
  origin_area: { id: 1, name: "ABAYI", postal_code: "450272", city_id: 1 },
  destination_country: { id: 1, iso_code: "NG", name: "NIGERIA" },
  destination_state: { id: 1, name: "ABIA", country_id: 1 },
  destination_city: { id: 2, name: "IKWUANO", state_id: 1 },
  destination_area: {
    id: 22,
    name: "IKWUANO",
    postal_code: "440110",
    city_id: 2
  },
  commodity_type: {
    id: 42,
    name: "Cars",
    created_at: "2019-08-13 11:52:49",
    updated_at: "2019-08-13 11:52:49",
    insurance_percentage: "0"
  },
  truck_type: {
    id: 7,
    name: "Car carrier Trailers",
    created_at: "2019-08-13 11:52:50",
    updated_at: "2019-10-31 13:52:14",
    calculation_type_id: 1
  },
  packaging: {
    id: 5,
    name: "Containers",
    created_at: "2019-09-23 07:33:26",
    updated_at: "2019-09-23 07:33:26"
  }
};

// eslint-disable-next-line import/prefer-default-export
export function mock() {
  if (
    process.env.NODE_ENV !== "development" ||
    localStorage.getItem("sh-mock") !== "1"
  ) {
    return;
  }
  this.set(data);
}
