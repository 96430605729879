/* eslint-disable class-methods-use-this,import/no-cycle */
import { action, observable, reaction, computed } from "mobx";
import { message } from "antd";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import { history } from "../../../contexts/history";
import OptionsService from "../../../services/api/options";
import Auth from "../../../services/api/auth";
import AuthStore from "../../auth";
import { checkErrors } from "../../../utils/check-errors";
import {
  DEFAULT_DIAL_CODE,
  INVALID_DATE_ERROR_MESSAGE,
  REGEX,
  COMMON_TITLE_OPTIONS
} from "../../../config/constants";
import { formatPhoneNumber, formatNumber } from "../../../utils/format";

const formatOptionsArrayFromSource = source => {
  return source.map(item => ({
    value: item.id ? item.id : item.value,
    label: item.name ? item.name : item.label
  }));
};

const fields = [
  "phone_number",
  "dial_code",
  "first_additional_phone_number",
  "first_additional_dial_code",
  "second_additional_phone_number",
  "second_additional_dial_code",
  "bank_name_id",
  "nuban_bank_account_number",
  "beneficiary_name"
];

const labels = {
  phone_number: "Phone Number",
  first_additional_phone_number: "Phone Number",
  second_additional_phone_number: "Phone Number",
  bank_name_id: "Bank Name",
  nuban_bank_account_number: "NUBAN Bank Account Number",
  beneficiary_name: "Beneficiary Name"
};

const types = {
  phone_number: "text",
  dial_code: "select",
  first_additional_phone_number: "text",
  first_additional_dial_code: "select",
  second_additional_phone_number: "text",
  second_additional_dial_code: "select",
  bank_name_id: "select",
  nuban_bank_account_number: "text",
  beneficiary_name: "text"
};

const rules = {
  phone_number: "required|string|between:8,10|phone_number",
  dial_code: "required",
  first_additional_phone_number:
    "string|add_phone_number|add_phone_number_length",
  first_additional_dial_code: "required_if:first_additional_phone_number",
  second_additional_phone_number:
    "string|add_phone_number|add_phone_number_length",
  second_additional_dial_code: "required_if:second_additional_phone_number",
  bank_name_id: "required",
  nuban_bank_account_number: "required|numeric|regex:/^[0-9]{10}$/",
  beneficiary_name: "required|regex_names|between:2,90"
};

const guards = {
  phone_number: "letters|specials",
  first_additional_phone_number: "letters|specials",
  second_additional_phone_number: "letters|specials",
  nuban_bank_account_number: "specials|letters|spaces",
  beneficiary_name: "numbers|specials"
};

const hooks = {
  dial_code: {
    onChange: field => {
      field.set("extra", { manuallyChanged: true });
    }
  },
  first_additional_phone_number: {
    onChange: field => {
      if (!field.value) {
        field
          .container()
          .$("first_additional_dial_code")
          .set("value", DEFAULT_DIAL_CODE);
      }
    }
  },
  first_additional_dial_code: {
    onChange: field => {
      field.set("extra", { manuallyChanged: true });
    }
  },
  second_additional_phone_number: {
    onChange: field => {
      if (!field.value) {
        field
          .container()
          .$("second_additional_dial_code")
          .set("value", DEFAULT_DIAL_CODE);
      }
    }
  },
  second_additional_dial_code: {
    onChange: field => {
      field.set("extra", { manuallyChanged: true });
    }
  }
};

const input = {
  dial_code: v => v || undefined,
  first_additional_dial_code: v => v || undefined,
  second_additional_dial_code: v => v || undefined,
  bank_name_id: v => v || undefined
};

const options = {
  bank_name_id: []
};

class EditProfileForm extends BaseForm {
  @observable loading = false;

  @observable user = null;

  constructor(...args) {
    super(...args);

    reaction(
      () => this.loadedValues.id,
      id => {
        if (id) {
          this.loadBanksOptions();
        }
      }
    );
  }

  @action
  setUser(user) {
    this.user = user;
  }

  @action
  resetForm() {
    this.reset();

    history.push("/view-profile");
  }

  @action
  preSubmit() {
    if (!this.changed) {
      setTimeout(() => {
        message.warning("No changes to update");
      }, 2000);

      return false;
    }

    return true;
  }

  @action
  async loadProfile() {
    this.loading = true;

    return Auth.showProfile()
      .then(user => {
        this.setUser(user);
      })
      .catch(() => {
        message.warning("Unable to load user profile.");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * set up the validator plugin
   * @returns {{dvr: *}}
   */
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");
          const newMessages = {
            ...messages,
            required: "This field is required.",
            required_without: "This field is required.",
            required_if: "This field is required.",
            required_unless: "This field is required.",
            alpha_num: "Invalid input.",
            between: "This field must be between :min and :max.",
            max: "This field cannot be longer than :max characters.",
            "regex.nuban_bank_account_number":
              "Invalid NUBAN Bank Account Number."
          };

          validator.setMessages("en", newMessages);
          validator.register(
            "regex_names",
            value => {
              return value.match(REGEX.NAMES);
            },
            INVALID_DATE_ERROR_MESSAGE
          );

          validator.register(
            "phone_number",
            value => {
              return value.match(REGEX.PHONE_NUMBER);
            },
            INVALID_DATE_ERROR_MESSAGE
          );
          validator.register(
            "add_phone_number",
            value => {
              return value.match(REGEX.PHONE_NUMBER) || value === null;
            },
            INVALID_DATE_ERROR_MESSAGE
          );
          validator.register(
            "add_phone_number_length",
            value => {
              return (
                (value.length >= 8 && value.length <= 10) || value === null
              );
            },
            "This field must be between 8 and 10."
          );
        }
      })
    };
  }

  async loadOptions() {
    this.loadBanksOptions();
  }

  async setUserFields() {
    if (this.user) {
      const { profile } = this.user;

      if (profile.bank_name_id) {
        this.$("bank_name_id").set("value", profile.bank_name_id);
      }

      if (profile.nuban_bank_account_number) {
        this.$("nuban_bank_account_number").set(
          "value",
          profile.nuban_bank_account_number
        );
      }

      if (profile.beneficiary_name) {
        this.$("beneficiary_name").set("value", profile.beneficiary_name);
      }

      const [dial_code, phone_number] = profile.phone_number.split("-");

      this.$("dial_code").set("value", dial_code);
      this.$("phone_number").set("value", phone_number);

      if (profile.first_additional_phone_number) {
        const [
          first_additional_dial_code,
          first_additional_phone_number
        ] = profile.first_additional_phone_number.split("-");

        this.$("first_additional_dial_code").set(
          "value",
          first_additional_dial_code
        );
        this.$("first_additional_phone_number").set(
          "value",
          first_additional_phone_number
        );
        if (profile.second_additional_phone_number) {
          const [
            second_additional_dial_code,
            second_additional_phone_number
          ] = profile.second_additional_phone_number.split("-");

          this.$("second_additional_dial_code").set(
            "value",
            second_additional_dial_code
          );
          this.$("second_additional_phone_number").set(
            "value",
            second_additional_phone_number
          );
        }
      }
    }
  }

  async load() {
    this.loading = true;
    this.loadOptions();
    this.setUserFields();
    this.loading = false;
  }

  async optionsLoader(fieldsToUpdate = [], loaderFunction, id) {
    fieldsToUpdate.forEach(fieldName => {
      if (!this.has(fieldName)) {
        return;
      }

      this.$(fieldName).loading = true;
    });

    const rawOptions = await loaderFunction(id);
    const formattedOptions = formatOptionsArrayFromSource(rawOptions);

    fieldsToUpdate.forEach(fieldName => {
      if (!this.has(fieldName)) {
        return;
      }

      const field = this.$(fieldName);

      field.set("options", formattedOptions);
      field.loading = false;
    });
  }

  async loadBanksOptions() {
    const { bank_name_id } = this.loadedValues;

    this.optionsLoader(
      ["bank_name_id"],
      OptionsService.getBankNames,
      bank_name_id
    );
  }

  @computed
  get companyNameInfo() {
    if (this.user) {
      const { company_name } = this.user.profile;

      return company_name;
    }

    return "—";
  }

  @computed
  get userNameInfo() {
    if (this.user) {
      const { title, first_name, middle_name, last_name } = this.user.profile;

      if (middle_name) {
        return `${COMMON_TITLE_OPTIONS[title]} ${first_name} ${middle_name} ${last_name}`;
      }

      return `${COMMON_TITLE_OPTIONS[title]} ${first_name} ${last_name}`;
    }

    return "—";
  }

  @computed
  get phoneNumber() {
    if (this.user) {
      const { phone_number } = this.user.profile;

      return formatPhoneNumber(phone_number);
    }

    return "—";
  }

  @computed
  get firstAdditionalPhoneNumber() {
    if (this.user) {
      const { first_additional_phone_number } = this.user.profile;

      if (first_additional_phone_number) {
        return formatPhoneNumber(first_additional_phone_number);
      }
    }

    return "—";
  }

  @computed
  get secondAdditionalPhoneNumber() {
    if (this.user) {
      const { second_additional_phone_number } = this.user.profile;

      if (second_additional_phone_number) {
        return formatPhoneNumber(second_additional_phone_number);
      }
    }

    return "—";
  }

  @computed
  get emailInfo() {
    if (this.user) {
      const { email } = this.user;

      return email;
    }

    return "—";
  }

  @computed
  get countryInfo() {
    if (this.user) {
      const { name } = this.user.profile.country;

      return name;
    }

    return "—";
  }

  @computed
  get addressInfo() {
    if (this.user) {
      const { street_address, house_number } = this.user.profile;

      return `${street_address} ${house_number}`;
    }

    return "—";
  }

  @computed
  get stateInfo() {
    if (this.user) {
      const { state_manual, state } = this.user.profile;

      if (state_manual) {
        return state_manual;
      }

      return state.name && state.name;
    }

    return "—";
  }

  @computed
  get cityInfo() {
    if (this.user) {
      const { city_manual, city } = this.user.profile;

      if (city_manual) {
        return city_manual;
      }

      return city.name && city.name;
    }

    return "—";
  }

  @computed
  get areaInfo() {
    if (this.user) {
      const { area_manual, area } = this.user.profile;

      if (area_manual) {
        return area_manual;
      }

      return area.name && area.name;
    }

    return "—";
  }

  @computed
  get bankName() {
    if (this.user) {
      const { bank_name } = this.user.profile;

      return (bank_name && bank_name.name) || "—";
    }

    return "—";
  }

  @computed
  get bankAccountNumber() {
    if (this.user) {
      const { nuban_bank_account_number } = this.user.profile;

      return (nuban_bank_account_number && nuban_bank_account_number) || "—";
    }

    return "—";
  }

  @computed
  get beneficiaryName() {
    if (this.user) {
      const { beneficiary_name } = this.user.profile;

      return (beneficiary_name && beneficiary_name) || "—";
    }

    return "—";
  }

  @computed
  get debtAmount() {
    if (this.user) {
      if (this.user.profile.debt_amount) {
        const { debt_amount } = this.user.profile;

        return `₦ ${formatNumber(debt_amount, true)}`;
      }
    }

    return "—";
  }

  hooks() {
    return {
      onInit() {
        Object.entries(guards).forEach(entry => {
          const [field, guard] = entry;

          this.$(field).guard = guard;
        });
        this.$("first_additional_dial_code").value = DEFAULT_DIAL_CODE;
        this.$("second_additional_dial_code").value = DEFAULT_DIAL_CODE;
      },

      onSuccess: () => {
        const {
          dial_code,
          phone_number,
          first_additional_dial_code,
          first_additional_phone_number,
          second_additional_dial_code,
          second_additional_phone_number,
          ...rest
        } = this.values();

        const values = {
          ...rest,
          phone_number: [dial_code, phone_number].join("-"),
          ...(first_additional_phone_number
            ? {
                first_additional_phone_number: [
                  !first_additional_dial_code
                    ? DEFAULT_DIAL_CODE
                    : first_additional_dial_code,
                  first_additional_phone_number
                ].join("-")
              }
            : { first_additional_phone_number }),
          ...(second_additional_phone_number
            ? {
                second_additional_phone_number: [
                  !second_additional_dial_code
                    ? DEFAULT_DIAL_CODE
                    : second_additional_dial_code,
                  second_additional_phone_number
                ].join("-")
              }
            : { second_additional_phone_number })
        };

        if (
          !values.first_additional_phone_number &&
          values.second_additional_phone_number
        ) {
          values.first_additional_phone_number =
            values.second_additional_phone_number;
          values.second_additional_phone_number = "";
        }

        return Auth.editProfile(values)
          .then(user => {
            setTimeout(() => {
              message.success("Changes saved successfully");
            }, 2000);
            AuthStore.setUser(user);
            AuthStore.persistUser(user);
            this.resetForm();

            return user;
          })
          .catch(error => {
            console.dir(error);
            // handle validation errors
            if (error.response && error.response.status === 422) {
              const messages = error.response.data.errors;

              Object.entries(messages).forEach(entry => {
                const [dbFieldName, errorMessage] = entry;

                this.$(dbFieldName).invalidate(errorMessage);
              });
            } else {
              message.warning(error.message);
            }

            return Promise.reject();
          });
      },
      onError() {
        console.log(this.errors());
        checkErrors(this.errors());
      },
      onReset() {
        this.resetForm();
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }
}

export default new EditProfileForm({
  fields,
  labels,
  types,
  rules,
  guards,
  hooks,
  input,
  options
});
