import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import FormContext from "../../models/forms/form-context";
import RegistrationForm from "./RegistrationForm";
import SignUpForm from "../../models/forms/auth/signup";

const Registration = inject("AuthStore", "AppState")(
  observer(({ AuthStore, AppState, ...rest }) => {
    useEffect(() => {
      SignUpForm.loadState(AuthStore.user.id ? AuthStore.user : null);
    }, [AuthStore.user]);

    useEffect(() => {
      AppState.addLayoutClass("registration");

      return () => AppState.removeLayoutClass("registration");
    }, [AppState]);

    return (
      <FormContext.Provider value={SignUpForm}>
        <RegistrationForm formState={SignUpForm} {...rest} />
      </FormContext.Provider>
    );
  })
);

Registration.isAuthRoute = true;

export default Registration;
