import Button from "antd/lib/button";
import React from "react";

import moment from "moment";
import { Tooltip } from "antd";
import Icon from "antd/lib/icon";
import AuthStore from "../../../../models/auth";
import { dateFormatter, formatLocation } from "../../../../utils/format";
import {
  SHIPMENT_ACTIVE_STATUSES,
  SHIPMENT_PAST_STATUSES,
  SHIPMENT_PENDING_STATUS_CODES,
  SHIPMENT_PENDING_STATUSES,
  SORT_DIRECTIONS,
  STATUS_QUOTE_AVAILABLE_TO_CANCEL as statusAvailableToCancel
} from "../../../../config/constants";
import StatusRenderer from "../../../../common/StatusRenderer";
import { ReactComponent as ContractSignedIcon } from "../../../../icons/contract-signed.svg";
import { ReactComponent as QuoteSelectedIcon } from "../../../../icons/quote-selected.svg";
import { ReactComponent as QuoteIcon } from "../../../../icons/quote.svg";
import { getDiffBetweenDatesAsCountdown } from "../../../../utils/date-time";
import { history } from "../../../../contexts/history";
import Shipment from "../../../../models/shipment";

const renderDates = row => {
  return (
    <span key={row.id}>
      <span style={{ whiteSpace: "nowrap" }}>
        {`${dateFormatter(row.pickup_date_start)} —`}
      </span>
      <br />
      <span>{dateFormatter(row.pickup_date_end)}</span>
    </span>
  );
};

const renderSentQuoteStatus = () => {
  return (
    <>
      <div>
        <QuoteIcon />
      </div>
      <div className="fs-12 font-bolder">Quote Sent</div>
    </>
  );
};

const renderPendingContractStatus = contract => {
  const { expired_at } = contract;
  const expiredAt = moment.utc(expired_at);
  const countDownString = getDiffBetweenDatesAsCountdown(
    moment.utc(),
    expiredAt
  );

  if (!countDownString) {
    return "—";
  }

  return (
    <>
      <div>{countDownString}</div>
      <div className="fs-11">Left to Sign</div>
    </>
  );
};

const renderSignedContractStatus = () => {
  return (
    <>
      <div>
        <ContractSignedIcon />
      </div>
      <div className="fs-12 font-bolder">Contract Signed</div>
    </>
  );
};

const renderQuoteSelected = () => {
  return (
    <>
      <div>
        <QuoteSelectedIcon />
      </div>
      <div className="fs-12 font-bolder">Quote Selected</div>
    </>
  );
};

export const columns = [
  {
    width: "15%",
    title: "Origin",
    dataIndex: "origin",
    key: "origin",
    render: (_, row) =>
      formatLocation({ city: row.origin_city, state: row.origin_state })
  },
  {
    width: "15%",
    title: "Destination",
    dataIndex: "destination",
    key: "destination",
    render: (_, row) =>
      formatLocation({
        city: row.destination_city,
        state: row.destination_state
      })
  },

  {
    width: "15%",
    title: "Commodity Type",
    dataIndex: "commodity_type",
    key: "commodity_type",
    render: ({ name }) => name
  },
  {
    width: "15%",
    title: (
      <span>
        Pick Up Date
        <Tooltip title="After the contract is signed, the system will replace initial date range with Pick Up date selected by Truck Owner.">
          <Icon
            type="info-circle"
            style={{
              marginLeft: 10
            }}
          />
        </Tooltip>
      </span>
    ),
    dataIndex: "pickup_date_start",
    key: "pickup_date_start",
    /**
     * @param _
     * @param row {Shipment}
     * @returns {*}
     */
    render: (_, row) => {
      const isPending = !row.isPast && !row.isActive;
      if (row.isShared && isPending) {
        if (AuthStore.isTruckOwner && row.acceptedQuoteForTruckOwner) {
          return dateFormatter(row.acceptedQuoteForTruckOwner.pickup_date_time);
        }

        if (row.sharedHasSignedContract) {
          return "See in details";
        }
      }

      if (!row.isShared && isPending && row.signedQuote) {
        return dateFormatter(row.signedQuote.pickup_date_time);
      }

      if (row.isActive) {
        switch (true) {
          case AuthStore.isTruckOwner:
            return dateFormatter(row.trackedEntity.pickup_date_time);
          case row.isShared:
            return "See in details";
          default:
            return dateFormatter(row.activeQuote.pickup_date_time);
        }
      }
      return renderDates(row);
    }
  },
  {
    width: "15%",
    title: "Date Posted",
    dataIndex: "post_date",
    key: "post_date",
    render: (_, { created_at }) => dateFormatter(created_at)
  },
  {
    width: "15%",
    title: "Status",
    dataIndex: "status",
    /**
     *
     * @param _
     * @param shipment {Shipment}
     * @returns {*|boolean|null}
     */
    render: (_, shipment) => {
      const { status } = shipment;

      if (AuthStore.isTruckOwner && shipment.hasPendingContractForTruckOwner) {
        return renderPendingContractStatus(
          shipment.pendingContractForTruckOwner
        );
      }

      if (shipment.hasSentQuoteForTruckOwner) {
        return renderSentQuoteStatus();
      }

      if (shipment.hasSelectedQuotesForTruckOwner) {
        return renderQuoteSelected();
      }

      if (status === SHIPMENT_PENDING_STATUS_CODES.PENDING) {
        return (
          <StatusRenderer
            currentStatus="pending"
            statuses={SHIPMENT_PENDING_STATUSES}
          />
        );
      }

      if (Object.keys(SHIPMENT_PAST_STATUSES).includes(status)) {
        return (
          <StatusRenderer
            currentStatus={status}
            statuses={SHIPMENT_PAST_STATUSES}
          />
        );
      }

      if (!AuthStore.isTruckOwner && shipment.signedQuote) {
        return renderSignedContractStatus();
      }

      if (AuthStore.isTruckOwner && shipment.hasAcceptedContractForTruckOwner) {
        return renderSignedContractStatus();
      }

      if (
        !AuthStore.isTruckOwner &&
        status === SHIPMENT_PENDING_STATUS_CODES.QUOTE_SELECTED
      ) {
        return renderQuoteSelected();
      }

      return null;
    }
  },
  {
    width: "15%",
    title: "Status",
    dataIndex: "status_for_active_shipment",
    /**
     *
     * @param u
     * @param shipment {Shipment}
     * @returns {*|boolean|null}
     */
    render: (u, shipment) => {
      let trackedEntity = shipment;
      if (shipment instanceof Shipment) {
        // eslint-disable-next-line prefer-destructuring
        trackedEntity = shipment.trackedEntity;
      }
      const { status } = trackedEntity;
      return (
        <StatusRenderer
          currentStatus={status}
          statuses={SHIPMENT_ACTIVE_STATUSES}
        />
      );
    }
  },
  {
    width: "15%",
    title: "Status",
    dataIndex: "status_for_past_shipment",
    /**
     *
     * @param u
     * @param shipment {Shipment}
     * @returns {*|boolean|null}
     */
    render: (u, shipment) => {
      const { status } = shipment.trackedPassedEntity;
      return (
        <StatusRenderer
          currentStatus={status}
          statuses={SHIPMENT_PAST_STATUSES}
        />
      );
    }
  },
  {
    width: "5%",
    title: "",
    dataIndex: "quote_column",
    /**
     *
     * @param _
     * @param shipment {Shipment}
     * @returns {null|*}
     */
    render: (_, shipment) => {
      const { quotes = [], id, status } = shipment;
      if (status === SHIPMENT_PENDING_STATUS_CODES.QUOTE_SELECTED) {
        return null;
      }
      const quotesAvailable = quotes.filter(
        v => statusAvailableToCancel.indexOf(v.status) > -1
      );
      if (quotesAvailable.length === 0) {
        return <span className="text-disabled">No Quotes</span>;
      }

      const label = quotesAvailable.length === 1 ? "Quote" : "Quotes";
      return (
        <Button
          type="secondary"
          disabled={!shipment.isOwnedByCurrentUser}
          onClick={() => {
            if (!shipment.isOwnedByCurrentUser) {
              return;
            }
            history.push(`/shipments/${id}/quotes`);
          }}
        >
          {quotesAvailable.length} {label}
        </Button>
      );
    }
  }
];

/**
 * this function returns a columns set by given data indexes
 * @param keys {string[]} dataIndex array to search through the common columns
 * @param columnsToInject
 * @param modifiers
 * @returns {*[]}
 */
function getColumns(keys = [], columnsToInject = [], modifiers = {}) {
  const columnsFiltered = keys
    .map(dataIndex => columns.find(column => column.dataIndex === dataIndex))
    .filter(Boolean)
    .map(column => {
      if (
        this &&
        Array.isArray(this.sortableFields) &&
        this.sortableFields.includes(column.dataIndex)
      ) {
        return {
          ...column,
          sorter: true,
          sortOrder: this.sortOrder(column.dataIndex),
          sortDirections: SORT_DIRECTIONS
        };
      }
      return column;
    });

  if (Array.isArray(columnsToInject)) {
    columnsToInject.forEach(({ atIndex, column }) => {
      columnsFiltered.splice(atIndex, 0, column);
    });
  }

  if (Object.keys(modifiers).length > 0) {
    Object.entries(modifiers).forEach(entry => {
      const [columnIndex, modifier] = entry;
      const column = columnsFiltered.find(
        ({ dataIndex }) => dataIndex === columnIndex
      );
      if (!column || typeof modifier !== "function") {
        return true;
      }

      modifier(column);

      return true;
    });
  }

  return columnsFiltered;
}

export default getColumns;
