import { action, computed, observable } from "mobx";
import moment from "moment";
import { message } from "antd";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import SendQuoteService from "../../../services/api/send-quote";
import {
  LOG_TRUCK_MAX_TRUCKS,
  SEND_QUOTE_COMMENT_LENGTH
} from "../../../config/constants";
import { formatTruckLabel } from "../../../utils/format";
import throttledMessage from "../../../utils/throttled-message";
import { history } from "../../../contexts/history";

const fields = [
  "trucks",
  "trucks[].id",
  "pickup_date",
  "price",
  "comment",
  "chargeable"
];

const labels = {
  "trucks[].id": "Select a Truck",
  pickup_date: "Pick Up Date",
  price: "Price, ₦",
  comment: "Your Comment"
};

const placeholders = {
  "trucks[].id": "Select Truck from the List",
  pickup_date: "dd/mm/yyyy",
  price: "Offer your price",
  comment: "Write Your Comment Here",
  chargeable: ""
};

const types = {
  "trucks[].id": "select",
  pickup_date: "date",
  price: "integer",
  comment: "text",
  chargeable: "integer"
};

const rules = {
  "trucks[].id": "required",
  pickup_date:
    "required|quote_pickup_date_range_exceed|quote_pickup_date_is_too_early",
  price: "required|between:8000,99999999",
  comment: `max:${SEND_QUOTE_COMMENT_LENGTH}|communication_guard`
};

const options = {
  "trucks[].id": []
};

const input = {};

const output = {
  pickup_date: v => v && moment(v).format("YYYY-MM-DD")
};

class SendQuoteForm extends BaseForm {
  @observable
  waitingLoadingOptions = false;

  @observable
  shipment = {};

  @observable
  availableTrucks = [];

  @observable
  isFormVisible = false;

  saveCallback = null;

  showCommodityMismatch = throttledMessage(
    "Commodity Weight/Volume and Truck Weight/Volume Mismatch. Please, add more Trucks.",
    "warning"
  );

  /**
   * * computed values
   */

  @computed
  get filteredAvailableTrucksForSingleDropDown() {
    return dropdownValue => {
      const selected = this.$("trucks")
        .values()
        .map(truck => truck.id)
        .filter(id => Boolean(id) && id !== dropdownValue.id);

      return this.availableTrucks.filter(
        truck => !selected.includes(truck.value)
      );
    };
  }

  @computed
  get canAddTruck() {
    return this.$("trucks").fields.size < LOG_TRUCK_MAX_TRUCKS;
  }

  @computed
  get commentRemainingChars() {
    const currentText = this.$("comment").value || "";

    if (currentText.length > SEND_QUOTE_COMMENT_LENGTH) {
      return 0;
    }

    return SEND_QUOTE_COMMENT_LENGTH - currentText.length;
  }

  @computed
  get isSelectedWeightMatchedToCommodityWeight() {
    const valueToCompare = this.isVolumeBased
      ? this.shipment.volume
      : this.shipment.weight;

    return valueToCompare <= this.selectedTrucksTotalWeight;
  }

  @computed
  get isChargeableWeightMatchedToTrucksWeight() {
    if (!this.shipment.is_shared_consignment) {
      return true;
    }

    const { chargeable } = this.values();

    return this.selectedTrucksTotalWeight >= chargeable;
  }

  @computed
  get selectedTrucksTotalWeight() {
    const values = this.values();

    const trucksIDs = values.trucks.map(truck => truck.id);

    const trucks = this.availableTrucksSource.filter(truck =>
      trucksIDs.includes(truck.id)
    );

    return trucks.reduce((current, truck) => {
      current += +truck[this.isVolumeBased ? "volume" : "weight"];

      return current;
    }, 0);
  }

  @computed
  get isVolumeBased() {
    const { volume } = this.shipment;

    return !!volume;
  }

  /**
   * * actions
   */

  @action
  addTruck() {
    this.$("trucks").add();
  }

  @action
  loadAvailableTrucks() {
    return SendQuoteService.getAvailableTrucks(this.shipment.id).then(
      trucks => {
        this.availableTrucksSource = trucks;
        this.availableTrucks = trucks.map(truck => ({
          value: truck.id,
          label: formatTruckLabel(truck)
        }));
      }
    );
  }

  @action
  async openForm({ shipment }) {
    this.shipment = shipment;
    this.isFormVisible = true;
    this.addTruck();
    this.updateChargeableField();
    await this.initForm();
  }

  @action
  closeForm() {
    this.isFormVisible = false;
    this.execHook("onClear");

    if (history.location.pathname.includes("send-quote")) {
      history.replace("/my-shipments/pending");
    }
    // this.reset();
    // this.clear();
  }

  @action
  async initForm() {
    this.waitingLoadingOptions = true;
    await this.loadAvailableTrucks();

    this.waitingLoadingOptions = false;
  }

  @action
  clearValues() {
    this.$("trucks").fields.clear();
    this.clear();
  }

  @action
  setSaveCallback(callback) {
    this.saveCallback = callback;
  }

  @action
  updateChargeableField() {
    const { is_shared_consignment, volume } = this.shipment;
    const isVolumeBasedField = !!volume;
    const chargeableField = this.$("chargeable");
    const rulesForChargeableField = [];

    if (is_shared_consignment) {
      rulesForChargeableField.push("required");
      if (isVolumeBasedField) {
        rulesForChargeableField.push("chargeable_volume");
        rulesForChargeableField.push("chargeable_volume_to_total_volume");
      } else {
        rulesForChargeableField.push("chargeable_weight");
        rulesForChargeableField.push("chargeable_weight_to_total_weight");
      }
    }

    const labelForChargeableField = isVolumeBasedField
      ? "Chargeable Volume, lt"
      : "Chargeable Weight, tons";

    const placeholderForChargeableField = isVolumeBasedField
      ? "Enter Volume"
      : "Enter Weight";

    chargeableField.hidden = !is_shared_consignment;
    chargeableField.set("rules", rulesForChargeableField.join("|"));
    chargeableField.set("label", labelForChargeableField);
    chargeableField.set("placeholder", placeholderForChargeableField);
  }

  hooks() {
    return {
      onClear: () => {
        setTimeout(() => {
          this.clearValues();
        }, 300);
      },
      onSuccess: () => {
        const { is_shared_consignment } = this.shipment;

        if (
          !is_shared_consignment &&
          !this.isSelectedWeightMatchedToCommodityWeight
        ) {
          this.showCommodityMismatch();

          return Promise.reject();
        }

        if (
          is_shared_consignment &&
          !this.isChargeableWeightMatchedToTrucksWeight
        ) {
          this.showCommodityMismatch();

          return Promise.reject();
        }

        const values = this.values();

        const trucks = values.trucks.map(truck => truck.id);

        return SendQuoteService.sendQuote(this.shipment.id, {
          ...{ ...values, pickup_date_time: values.pickup_date },
          trucks
        })
          .then(() => {
            this.closeForm();
            if (typeof this.saveCallback === "function") {
              this.saveCallback();
            }
          })
          .catch(error => {
            console.dir(error);
            // handle validation errors
            if (error.response && error.response.status === 422) {
              const messages = error.response.data.errors;

              Object.entries(messages).forEach(entry => {
                const [fieldName] = entry;
                let [, errorMessage] = entry;

                if (fieldName.includes("truck")) {
                  if (
                    Array.isArray(errorMessage) &&
                    errorMessage.includes("validation.enough_capacity")
                  ) {
                    errorMessage =
                      "Commodity Weight/Volume and Truck Weight/Volume Mismatch. Please, add more Trucks.";
                  }

                  this.$(`trucks[0].id`).invalidate(errorMessage);
                } else if (this.has(fieldName)) {
                  this.$(fieldName).invalidate(errorMessage);
                } else if (fieldName === "pickup_date_time") {
                  this.$("pickup_date").invalidate(errorMessage);
                }
              });
            } else {
              message.warning(error.message);
            }
          });
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnClear: false,
      validateOnClear: false,
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }

  // eslint-disable-next-line class-methods-use-this
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");
          const newMessages = {
            ...messages,
            "max.comment":
              "You have exceeded the maximum number of characters allowed for this field.",
            "between.price": "This field must be between ₦8.000-99.999.999."
          };

          validator.setMessages("en", newMessages);

          validator.register(
            "chargeable_weight",
            value => {
              return value > 0 && value <= 999999;
            },
            "This field must be between 1 and 6 characters."
          );
          validator.register(
            "chargeable_volume",
            value => {
              return value > 0 && value <= 99999999;
            },
            "This field must be between 1 and 8 characters."
          );
          validator.register(
            "chargeable_weight_to_total_weight",
            value => {
              const { volume, weight } = this.shipment;
              const valueToCompareWith = this.isVolumeBased ? volume : weight;

              return value <= valueToCompareWith;
            },
            "Chargeable Weight must be less or equal to Total Commodity Weight."
          );
          validator.register(
            "chargeable_volume_to_total_volume",
            value => {
              const { volume, weight } = this.shipment;
              const valueToCompareWith = this.isVolumeBased ? volume : weight;

              return value <= valueToCompareWith;
            },
            "Chargeable Volume must be less or equal to Total Commodity Volume."
          );

          validator.register(
            "quote_pickup_date_range_exceed",
            value => {
              const selectedPickUpDate = moment(value).startOf("day");

              const { pickup_date_start, pickup_date_end } = this.shipment;
              const startDate = moment(pickup_date_start);
              const endDate = moment(pickup_date_end);
              const endTimeWithOffset = endDate.clone().workingAdd(3, "d");

              return (
                selectedPickUpDate.isAfter(startDate.startOf("day")) &&
                selectedPickUpDate.isSameOrBefore(endTimeWithOffset.endOf("d"))
              );
            },
            "Pick Up Date cannot be later than 3 working days after Pick Up Date requested by Shipper."
          );
          validator.register(
            "quote_pickup_date_is_too_early",
            value => {
              const currentTime = moment();
              const selectedPickUpDate = moment(value).set({
                hours: currentTime.hours(),
                minutes: currentTime.minutes()
              });
              // const startOfCurrentWorkingDay = moment()
              //   .startOf("day")
              //   .add(9, "h");
              const nextWorkingDay = moment()
                .startOf("day")
                .add(1, "d")
                .add(9, "h");

              // const selectedDateIsBeforeStartOfCurrentWorkingDay = selectedPickUpDate.isSameOrBefore(
              //   startOfCurrentWorkingDay
              // );
              //
              // user selects today before
              if (selectedPickUpDate.isSame(currentTime, "day")) {
                return false;
              }

              // user selects tomorrow after today's 9am and before tomorrow's 9am
              if (
                selectedPickUpDate.isSameOrAfter(currentTime) &&
                selectedPickUpDate.isSameOrAfter(nextWorkingDay) &&
                selectedPickUpDate.isSame(nextWorkingDay, "day")
              ) {
                return false;
              }

              return selectedPickUpDate.isAfter(currentTime);
            },
            "Pick Up Date cannot be earlier than 8 working hours."
          );
        }
      })
    };
  }
}

export default new SendQuoteForm(
  {
    fields,
    labels,
    types,
    rules,
    input,
    output,
    placeholders,
    options
  },
  { hasNestedFields: true }
);
