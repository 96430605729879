import React from "react";
import { Helmet as HelmetBase } from "react-helmet";
import { observer } from "mobx-react";
import { APP_NAME } from "../config/constants";

const Helmet = observer(({ title, description = APP_NAME }) => (
  <HelmetBase titleTemplate={`%s - ${APP_NAME}`} defaultTitle={APP_NAME}>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description} />
  </HelmetBase>
));

export default Helmet;
