/* eslint-disable class-methods-use-this */
import { action } from "mobx";
import validatorjs from "validatorjs";
import { message } from "antd";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import Field from "../field";
import BaseForm from "../base-form";
import Auth from "../../../services/api/auth";
import { history } from "../../../contexts/history";
import { passwordAnalyst } from "../../../utils/password-analyst";

class ChangePasswordForm extends BaseForm {
  setup() {
    return {
      fields: [
        {
          name: "old_password",
          label: "Old Password",
          rules: "required|string|between:8,25"
        },
        {
          name: "new_password",
          label: "New Password",
          rules: "required|string|strong_password|between:8,25",
          hooks: {
            onChange: field => {
              passwordAnalyst(field);
            }
          }
        },
        {
          name: "new_password_confirmation",
          label: "Re-enter Password",
          rules: "required|string|same:new_password|between:8,25"
        }
      ]
    };
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");
          const newMessages = {
            ...messages,
            "same.new_password_confirmation":
              "Passwords are not identical, re-enter correct password."
          };

          validator.setMessages("en", newMessages);
        }
      })
    };
  }

  @action
  resetForm() {
    this.reset();
    history.push("/");
  }

  @action
  save() {
    const values = {
      ...this.values()
    };

    return Auth.changePassword(values)
      .then(({ data }) => {
        this.resetForm();
        setTimeout(() => {
          message.success("New Password will be effective at your next Login.");
        }, 2000);

        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          const {
            data: { errors }
          } = e.response;

          Object.entries(errors).forEach(entry => {
            const [fieldName, errorMessage] = entry;

            if (this.has(fieldName)) {
              this.$(fieldName).invalidate(errorMessage);
            }

            message.error(errorMessage);
          });
        }
      });
  }

  makeField(props) {
    return new Field(props);
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.save();
      }
    };
  }
}

export default new ChangePasswordForm();
