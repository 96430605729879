/* eslint-disable class-methods-use-this,import/no-cycle */
/* eslint-disable no-useless-escape */
import { message } from "antd";
import cleaner from "deep-cleaner";
import validatorjs from "validatorjs";
import FileDownload from "js-file-download";
import { computed, decorate, observable, action, reaction } from "mobx";
import { passwordAnalyst } from "../../../utils/password-analyst";
import { Form } from "../../../libs/mobx-react-form";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import { history } from "../../../contexts/history";
import SignUpService from "../../../services/api/signup";
import OptionsService from "../../../services/api/options";
import AuthStore, {
  USER_STATUS_CONTRACT_REJECTED,
  USER_STATUS_PENDING,
  USER_STATUS_REJECTED
} from "../../auth";
import { setUpAuthHeader } from "../../../services/common";
import Field from "../field";
import {
  SUPPORTED_FILE_TYPES,
  MAX_FILE_SIZE,
  RECAPTCHA_PERSISTENT_KEY,
  SUPPORTED_ROLES
} from "../../../config/constants";
import { checkErrors } from "../../../utils/check-errors";
import signupValidator from "./signup-validator";
import { clearStorage } from "../../../utils/storage";

class SignUpForm extends Form {
  persisted_key = "__persisted_form__";

  DEFAULT_COUNTRY_ISO_CODE = "NG";

  // only two additional numbers allowed to add
  additionalPhoneNumberMaxLength = 2;

  resendingVerificationCode = false;

  registrationCompleted = false;

  currentStep = {};

  initialValues = {};

  /**
   * this field is required to load and persist the state of dial code numbers
   * @type {{}}
   */
  signupExtraState = {};

  additionalPhoneNumbers = [];

  autoSaveDisposer;

  // geo options
  addressesLoaded = false;

  countries = [];

  states = [];

  cities = [];

  areas = [];

  dialCodes = [];

  isEmailVerified = false;

  isDeclineContractComment = false;

  constructor() {
    super();
    // this reaction is needed to load required dropdown options for the particular step
    reaction(
      () => this.currentStep.key,
      key => {
        if (key === "registration") {
          if (this.addressesLoaded) {
            return;
          }

          this.addressesLoaded = true;
          OptionsService.getCountries().then(countries => {
            this.countries = countries;
            import("../../../data/phones").then(phones => {
              this.dialCodes = phones.default || [];
              this.setDialCodeByCountry();
            });
            if (
              !this.$("signup.country_id").value ||
              this.$("signup.country_id").value === "value"
            ) {
              this.$("signup.country_id").set("value", this.defaultCountry.id);
            }

            this.setAddressDependsFieldsValidationRules(false);

            this.loadAddressOptionsAfterInitialCountryLoaded();
          });
        }

        if (key === "bank_account_details") {
          OptionsService.getBankNames().then(bankOptions => {
            this.$("bank_account_details.bank_name_id").set(
              "extra",
              bankOptions
            );
          });
        }

        if (key === "prequalification_checklist") {
          Promise.all([OptionsService.getUserIdentificationTypes()]).then(
            result => {
              const [userIdentityTypes] = result;

              this.$(
                "prequalification_checklist.user_identification_type_id"
              ).set("extra", userIdentityTypes);
            }
          );
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  loadAddressOptionsAfterInitialCountryLoaded() {
    OptionsService.getStates(this.$("signup.country_id").value).then(states => {
      this.states = states;
    });
    OptionsService.getCities(this.$("signup.state_id").value).then(cities => {
      this.cities = cities;
    });
    OptionsService.getAreas(this.$("signup.city_id").value).then(areas => {
      this.areas = areas;
    });
  }

  options() {
    return {
      validateOnInit: false,
      validateOnReset: false,
      showErrorsOnReset: false,
      validationDebounceWait: 100
    };
  }

  /**
   * set up the validator plugin
   * @returns {{dvr: *}}
   */
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: signupValidator.bind(this)
      })
    };
  }

  /**
   * set up the fields
   * @returns {{fields: *[]}}
   */
  setup() {
    return {
      fields: [
        {
          name: "signup",
          fields: [
            {
              name: "id",
              type: "number",
              input: v => (v !== 0 ? v : null)
            },
            {
              name: "recaptcha",
              type: "text"
            },
            {
              name: "email",
              label: "Email Address",
              rules: "required_without:signup.id|email_ext|string|max:255"
            },
            {
              name: "password",
              label: "Password",
              rules:
                "required_without:signup.id|string|strong_password|between:8,25",
              hooks: {
                onChange: field => {
                  passwordAnalyst(field);
                }
              }
            },
            {
              name: "password_confirmation",
              label: "Re-enter Password",
              rules:
                "required_without:signup.id|string|confirm_password|between:8,25"
            },
            {
              name: "terms_of_service",
              label: "terms of service",
              rules: "required|accepted",
              type: "checkbox",
              extra: {
                hideLabel: true
              },
              hooks: {
                onChange: field => {
                  field.resetValidation();
                }
              }
            },
            {
              name: "country_id",
              type: "select",
              placeholder: "Select Country",
              rules: "required",
              input: value => value || undefined,
              hooks: {
                onChange: async field => {
                  // apply country dial code for phone numbers
                  this.setDialCodeByCountry();
                  this.setAddressDependsFieldsValidationRules();
                  // load the options for dropdown only for default country
                  if (this.isDefaultCountry) {
                    this.states = await OptionsService.getStatesByCountry(
                      field.value
                    );
                  }

                  this.resetAddressOptionsByParent();
                }
              }
            },
            {
              name: "street_address",
              type: "text",
              label: "Street Address",
              placeholder: "Street Name, Avenue, Boulevard etc",
              rules: "required|max:255"
            },
            {
              name: "house_number",
              type: "text",
              placeholder: "House Number, Apartment, Suite etc",
              rules: "required|max:255"
            },
            {
              name: "area_id",
              type: "select",
              label: "Area",
              placeholder: "Select Area",
              input: value => value || undefined,
              hooks: {
                onChange: async field => {
                  if (this.isDefaultCountry) {
                    this.$("signup.postal_code").set(
                      "value",
                      this.getPostalCodeByAreaId(field.value)
                    );
                  }
                }
              }
            },
            {
              name: "city_id",
              type: "select",
              placeholder: "Select City",
              label: "City",
              input: value => value || undefined,
              hooks: {
                onChange: async field => {
                  if (this.isDefaultCountry) {
                    this.areas = await OptionsService.getAreasByCity(
                      field.value
                    );
                    this.resetAddressOptionsByParent("city_id");
                  }
                }
              }
            },
            {
              name: "state_id",
              type: "select",
              placeholder: "Select State",
              label: "State",
              input: value => value || undefined,
              hooks: {
                onChange: async field => {
                  if (this.isDefaultCountry) {
                    this.cities = await OptionsService.getCitiesByState(
                      field.value
                    );
                    this.resetAddressOptionsByParent("state_id");
                  }
                }
              }
            },
            {
              name: "state_manual",
              type: "text",
              placeholder: "State",
              label: "State"
            },
            {
              name: "city_manual",
              type: "text",
              placeholder: "City",
              label: "City"
            },
            {
              name: "area_manual",
              type: "text",
              placeholder: "Area",
              label: "Area"
            },
            {
              name: "postal_code",
              type: "text",
              label: "Postal Code (optional)",
              rules: "max:255"
            },
            {
              name: "company_type",
              type: "radio",
              // if all three roles are supported by the app the set agent as default value
              // in other case - first available role is set by default
              value:
                SUPPORTED_ROLES.length === 3 ? "agent" : SUPPORTED_ROLES[0],
              extra: {
                hideLabel: true
              },
              hooks: {
                onChange: field => {
                  // * this delay is required to prevent showing validation errors which triggered by onBlur event
                  setTimeout(() => {
                    const { value } = field;
                    const id = field.container().$("id").value;
                    const isAuthorizedSignup = !!id;
                    const persistedEmail = field.container().$("email").value;

                    field.container().reset();
                    field
                      .container()
                      .$("account_type")
                      .set("value", "individual");
                    field.set("value", value);
                    if (isAuthorizedSignup) {
                      field
                        .container()
                        .$("email")
                        .set("value", persistedEmail);
                      field
                        .container()
                        .$("id")
                        .set("value", id);
                    }

                    this.setDefaultCountryData();
                    this.setAddressDependsFieldsValidationRules();
                    this.togglePasswordMandatoryState(true);
                  }, 110);
                }
              }
            },
            {
              name: "account_type",
              type: "radio",
              value: "individual",
              rules: "required_unless:signup.company_type,truck_owner"
            },
            {
              name: "company_name",
              label: "Company Name",
              type: "text",
              guard: "specials",
              rules:
                "required_if:signup.account_type,company|required_if:signup.company_type,truck_owner|alphanumeric_ext|between:5,50"
            },
            {
              name: "company_rc",
              type: "text",
              guard: "specials",
              rules: "alpha_num|max:8"
            },
            {
              name: "title",
              type: "select",
              rules: "required",
              placeholder: "Mr.",
              input: value => value || undefined
            },
            {
              name: "first_name",
              type: "text",
              placeholder: "Contact Person",
              rules: "required|string|between:1,30|name",
              guard: "name|numbers"
            },
            {
              name: "middle_name",
              type: "text",
              rules: "string|between:1,30|name",
              guard: "name|numbers"
            },
            {
              name: "last_name",
              type: "text",
              rules: "required|string|between:1,30|name",
              guard: "name|numbers"
            },
            {
              name: "phone_number",
              type: "text",
              rules: "required|string|between:8,10|phone_number",
              guard: "letters|specials"
              // placeholder: "(+___) ___ ___ ___"
            },
            {
              name: "phone_number_dial_code",
              type: "select",
              default: undefined,
              initial: undefined,
              input: value => value || undefined,
              hooks: {
                onChange: field => {
                  field.set("extra", { manuallyChanged: true });
                }
              }
            },
            {
              name: "first_additional_phone_number",
              type: "phone",
              rules: "string|between:8,10|phone_number",
              guard: "letters|specials"
              // placeholder: "(+___) ___ ___ ___"
            },
            {
              name: "first_additional_phone_number_dial_code",
              type: "select",
              input: value => value || undefined,
              hooks: {
                onChange: field => {
                  field.set("extra", { manuallyChanged: true });
                }
              }
            },
            {
              name: "second_additional_phone_number",
              type: "phone",
              rules: "string|between:8,10|phone_number",
              guard: "letters|specials"
              // placeholder: "(+___) ___ ___ ___"
            },
            {
              name: "second_additional_phone_number_dial_code",
              type: "select",
              default: undefined,
              initial: undefined,
              input: value => value || undefined,
              hooks: {
                onChange: field => {
                  field.set("extra", { manuallyChanged: true });
                }
              }
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              const values = fieldset.values();
              const isAuthorizedSignup = !!values.id;

              values.recaptcha = localStorage.getItem(RECAPTCHA_PERSISTENT_KEY);

              this.transformPhoneDataForRequest(values);

              return SignUpService.signUp(
                this.companyType,
                cleaner(values),
                isAuthorizedSignup
              )
                .then(({ token, user }) => {
                  const { profile, registration_request = {}, role } = user;

                  AuthStore.user.role = role;

                  if (!isAuthorizedSignup && token) {
                    // this flag needed in AuthStore reaction to prevent any messages while setting up user's entity
                    setTimeout(() => {
                      user.disable_signup_notifications = true;
                      AuthStore.setUser(user);
                    }, 1000);

                    setUpAuthHeader({ accessToken: token });
                    window.localStorage.setItem("auth-token", token);
                  }

                  // if shipper previously confirmed his email (back fun-ty) then mark signup as completed
                  if (
                    this.isShipper &&
                    registration_request.email_verification
                  ) {
                    this.registrationCompleted = true;

                    return;
                  }

                  fieldset.set("value", {
                    ...profile,
                    ...this.preparePhoneData(profile)
                  });
                  fieldset.$("email").set("disabled", true);
                  this.togglePasswordMandatoryState();
                  this.setStep(this.nextStep);
                })
                .catch(this.handleSubmitError(fieldset));
            },
            onError() {
              checkErrors(this.errors());
            }
          }
        },
        {
          name: "email_verification",
          fields: [
            {
              name: "code",
              type: "text",
              rules: "required|max:6",
              label: "Code from email",
              guard: "specials",
              hooks: {
                onChange: () => {
                  this.validate();
                }
              }
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              // eslint-disable-next-line consistent-return
              return SignUpService.verifyEmail(fieldset.values())
                .then(() => {
                  if (this.isShipper) {
                    this.registrationCompleted = true;

                    return;
                  }

                  this.isEmailVerified = true;
                  this.currentStep.index = 0;
                  this.setStep(this.nextStep);
                  fieldset.$("code").reset();

                  message.success(
                    "Congratulations! You have successfully signed up."
                  );
                })
                .catch(this.handleSubmitError(fieldset));
            },
            onError() {
              checkErrors(this.errors());
            }
          }
        },
        {
          name: "prequalification_checklist",
          fields: [
            {
              name: "company_rc",
              type: "text",
              guard: "specials",
              rules:
                "required_if:signup.company_type,truck_owner|required_if:signup.account_type,company|string|max:8",
              label: "Company RC Number"
            },
            {
              name: "number_of_trucks",
              type: "integer",
              rules:
                "required_if:signup.company_type,truck_owner|integer|max:1000000",
              label: "Number of trucks to be used on the VH Platform",
              guard: "letters"
            },
            {
              name: "user_identification_type_id",
              type: "select",
              rules: "required_unless:signup.company_type,truck_owner",
              label: "User Identification Type",
              placeholder: "Select Verification Document",
              extra: []
            },
            {
              name: "picture_identity",
              type: "text",
              rules: `required_if:signup.company_type,agent|required_if:signup.company_type,company|file_type:${SUPPORTED_FILE_TYPES.join(
                ","
              )}|file_size:${MAX_FILE_SIZE.SIGNUP}`,
              label: "Attach Document Copy",
              placeholder: "Scanned Copy of ID",
              hooks: {
                onChange: field => {
                  const file = field.value;

                  this.$("prequalification_checklist")
                    .$("picture_identity_name")
                    .set("value", file.name);
                }
              }
            },
            {
              name: "picture_identity_name",
              type: "hidden"
            },
            {
              name: "all_trucks_have_all_documents",
              type: "checkbox",
              rules:
                "required_if:signup.company_type,truck_owner|all_docs_truck_accepted",
              hooks: {
                onChange: field => {
                  field.validate();
                }
              }
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              const formData = new FormData();

              Object.entries(fieldset.values()).forEach(entry => {
                const [fieldName, value] = entry;
                const isBoolean = typeof value === "boolean";

                formData.append(fieldName, isBoolean ? +value : value);
              });

              return SignUpService.submitPreQualificationChecklist(
                this.companyType,
                formData
              )
                .then(() => this.setStep(this.nextStep))
                .catch(this.handleSubmitError(fieldset));
            },
            onError() {
              checkErrors(this.errors());
            }
          }
        },
        {
          name: "bank_account_details",
          fields: [
            {
              name: "bank_name_id",
              type: "select",
              input: value => value || undefined,
              rules: "required",
              label: "Bank Name",
              placeholder: "Select your Bank",
              extra: []
            },
            {
              name: "nuban_bank_account_number",
              type: "text",
              rules: "required|numeric|regex:/^[0-9]{10}$/",
              guard: "specials|letters|spaces",
              label: "NUBAN Bank Account Number"
            },
            {
              name: "beneficiary_name",
              type: "text",
              rules: "required|max:255",
              // guard: "specials",
              label: "Beneficiary Name"
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              // eslint-disable-next-line consistent-return
              return SignUpService.submitBankAccountDetails(
                this.companyType,
                fieldset.values()
              )
                .then(() => this.setStep(this.nextStep))
                .catch(this.handleSubmitError(fieldset));
            },
            onError() {
              checkErrors(this.errors());
            }
          }
        },
        {
          name: "contract",
          fields: [
            {
              name: "terms_of_contract",
              label: "terms of contract",
              rules: "required|accepted",
              type: "checkbox",
              extra: {
                hideLabel: true
              },
              hooks: {
                onChange: () => {
                  this.validate();
                }
              }
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              return SignUpService.acceptContract(
                this.companyType,
                fieldset.values()
              )
                .then(() => {
                  message.info(
                    "Your registration request is submitted and will be reviewed by VH Trucking team."
                  );
                  this.registrationCompleted = true;
                  localStorage.removeItem(RECAPTCHA_PERSISTENT_KEY);
                  AuthStore.setToken();
                })
                .catch(this.handleSubmitError(fieldset));
            }
          }
        },
        {
          name: "comment",
          fields: [
            {
              name: "comment",
              label: "Comment",
              rules: "max:1000",
              type: "text",
              placeholder: "You May Specify Reason for Contract Rejection"
            }
          ],
          hooks: {
            onSuccess: fieldset => {
              // eslint-disable-next-line consistent-return
              return SignUpService.addRejectContractMessage(
                this.companyType,
                fieldset.values()
              ).then(() => {
                history.replace("/");
                this.fullReset();
                this.toggleIsDeclineContractComment();
                clearStorage();
              });
            }
          }
        }
      ]
    };
  }

  transformPhoneDataForRequest(values) {
    const phoneFields = [
      "phone_number",
      "first_additional_phone_number",
      "second_additional_phone_number"
    ];

    phoneFields.forEach(fieldName => {
      if (values[`${fieldName}_dial_code`] && values[fieldName]) {
        const dialCode = values[`${fieldName}_dial_code`];
        const rawPhoneNumber = values[fieldName];

        values[fieldName] = `${dialCode}-${rawPhoneNumber}`;
      }
    });
  }

  setAddressDependsFieldsValidationRules(clearValues = true) {
    const shouldBeRequiredIfDefaultCountry = ["state_id", "city_id", "area_id"];
    const shouldBeRequiredIfCustomCountry = [
      "state_manual",
      "city_manual",
      "area_manual"
    ];

    shouldBeRequiredIfCustomCountry.forEach(fieldName => {
      this.$("signup")
        .$(fieldName)
        .set("rules", this.isDefaultCountry ? "" : "required|max:255");
    });
    shouldBeRequiredIfDefaultCountry.forEach(fieldName => {
      this.$("signup")
        .$(fieldName)
        .set("rules", this.isDefaultCountry ? "required" : "");
    });
    if (clearValues) {
      [
        ...shouldBeRequiredIfDefaultCountry,
        ...shouldBeRequiredIfCustomCountry
      ].forEach(fieldName => {
        this.$("signup")
          .$(fieldName)
          .set("value", null);
      });
    }
  }

  setDialCodeByCountry() {
    const dialCode = this.getCountryDialCodeById();

    if (dialCode) {
      const codesFields = [
        "phone_number_dial_code",
        "first_additional_phone_number_dial_code",
        "second_additional_phone_number_dial_code"
      ];

      codesFields.forEach(fieldName => {
        const field = this.$(`signup.${fieldName}`);

        if (field.extra && field.extra.manuallyChanged) {
          return;
        }

        field.set("value", dialCode);
      });
    }
  }

  resetAddressOptionsByParent(parentName = "country_id") {
    const addressFields = ["state_id", "city_id", "area_id", "postal_code"];
    let fieldsToReset = [];

    switch (parentName) {
      case "country_id":
        fieldsToReset = addressFields.slice();
        break;
      case "state_id":
        fieldsToReset = addressFields.slice(1);
        break;
      case "city_id":
        fieldsToReset = addressFields.slice(2);
        break;
      case "area_id":
        fieldsToReset = addressFields.slice(3);
        break;
      default:
        fieldsToReset = addressFields;
    }

    fieldsToReset.forEach(fieldName => {
      this.$("signup")
        .$(fieldName)
        .set("value", null);
    });
  }

  /**
   * set up the hooks (events)
   * @returns {{onInit: onInit}}
   */
  hooks() {
    return {
      onInit: () => {
        // this.autoSaveDisposer = reaction(
        //   () => this.validatedValues,
        //   this.persist.bind(this),
        //   {
        //     name: "persist-signup-form-data",
        //     delay: 500
        //   }
        // );
        // reaction(() => this.currentStep, this.persist.bind(this));
      },
      onReset: () => {
        this.setDefaultCountryData();
      }
    };
  }

  fullReset() {
    AuthStore.reset();

    this.reset();
    this.$("signup.email").set("disabled", false);
    this.setDefaultCountryData();
    this.initialValues = {};
    this.signupExtraState = {};
    this.additionalPhoneNumbers = [];
    this.isEmailVerified = false;
    this.currentStep = this.firstStep;
    this.setIsRegistrationCompleted(false);
  }

  setDefaultCountryData() {
    this.$("signup.country_id").set("value", this.defaultCountry.id);
    this.setDialCodeByCountry();
  }

  get companyType() {
    return this.values().signup.company_type;
  }

  /**
   * get if current selected company type is individual
   * @returns {boolean}
   */
  get isPersonal() {
    if (this.isTruckOwner) {
      return true;
    }

    return this.values().signup.account_type === "individual";
  }

  /**
   * get if current selected company type is individual
   * @returns {boolean}
   */
  get isCompany() {
    if (this.isTruckOwner) {
      return false;
    }

    return this.values().signup.account_type === "company";
  }

  /**
   * get if current selected account type is shipper
   * @returns {boolean}
   */
  get isShipper() {
    return this.values().signup.company_type === "shipper";
  }

  /**
   * get if current selected account type is agent
   * @returns {boolean}
   */
  get isAgent() {
    return this.values().signup.company_type === "agent";
  }

  /**
   * get if current selected account type is truck owner
   * @returns {boolean}
   */
  get isTruckOwner() {
    return this.values().signup.company_type === "truck_owner";
  }

  /**
   * a computed value of all available steps depends on selected account type
   * @returns {*[]}
   */
  get steps() {
    let steps = [];

    if (this.isShipper) {
      steps = [
        {
          label: "Registration",
          key: "registration"
        },
        {
          label: "Email Verification",
          key: "email_verification"
        }
      ];
    } else {
      steps = [
        {
          label: "Registration",
          key: "registration"
        },
        {
          label: "Email Verification",
          key: "email_verification"
        },
        {
          label: "Pre-Qualification Checklist",
          key: "prequalification_checklist"
        },
        {
          label: "Bank Account Details",
          key: "bank_account_details"
        },
        {
          label: "Contract",
          key: "contract"
        }
      ];
    }

    if (this.isEmailVerified) {
      steps = steps.filter(step => step.key !== "email_verification");
    }

    return steps.map((step, index) => ({ ...step, index }));
  }

  get stepKeys() {
    return this.steps.map(step => step.key);
  }

  get firstStep() {
    return this.steps[0];
  }

  /**
   * a getter to get next available step
   * @returns {NodePath | * | number | never | bigint | T | T}
   */
  get nextStep() {
    const currentIndex = this.currentStep.index;
    const nextStep = this.steps.find(step => step.index === currentIndex + 1);

    if (!nextStep) {
      return this.currentStep;
    }

    return nextStep;
  }

  /**
   * a getter to get previous available step
   * @returns {NodePath | * | number | never | bigint | T | T}
   */
  get prevStep() {
    const currentIndex = this.currentStep.index;
    const prevStep = this.steps.find(step => step.index === currentIndex - 1);

    if (!prevStep) {
      return this.currentStep;
    }

    return prevStep;
  }

  get additionalNumbersLength() {
    return this.additionalPhoneNumbers.length;
  }

  get canAddNewAdditionalNumber() {
    return this.additionalNumbersLength < this.additionalPhoneNumberMaxLength;
  }

  /**
   * an action to set current step
   * @param step
   */
  setStep(step) {
    if (!step) {
      return;
    }

    this.currentStep = step;
  }

  goBack() {
    this.setStep(this.prevStep);
  }

  addComment() {
    this.isDeclineContractComment = true;
  }

  /**
   * prepare dial codes and phone numbers to be pushed into appropriate fields from single phone number string
   * @param rawData
   */
  preparePhoneData(rawData) {
    const phoneFields = [
      "phone_number",
      "first_additional_phone_number",
      "second_additional_phone_number"
    ];
    const phonesData = {};

    phoneFields.forEach(fieldName => {
      if (fieldName in rawData && rawData[fieldName]) {
        const phone = rawData[fieldName];
        const [dialCode, ...phoneParts] = phone.split("-");

        if (dialCode.includes("+")) {
          phonesData[fieldName] = phoneParts.join();
          phonesData[`${fieldName}_dial_code`] = dialCode;
        } else {
          phonesData[fieldName] = phone;
        }
      }
    });

    return phonesData;
  }

  async loadState(state = null) {
    if (state) {
      const {
        registration_request: { status }
      } = state;

      const isContractRejected = status === USER_STATUS_CONTRACT_REJECTED;

      const isRegistrationRejected = status === USER_STATUS_REJECTED;

      // if contract rejected user's status still inactive,
      // but the user have to sign contract anyway so we have to present last step to the user
      if (
        !isContractRejected &&
        !isRegistrationRejected &&
        state.registration_request.status === USER_STATUS_PENDING
      ) {
        this.setIsRegistrationCompleted(true);

        return;
      }

      this.initStateFromRequest(state);
    } else {
      // await this.initStateFromStorage();
      // this.update(this.initialValues);
      this.currentStep = {
        index: 0,
        label: "Registration",
        key: "registration"
      };
    }

    if (this.initialValues.signup) {
      const additionalPhoneNumberFields = Object.keys(this.initialValues.signup)
        .filter(key => key.includes("_additional_phone_number"))
        .filter(key => !key.includes("_dial_code"));

      const hasAdditionalPhoneNumbers = !!additionalPhoneNumberFields.filter(
        Boolean
      ).length;

      if (hasAdditionalPhoneNumbers) {
        additionalPhoneNumberFields.forEach(() =>
          this.addAdditionalPhoneNumber()
        );
      }

      this.$("signup").set("extra", this.signupExtraState);
    }

    this.setAddressDependsFieldsValidationRules(false);

    this.validate();
  }

  async initStateFromStorage() {
    const dataFromStorage = localStorage.getItem(this.persisted_key) || "{}";

    const state = JSON.parse(dataFromStorage);

    this.initialValues = state.form_data || {};
    this.signupExtraState = state.signupExtraState || {};

    if (this.initialValues.signup) {
      this.initialValues.signup = {
        ...this.initialValues.signup,
        ...this.preparePhoneData(this.initialValues.signup)
      };
    }

    this.currentStep =
      state.step && state.step.key
        ? state.step
        : {
            index: 0,
            label: "Registration",
            key: "registration"
          };
  }

  initStateFromRequest(state) {
    // set company type to know how many steps in the sign up flow
    this.$("signup.company_type").set("value", state.role);
    this.$("signup.email").set("disabled", true);
    this.isEmailVerified = state.registration_request.email_verification;

    // if registration is rejected then move to 1st step, else them move to last filled step
    // if this is an initial sign up after reapply or rejection we should show first step
    if (state.shouldShowFirstStepAfterReapply === true) {
      this.setStep(this.firstStep);
    } else {
      let isCurrentStepSet = false;

      this.steps.forEach(step => {
        // it means that the user is already signed up
        if (step.key === "registration") {
          return;
        }

        // if registration step is falsy or current step is set during prev. iteration then skip iteration
        if (state.registration_request[step.key] || isCurrentStepSet) {
          return;
        }

        isCurrentStepSet = true;
        this.setStep(step);
      });
    }

    const { profile } = state;

    this.$("signup").set("value", {
      id: state.id,
      email: state.email,
      ...profile,
      ...this.preparePhoneData(state.profile)
    });
    this.$("prequalification_checklist").set("value", {
      number_of_trucks: profile.number_of_trucks,
      company_rc: profile.company_rc,
      user_identification_type_id: profile.user_identification_type_id
    });
    this.$("bank_account_details").set("value", {
      bank_name_id: profile.bank_name_id,
      nuban_bank_account_number: profile.nuban_bank_account_number,
      beneficiary_name: profile.beneficiary_name
    });
  }

  persist() {
    const values = cleaner({ ...this.values() });

    const signupExtraState = cleaner(this.$("signup").get("extra"));

    const dataToPersist = {
      step: this.isRegistrationCompleted ? {} : this.currentStep,
      form_data: values,
      signupExtraState
    };

    try {
      localStorage.setItem(this.persisted_key, JSON.stringify(dataToPersist));
    } catch (e) {
      if (
        ["QuotaExceededError", "NS_ERROR_DOM_QUOTA_REACHED"].includes(e.name)
      ) {
        console.info("The file you tried to upload is too large.");
      }
    }
  }

  addAdditionalPhoneNumber() {
    if (!this.canAddNewAdditionalNumber) {
      return;
    }

    const currentNumbersLength = this.additionalNumbersLength;
    const fieldNameToPush = currentNumbersLength === 0 ? "first" : "second";

    this.additionalPhoneNumbers.push(
      `${fieldNameToPush}_additional_phone_number`
    );

    const dialCode = this.getCountryDialCodeById();

    if (!dialCode) {
      return;
    }

    const hasDialCodeField = this.$("signup").has(
      `${fieldNameToPush}_additional_phone_number_dial_code`
    );

    if (hasDialCodeField) {
      this.$(`signup.${fieldNameToPush}_additional_phone_number_dial_code`).set(
        "value",
        this.getCountryDialCodeById()
      );
    }
  }

  resendVerificationCode() {
    this.resendingVerificationCode = true;
    SignUpService.resendVerification()
      .then(() => {
        message.success("A new verification code has been sent to your email.");
      })
      .finally(() => {
        this.resendingVerificationCode = false;
      });
  }

  setCaptchaToken(token) {
    // this.$("signup.recaptcha").set("value", token);
    localStorage.setItem(RECAPTCHA_PERSISTENT_KEY, token);
  }

  setIsRegistrationCompleted(isCompleted) {
    this.registrationCompleted = isCompleted;
  }

  get isRegistrationCompleted() {
    return this.registrationCompleted;
  }

  getCountryDialCodeById() {
    const countryId = this.$("signup.country_id").value;
    const country = this.countries.find(entry => entry.id === countryId);

    if (!country) {
      return undefined;
    }

    const dialCodeEntry = this.dialCodes.find(entry =>
      entry.codes.includes(country.iso_code)
    );

    if (!dialCodeEntry) {
      return undefined;
    }

    return dialCodeEntry.dial_code;
  }

  getPostalCodeByAreaId(areaId = null) {
    if (!areaId) {
      areaId = this.$("signup.area_id").value;
    }

    if (!areaId) {
      return null;
    }

    const areaEntry = this.areas.find(area => area.id === areaId);

    return areaEntry && areaEntry.postal_code;
  }

  get defaultCountry() {
    return (
      this.countries.find(
        country => country.iso_code === this.DEFAULT_COUNTRY_ISO_CODE
      ) || {}
    );
  }

  get isDefaultCountry() {
    if (!this.defaultCountry) {
      return true;
    }

    return this.defaultCountry.id === this.$("signup.country_id").value;
  }

  handleSubmitError = fieldset => e => {
    console.dir(e);
    const { response: { data: { errors } = {} } = {} } = e;

    if (!errors) {
      return;
    }

    Object.keys(errors).forEach(fieldName =>
      fieldset.$(fieldName).invalidate(errors[fieldName])
    );
  };

  downloadContractExample() {
    this.isDownloadingContract = true;
    SignUpService.downloadContractExample(this.$("signup.company_type").value)
      .then(response => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined ? "Contract.pdf" : suggestedFileName;

        FileDownload(response.data, effectiveFileName);
        message.success("Contract has been downloaded...");
      })
      .catch(() => {
        message.error(
          "Something went wrong, please try to download Contract later."
        );
      })
      .finally(() => {
        this.isDownloadingContract = false;
      });
  }

  togglePasswordMandatoryState(shouldBeRequired = false) {
    const passwordFields = ["password", "password_confirmation"];
    const requiredRuleIfAuthorizedSignup = "required_without:signup.id";
    const requiredRule = "required";

    passwordFields.forEach(fieldName => {
      const field = this.$("signup").$(fieldName);
      const currentRules = field.rules;
      const ruleToInsert = shouldBeRequired
        ? requiredRule
        : requiredRuleIfAuthorizedSignup;

      const mutatedRules = currentRules
        .split("|")
        .filter(rule => !rule.includes("required"));

      mutatedRules.push(ruleToInsert);

      field.set("rules", mutatedRules.join("|"));
    });
  }

  toggleIsDeclineContractComment() {
    this.isDeclineContractComment = !this.isDeclineContractComment;
  }

  makeField(props) {
    return new Field(props);
  }
}

decorate(SignUpForm, {
  isPersonal: computed,
  isCompany: computed,
  isShipper: computed,
  isAgent: computed,
  isTruckOwner: computed,
  currentStep: observable,
  steps: computed.struct,
  nextStep: computed.struct,
  prevStep: computed.struct,
  setStep: action,
  additionalPhoneNumbers: observable,
  addAdditionalPhoneNumber: action,
  additionalNumbersLength: computed,
  goBack: action,
  resendingVerificationCode: observable,
  setCaptchaToken: action,
  stepKeys: computed,
  setIsRegistrationCompleted: action,
  loadState: action,
  isRegistrationCompleted: computed,
  registrationCompleted: observable,
  countries: observable,
  states: observable,
  cities: observable,
  areas: observable,
  dialCodes: observable,
  defaultCountry: computed,
  captchaToken: observable,
  firstStep: computed,
  isDefaultCountry: computed,
  isEmailVerified: observable,
  loadAddressOptionsAfterInitialCountryLoaded: action,
  isDownloadingContract: observable,
  downloadContractExample: action,
  isDeclineContractComment: observable,
  toggleIsDeclineContractComment: action
});

export default new SignUpForm(AuthStore);
