import moment from "moment";

/**
 * returns a difference between two dates as string representation in the following format
 * - 1d 15h 15m
 * - 4h 28m
 * - 12m
 * if time elapsed returns false
 * @param start {Moment|string}
 * @param end {Moment|string}
 * @returns {string|boolean}
 */
export const getDiffBetweenDatesAsCountdown = (start, end) => {
  if (!moment.isMoment(start)) {
    start = moment.utc(start);
  }
  if (!moment.isMoment(end)) {
    end = moment.utc(end);
  }
  const delta = moment.duration(end.diff(start));
  const days = Number.parseInt(delta.asDays(), 10);

  let hours = Number.parseInt(delta.asHours(), 10);

  hours -= days * 24;

  let minutes = Number.parseInt(delta.asMinutes(), 10);

  minutes -= days * 24 * 60 + hours * 60;

  if (days <= 0 && hours <= 0 && minutes <= 0) {
    return false;
  }

  return [days && `${days}d`, hours && `${hours}h`, minutes && `${minutes}m`]
    .filter(Boolean)
    .join(" ");
};

/**
 * returns an array of moment date which are before current date
 * @param current
 * @returns {*|boolean}
 */
export const getDatesBeforeCurrent = current => {
  return current && current < moment().startOf("day");
};

/**
 * returns an array of moment date which are after current date
 * @param current
 * @returns {*|boolean}
 */
export const getDatesAfterCurrent = current => {
  return current && current > moment().endOf("day");
};

export const validateTimeRangeInWorkingHours = timeRange => {
  const startOfWorkingDay = moment
    .utc()
    .startOf("day")
    .add(8, "h");
  const endOfWorkingDay = moment
    .utc()
    .startOf("day")
    .add("17", "h");

  const [timeStart, timeEnd] = timeRange.map(value => {
    if (!moment.isMoment(value)) {
      value = moment.utc(value);
    }
    if (!value.isUTC()) {
      const offset = value.utcOffset();
      value = moment.utc(value).add(offset, "m");
    }
    return moment.utc(moment.utc(value).format("HH:mm"), "HH:mm");
  });

  if (!timeStart || !timeEnd) {
    return false;
  }

  const isRangeStartAfterDayStart = timeStart.isSameOrAfter(startOfWorkingDay);

  const isRangeStartBeforeDayEnd = timeStart.isSameOrBefore(endOfWorkingDay);

  const isRangeEndIsBeforeDayEnd = timeEnd.isSameOrBefore(endOfWorkingDay);

  const isRangeStartIsBeforeRangeEnd = timeStart.isSameOrBefore(timeEnd);

  return (
    isRangeStartAfterDayStart &&
    isRangeStartBeforeDayEnd &&
    isRangeEndIsBeforeDayEnd &&
    isRangeStartIsBeforeRangeEnd
  );
};

export const isDateExpired = date => {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }
  return date.startOf("d").isSameOrBefore(moment().startOf("d"));
};

export const weekendDayShouldBeDisabled = (weekendDate, rangeEnd) => {
  if (weekendDate.isWorkingDay()) {
    return false;
  }

  return !weekendDate.isBefore(rangeEnd);
};

export default {
  getDiffBetweenDatesAsCountdown,
  getDatesBeforeCurrent,
  isDateExpired,
  weekendDayShouldBeDisabled
};
