import { computed, isObservableArray } from "mobx";
import moment from "moment";
import collect from "collect.js";
import AuthStore from "./auth";
import {
  CONTRACT_STATUS_CODES,
  QUOTE_STATUS_CODES,
  SHIPMENT_ACTIVE_STATUS_CODES,
  SHIPMENT_ACTIVE_STATUSES,
  SHIPMENT_PAST_CODES,
  SHIPMENT_PAST_STATUSES
} from "../config/constants";
import { formatNameFromProfile } from "../utils/format";

/**
 * @property parent {Shipment|undefined}
 */
class Shipment {
  constructor(attributes) {
    Object.assign(this, attributes);
  }

  calculateInsuranceForQuote(quote) {
    const { cargo_value, value } = this;

    if (this.isShared) {
      if (!this.insurancePercentage) {
        quote.insurance = null;

        return quote;
      }

      const { chargeable } = quote;

      const insurance =
        ((+cargo_value * +chargeable) / value) * this.insurancePercentage;

      return Number.parseFloat(insurance).toFixed(2);
    }

    const insurance = +cargo_value * this.insurancePercentage;

    return Number.parseFloat(insurance).toFixed(2);
  }

  calculateContractValue() {
    if (this.quoteToSignAsTruckOwner) {
      return +this.quoteToSignAsTruckOwner.price;
    }

    return 0;
  }

  @computed
  get isPaid() {
    return [
      ...Object.values(SHIPMENT_ACTIVE_STATUS_CODES),
      SHIPMENT_PAST_CODES.CLOSED
    ].includes(this.status);
  }

  @computed
  get insurancePercentage() {
    return +this.commodity_type.insurance_percentage;
  }

  @computed
  get isShared() {
    return !!(
      this.is_shared_consignment ||
      (this.parent && this.parent.is_shared_consignment)
    );
  }

  @computed
  get hasQuotes() {
    return (
      (Array.isArray(this.quotes) || isObservableArray(this.quotes)) &&
      this.quotes.length > 0
    );
  }

  @computed
  get hasContracts() {
    if (!this.hasQuotes) {
      return false;
    }

    return this.quotes.filter(quote => quote.contract);
  }

  @computed
  get hasPendingContractForTruckOwner() {
    return !!this.pendingContractForTruckOwner;
  }

  @computed
  get hasAcceptedContractForTruckOwner() {
    return !!this.acceptedContractForTruckOwner;
  }

  @computed
  get hasSignedContract() {
    return this.signedContracts.length > 0;
  }

  @computed
  get sharedHasSignedContract() {
    if (!this.hasChildShipments) {
      return false;
    }

    return (
      this.child_shipments.filter(child => child.hasSignedContract).length > 0
    );
  }

  @computed
  get hasSelectedQuotesForTruckOwner() {
    return this.selectedQuotesForTruckOwner.length > 0;
  }

  @computed
  get hasSelectedQuotes() {
    return this.selectedQuotes.length > 0;
  }

  @computed
  get hasSentQuoteForTruckOwner() {
    return this.sentQuotesForTruckOwner.length > 0;
  }

  @computed
  get pendingContractForTruckOwner() {
    if (!this.hasQuotes) {
      return undefined;
    }

    let contracts = this.quotes
      .filter(quote => quote.contract)
      .filter(
        ({ contract: { status: contractStatus } }) =>
          contractStatus === CONTRACT_STATUS_CODES.PENDING
      );

    if (AuthStore.isTruckOwner) {
      contracts = contracts.filter(
        quote => quote.owner_id === AuthStore.userId
      );
    }

    return contracts.length > 0 ? contracts[0].contract : false;
  }

  @computed
  get acceptedContractForTruckOwner() {
    if (!this.hasQuotes) {
      return undefined;
    }

    let contracts = this.quotes
      .filter(quote => quote.contract)
      .filter(
        ({ contract: { status: contractStatus } }) =>
          contractStatus === CONTRACT_STATUS_CODES.ACCEPTED
      );

    if (AuthStore.isTruckOwner) {
      contracts = contracts.filter(
        quote => quote.owner_id === AuthStore.userId
      );
    }

    return contracts.length > 0 ? contracts[0].contract : false;
  }

  @computed
  get selectedQuotesForTruckOwner() {
    if (!this.hasQuotes) {
      return [];
    }

    return this.quotes.filter(
      quote =>
        quote.owner_id === AuthStore.userId &&
        quote.status === QUOTE_STATUS_CODES.QUOTE_SELECTED
    );
  }

  @computed
  get selectedQuotes() {
    if (!this.hasQuotes) {
      return [];
    }

    return this.quotes.filter(
      quote => quote.status === QUOTE_STATUS_CODES.QUOTE_SELECTED
    );
  }

  @computed
  get acceptedQuoteForTruckOwner() {
    if (!this.hasQuotes) {
      return false;
    }

    return this.quotes.find(quote => {
      return (
        quote.owner_id === AuthStore.userId &&
        quote.status === QUOTE_STATUS_CODES.ACCEPTED &&
        quote.contract &&
        quote.contract.status === CONTRACT_STATUS_CODES.ACCEPTED
      );
    });
  }

  @computed
  get sentQuotesForTruckOwner() {
    if (!this.hasQuotes) {
      return [];
    }

    return this.quotes.filter(quote => {
      return (
        quote.owner_id === AuthStore.userId &&
        quote.status === QUOTE_STATUS_CODES.PENDING
      );
    });
  }

  @computed
  get signedContracts() {
    if (!this.hasQuotes) {
      return [];
    }

    return this.quotes.filter(
      quote => quote.status === QUOTE_STATUS_CODES.ACCEPTED
    );
  }

  @computed
  get hasSignedContractRegardlessEntityStatus() {
    if (!this.hasQuotes) {
      return false;
    }

    return (
      this.quotes.filter(
        quote =>
          quote.contract &&
          quote.contract.status === QUOTE_STATUS_CODES.ACCEPTED
      ).length > 0
    );
  }

  @computed
  get pendingQuote() {
    if (this.status === QUOTE_STATUS_CODES.QUOTE_SELECTED && this.hasQuotes) {
      return this.quotes[0] || {};
    }

    return {};
  }

  @computed
  get isVolumeBased() {
    if (this.truck_type && this.truck_type.calculation_type) {
      return this.truck_type.calculation_type.is_required_volume;
    }

    return !!this.volume;
  }

  @computed
  get signedQuote() {
    return this.quotes.find(
      acceptedQuote => acceptedQuote.status === QUOTE_STATUS_CODES.ACCEPTED
    );
  }

  @computed
  get activeQuote() {
    return (
      this.quotes.find(acceptedQuote =>
        Object.keys(SHIPMENT_ACTIVE_STATUSES).includes(acceptedQuote.status)
      ) || {}
    );
  }

  @computed
  get pastQuote() {
    if (AuthStore.isTruckOwner && this.quote_id) {
      return (
        this.quotes.find(
          acceptedQuote =>
            Object.keys(SHIPMENT_PAST_STATUSES).includes(
              acceptedQuote.status
            ) && acceptedQuote.id === this.quote_id
        ) || {}
      );
    }

    return (
      this.quotes.find(acceptedQuote =>
        Object.keys(SHIPMENT_PAST_STATUSES).includes(acceptedQuote.status)
      ) || {}
    );
  }

  @computed
  get ownerUser() {
    if (this.owner_id === this.owner.id) {
      return this.owner;
    }

    if (this.shipper !== null) {
      return this.shipper;
    }

    return null;
  }

  @computed
  get value() {
    if (this.isVolumeBased) {
      return +this.volume;
    }

    return +this.weight;
  }

  @computed
  get quoteToSignAsTruckOwner() {
    return this.quotes.find(
      quote =>
        quote.status === QUOTE_STATUS_CODES.PENDING &&
        quote.contract &&
        quote.owner_id === AuthStore.userId
    );
  }

  @computed
  get commodityTypeName() {
    const {
      commodity_type: { name }
    } = this;

    return name;
  }

  @computed
  get origin() {
    const {
      origin_city: { name: cityName },
      origin_state: { name: stateName }
    } = this;

    return `${cityName}, ${stateName}`;
  }

  @computed
  get destination() {
    const {
      destination_city: { name: cityName },
      destination_state: { name: stateName }
    } = this;

    return `${cityName}, ${stateName}`;
  }

  @computed
  get truckOwnerName() {
    const { owner } = this.activeQuote;

    if (!owner || !owner.profile) {
      return "";
    }

    const { profile } = owner;

    if (profile.company_name) {
      return profile.company_name;
    }

    return formatNameFromProfile(owner.profile);
  }

  @computed
  get ownerName() {
    const {
      owner: { profile }
    } = this;

    return formatNameFromProfile(profile);
  }

  @computed
  get deliveryHistory() {
    const statuses = [
      SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN,
      SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP,
      SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED
    ];
    let deliveryStatusesHistory = collect(this.statuses_history);

    if (AuthStore.isTruckOwner) {
      deliveryStatusesHistory = collect(
        this.isPast
          ? this.pastQuote.statuses_history
          : this.activeQuote.statuses_history
      );
    }

    return statuses.map(status => {
      let updatedDate = null;
      const change = deliveryStatusesHistory.last(
        ({ status: actualStatus }) => actualStatus === status
      );

      if (change) {
        updatedDate = change.created_at;
      }

      return {
        status,
        updatedDate
      };
    });
  }

  @computed
  get activeQuoteDeliveryHistory() {
    const statuses = [
      SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN,
      SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP,
      SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED
    ];
    const deliveryStatusesHistory = collect(this.activeQuote.statuses_history);

    return statuses.map(status => {
      let updatedDate = null;
      const change = deliveryStatusesHistory.last(
        ({ status: actualStatus }) => actualStatus === status
      );

      if (change) {
        updatedDate = change.created_at;
      }

      return {
        status,
        updatedDate
      };
    });
  }

  @computed
  get isAbleToChangeStatus() {
    const { status } = this;
    const codes = Object.values(SHIPMENT_ACTIVE_STATUS_CODES);
    const quoteStatus = this.activeQuote.status;
    const statusesAreIdentical = status === quoteStatus;

    const isQuoteAhead =
      codes.findIndex(st => st === quoteStatus) >
      codes.findIndex(st => st === status);
    const isShipmentAhead =
      codes.findIndex(st => st === status) >
      codes.findIndex(st => st === quoteStatus);

    if (AuthStore.isTruckOwner) {
      return statusesAreIdentical || isShipmentAhead;
    }

    return statusesAreIdentical || isQuoteAhead;
  }

  @computed
  get disabledStateMessage() {
    if (this.isAbleToChangeStatus) {
      return null;
    }

    if (AuthStore.isTruckOwner) {
      const quoteStatus =
        SHIPMENT_ACTIVE_STATUSES[this.activeQuote.status].name;

      return `Selected Status: ${quoteStatus}. You can update the status further when Shipper will update his status to ${quoteStatus}.`;
    }

    const shipmentStatus = SHIPMENT_ACTIVE_STATUSES[this.status].name;

    return `Selected Status: ${shipmentStatus}. You can update the status further when Truck Owner will update his status to ${shipmentStatus}.`;
  }

  @computed
  get trackedEntity() {
    if (AuthStore.isTruckOwner) {
      if (this.isShared && this.child_shipments) {
        const shipmentWithTOQuote = this.child_shipments.find(({ quotes }) =>
          quotes.find(({ owner_id }) => owner_id === AuthStore.userId)
        );

        if (shipmentWithTOQuote) {
          return shipmentWithTOQuote.activeQuote;
        }
      }

      return this.activeQuote;
    }

    return this;
  }

  @computed
  get trackedPassedEntity() {
    if (AuthStore.isTruckOwner) {
      if (this.isShared && this.child_shipments) {
        const shipmentWithTOQuote = this.child_shipments.find(({ quotes }) =>
          quotes.find(({ owner_id }) => owner_id === AuthStore.userId)
        );

        if (shipmentWithTOQuote) {
          return shipmentWithTOQuote.pastQuote;
        }
      }

      return this.pastQuote;
    }

    return this;
  }

  @computed
  get updateStatesForEntities() {
    return status => {
      const quote = this.isPast ? this.pastQuote : this.activeQuote;
      const { statuses_history: shipmentHistory } = this;
      const { statuses_history: quoteHistory } = quote;

      const shipmentStatusState = collect(shipmentHistory).last(
        state => state.status === status
      );
      const quoteStatusState = collect(quoteHistory).last(
        state => state.status === status
      );

      return { quoteStatusState, shipmentStatusState };
    };
  }

  @computed
  get isQuoteAndShipmentStateIsSameForStatus() {
    return status => {
      const {
        quoteStatusState,
        shipmentStatusState
      } = this.updateStatesForEntities(status);

      return !!quoteStatusState && !!shipmentStatusState;
    };
  }

  @computed
  get dateForLastStateUpdateOfStatus() {
    return status => {
      if (!this.isQuoteAndShipmentStateIsSameForStatus(status)) {
        return null;
      }

      const {
        quoteStatusState: { created_at: quoteUpdateDate },
        shipmentStatusState: { created_at: shipmentUpdateDate }
      } = this.updateStatesForEntities(status);

      return [moment(quoteUpdateDate), moment(shipmentUpdateDate)].sort(
        (a, b) => b - a
      )[0];
    };
  }

  @computed
  get isOwnedByCurrentUser() {
    return this.owner_id === AuthStore.userId;
  }

  @computed
  get isPast() {
    return [...Object.values(SHIPMENT_PAST_CODES)].includes(this.status);
  }

  @computed
  get isActive() {
    return [...Object.values(SHIPMENT_ACTIVE_STATUS_CODES)].includes(
      this.status
    );
  }

  @computed
  get isClosed() {
    return this.status === SHIPMENT_PAST_CODES.CLOSED;
  }

  @computed
  get closedQuote() {
    return this.quotes.find(
      quote => quote.status === SHIPMENT_PAST_CODES.CLOSED
    );
  }

  @computed
  get hasChildShipments() {
    const { is_shared_consignment, child_shipments } = this;

    if (!is_shared_consignment) {
      return false;
    }

    return Array.isArray(child_shipments) && child_shipments.length > 0;
  }

  @computed
  get hasPendingQuotes() {
    if (!this.hasQuotes) {
      return false;
    }

    return this.quotes.find(
      quote => quote.status === QUOTE_STATUS_CODES.PENDING
    );
  }

  @computed
  get couldBeCancelled() {
    const { status, is_shared_consignment } = this;

    // if shipment has inactive status
    if (Object.values(SHIPMENT_PAST_CODES).includes(status)) {
      return false;
    }

    // is shared and has one or more accepted contracts
    if (is_shared_consignment) {
      if (this.hasChildShipments) {
        return false;
      }

      if (this.hasSelectedQuotes) {
        return false;
      }

      return true;
    }

    return true;
  }

  @computed
  get activeChildShipmentForTruckOwner() {
    if (!this.isShared) {
      return this;
    }

    return this.child_shipments[0];
  }
}

export default Shipment;
