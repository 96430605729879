import React, { useEffect, useState } from "react";
import Col from "antd/lib/grid/col";
import { PageHeader, Typography, Affix } from "antd";
import ScrollSpy from "react-scrollspy";
import useToggle from "react-use-toggle";
import Row from "antd/lib/grid/row";
import { observer } from "mobx-react";
import StaticPagesService from "../../services/api/static-pages";
import Helmet from "../../common/Helmet";
import SchemaRenderer from "../../common/StaticPage/schema-renderer";
import { useScrollTop } from "../../utils/navigation";
import { toKebabCase } from "../../utils/format";
import { prepareBlockData } from "../../common/StaticPage/block";

import "./styles.less";

const gutter = { lg: 10, sm: 10, xs: 15 };

const TermsAndConditions = observer(({ preloadedSchema }) => {
  const [pageLoaded, toggleLoaded] = useToggle();
  const [data, setData] = useState();

  useScrollTop();

  useEffect(() => {
    if (preloadedSchema) {
      preloadedSchema.rich_text.blocks = preloadedSchema.rich_text.blocks.map(
        block => {
          if (block.type === "header") {
            block.data.key = toKebabCase(block.data.text);
          }

          return prepareBlockData(block);
        }
      );
      setData(preloadedSchema);
      toggleLoaded();
      return;
    }
    StaticPagesService.getTermsPageContent().then(pageData => {
      pageData.rich_text.blocks = pageData.rich_text.blocks.map(block => {
        if (block.type === "header") {
          block.data.key = toKebabCase(block.data.text);
        }

        return prepareBlockData(block);
      });

      setData(pageData);
      toggleLoaded();
    });
  }, [preloadedSchema]);

  const handleSwitchSection = sectionName => e => {
    const element = document.querySelector(
      `#${sectionName.replace("-button", "")}`
    );

    if (!element) {
      return;
    }
    e.preventDefault();

    element.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  if (!pageLoaded || !data) {
    return null;
  }

  const titles = data.rich_text.blocks.filter(
    block => block.type === "header" && block.data.level === 3
  );

  const titleKeys = titles.map(({ data: { key } }) => key);

  const groupedSections = data.rich_text.blocks.reduce(
    (sections, block) => {
      if (block.type === "header" && block.data.level === 3) {
        sections.push([]);
      }
      sections[sections.length - 1].push(block);

      return sections;
    },
    [[]]
  );

  const renderNavigation = () => {
    if (!pageLoaded || !data) {
      return null;
    }
    return titles.map(({ data: { text, key } }) => (
      <a
        key={key}
        className="button buttons-wrapper"
        href={`#${key}`}
        onClick={handleSwitchSection(`${key}-button`)}
      >
        {text}
      </a>
    ));
  };

  return (
    <div className="terms-and-conditions">
      <Helmet title="Terms & Conditions" />
      <Row gutter={gutter}>
        <Col
          lg={{ span: 22, offset: 1 }}
          md={{ span: 21, offset: 0 }}
          sm={{ span: 24 }}
        >
          <PageHeader title={data.hero.title} />
        </Col>
      </Row>

      <Row gutter={gutter} className="main-wrapper">
        <Col
          className="affix-wrapper"
          xl={{ span: 5, offset: 1 }}
          lg={{ span: 7, offset: 1 }}
          md={{ span: 8, offset: 0 }}
          sm={{ span: 18, offset: 0 }}
          xs={{ span: 24 }}
        >
          <Affix offsetTop={5} className="desktop-nav-menu">
            <ScrollSpy items={titleKeys} currentClassName="button active">
              {renderNavigation()}
            </ScrollSpy>
          </Affix>
        </Col>

        <Col
          xl={{ span: 17, offset: 1 }}
          lg={{ span: 15, offset: 1 }}
          md={{ span: 16, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <Typography className="content-wrapper">
            {groupedSections.map(blocks => {
              const firstBlock = blocks[0];
              const isFirstBlockIsSectionTitle =
                firstBlock.type === "header" && firstBlock.data.level === 3;
              if (!isFirstBlockIsSectionTitle) {
                return <SchemaRenderer schema={{ blocks }} />;
              }

              return (
                <div id={firstBlock.data.key}>
                  <SchemaRenderer schema={{ blocks }} />
                </div>
              );
            })}
          </Typography>
        </Col>
      </Row>
    </div>
  );
});

export default TermsAndConditions;
