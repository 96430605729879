import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import React, { useState } from "react";
import cx from "classnames";
import ModalVideo from "react-modal-video";
import VideoPoster from "../../styles/images/poster-video.jpg";
import { ReactComponent as PlayIcon } from "../../icons/play-button.svg";

import "./video.less";

const gutter = { lg: 10, sm: 10, xs: 15 };

export default function VideoBlock({
  videoId,
  wrapToRow = false,
  useThumbnail,
  noShadow
}) {
  const [isOpen, setIsOpen] = useState(false);

  let thumbnail = VideoPoster;
  let srcSet = VideoPoster;

  if (useThumbnail) {
    thumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    srcSet = `${thumbnail}, ${VideoPoster}`;
  }

  const renderModalVideo = () => {
    return (
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
    );
  };

  const renderHandlers = () => (
    <div
      className={cx("embed-video", {
        "no-shadow": noShadow
      })}
    >
      <img
        className="thumbnail"
        src={thumbnail}
        srcSet={srcSet}
        alt="Virtual Haulage"
      />

      <div className="overlay">
        <PlayIcon onClick={() => setIsOpen(true)} className="play-handler" />
      </div>
    </div>
  );

  const renderEmbed = () => {
    if (!wrapToRow) {
      return renderHandlers();
    }

    return (
      <Row gutter={gutter}>
        <Col className="m-top-20 m-bottom-50" lg={{ span: 19 }} md={24} xs={24}>
          {renderHandlers()}
        </Col>
      </Row>
    );
  };

  return (
    <>
      {renderModalVideo()}
      {renderEmbed()}
    </>
  );
}
