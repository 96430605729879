import React, { useMemo, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import Icon from "antd/lib/icon";
import Tooltip from "antd/lib/tooltip";

import { ReactComponent as DotsIcon } from "../styles/images/more.svg";

import "./ActionsMenu.less";

const ActionsMenu = ({ row, actions = [], style = {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const visibleActions = useMemo(
    () =>
      actions
        .filter(({ onClick }) => typeof onClick === "function")
        .filter(({ visible }) => {
          return typeof visible === "function" ? visible(row) : true;
        }),
    [actions, row]
  );

  if (visibleActions.length === 0) {
    return null;
  }

  const handleMenuClick = key => () => {
    const actionDescriptor = actions.find(action => action.key === key);
    if (!actionDescriptor || typeof actionDescriptor.onClick !== "function") {
      return;
    }

    const promise = actionDescriptor.onClick(row);
    if (promise instanceof Promise) {
      setIsLoading(true);
      promise.finally(() => {
        setIsLoading(false);
      });
    }
  };

  const getWrapper = (isDisabled, tooltip) => children => {
    if (!isDisabled || !tooltip) {
      return children;
    }
    return <Tooltip title={tooltip}>{children}</Tooltip>;
  };

  const getWrappedOption = (
    { key, label, disabled, tooltipIfDisabled },
    index
  ) => {
    const isDisabled = disabled && disabled(row);
    return (
      <Menu.Item
        onClick={handleMenuClick(key)}
        key={index}
        disabled={isDisabled}
        className="popup-menu-item"
      >
        {getWrapper(isDisabled, tooltipIfDisabled)(<span>{label}</span>)}
      </Menu.Item>
    );
  };

  const menu = (
    <Menu style={{ borderRadius: "4px", padding: "0px" }}>
      {visibleActions.map(getWrappedOption)}
    </Menu>
  );
  if (isLoading) {
    return <Icon type="loading" style={{ color: "#CDD1D9" }} spin />;
  }

  return (
    <Dropdown
      overlay={menu}
      trigger={["click", "hover"]}
      placement="bottomRight"
    >
      <Button style={style} className="btn-more">
        <DotsIcon />
      </Button>
    </Dropdown>
  );
};

export default ActionsMenu;
