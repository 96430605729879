import React, { useCallback, useEffect } from "react";
import { PageHeader, message, Button } from "antd";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import cx from "classnames";
import moment from "moment";
import { ReactComponent as IconPlus } from "../../../icons/add.svg";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";
import { ReactComponent as PendingIcon } from "../../../styles/images/temporary.svg";
import RemoveTruckModal from "./remove-truck-modal";
import UnlinkTruckModal from "../manage-drivers/unlink-truck-modal";

import {
  TRUCK_STATUSES as statuses,
  SORT_DIRECTIONS,
  LOG_TRUCK_MAX_TRUCKS,
  TRUCK_STATUSES_CODES,
  DRIVER_STATUSES_CODES,
  DRIVER_STATUSES,
  CONTRACT_STATUS_CODES,
  QUOTE_STATUS_CODES
} from "../../../config/constants";

import LogTruckForm from "../../../models/forms/log-truck";
import ActionsMenu from "../../../common/ActionsMenu";
import ItemsTable from "../../../common/ItemsTable";
import TaggedNavBar from "../../../common/TaggedNavBar";
import FilterableTableContext from "../../../contexts/filterable-table";
import StatusRenderer from "../../../common/StatusRenderer";
// eslint-disable-next-line import/no-cycle
import ACTIONS from "./actions";
import { isDateExpired } from "../../../utils/date-time";

import "../styles.less";
import { TrackingIcon } from "../../../common/Icons/TrackingIcon";

const formatTooltipLabel = (reasons = []) => {
  if (reasons.length === 0) {
    return null;
  }

  return (
    <>
      <p>
        Your Truck cannot be logged as available for the following reason(-s):
      </p>
      <ol>
        {reasons.map((reason, i) => (
          <li key={`reason-${i}`}>{reason}</li>
        ))}
      </ol>
    </>
  );
};

const getExpiredDocLabel = key => {
  const labels = {
    vehicle_license_expires_at: "Vehicle License",
    certificate_road_worthiness_expires_at: "Certificate of Road Worthiness",
    vehicle_insurance_expires_at: "Vehicle Insurance"
  };

  return labels[key];
};

export const getIsTruckHasPendingAttributes = truck => {
  const truckHasPendingAttributes =
    Array.isArray(truck.attributes_waiting_confirmation) &&
    truck.attributes_waiting_confirmation.length > 0;

  if (!truckHasPendingAttributes) {
    return null;
  }

  return 'Trucks with "Other" value in Manufacturer, Model, Truck Type, Weight or Volume, Length;';
};

export const getIsTruckHasExpiredDocuments = truck => {
  const expiredDocuments = [
    "vehicle_license_expires_at",
    "certificate_road_worthiness_expires_at",
    "vehicle_insurance_expires_at"
  ].filter(fieldName => {
    return isDateExpired(moment(truck[fieldName]));
  });

  const truckHasExpiredDocuments = expiredDocuments.length !== 0;

  if (truckHasExpiredDocuments) {
    return (
      <span>
        <span>
          Expiration date of one or more of the following Truck documents is
          today or has already passed:
        </span>
        <ul>
          {expiredDocuments.map((expiredDoc, i) => (
            <li key={`doc-${i}`}>{getExpiredDocLabel(expiredDoc)}</li>
          ))}
        </ul>
      </span>
    );
  }

  return null;
};

export const getIsTruckHasWaitingStatus = truck => {
  if (
    [
      TRUCK_STATUSES_CODES.PENDING_APPROVAL,
      TRUCK_STATUSES_CODES.DECLINED,
      TRUCK_STATUSES_CODES.REMOVED,
      TRUCK_STATUSES_CODES.REAPPLIED
    ].includes(truck.status)
  ) {
    return `Trucks status is ${statuses[truck.status].name};`;
  }

  return null;
};

export const getIsTruckLoggedAsAvailable = truck => {
  if (truck.actual_log_available_truck) {
    return "Already logged Trucks until that instance expires;";
  }

  return null;
};

export const getIsTruckLinkedToDriver = truck => {
  if (truck.driver === null) {
    return "Truck is not linked to Driver;";
  }

  return null;
};

export const getIsDriverHasWaitingStatus = ({ driver }) => {
  if (!driver) {
    return null;
  }

  if (
    [
      DRIVER_STATUSES_CODES.PENDING_APPROVAL,
      DRIVER_STATUSES_CODES.DECLINED,
      DRIVER_STATUSES_CODES.REAPPLIED
    ].includes(driver.status)
  ) {
    return `Driver linked to Truck has ${DRIVER_STATUSES[driver.status].name} status`;
  }

  return null;
};

export const getIsDriverLicenseExpired = ({ driver }) => {
  if (!driver) {
    return null;
  }

  if (isDateExpired(moment(driver.license_expiry_date))) {
    return "Driver License expiration date is today or has already passed;";
  }

  return null;
};

export const getIsTruckHasWaitingContract = ({ quotes = [] }) => {
  if (quotes.length === 0) {
    return null;
  }

  const quoteWithContract = quotes.find(({ contract }) => contract);

  if (
    quoteWithContract &&
    quoteWithContract.contract.status === CONTRACT_STATUS_CODES.PENDING
  ) {
    return "There is Pending Contract related to this Truck;";
  }

  return null;
};

export const getIsTruckHasActiveQuote = ({ quotes = [] }) => {
  if (
    quotes.filter(({ status }) => status !== QUOTE_STATUS_CODES.PENDING)
      .length > 0
  ) {
    return "There is Accepted, Active, Truck at Origin, Picked Up, Delivered Quote associated with this Truck;";
  }

  return null;
};

export const getIsTruckHasPendingQuote = ({ quotes = [] }) => {
  if (
    quotes.filter(({ status }) => status === QUOTE_STATUS_CODES.PENDING)
      .length > 0
  ) {
    return "There is Pending Quote associated with this Truck;";
  }

  return null;
};

export const getIsInspectorsApprovalDateEarlierThanMonth = ({
  inspection_date
}) => {
  if (
    inspection_date !== null &&
    isDateExpired(
      moment(inspection_date)
        .add(1, "year")
        .subtract(1, "month")
    )
  ) {
    return "Inspector's Approval expiration date earlier than a month;";
  }

  return null;
};

const getTruckPendingTooltip = ({ truckIsNotAvailableForLogReasons }) => {
  return formatTooltipLabel(truckIsNotAvailableForLogReasons);
};

const pendingRow = row => {
  if (getTruckPendingTooltip(row)) {
    return "pending-row";
  }

  return "normal-row";
};

const renderStatusName = (text, row) => {
  const tooltip = getTruckPendingTooltip(row);

  return (
    <StatusRenderer
      currentStatus={row.status}
      statuses={statuses}
      hasTooltip={row.truckIsNotAvailableForLog}
      tooltipIcon={PendingIcon}
      tooltipText={tooltip}
    />
  );
};

const ManageTrucksList = inject(
  "TrucksStore",
  "RemoveTruckForm",
  "LinkDriverForm",
  "UnlinkTruckForm"
)(
  observer(
    ({ TrucksStore, RemoveTruckForm, LinkDriverForm, UnlinkTruckForm }) => {
      useEffect(() => {
        if (RemoveTruckForm.isRemovingSuccess) {
          TrucksStore.load();
        }
      }, [TrucksStore, RemoveTruckForm.isRemovingSuccess]);

      useEffect(() => {
        if (LinkDriverForm.isLinkSuccessful) {
          TrucksStore.load();
        }
      }, [TrucksStore, LinkDriverForm.isLinkSuccessful]);
      useEffect(() => {
        if (UnlinkTruckForm.isUnlinkSuccessful) {
          TrucksStore.load();
        }
      }, [TrucksStore, UnlinkTruckForm.isUnlinkSuccessful]);

      const handleLogTruckClick = useCallback(() => {
        const selectedTrucksLengthAllowed = TrucksStore.canAddTrucks;

        if (selectedTrucksLengthAllowed) {
          LogTruckForm.openForm(TrucksStore.selectedRowKeys, true);
        } else if (!selectedTrucksLengthAllowed) {
          message.warning(
            `You can log as available up to ${LOG_TRUCK_MAX_TRUCKS} trucks at a time.`
          );
        }
      }, [TrucksStore]);

      const renderManufacturer = row => TrucksStore.formatTruckDetail(row);

      const renderTruckSpecification = (row, field) =>
        TrucksStore.formatTruckSpecifications(row, field);

      const renderTruckingIcon = row => {
        if (!row.vehicle_have_trucker) {
          return null;
        }

        return (
          <TrackingIcon
            key={row.id}
            className={pendingRow(row) === "pending-row" ? "inactive" : ""}
          />
        );
      };

      const title = "Manage Trucks";
      const columns = [
        {
          title: "Vehicle Manufacturer",
          dataIndex: "manufacturer",
          key: "manufacturer",
          render: (_, row) => renderManufacturer(row),
          sorter: true,
          sortOrder: TrucksStore.sortOrder("manufacturer"),
          sortDirections: SORT_DIRECTIONS,
          width: "28.4%"
        },
        {
          title: "",
          dataIndex: "trucking_icon",
          key: "trucking_icon",
          className: "tracking-icon-col",
          render: (_, row) => renderTruckingIcon(row)
        },
        {
          title: "Truck Type",
          dataIndex: "type",
          key: "type",
          render: text => (text ? text.name : "other"),
          sorter: true,
          sortOrder: TrucksStore.sortOrder("type"),
          sortDirections: SORT_DIRECTIONS,
          width: "20.4%"
        },
        {
          title: "Weight, tons",
          dataIndex: "weight",
          key: "weight",
          render: (_, row) => renderTruckSpecification(row, "weight"),
          sorter: true,
          sortOrder: TrucksStore.sortOrder("weight"),
          sortDirections: SORT_DIRECTIONS,
          align: "right",
          width: "14%"
        },
        {
          title: "Volume, ltr",
          dataIndex: "volume",
          key: "volume",
          render: (_, row) => renderTruckSpecification(row, "volume"),
          sorter: true,
          sortOrder: TrucksStore.sortOrder("volume"),
          sortDirections: SORT_DIRECTIONS,
          align: "right",
          width: "14%"
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, row) => renderStatusName(text, row),
          width: "16.5%",
          className: "status-name"
        },
        {
          render: (_, row) => <ActionsMenu row={row} actions={ACTIONS} />,
          width: "6.7%"
        }
      ];

      return (
        <FilterableTableContext.Provider value={{ gridStore: TrucksStore }}>
          <PageHeader
            title={title}
            extra={[
              <Button
                key="log-link"
                size="large"
                className="font-bolder action-button log-truck-btn"
                onClick={handleLogTruckClick}
                disabled={LogTruckForm.isLoadingTrucks}
                loading={LogTruckForm.isLoadingTrucks}
              >
                <i
                  className={cx("anticon", {
                    "d-none": LogTruckForm.isLoadingTrucks
                  })}
                >
                  <CalendarIcon />
                </i>
                Log Available Trucks
              </Button>,
              <Link
                key="register-link"
                to="/register-truck"
                className="ant-btn ant-btn-lg ant-btn-default font-bolder action-button"
              >
                <i className="anticon">
                  <IconPlus />
                </i>
                Register Truck
              </Link>
            ]}
          />
          <TaggedNavBar tags={statuses} prefix="Status" />
          <ItemsTable
            columns={columns}
            title={title}
            rowClassName={row => pendingRow(row)}
            rowSelectionOverrides={{
              getCheckboxProps: ({ truckIsNotAvailableForLog }) => ({
                disabled: truckIsNotAvailableForLog
              })
            }}
          />
          <RemoveTruckModal />
          <UnlinkTruckModal />
        </FilterableTableContext.Provider>
      );
    }
  )
);

export default ManageTrucksList;
