import React, { useEffect } from "react";

import { Switch, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Layout, Spin } from "antd";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import LoginForm from "../../components/login/LoginForm";
import Registration from "../../components/registration";
import ForgotPassword from "../../components/login/ForgotPassword";

import PrivateRoute from "../../common/PrivateRoute";
import FooterComponent from "../../common/LayoutPartials/Footer";
import AuthRoute from "../../common/AuthRoute";
import ResetPassword from "../../components/login/ResetPassword";
import CmsPreview from "../../components/cms-preview";
import HeaderMenu from "../../common/LayoutPartials/HeaderMenu";
import ReapplyHandler from "../../components/registration/ReapplyHandler";

import Grid from "../../common/DeveloperTools/grid";

import "./styles.less";

const { Footer, Header } = Layout;

const AuthorizedContainer = React.lazy(() => import("../AuthorizedContainer"));
const PublicContainer = React.lazy(() => import("../PublicContainer"));

export default inject("AuthStore", "AppState")(
  observer(function App({ AuthStore, AppState, ...rest }) {
    useEffect(() => {
      AuthStore.authorize();
    }, [AuthStore]);

    return (
      <Layout className="layout">
        <Header>
          <HeaderMenu />
        </Header>
        <div className={`${AppState.layoutClassNames} ant-layout-content`}>
          <Row style={{ maxWidth: 1200, margin: "0 auto" }}>
            <Col span={24}>
              {AuthStore.authorizing && (
                <Spin className="authorization-loader" size="large" />
              )}
              {!AuthStore.authorizing && (
                <Switch>
                  <AuthRoute path="/login" component={LoginForm} />
                  <AuthRoute path="/registration" component={Registration} />
                  <AuthRoute
                    path="/forgot/password"
                    component={ForgotPassword}
                    {...rest}
                  />
                  <AuthRoute
                    path="/reset/password"
                    component={ResetPassword}
                    {...rest}
                  />
                  <AuthRoute
                    path="/auth/reapply/:token"
                    component={ReapplyHandler}
                  />
                  <Route
                    exact
                    path="/cms/preview/:type"
                    component={CmsPreview}
                  />
                  {!AuthStore.isLoggedIn && (
                    <React.Suspense fallback={null}>
                      <PublicContainer />
                    </React.Suspense>
                  )}
                  <PrivateRoute
                    path="/"
                    render={props => (
                      <React.Suspense fallback={null}>
                        <AuthorizedContainer {...props} />
                      </React.Suspense>
                    )}
                  />
                </Switch>
              )}
            </Col>
          </Row>
        </div>
        <Footer>
          <FooterComponent />
        </Footer>
        <Grid />
      </Layout>
    );
  })
);
