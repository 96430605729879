/* eslint-disable no-unused-vars */
import axios from "axios";

const normalizeAccountType = type => type.replace("_", "-");

const SignUpService = {
  signUp: (accountType, values, isAuthorizedSignup = false) => {
    return axios[isAuthorizedSignup ? "put" : "post"](
      `/sign-up/${normalizeAccountType(accountType)}`,
      values
    ).then(({ data }) => {
      if (isAuthorizedSignup) {
        return { user: data };
      }
      return data;
    });
  },
  verifyEmail: values => {
    return axios.post(`/sign-up/verify-code`, values);
  },
  submitPreQualificationChecklist: (accountType, values) => {
    return axios.post(
      `/sign-up/${normalizeAccountType(
        accountType
      )}/pre-qualification-checklist`,
      values
    );
  },
  submitBankAccountDetails: (accountType, values) => {
    return axios.post(
      `/sign-up/${normalizeAccountType(accountType)}/bank-account-details`,
      values
    );
  },
  acceptContract: (accountType, values) => {
    return axios.post(
      `/sign-up/${normalizeAccountType(accountType)}/accept-contract`,
      values
    );
  },
  resendVerification: () => {
    return axios.put("/sign-up/verify-code/regenerate");
  },
  addRejectContractMessage: (accountType, values) => {
    return axios.post(
      `/sign-up/${normalizeAccountType(accountType)}/reject-contract`,
      values
    );
  },
  downloadContractExample: accountType => {
    return axios
      .get(`/accounts/contract/${accountType}`, {
        responseType: "blob"
      })
      .then(response => response)
      .catch(() => []);
  }
};

export default SignUpService;
