import { computed, observable } from "mobx";
import message from "antd/lib/message";
import BaseForm from "../base-form";
import ShipmentsService from "../../../services/api/shipments";

class ShipmentDeliveryCodeForm extends BaseForm {
  @observable
  shipment;

  @computed
  get shouldBeOpened() {
    return this.shipment && this.shipment.id;
  }

  setup() {
    return {
      fields: [
        {
          name: "code",
          label: "Delivery Code",
          placeholder: "Provide Delivery Code",
          guard: "letters_digits",
          rules: "required|max:6"
        }
      ]
    };
  }

  hooks() {
    return {
      onSuccess: () => {
        return ShipmentsService.confirmDelivery(this.shipment.id, this.values())
          .then(() => {
            if (this.callback && typeof this.callback === "function") {
              this.callback();
            }

            message.success(
              'Your delivery code was successfully applied. Shipment status was updated to "Closed" status.'
            );

            this.execHook("onReset");
          })
          .catch(e => {
            const {
              response: { status, data: { message: errorMessage } = {} } = {}
            } = e;

            switch (status) {
              case 403:
                message.warning(errorMessage);
                break;
              case 401:
                message.warning(
                  `You can't confirm delivery for this shipment.`
                );
                break;
              default:
                message.warning(
                  "Something went wrong, please try again later."
                );
            }
          });
      },
      onReset: () => {
        this.shipment = null;
        this.callback = null;
      }
    };
  }
}

export default new ShipmentDeliveryCodeForm();
