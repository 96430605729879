/* eslint-disable class-methods-use-this */
import { observable, action, computed } from "mobx";
import { message } from "antd";
import TruckService from "../../../services/api/trucks";
import BaseForm from "../base-form";

class RemoveTruckForm extends BaseForm {
  @observable truckId = null;

  @observable manufacturer = null;

  @observable isRemovingSuccess = false;

  @computed
  get shouldBeOpened() {
    return !!this.truckId;
  }

  showPostRemoveMessage() {
    const postRemoveMessageString = this.postRemoveMessage;
    if (typeof postRemoveMessageString !== "string") {
      return;
    }

    message.info(postRemoveMessageString, 5, () => {
      this.postRemoveMessage = null;
    });
  }

  @action
  delete() {
    const values = {
      id: this.truckId,
      ...this.values()
    };
    return TruckService.delete(values)
      .then(({ data }) => {
        this.isRemovingSuccess = true;
        this.showPostRemoveMessage();
        this.resetState();
        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          const {
            data: { errors }
          } = e.response;

          Object.entries(errors).forEach(entry => {
            const [fieldName, errorMessage] = entry;
            if (this.has(fieldName)) {
              this.$(fieldName).invalidate(errorMessage);
            }
          });
        } else {
          message.warning(e.message);
        }
      });
  }

  @action
  initState({ id, manufacturer, postRemoveMessage }) {
    this.truckId = id;
    this.manufacturer = manufacturer;
    this.postRemoveMessage = postRemoveMessage;
  }

  setup() {
    return {
      fields: [
        {
          name: "reason",
          label: "Specify Reason for Removing Truck",
          placeholder: "You Must Specify Reason for Removing Truck",
          rules: "required|max:1000",
          type: "text"
        }
      ]
    };
  }

  resetState() {
    this.$("reason").clear();
    this.$("reason").resetValidation();
    this.truckId = null;
    this.manufacturer = null;
    this.isRemovingSuccess = false;
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.delete();
      }
    };
  }
}

export default new RemoveTruckForm();
