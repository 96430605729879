import React from "react";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import StaticPage from "../../common/StaticPage";

export default function InfoPagePreview({ schema, withoutPostfix }) {
  const extraProps = {};
  if (!withoutPostfix) {
    extraProps.postfix = () => (
      <>
        <Title level={3} className="bottom-text">
          We look forward to serving you
        </Title>
        <Link className="link-primary btn-sign-up" to="/registration">
          Sign Up
        </Link>
      </>
    );
  }
  return <StaticPage preloadedSchema={schema} {...extraProps} />;
}
