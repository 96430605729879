/* eslint-disable import/no-cycle */
import { action, computed, observable, reaction } from "mobx";
import { message } from "antd";
import { matchPath } from "react-router";
import { Base64 } from "js-base64";
import sysend from "sysend";
import Auth from "../services/api/auth";
import { removeAuthHeader, setUpAuthHeader } from "../services/common";
import { history } from "../contexts/history";

// eslint-disable-next-line import/no-cycle
import AccessControl from "./access-control";
import SignUpForm from "./forms/auth/signup";
import { clearStorage } from "../utils/storage";

export const USER_ROLE_GUEST = "guest";
export const USER_ROLE_AGENT = "agent";
export const USER_ROLE_TRUCK_OWNER = "truck_owner";
export const USER_ROLE_SHIPPER = "shipper";

export const USER_STATUS_NOT_COMPLETED = "not_completed";
export const USER_STATUS_REJECTED = "rejected_awaiting_reapplication";
export const USER_STATUS_REAPPLIED = "reapplied";
export const USER_STATUS_CONTRACT_REJECTED = "contract_rejected";
export const USER_STATUS_ACTIVE = "active";
export const USER_STATUS_INACTIVE = "inactive";
export const USER_STATUS_PENDING = "pending";
export const USER_STATUS_UNKNOWN = "unknown";

class AuthStore {
  STORED_USER_KEY = "__user__";

  /**
   * auth token
   */
  @observable
  token;

  @observable
  authorizing = true;

  /**
   * indicates user's auth state
   */
  @observable
  isLoggedIn = false;

  @observable
  isForgotPassword = false;

  @observable
  isResetPassword = false;

  @observable
  isRequestingReapply = false;

  @observable
  resetPasswordToken;

  @observable
  user = {
    role: USER_ROLE_GUEST,
    status: USER_STATUS_UNKNOWN,
    profile: {}
  };

  /**
   * indicates the async sign in process
   * @type {boolean}
   */
  @observable
  isSubmitting = false;

  @observable
  isSubmitForgotPassword = false;

  @observable
  isSubmitResetPassword = false;

  @observable
  email;

  postLoginCallbackQueue = new Map();

  guardedPathsToPreventRedirectToSignUpCompletion = [
    "/reset/password",
    "/forgot/password"
  ];

  pathsToIgnoreAuthorization = ["/auth/reapply/:token"];

  constructor() {
    sysend.on("clear-site", () => {
      window.location.reload();
    });
    sysend.on("unauthorized-request", () => {
      window.location.reload();
    });
    reaction(
      () => this.token,
      accessToken => {
        if (accessToken) {
          window.localStorage.setItem("auth-token", accessToken);
          setUpAuthHeader({ accessToken });
        } else {
          window.localStorage.removeItem("auth-token");
          removeAuthHeader();
        }
      }
    );

    reaction(
      () => this.user,
      () => {
        const skipReactionDueToSignUpSettingUp =
          this.user.disable_signup_notifications === true;

        // in case of setting user within sign up (first step) skip this reaction due to useless
        if (skipReactionDueToSignUpSettingUp) {
          return;
        }

        const isAbleToRedirect = this.isAbleToRedirectToSignUp;

        if (
          isAbleToRedirect &&
          (this.isAccountNotCompleted ||
            this.isAccountRejected ||
            this.isAccountReapplied)
        ) {
          history.push({
            pathname: "/registration"
          });
          const registrationState = this.user.registration_request;

          if (!registrationState.email_verification) {
            message.warning(
              `Your Registration is Incomplete. Please use the code in the verification email sent to 
              ${this.user.email} to verify your email.`
            );
          } else if (this.isAccountRejected) {
            message.warning(
              `Your registration request has been rejected. Please, reapply it using a Re-apply link sent to ${this.user.email}`
            );
            this.reset();
            history.replace({
              pathname: "/"
            });

            return;
          } else {
            message.warning("Your Registration is Incomplete.");
          }

          return;
        }

        if (isAbleToRedirect && this.isContractRejected) {
          history.push({
            pathname: "/registration"
          });

          return;
        }

        if (isAbleToRedirect && this.isAccountInactive) {
          history.push({
            pathname: "/registration"
          });
          message.info(
            "Your account has not been verified by an administrator. Your registration request will be reviewed within 24 hours and you will be notified via email."
          );
        }

        AccessControl.setUser(this.user);
      }
    );

    reaction(
      () => this.isLoggedIn,
      isLoggedIn => {
        if (!isLoggedIn) {
          this.logout();
        }
      }
    );

    reaction(
      () => this.isAccountActive,
      () => {
        this.isLoggedIn = this.isAccountActive;
      }
    );

    this.loadToken();
  }

  /**
   * if user has fresh sate after reapply set the flag `shouldShowFirstStepAfterReapply`
   * to ensure that the first step of sign up will be shown
   * @param user
   * @returns {*}
   */
  populateUserWithStatusState = user => {
    const {
      registration_request: { status, prequalification_checklist } = {}
    } = user;

    if ([USER_STATUS_REAPPLIED, USER_STATUS_REJECTED].includes(status)) {
      user.shouldShowFirstStepAfterReapply = !prequalification_checklist;
    }

    // user.role = USER_ROLE_SHIPPER;
    return user;
  };

  @action
  async login(values) {
    this.isSubmitting = true;

    return Auth.login(values)
      .then(({ user, accessToken }) => {
        this.setToken(accessToken);
        this.user = user;
        this.persistUser(user);
        this.executePostLoginCallbacks();

        return user;
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  @action
  async logout() {
    history.replace("/");

    Auth.logout(this.token);

    message.info("Successfully logged out");
    this.reset();
    SignUpForm.fullReset();
  }

  @action
  localLogout() {
    this.reset();
    SignUpForm.fullReset();
  }

  @action
  authorize() {
    // const persistedUser = this.loadUserFromStorage();
    // * if there is no token or opened path shouldn't authorize user then prevent authorization
    if (!this.hasToken || !this.shouldBeAuthorized) {
      this.authorizing = false;

      return Promise.resolve();
    }
    //
    // if (persistedUser) {
    //   this.user = this.populateUserWithStatusState(persistedUser);
    //   this.authorizing = false;
    //   return Promise.resolve();
    // }

    this.authorizing = true;

    return Auth.authorizeByToken()
      .then(user => {
        this.user = this.populateUserWithStatusState(user);
        if (this.user.status === USER_STATUS_ACTIVE) {
          this.persistUser(this.user);
        }
      })
      .catch(() => {
        this.token = undefined;
      })
      .finally(() => {
        this.authorizing = false;
      });
  }

  @action
  setToken(token = null) {
    this.token = token;
  }

  @action
  setUser(user) {
    this.user = user;
  }

  @action
  setResetPasswordToken(token = null) {
    this.resetPasswordToken = token;
  }

  @action
  setEmail(email = null) {
    this.email = email;
  }

  @action
  loadToken() {
    this.token = localStorage.getItem("auth-token");
  }

  @action
  async forgotPassword(values) {
    this.isSubmitForgotPassword = true;

    return Auth.resetPassword("forgot", { email: values.email_request })
      .then(({ data }) => {
        this.isForgotPassword = true;
        message.info("Change password link sent to your Email.");

        return data;
      })
      .finally(() => {
        this.isSubmitForgotPassword = false;
      });
  }

  @action
  async resetPassword(values) {
    this.isSubmitResetPassword = true;
    const params = {
      email: this.email,
      token: this.resetPasswordToken,
      ...values
    };

    return Auth.resetPassword("reset", params)
      .then(({ data: { user, token } }) => {
        this.isResetPassword = true;
        message.info("Password changed successfully.");
        this.setToken(token);
        this.user = user;

        return user;
      })
      .finally(() => {
        this.isSubmitResetPassword = false;
      });
  }

  @action
  requestReapply(email) {
    if (!email) {
      return Promise.resolve();
    }

    this.isRequestingReapply = true;

    return new Promise((resolve, reject) => {
      Auth.requestReapply(email)
        .then(() => {
          message.success(
            `A re-apply link has been sent to ${email}. Please, check your inbox.`
          );
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          this.isRequestingReapply = false;
        });
    });
  }

  @action
  loadRejectedAccount(reapplyToken) {
    this.token = null;
    Auth.loadRejectedAccount(reapplyToken)
      .then(({ user, token }) => {
        this.setToken(token);
        this.user = this.populateUserWithStatusState(user);
      })
      .catch(error => {
        const { response: { status } = {} } = error;

        // * 422 code indicates that reapply token is invalid
        if ([426, 422].includes(status)) {
          message.warning(
            "Your link is expired or was already used. Please, request for another link."
          );
          history.replace("/");
        }
      });
  }

  @action
  activateUser() {
    this.user.status = USER_STATUS_ACTIVE;
    this.isLoggedIn = true;
  }

  @action
  reset() {
    this.user = {
      role: USER_ROLE_GUEST,
      status: USER_STATUS_UNKNOWN
    };
    this.isLoggedIn = false;
    this.setToken(null);
    sysend.broadcast("clear-site");
    clearStorage();
    this.removeUserFromStorage();
  }

  persistUser() {
    // try {
    //   sessionStorage.setItem(
    //     this.STORED_USER_KEY,
    //     Base64.encode(JSON.stringify(user))
    //   );
    // } catch (e) {
    //   console.dir(e);
    // }
  }

  loadUserFromStorage() {
    const user = sessionStorage.getItem(this.STORED_USER_KEY);

    try {
      const decodedUser = Base64.decode(user);

      return JSON.parse(decodedUser);
    } catch (e) {
      return false;
    }
  }

  removeUserFromStorage() {
    sessionStorage.removeItem(this.STORED_USER_KEY);
  }

  pushLoginCallback(callback) {
    if (typeof callback !== "function") {
      return;
    }

    this.postLoginCallbackQueue.set(callback, false);
  }

  executePostLoginCallbacks() {
    if (this.postLoginCallbackQueue.size === 0) {
      return;
    }

    this.postLoginCallbackQueue.forEach((isExecuted, callback) => {
      if (!isExecuted && typeof callback === "function") {
        new Promise((resolve, reject) => {
          try {
            callback();
            resolve();
          } catch (e) {
            reject();
          }
        }).then(() => {
          this.postLoginCallbackQueue.delete(callback);
        });
      }
    });
  }

  @computed
  get hasToken() {
    return !!this.token;
  }

  @computed
  get isAccountActive() {
    return this.user.status === USER_STATUS_ACTIVE;
  }

  @computed
  get isAccountInactive() {
    return this.user.status === USER_STATUS_INACTIVE;
  }

  @computed
  get isAccountNotCompleted() {
    return (
      this.user.registration_request &&
      this.user.registration_request.status === USER_STATUS_NOT_COMPLETED
    );
  }

  @computed
  get isAccountRejected() {
    return (
      this.user.registration_request &&
      this.user.registration_request.status === USER_STATUS_REJECTED
    );
  }

  @computed
  get isAccountReapplied() {
    return (
      this.user.registration_request &&
      this.user.registration_request.status === USER_STATUS_REAPPLIED
    );
  }

  @computed
  get isContractRejected() {
    return (
      this.user.registration_request &&
      this.user.registration_request.status === USER_STATUS_CONTRACT_REJECTED
    );
  }

  @computed
  get userIs() {
    return (role = USER_ROLE_GUEST) => this.user.role === role;
  }

  @computed
  get isGuest() {
    return this.userIs() || !this.isLoggedIn;
  }

  @computed
  get isTruckOwner() {
    return this.userIs(USER_ROLE_TRUCK_OWNER);
  }

  @computed
  get isShipper() {
    return this.userIs(USER_ROLE_SHIPPER);
  }

  @computed
  get isAgent() {
    return this.userIs(USER_ROLE_AGENT);
  }

  get isAbleToRedirectToSignUp() {
    const isGuardedPath = this.guardedPathsToPreventRedirectToSignUpCompletion.includes(
      history.location.pathname
    );

    return this.isForgotPassword || this.isResetPassword || !isGuardedPath;
  }

  /**
   * some action like reapply should prevent authorization and setting up the user by token
   * @returns {boolean}
   */
  get shouldBeAuthorized() {
    return (
      this.pathsToIgnoreAuthorization.filter(path => {
        return !!matchPath(history.location.pathname, {
          path
        });
      }).length === 0
    );
  }

  @computed
  get userId() {
    return this.user.id;
  }

  @computed
  get isBankInfoFull() {
    const { bank_name_id, nuban_bank_account_number, beneficiary_name } =
      this.user.profile || {};

    return (
      bank_name_id !== null &&
      nuban_bank_account_number !== null &&
      beneficiary_name !== null
    );
  }
}

export default new AuthStore();
