import React, { useState, useEffect } from "react";
import Col from "antd/lib/grid/col";
import { PageHeader, Collapse, Icon, Affix } from "antd";
import cx from "classnames";
import Row from "antd/lib/grid/row";
import { inject, observer } from "mobx-react";
import Helmet from "../../common/Helmet";
import FormContext from "../../models/forms/form-context";
import { SUPPORT_ADDITIONAL_MAIL, INFO_PHONE } from "../../config/constants";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";

import "./styles.less";

const gutter = { lg: 10, sm: 10, xs: 15 };
const { Panel } = Collapse;

const FAQ = inject("AppState")(
  observer(({ AppState }) => {
    useEffect(() => {
      AppState.addLayoutClass("faq");

      return () => AppState.removeLayoutClass("faq");
    }, [AppState]);
    const [activeButton, setButtonState] = useState("vh-button");

    const handleSwitchSection = sectionName => e => {
      setButtonState(sectionName);
      const element = document.querySelector(
        `#${sectionName.replace("-button", "")}`
      );

      if (!element) {
        return;
      }
      e.preventDefault();

      element.scrollIntoView({ block: "start", behavior: "smooth" });
    };

    return (
      <FormContext.Provider>
        <Helmet title="FAQ" />
        <Row className="faq-img" type="flex" justify="center">
          {" "}
        </Row>
        <Row gutter={gutter} className="header">
          <Col
            lg={{ span: 21, offset: 1 }}
            md={{ span: 21, offset: 0 }}
            sm={{ span: 21, offset: 0 }}
          >
            <PageHeader title="Frequently Asked Questions" />
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col
            className="affix-wrapper"
            lg={{ span: 4, offset: 1 }}
            md={{ span: 6, offset: 1 }}
            sm={{ span: 6, offset: 0 }}
            xs={{ span: 24 }}
          >
            <Affix>
              <div className="buttons-wrapper">
                <a
                  className={cx("button", {
                    active: activeButton === "vh-button"
                  })}
                  href="#vh"
                  onClick={handleSwitchSection("vh-button")}
                >
                  Virtual Haulage
                </a>
                <a
                  className={cx("button", {
                    active: activeButton === "shipper-button"
                  })}
                  onClick={handleSwitchSection("shipper-button")}
                  href="#shipper"
                >
                  Shipper
                </a>
                <a
                  className={cx("button", {
                    active: activeButton === "truck-owner-button"
                  })}
                  href="#truck-owner"
                  onClick={handleSwitchSection("truck-owner-button")}
                >
                  Truck Owner
                </a>
                <a
                  className={cx("button", {
                    active: activeButton === "agent-button"
                  })}
                  href="#agent"
                  onClick={handleSwitchSection("agent-button")}
                >
                  Agent
                </a>
              </div>
            </Affix>
          </Col>

          <Col
            lg={{ span: 18, offset: 1 }}
            md={{ span: 14, offset: 0 }}
            sm={{ span: 16, offset: 1 }}
            xs={{ span: 24, offset: 0 }}
          >
            <div className="section-wrapper">
              <div className="vh-section font-bolder fs-24" id="vh">
                Virtual Haulage
              </div>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <Icon component={Arrow} rotate={isActive ? 180 : 0} />
                )}
              >
                <Panel
                  header="What Service Does Virtual Haulage Provide and How?"
                  key="1"
                >
                  <p className="faq-content">
                    VH connects Shippers with vetted Truck Owners and provides
                    Truck Owners with easy access to shipments along their
                    preferred routes. VH accomplishes this in two ways to ensure
                    optimal success rates for both Shippers and Truck Owners:
                  </p>
                  <ul>
                    <li>Matching of Shipments to Truck Routes:</li>
                    <ul>
                      <li>
                        Truck Owner logs available truck routes, Shipper posts
                        shipment request
                      </li>
                      <li>
                        Shipment requests are matched with available truck
                        routes
                      </li>
                      <li>Successful Truck Owners send quotes to Shipper</li>
                      <li>Shipper selects preferred quote/truck owner</li>
                      <li>Preferred Truck Owner accepts shipment</li>
                      <li>
                        Shipment is picked up at origin and delivered to
                        destination
                      </li>
                      <li>
                        After delivery, Truck Owner is paid within 48 hours
                      </li>
                    </ul>
                    <li>
                      VH MarketPlace:
                      <p className="faq-content">
                        All “Available Truck Routes” logged by Truck Owners and
                        “Shipment Requests” posted by Shippers are displayed in
                        the “VH Virtual MarketPlace”. Truck Owners may also send
                        quotes for Shipment Requests from the market place. Both
                        registered and non-registered users have access to the
                        “VH MarketPlace”
                      </p>
                    </li>
                  </ul>
                </Panel>
                <Panel
                  header="What is Virtual Haulage Service Coverage Area?"
                  key="2"
                >
                  <p className="faq-content">
                    Truck Owners on the VH Platform provide Haulage Services
                    to/from major cities and business hubs in Nigeria. Smaller
                    towns with manufacturing industries, agricultural or raw
                    material hubs are also covered. VH will continue to expand
                    the network of destinations as more Truck Owners with trucks
                    plying various routes join the platform and as the Virtual
                    Haulage marketplace grows.
                  </p>
                </Panel>
                <Panel
                  header="Are shipment quotes based on shipment weight or full truck loads?"
                  key="3"
                >
                  <p className="faq-content">
                    Virtual Haulage assumes full truck loads for all shipments.
                    Total shipment weight is required when requesting for a
                    quote or posting a shipment to enable Truck Owners determine
                    the size and number of trucks required to transport the
                    shipment. Shipments posted which are less than a full truck
                    load will receive quotes for a full truck load.
                  </p>
                </Panel>
                <Panel
                  header="Do Contracts between Shippers and Truck Owners need to be in writing?"
                  key="4"
                >
                  <p className="faq-content">
                    To make certain that the agreement between the Truck Owners
                    and Shipper is binding and ensure that the rights of each
                    party are protected, all agreements must be written.
                    However, Virtual Haulage has taken the burden off Shippers
                    and Truck Owners as written agreements are system generated
                    by the VH Platform and are signed electronically by both
                    parties.
                  </p>
                </Panel>
                <Panel
                  header="Can Shippers and Truck Owners contact each other outside the Virtual Haulage Platform?"
                  key="5"
                >
                  <p className="faq-content">
                    Shippers and Truck Owners may contact each other directly
                    after both parties are introduced on the Virtual Haulage
                    Platform, contact details provided and the shipment contract
                    has been signed by both parties. Truck Owners may require
                    further directions about the pickup location and other
                    logistics details and may contact the shipper by phone,
                    email or via the Virtual Haulage In-System messaging
                    platform. Before, the exchange of contact details
                    facilitated by Virtual Haulage, shippers and truck owners
                    may communicate with each other with respect to a particular
                    shipment via the In-System messaging platform.
                  </p>
                  <p className="faq-content">
                    Shippers and Truckers may not contact each other directly
                    for haulage purposes for a minimum of 12 months after the
                    last contract between both parties on the Virtual Haulage
                    Platform. Shippers and Truck Owners who fail to respect this
                    regulation will be blacklisted and removed from the Virtual
                    Haulage market place. It is important to note that Virtual
                    Haulage will not be held accountable for any job contracted
                    between a shipper/agent and a truck owner outside the
                    Virtual Haulage Platform
                  </p>
                </Panel>
                <Panel header="Who do I contact if I have enquiries?" key="6">
                  <p className="faq-content">
                    For any concerns, questions or comments, you may send an
                    email to{" "}
                    <a
                      href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                      className="email-link link-common font-bolder fs-16"
                    >
                      {SUPPORT_ADDITIONAL_MAIL}
                    </a>{" "}
                    We will respond within 24 hours as Service excellence and
                    meeting the needs of our clients is top priority for us at
                    Virtual Haulage.
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="section-wrapper">
              <div className="shipper-section font-bolder fs-24" id="shipper">
                Shipper
              </div>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <Icon component={Arrow} rotate={isActive ? 180 : 0} />
                )}
              >
                <Panel
                  header="Do Shippers have to register to use the VH Platform?"
                  key="1"
                >
                  <p className="faq-content">
                    Yes, shippers have to provide contact information and some
                    other required details to enable VH create accounts that
                    will enable them access shipper’s features.
                  </p>
                </Panel>
                <Panel
                  header="Are Truck Owners pre-screened before they can participate on the Virtual Haulage Platform?"
                  key="2"
                >
                  <p className="faq-content">
                    Truck Owners go through a pre-qualification process before
                    they receive approval to participate on the Virtual Haulage
                    platform. After company registration approval, trucks
                    registered on the platform by Truck Owners are allowed to
                    transport shipments only after they are physically inspected
                    by VH agents to ensure that they are road worthy, have the
                    required documentation stipulated by law and meet minimum
                    insurance standards. The on-going performance of Truck
                    Owners is also monitored as Shippers are required to rate
                    Truck Owners performance in terms of On-time Delivery,
                    Communication with Trucking Company/Driver, Truck Appearance
                    etc. Truck Owner’s rating is also dependent on the state of
                    their trucks which is rated during the truck inspection
                    process. Truck Owner’s rating is visible to shippers and
                    agents.
                  </p>
                </Panel>
                <Panel header="Does VT insure my shipment?" key="3">
                  <p className="faq-content">
                    VT provides Goods-In-Transit (GIT) Cargo Insurance via a
                    reputable insurance company at a fee. The Shipper/Agent is
                    given the opportunity to select the insurance option during
                    the Truck Owner selection Process after quotes for the
                    shipment are received. The GIT insurance premium is a small
                    percentage of the cargo value declared by the shipper/agent.
                    If the insurance option is selected, the invoice sent to the
                    shipper/agent contains the Truck Owner’s haulage charge as
                    well as the insurance cost.
                  </p>
                </Panel>
                <Panel
                  header="Can I get a quote before book a shipment?"
                  key="4"
                >
                  <p className="faq-content">
                    Yes, estimated quotes are available using the “Get Quote”
                    feature on the VH platform. You do not have to be a
                    registered user to utilize this function. Also, when a
                    shipment/haulage job is posted by a Shipper/Agent, Truck
                    Owners send in specific quotes for that shipment. This
                    allows the Shipper/Agent to compare quotes received.
                  </p>
                </Panel>
                <Panel
                  header="Can I select the Truck Owner to handle my shipment?"
                  key="5"
                >
                  <p className="faq-content">
                    Yes, VH allows Shippers/Agents select the Truck Owner who
                    will handle their shipment and provides relevant information
                    to support Shippers to make the best decision. Quotes sent
                    in by Truck Owners for the particular shipment are presented
                    to the shipper along with the Truck Owners rating. Shippers
                    are allowed to select up to 3 Truck Owners in order of
                    preference. The preferred Truck Owner is given the first
                    option of refusal and the Shipper is informed when a Truck
                    Owner accepts the haulage job.{" "}
                  </p>
                </Panel>
                <Panel
                  header="Must I provide a Bill of Lading to the Truck Owner who is transporting my cargo?"
                  key="6"
                >
                  <p className="faq-content">
                    At the point of Shipment Pick Up, the shipper must provide
                    the Truck Driver with a Bill of Lading which will be
                    delivered to the recipient at the destination. The bill of
                    Lading authorizes the Truck Owner to transport the Shipper’s
                    cargo and is usually required by security operatives along
                    the route to prove that the Truck Owner has been authorized
                    by the Shipper/Agent. It is also advised that copies of
                    other documents that show cargo proof of ownership are made
                    available to the truck driver to ensure hitch free passage
                    at security check points along the route.
                  </p>
                </Panel>
                <Panel
                  header="How do I monitor delivery progress of my shipment?"
                  key="7"
                >
                  <p className="faq-content">
                    VH provides Delivery Status online for Active Shipments.
                  </p>
                </Panel>
                <Panel
                  header="How do I provide feedback for a shipment?"
                  key="8"
                >
                  <p className="faq-content">
                    During delivery execution and after delivery completion, VH
                    gives Shippers/Agents the option to provide feedback on a
                    shipment using the “Report Issue” feature. Shippers/Agents
                    may also rate Truck Owner’s performance for each shipment.
                  </p>
                </Panel>
                <Panel
                  header="Who do I contact if there is a problem with a shipment?"
                  key="9"
                >
                  <p className="faq-content">
                    The “Report Issue” feature which is available to all
                    registered users, allows Shippers/Agents to inform VH about
                    shipment issues. This is done via the Messaging System on
                    the VH portal. An Issue Number is allocated to every
                    issue/complaint reported. This number enables VH to track
                    issues until they are resolved. You may also send an email
                    to{" "}
                    <a
                      href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                      className="email-link link-common font-bolder fs-16"
                    >
                      {SUPPORT_ADDITIONAL_MAIL}.
                    </a>
                  </p>
                </Panel>
                <Panel
                  header="Who do I contact for billing or service issues?"
                  key="10"
                >
                  <p className="faq-content">
                    You may send an email to{" "}
                    <a
                      href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                      className="email-link link-common font-bolder fs-16"
                    >
                      {SUPPORT_ADDITIONAL_MAIL}
                    </a>
                    for any concerns or questions that you may have. We will
                    respond within 24 hours as service excellence and meeting
                    the needs of our clients is top priority for us at Virtual
                    Haulage.
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="section-wrapper">
              <div
                className="truck_owner-section font-bolder fs-24"
                id="truck-owner"
              >
                Truck Owner
              </div>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <Icon component={Arrow} rotate={isActive ? 180 : 0} />
                )}
              >
                <Panel
                  header="Who can register to participate as a Truck Owner on the Virtual Haulage Platform?"
                  key="1"
                >
                  <p className="faq-content">
                    Trucking Companies legally registered with the Corporate
                    Affairs Commission in Nigeria. Truck Owners are required to
                    fill a company registration form on the VH Web App. VH
                    pre-screens Truck Owners and approval is given if the Truck
                    Owner meets the required criteria. If approved, Truck Owners
                    are required to register their trucks on the VH platform.
                    Only approved trucks are allowed to participate in haulage
                    job bids.{" "}
                  </p>
                </Panel>
                <Panel
                  header="What kind of trucks are allowed on the Virtual Haulage Platform?"
                  key="2"
                >
                  <p className="faq-content">
                    VH allows most Commercial Trucks. These include Tractor
                    Trailers, Dump or Tipper Trucks, Lorries, Cargo Vans,
                    Cooling Vans, Equipment Trailers, Mobile Cranes, Escavators
                    as well as other earth moving and lifting operations
                    equipment etc.{" "}
                  </p>
                </Panel>
                <Panel
                  header="Can I use any of my trucks on the Virtual Haulage Platform?"
                  key="3"
                >
                  <p className="faq-content">
                    Only trucks which have been registered by Truck Owners and
                    approved by Virtual Haulage may be used to transport
                    shipments for Shippers on the VH platform. To register a
                    truck, a Truck Registration form should be filled with the
                    required information. An Inspection Agent is selected by the
                    Truck Owner during the truck registration process. After the
                    truck is inspected, approval is given or the Truck Owner is
                    advised to correct some anomalies. Recertification of trucks
                    is required annually to ensure road worthiness of all trucks
                    on the VH platform.
                  </p>
                </Panel>
                <Panel
                  header="How long does it take for trucks registered on the platform to be approved?"
                  key="4"
                >
                  <p className="faq-content">
                    Trucks registered on the platform must be inspected within
                    one month by a VH Inspection Agent and approval is given
                    within 72 hours after inspection in completed. Trucks will
                    be available for use on the platform immediately approval is
                    given.
                  </p>
                </Panel>
                <Panel
                  header="After Registration Approval, how long before I can begin to bid for haulage jobs?"
                  key="5"
                >
                  <p className="faq-content">
                    After company registration and registered truck approval,
                    trucks are made available on the VH Platform and Truck
                    Owners can begin to send quotes for haulage jobs
                    immediately.
                  </p>
                </Panel>
                <Panel
                  header="Can any Driver drive my trucks registered on the VH Platform?"
                  key="6"
                >
                  <p className="faq-content">
                    Only drivers who have been registered and approved on the VH
                    platform can drive approved trucks. To obtain approval,
                    drivers must have the required driver’s license for the
                    specific vehicle types which the driver will be linked to on
                    the platform. E.g. Driver’s must have License Class G to
                    drive articulated trucks. Also, note that each driver may be
                    assigned to only one truck at a time.
                  </p>
                </Panel>
                <Panel
                  header="How soon do truck owners get paid after successful shipment delivery?"
                  key="7"
                >
                  <p className="faq-content">
                    Truck Owners get paid within 48 hours after successful
                    delivery of shipments.
                  </p>
                </Panel>
                <Panel
                  header="Who do I contact if I have a problem with a shipment?"
                  key="8"
                >
                  <p className="faq-content">
                    The “Report Issue” feature which is available to all
                    registered users, allows Truck Owners to inform VH about
                    shipment issues. This is done via the Messaging System on
                    the VH portal. An Issue Number is allocated to every
                    issue/complaint reported. This number enables VH to track
                    issues until they are resolved. You may also send an email
                    to{" "}
                    <a
                      href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                      className="email-link link-common font-bolder fs-16"
                    >
                      {SUPPORT_ADDITIONAL_MAIL}.
                    </a>
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="section-wrapper">
              <div className="agent-section font-bolder fs-24" id="agent">
                Agent
              </div>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <Icon component={Arrow} rotate={isActive ? 180 : 0} />
                )}
              >
                <Panel
                  header="What is the process to become an Agent on the Virtual Haulage Platform."
                  key="1"
                >
                  <p className="faq-content">
                    An Agent may register as a company or an individual. VH
                    pre-screens Agents and approval is given if the Agent meets
                    the required criteria.
                  </p>
                </Panel>
                <Panel
                  header="Can Agents post a shipment on behalf of a client/shipper?"
                  key="2"
                >
                  <p className="faq-content">
                    Once approved, Agents may post shipments on behalf of their
                    clients on the VH platform.
                  </p>
                </Panel>
                <Panel
                  header="Can I pay for a shipment on behalf of a client/shipper?"
                  key="3"
                >
                  <p className="faq-content">
                    Yes, Agents may pay for shipments on behalf of clients.
                  </p>
                </Panel>
                <Panel
                  header="Can a Client/Shipper pay for a shipment which I have posted?"
                  key="4"
                >
                  <p className="faq-content">
                    Yes, Clients/Shippers may pay for a shipment which was
                    posted by an Agent. However, Shippers will need to register
                    on the VT platform to be able to view shipments posted on
                    their behalf and make payments for same.
                  </p>
                </Panel>
                <Panel
                  header="Who do I contact if I have a problem with a shipment?"
                  key="5"
                >
                  <p className="faq-content">
                    The “Report Issue” feature which is available to all
                    registered users, allows Shippers/Agents to inform VH about
                    shipment issues. This is done via the Messaging System on
                    the VH portal. An Issue Number is allocated to every
                    issue/complaint reported. This number enables VH to track
                    issues until they are resolved. You may also send an email
                    to{" "}
                    <a
                      href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                      className="email-link link-common font-bolder fs-16"
                    >
                      {SUPPORT_ADDITIONAL_MAIL}
                    </a>
                    .
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="footer-section m-top-20 m-left-35 fs-16">
              <p>Do You Have Other Questions or Enquiries?</p>
              <p className="footer-content">
                Please call our helpdesk{" "}
                <a
                  href={`tel:${INFO_PHONE}`}
                  className="phone-number link-common font-bolder fs-16"
                >
                  {INFO_PHONE}
                </a>{" "}
                or send an email to{" "}
                <a
                  href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                  className="email-link link-common font-bolder fs-16"
                >
                  {SUPPORT_ADDITIONAL_MAIL}
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </FormContext.Provider>
    );
  })
);
export default FAQ;
