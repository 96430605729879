import "core-js/stable";
import "proxy-polyfill/proxy.min";
import "regenerator-runtime/runtime";
import axios from "axios";
import sysend from "sysend";

import { setUpAuthHeader } from "../services/common";
import throttledMessage from "../utils/throttled-message";
import AuthStore from "../models/auth";
import "../utils/sentry";
import "./setup-moment";

const notifiableStatuses = [405, 500, 404];

const axiosErrorHandlingMessage = throttledMessage(
  "Something went wrong, please try again later.",
  "warning"
);

const apiRoutesToIgnoreDefaultErrorMessage = [
  "/collaboration/confirm",
  "/contract/cancel",
  "/trucks/reapply/",
  /drivers\/\d+$/g
];

const apiRoutesToSkipLogoutAfterUnauthorizedRequest = [
  "/trucks/request/inspection"
];

const requesterUrlIsIngored = url => {
  return (
    apiRoutesToIgnoreDefaultErrorMessage.filter(subUrl => {
      return url.match(subUrl);
    }).length !== 0
  );
};

const shouldSkipLogoutAfterUnauthorizedRequest = url =>
  apiRoutesToSkipLogoutAfterUnauthorizedRequest.filter(subUrl => {
    return url.match(subUrl);
  }).length !== 0;

(() => {
  const accessToken = localStorage.getItem("auth-token");
  if (accessToken) {
    setUpAuthHeader({ accessToken });
  }
  axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers["Content-Type"] = "application/json";
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.response.use(
    response => response,
    error => {
      console.dir(error);
      const { response: { status } = {}, config: { url = "" } = {} } = error;

      if (notifiableStatuses.includes(status) || !status) {
        if (!requesterUrlIsIngored(url)) {
          axiosErrorHandlingMessage();
        }
      }

      if (status === 401) {
        sysend.broadcast("unauthorized-request");

        if (
          AuthStore.isLoggedIn &&
          !shouldSkipLogoutAfterUnauthorizedRequest(url)
        ) {
          console.info("%cUnauthorized request", "color: red");
          AuthStore.localLogout();
        }
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );
})();
