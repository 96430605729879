import { action, observable } from "mobx";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import TruckInspectionService from "../../../services/api/trucks-inspection";

import TrucksInspectionStore from "../../trucks-inspection";

class TruckInspectionDeclineForm extends BaseForm {
  @observable
  shouldBeOpened = false;

  @observable
  truck = {};

  constructor(inspectionsStore) {
    super();

    this.inspectionsStore = inspectionsStore;
  }

  @action
  openForm(truck) {
    this.truck = truck;
    this.loadDeclineReasons();

    if (this.truck.reason) {
      this.$("reason").set("value", this.truck.reason);
      this.$("comment").set("value", this.truck.comment);
    }

    this.$("reason").set("disabled", !this.inspectionsStore.canSubmit);
    this.$("comment").set("disabled", !this.inspectionsStore.canSubmit);

    this.shouldBeOpened = true;
  }

  @action
  closeForm() {
    this.truck = {};
    this.shouldBeOpened = false;
    this.clear();
  }

  @action
  async loadDeclineReasons() {
    const reasonsField = this.$("reason");

    if (reasonsField.options.length !== 0) {
      return;
    }

    reasonsField.loading = true;
    const options = await TruckInspectionService.loadDeclineReasons();

    options.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );

    const otherOptionIndex = options.findIndex(
      option => option.label.toLowerCase() === "others"
    );

    options.push(...options.splice(otherOptionIndex, 1));

    reasonsField.set("options", options);
    reasonsField.loading = false;
  }

  // eslint-disable-next-line class-methods-use-this
  setup() {
    return {
      fields: [
        {
          name: "reason",
          label: "Specify Reason for Declining Truck",
          type: "select",
          rules: "required",
          options: []
        },
        {
          name: "comment",
          type: "text",
          rules: "max_1000",
          label: "Comment",
          placeholder: "Some words about the Reason for Declining Truck"
        }
      ],
      hooks: {}
    };
  }

  // eslint-disable-next-line class-methods-use-this
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          validator.register(
            "max_1000",
            value => {
              return value.length <= 1000;
            },
            "This field cannot be longer than 1000 characters."
          );
        }
      })
    };
  }

  hooks() {
    return {
      onSuccess: () => {
        this.inspectionsStore.declineTruck({
          id: this.truck.id,
          ...this.values()
        });

        this.closeForm();
      },
      onReset: () => {
        this.closeForm();
      }
    };
  }
}

export default new TruckInspectionDeclineForm(TrucksInspectionStore);
