import moment from "moment";

import deepCleaner from "deep-cleaner";
import {
  INVALID_DATE_ERROR_MESSAGE,
  MAX_CARGO_VALUE,
  MAX_COMMODITY_LENGTH,
  // MAX_COMMODITY_LENGTH_SHARED,
  MAX_COMMODITY_VOLUME,
  MAX_COMMODITY_VOLUME_SHARED,
  MAX_COMMODITY_WEIGHT,
  MAX_COMMODITY_WEIGHT_SHARED,
  MAX_PICKUP_DAYS,
  REGEX
} from "../../../config/constants";
import { validateTimeRangeInWorkingHours } from "../../../utils/date-time";
import ShipmentsService from "../../../services/api/shipments";

export default function({ validator }) {
  const ruleMessage = `${INVALID_DATE_ERROR_MESSAGE} This field must be between 1 and`;
  const volumeRuleMessage = `${ruleMessage} ${MAX_COMMODITY_VOLUME}.`;
  const cargoValueRuleMessage = `${ruleMessage} ${
    MAX_CARGO_VALUE.toString().length
  } characters.`;
  let messages = validator.getMessages("en");

  messages = {
    ...messages,
    weight_rule: "Maximum weight is 2500 tons.",
    length_rule: `This field must be between 1 and 4 characters e.g. 1-1000 feet.`,
    volume_rule: volumeRuleMessage,
    between: `This field must be between :min and :max.`,
    cargo_rule: cargoValueRuleMessage
  };
  validator.setMessages("en", messages);
  validator.register(
    "weight_rule",
    value => {
      let valueToCompare = MAX_COMMODITY_WEIGHT;
      let validationMessage = "Maximum weight is 2500 tons.";

      if (this.$("is_shared_consignment").value) {
        valueToCompare = MAX_COMMODITY_WEIGHT_SHARED;
        validationMessage = "Maximum weight is 25.000 tons.";
      }

      messages = {
        ...messages,
        weight_rule: validationMessage
      };

      validator.setMessages("en", messages);

      return value >= 1 && value <= valueToCompare;
    },
    messages.weight_rule
  );

  validator.register(
    "length_rule",
    value => {
      return value >= 1 && value <= MAX_COMMODITY_LENGTH;
    },
    messages.length_rule
  );

  validator.register(
    "volume_rule",
    value => {
      let valueToCompare = MAX_COMMODITY_VOLUME;
      let validationMessage = "Maximum volume is 600.000 liters.";

      if (this.$("is_shared_consignment").value) {
        valueToCompare = MAX_COMMODITY_VOLUME_SHARED;
        validationMessage = "Maximum volume is 6.000.000 liters.";
      }

      messages = {
        ...messages,
        volume_rule: validationMessage
      };

      validator.setMessages("en", messages);

      return value >= 1 && value <= valueToCompare;
    },
    messages.volume_rule
  );

  validator.register(
    "cargo_value_rule",
    value => {
      return value >= 1 && value <= MAX_CARGO_VALUE;
    },
    messages.cargo_rule
  );

  validator.register(
    "regex_names",
    value => {
      return value.match(REGEX.NAMES);
    },
    INVALID_DATE_ERROR_MESSAGE
  );

  validator.register(
    "phone_number",
    value => {
      return value.match(REGEX.PHONE_NUMBER);
    },
    INVALID_DATE_ERROR_MESSAGE
  );

  validator.register(
    "date_range",
    value => {
      if (!Array.isArray(value)) {
        return false;
      }

      return (
        value.map(date => moment.isMoment(date)).filter(Boolean).length === 2
      );
    },
    "This field is required."
  );

  validator.register(
    "date_start_least_8h_from_now",
    (value, ...rest) => {
      const [, fieldName] = rest;
      const field = this.$(fieldName);
      const [pickUpDateStart] = value;

      if (!this.isNew && !field.changed) {
        return true;
      }

      if (!this.isNew && field.changed) {
        const initialValue = moment(
          this.loadedValues.pickup_date_start
        ).startOf("d");
        const startDateIsSameAsSaved = moment(pickUpDateStart)
          .startOf("d")
          .isSame(initialValue);

        if (startDateIsSameAsSaved) {
          return true;
        }
      }

      const selectedPickUpDate = moment(pickUpDateStart);
      const startOfCurrentWorkingDay = moment()
        .startOf("day")
        .add(9, "h");
      const nextWorkingDay = moment()
        .startOf("day")
        .add(1, "d")
        .add(8, "h");

      const selectedDateIsBeforeStartOfCurrentWorkingDay = selectedPickUpDate.isSameOrBefore(
        startOfCurrentWorkingDay
      );

      if (selectedDateIsBeforeStartOfCurrentWorkingDay) {
        return true;
      }

      return selectedPickUpDate.isSameOrAfter(nextWorkingDay);
    },
    `Pick Up Date cannot be earlier than 8 working hours.`
  );

  validator.register(
    `end_within_${MAX_PICKUP_DAYS}_days_from_start`,
    value => {
      const [pickUpDateStart, pickUpDateEnd] = value;
      const dateBetweenStart = moment(pickUpDateStart);
      const dateBetweenEnd = moment(dateBetweenStart)
        .add(Number.parseInt(MAX_PICKUP_DAYS, 10), "d")
        .add(1, "h");

      // console.log("7 days", dateBetweenStart, pickUpDateEnd, dateBetweenEnd);
      return moment(pickUpDateEnd).isBetween(dateBetweenStart, dateBetweenEnd);
    },
    `End Pick Up Date must be within ${MAX_PICKUP_DAYS} working days after First Pick Up Date.`
  );

  validator.registerAsync(
    "pickup_dates_api",
    (value, attribute, key, passes) => {
      const [pickup_date_start, pickup_date_end] = value;
      const dateStart = moment(pickup_date_start, "YYYY-MM-DD");
      const dateEnd = moment(pickup_date_end, "YYYY-MM-DD");

      const isPostWithoutShipperSelected = this.$("is_shipment_without_shipper")
        .value;

      const payload = {
        pickup_date_start: dateStart.format("YYYY-MM-DD"),
        pickup_date_end: dateEnd.format("YYYY-MM-DD"),
        pickup_date: moment(this.$("delivery_date").value).format()
      };

      if (!isPostWithoutShipperSelected) {
        payload.shipper_email =
          this.$("shipper_email").value || this.$("shipper_id").value;
      }

      ShipmentsService.validatePickUpDates(deepCleaner(payload)).catch(e => {
        const {
          response: {
            data: { errors }
          }
        } = e;

        const errorKeys = Object.keys(errors);

        if (
          errorKeys.includes("pickup_date_start") ||
          errorKeys.includes("pickup_date_end")
        ) {
          const dateRangeError = [
            ...(errors.pickup_date_start || []),
            ...(errors.pickup_date_end || [])
          ].join(" ");

          passes(false, dateRangeError);

          return;
        }

        passes();
      });
    }
  );

  validator.register(
    "pickup_time_between_working_hours",
    validateTimeRangeInWorkingHours,
    "Pick Up Time Range can be between 08:00 AM and 05:00 PM"
  );

  validator.register(
    "delivery_date_rule",
    value => {
      if (this.$("pickup_date_range").value) {
        const [, pickUpDateEnd] = this.$("pickup_date_range").value;

        // console.log(value, pickUpDateEnd);
        return moment(value)
          .startOf("d")
          .isSameOrAfter(
            moment(pickUpDateEnd)
              .startOf("d")
              .add(-1, "h")
          );
      }

      return true;
    },
    "Expected Delivery Date cannot be before Pick Up Date."
  );
}
