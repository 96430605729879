/* eslint-disable class-methods-use-this */
import { observable, action, computed } from "mobx";
import { message } from "antd";
import DriverService from "../../../services/api/drivers";
import BaseForm from "../base-form";
import { formatTruckLabel } from "../../../utils/format";

class UnlinkTruckForm extends BaseForm {
  @observable driverId = null;

  @observable truckId = null;

  @observable driver = null;

  @observable truck = null;

  @observable isLoading = false;

  @observable isUnlinkSuccessful = false;

  @computed
  get shouldBeOpened() {
    return !!this.driverId;
  }

  @action
  unlinkTruck() {
    const values = {
      id: this.driverId,
      ...this.values()
    };
    return DriverService.unlinkTruckFromDriver(values)
      .then(({ data }) => {
        this.resetState();
        this.isUnlinkSuccessful = true;
        message.success("Truck unlinked from Driver successfully.");
        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          const {
            data: { errors }
          } = e.response;
          Object.entries(errors).forEach(entry => {
            const [fieldName, errorMessage] = entry;
            if (this.has(fieldName)) {
              this.$(fieldName).invalidate(errorMessage);
            }
            message.error(errorMessage);
          });
        }
      });
  }

  @action
  getTruckLinkedToDriver({ id, first_name, middle_name, last_name }) {
    this.isLoading = true;
    return new Promise(resolve =>
      DriverService.getTruckLinkedToDriver(id)
        .then(truck => {
          this.truckId = truck.id;
          this.truck = truck;
          this.initState({
            id,
            driver: `${first_name} ${
              middle_name !== null ? middle_name : ""
            } ${last_name}`
          });
          return resolve(truck);
        })
        .catch(e => {
          if (e.response && e.response.status === 422) {
            const {
              data: { errors }
            } = e.response;
            Object.entries(errors).forEach(entry => {
              const [errorMessage] = entry;
              message.error(errorMessage);
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        })
    );
  }

  @action
  initState({ id, driver, truck }) {
    this.driverId = id;
    this.driver = driver;
    if (!this.truck) {
      this.truck = truck;
      this.truckId = truck.id;
    }
  }

  @computed
  get linkedTruckInfo() {
    return this.truck ? formatTruckLabel(this.truck) : "undefined";
  }

  resetState() {
    this.driverId = null;
    this.driver = null;
    this.truckId = null;
    this.truck = null;
    this.isUnlinkSuccessful = false;
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.unlinkTruck();
      }
    };
  }
}

export default new UnlinkTruckForm();
