import { action, computed, observable } from "mobx";
import { message, Modal } from "antd";
import BaseForm from "../base-form";
import DriverService from "../../../services/api/drivers";
import { LINK_DRIVER_MAX_TRUCKS } from "../../../config/constants";
import { formatTruckLabel } from "../../../utils/format";
import throttledMessage from "../../../utils/throttled-message";

const fields = ["truck_id", "driver_id"];

const labels = {
  truck_id: "Select a Truck",
  driver_id: "Select Driver"
};

const placeholders = {
  truck_id: "Select Truck from the List",
  driver_id: "Select Driver from the List"
};

const types = {
  truck_id: "select",
  driver_id: "select"
};

const rules = {
  truck_id: "required",
  driver_id: "required"
};

const options = {
  driver_id: {
    validateOnChange: true
  }
};

class LinkDriverForm extends BaseForm {
  @observable
  linkToUpdate = {
    driver_id: null,
    truck_id: null
  };

  @observable
  availableTrucks = [];

  @observable
  availableDrivers = [];

  @observable
  availableDriversRaw = [];

  @observable
  isFormVisible = false;

  @observable
  isMultipleMode = false;

  @observable
  predefinedDriver = null;

  @observable
  predefinedTruck = null;

  @observable
  isLinkSuccessful = false;

  @observable
  isLoadingDrivers = false;

  @observable
  isLoadingTrucks = false;

  @observable
  mode = "drivers"; // other value === "trucks"

  throttledNoChangeMessage = throttledMessage(
    "There is no changed to update.",
    "warning"
  );

  /**
   * * computed values
   */

  @computed
  get canLinkDriver() {
    return this.$("drivers").fields.size < LINK_DRIVER_MAX_TRUCKS;
  }

  @computed
  get driversInfo() {
    const driverId = this.$("driver_id").value;
    if (!driverId) {
      return null;
    }

    let driver = this.availableDriversRaw.find(
      driverItem => driverItem.id === driverId
    );

    if (!driver) {
      driver = this.predefinedDriver;
    }

    return driver;
  }

  @computed
  get isDriversSelect() {
    if (this.mode === "drivers") {
      return !this.mode || !this.predefinedDriver;
    }

    return !this.mode || this.mode === "trucks";
  }

  @computed
  get isTrucksSelect() {
    return !this.mode || this.mode === "drivers";
  }

  /**
   * * actions
   */

  @action
  setMode(mode) {
    this.mode = mode;
  }

  @action
  setLinkToUpdate(driverId, truckId) {
    this.linkToUpdate = {
      ...this.linkToUpdate,
      driver_id: driverId,
      truck_id: truckId
    };
  }

  @action
  loadAvailableTrucks() {
    if (this.isLoadingTrucks) {
      return;
    }

    this.isLoadingTrucks = true;

    DriverService.getTrucksAvailableToLink()
      .then(trucks => {
        this.availableTrucks = trucks.map(truck => ({
          value: truck.id,
          label: formatTruckLabel(truck)
        }));
        if (this.predefinedTruck) {
          this.availableTrucks.push({
            value: this.predefinedTruck.id,
            label: formatTruckLabel(this.predefinedTruck)
          });
        }
      })
      .finally(() => {
        this.isLoadingTrucks = false;
      });
  }

  @action
  loadAvailableDrivers() {
    if (this.isLoadingDrivers) {
      return;
    }

    this.isLoadingDrivers = true;

    DriverService.getDriversAvailableToLink()
      .then(drivers => {
        this.availableDriversRaw = drivers;
        this.availableDrivers = drivers.map(driver => ({
          value: driver.id,
          label: this.formatDriverLabel(driver)
        }));
        if (this.predefinedDriver) {
          this.availableDrivers.push({
            value: this.predefinedDriver.id,
            label: this.formatDriverLabel(this.predefinedDriver)
          });
        }
      })
      .finally(() => {
        this.isLoadingDrivers = false;
      });
  }

  formatDriverLabel = driver => {
    const { first_name, middle_name, last_name } = driver;
    return [first_name, middle_name, last_name].filter(Boolean).join(" ");
  };

  @action
  openForm({ driver, truck, mode } = {}) {
    this.isFormVisible = true;
    this.setMode(mode);

    if (driver) {
      this.predefinedDriver = driver;
      this.loadAvailableDrivers();
      this.$("driver_id").set("value", driver.id);
    }
    if (truck) {
      this.predefinedTruck = truck;
      this.loadAvailableTrucks();
      this.$("truck_id").set("value", truck.id);
    }
  }

  @action
  closeForm() {
    this.isFormVisible = false;

    setTimeout(() => {
      this.execHook("onClear");
      this.isLinkSuccessful = false;
      this.reset();
    }, 10);
  }

  @action
  async initForm() {
    await this.loadAvailableDrivers();
    await this.loadAvailableTrucks();
  }

  @action
  clearValues() {
    this.predefinedDriver = null;
    this.predefinedTruck = null;
  }

  @action
  setMultipleMode(isMultiple) {
    this.isMultipleMode = isMultiple;
  }

  @action
  async removeRelation() {
    const valuesToRemove = {
      id: this.linkToUpdate.driver_id,
      driver_id: this.linkToUpdate.driver_id,
      truck_id: this.linkToUpdate.truck_id
    };
    await DriverService.unlinkTruckFromDriver(valuesToRemove)
      .then(() => {
        this.setLinkToUpdate(null, null);
        return this.makeRelation();
      })
      .catch(error => {
        console.dir(error);
        // handle validation errors
        if (error.response && error.response.status === 422) {
          const messages = error.response.data.errors;
          Object.entries(messages).forEach(entry => {
            const [fieldName, errorMessage] = entry;

            if (fieldName === "driver_id") {
              this.$(fieldName).invalidate(
                "This Truck is logged as available or used in a signed contract. Please, link another Driver to this Truck before connecting Driver to a new Truck."
              );
              return;
            }
            this.$(fieldName).invalidate(errorMessage);
          });
        } else {
          message.warning(error.message);
        }
        // this.closeForm();
        return Promise.reject();
      });
  }

  @action
  requestRemoveRelationBeforeLink() {
    if (this.linkToUpdate.driver_id && this.linkToUpdate.truck_id) {
      Modal.confirm({
        icon: null,
        title: "Please, confirm you want to proceed.",
        content:
          "Confirm that you want to change the Driver attached to this Truck.",
        okButtonProps: {
          size: "large"
        },
        cancelButtonProps: {
          size: "large"
        },
        okText: "Confirm",
        width: 390,
        autoFocusButton: null,
        onOk: () => this.removeRelation()
      });

      return true;
    }

    return false;
  }

  @action
  async makeRelation() {
    const values = this.values();

    return DriverService.linkDriverToTruck(values)
      .then(() => {
        if (this.isMultipleMode) {
          this.execHook("onClear");
          this.reset();
          this.initForm();
          this.setMultipleMode(false);
        } else {
          this.closeForm();
        }
        this.isLinkSuccessful = true;
        message.success("Driver linked successfully.");
      })
      .catch(error => {
        console.dir(error);
        // handle validation errors
        if (error.response && error.response.status === 422) {
          const messages = error.response.data.errors;
          Object.entries(messages).forEach(entry => {
            const [fieldName, errorMessage] = entry;

            this.$(fieldName).invalidate(errorMessage);
          });
        } else {
          message.warning(error.message);
        }

        return Promise.reject();
      })
      .finally(() => {
        this.isLinkSuccessful = false;
      });
  }

  @action
  async linkTruck() {
    if (this.changed) {
      if (this.requestRemoveRelationBeforeLink()) {
        return null;
      }

      return this.makeRelation();
    }

    this.throttledNoChangeMessage();
    return null;
  }

  hooks() {
    return {
      onClear: () => {
        this.clearValues();
        this.clear();
      },
      onSuccess: () => {
        return this.linkTruck();
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false,
      validateOnInit: false
    };
  }
}

export default new LinkDriverForm(
  {
    fields,
    labels,
    types,
    rules,
    placeholders,
    options
  },
  { hasNestedFields: true }
);
