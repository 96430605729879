/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { Col, Row, Button } from "antd";
import { history } from "../../contexts/history";
import VideoPoster from "../../styles/images/poster-video.jpg";
import VideoSmPoster from "../../styles/images/poster-video-sm.jpg";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";
import "./styles.less";

const Home = inject("AppState")(
  observer(({ AppState }) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      AppState.addLayoutClass("home");

      return () => AppState.removeLayoutClass("home");
    }, [AppState]);

    const handleClick = () => {
      history.push("/get-quote");
    };

    const renderModalVideo = () => {
      return (
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="HtQdDRbr55A" // "2QCVIjZx7vM"
          onClose={() => setIsOpen(false)}
        />
      );
    };

    return (
      <>
        {renderModalVideo()}
        <Row className="main-img" type="flex" justify="start">
          <Col
            lg={{ span: 10, offset: 2 }}
            md={{ span: 18, offset: 2 }}
            sm={{ span: 18, offset: 2 }}
            xs={24}
          >
            <h1 className="h1-main">The One Stop Trucking Solution</h1>
            <div className="p-main">
              Empowers the truckers and the shippers, improving their {""}
              ability to transport goods across different geographical {""}
              locations efficiently and competitive
            </div>
            <div>
              <Link
                className="link-primary btn-create-account"
                to="/registration"
              >
                Create Account
              </Link>
            </div>
          </Col>
        </Row>
        <Row gutter={{ lg: 10, md: 35, xs: 10 }}>
          <Col
            lg={{ span: 6, offset: 1 }}
            md={{ span: 8, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
          >
            <div className="card-img card-img-shipper" />
            <div className="section-title">Shipper</div>
            <div>
              <p className="p-main">
                Virtual Haulage connects you to multiple vetted truckers and
                provides relevant information such as trucker ratings to help
                you make the best choice.
              </p>
            </div>
          </Col>
          <Col
            lg={{ span: 6, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
          >
            <div className="card-img card-img-agent" />
            <div className="section-title">Agent</div>
            <div>
              <p className="p-main">
                Virtual Haulage quickly and easily connects you to multiple
                vetted truckers and helps you save time and money with better
                deals for your clients and prompt service.
              </p>
            </div>
          </Col>
          <Col
            lg={{ span: 6, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            xs={{ span: 22, offset: 1 }}
          >
            <div className="card-img card-img-truck-owner" />
            <div className="section-title">Truck Owner</div>
            <div>
              <p className="p-main">
                Virtual Haulage gives you Instant Access to shipments on your
                preferred routes. VH helps you eliminate the Dead-End Miles by
                providing a platform that allows several shippers find you
                easily.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="get-quote-bg" type="flex" justify="center">
          <Col lg={10} md={15} xs={24}>
            <Row gutter={10} className="m-xs-left-10 m-xs-right-10">
              <Col span={24}>
                <h1 className="h1-quote">Get Quote</h1>
                <div className="p-get-quote">
                  You can get an estimated quote and view available trucks to{" "}
                  {""}
                  your preferred destination before you ship
                </div>
                <div className="p-get-quote">
                  <Button
                    ghost
                    onClick={handleClick}
                    block
                    className="btn-get-quote"
                    size="large"
                  >
                    Get Quote
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          gutter={{ lg: 10, md: 34, xs: 10 }}
          className="m-lg-top-110 m-md-top-100 m-xs-top-100"
        >
          <Col className="video-what-we-do" lg={{ span: 14 }} md={24} xs={24}>
            <picture>
              <source media="(max-width: 375px)" srcSet={VideoSmPoster} />
              <img src={VideoPoster} alt="Virtual Haulage" />
            </picture>

            <div className="overlay">
              <Button
                ghost
                onClick={() => setIsOpen(true)}
                shape="circle"
                block
                size="large"
              >
                &#9654;
              </Button>
            </div>
          </Col>
          <Col lg={{ span: 9, offset: 1 }} md={24} xs={24}>
            <h1 className="h1-what-we-do">What We Do</h1>
            <div className="p-main">
              Virtual Haulage Trucking is a tracing platform for the {""}
              African Market that will connect customers and carriers
            </div>
          </Col>
        </Row>
      </>
    );
  })
);

export default Home;
