import axios from "axios";

const MAIN_ROUTE = "shipments";

const SendQuoteService = {
  getAvailableTrucks: shipmentId => {
    return axios
      .get(`/${MAIN_ROUTE}/${shipmentId}/trucks`)
      .then(({ data }) => data);
  },
  sendQuote: (shipmentId, values) => {
    return axios.post(`/${MAIN_ROUTE}/${shipmentId}/quote`, values);
  },
  cancelQuote: id => {
    return axios.delete(`/${MAIN_ROUTE}/quotes/${id}`).then(({ data }) => data);
  }
};

export default SendQuoteService;
