import { action, computed, observable } from "mobx";
import OptionsService from "../services/api/options";
import { capitalize } from "../utils/format";

class CitiesOptionsStore {
  @observable
  citiesOptions = [];

  @observable
  statesOptions = [];

  @observable
  loading = false;

  @action
  loadCities() {
    if (this.loading || this.citiesOptions.length !== 0) {
      return Promise.resolve;
    }

    this.loading = true;

    return Promise.all([OptionsService.getCities(), OptionsService.getStates()])
      .then(response => {
        const [citiesResponse, statesResponse] = response;

        this.citiesOptions = citiesResponse.map(({ id, name, ...rest }) => ({
          id,
          value: capitalize(name),
          text: capitalize(name),
          ...rest
        }));

        this.statesOptions = statesResponse.map(({ id, name, ...rest }) => ({
          id,
          value: capitalize(name),
          text: capitalize(name),
          ...rest
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @computed
  get cities() {
    return this.citiesOptions;
  }

  @computed
  get states() {
    return this.statesOptions;
  }
}

export default new CitiesOptionsStore();
