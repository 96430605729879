/* eslint-disable import/no-cycle */
import React from "react";
import { Button, Row } from "antd";
import Tooltip from "antd/lib/tooltip";
import TableRowDescriptionRenderer from "../../../../../common/TableRowDescriptionRenderer";
import {
  dateFormatter,
  formatTimeRange,
  formatTruckLabel
} from "../../../../../utils/format";
import getColumns from "../columns-builder";
import ChangeStatusForm from "../../../../../models/forms/items-managers/shipments/change-status";
import AuthStore from "../../../../../models/auth";
import ChangeableStatusesColumns from "../delivery-line";
import ActionsMenu from "../../../../../common/ActionsMenu";
import ACTIVE_SHIPMENTS_ACTIONS from "./actions";
import { SHIPPER_PENDING_CHILD_ACTIONS } from "../../shipper-shipments/pending/actions";
import PAST_SHIPMENTS_ACTIONS from "../past-shipments/actions";
import { CONTRACT_STATUS_CODES } from "../../../../../config/constants";
import { StyledMessagingButton } from "../styled-messaging-button";
import { getShipmentValue } from "../shipment-value-renderer";

/**
 * @param shipment {Shipment}
 */
export const getQuote = shipment => {
  if (shipment.isPast) {
    return shipment.pastQuote;
  }

  if (shipment.isActive) {
    return shipment.activeQuote;
  }

  return shipment.pendingQuote;
};

/**
 * @param shipment {Shipment}
 * @returns {*}
 */
const getStatusRenderer = shipment => {
  let columnKey = "status";

  if (shipment.isActive) {
    columnKey = "status_for_active_shipment";
  }

  if (shipment.isPast) {
    columnKey = "status_for_past_shipment";
  }

  return getColumns([columnKey])[0].render;
};

/**
 * @param shipment {Shipment}
 * @returns {*}
 */
const getActionsMenu = shipment => {
  let actions = SHIPPER_PENDING_CHILD_ACTIONS;

  if (shipment.isActive) {
    actions = ACTIVE_SHIPMENTS_ACTIONS;
  }

  if (shipment.isPast) {
    if (!shipment.isClosed) {
      actions = [];
    } else {
      actions = PAST_SHIPMENTS_ACTIONS;
    }
  }

  return (
    <>
      <StyledMessagingButton
        row={shipment}
        rightMarginValue={actions.length ? 20 : 59}
      />
      <ActionsMenu actions={actions} row={shipment} />
    </>
  );
};

/**
 * @param shipment {Shipment}
 */
const getFirstRow = shipment => {
  const {
    id,
    commodity_type: { name: commodityTypeName },
    delivery_date,
    packaging: { name: packagingName }
  } = shipment;
  const quote = getQuote(shipment);
  const shipmentValue = getShipmentValue(shipment);

  let truckTypeName = null;

  if (quote && Array.isArray(quote.trucks) && quote.trucks[0]) {
    const firstTruckFromQuote = quote.trucks[0] || { type: {} };

    truckTypeName = firstTruckFromQuote.type.name;
  }

  const firstRow = [
    {
      label: "Shipment ID",
      value: id
    },
    {
      label: "Commodity Type",
      value: commodityTypeName
    },
    {
      label: shipmentValue.label,
      value: shipmentValue.value
    },
    {
      label: "Expected Delivery Date",
      value: dateFormatter(delivery_date)
    },
    {
      label: "Packaging",
      value: packagingName
    },
    {
      label: "Truck Type",
      visibility: !!truckTypeName,
      value: truckTypeName
    },
    {
      styles: { width: "auto", paddingRight: 16 },
      value: getActionsMenu(shipment),
      visibility: !AuthStore.isTruckOwner && !!shipment.parent_shipment_id
    }
  ];

  return firstRow;
};

/**
 * @param shipment {Shipment}
 * @returns {[{colspan: number, visibility, label: string, value: *|string}, {colspan: number, visibility, label: string, value: *}, {visibility: *}, {visibility: *, label: string, value: *}]}
 */
const getSecondRow = shipment => {
  const quote = getQuote(shipment);
  const truckFromQuote = quote.trucks ? quote.trucks[0] : null;
  const isContractSigned =
    quote.contract && quote.contract.status === CONTRACT_STATUS_CODES.ACCEPTED;
  const secondRow = [
    {
      label: "Vehicle Manufacturer/Model",
      value: truckFromQuote ? formatTruckLabel(truckFromQuote) : "-",
      visibility: !!truckFromQuote,
      colspan: shipment.isShared ? 2 : 3
    },
    {
      label: "Chargeable Weight, tons",
      value: quote.chargeable,
      // colspan: shipment.isShared ? 2 : 4,
      visibility: !!quote.chargeable
    },
    {
      label: "Pick Up Date",
      value: (() => {
        if (isContractSigned) {
          return dateFormatter(quote.pickup_date_time);
        }

        return "Contract is not signed";
      })(),
      visibility: shipment.isShared
    },
    {
      label: "Pick Up time range",
      value: formatTimeRange(shipment)
    },
    {
      label: "Shipment Status",
      value: getStatusRenderer(shipment)(null, shipment.trackedEntity),
      visibility: shipment.isShared && !AuthStore.isTruckOwner
    }
  ];

  return secondRow;
};

/**
 * @param columns
 * @param shipment {Shipment}
 * @param index
 * @returns {*}
 */
const renderSingleRow = (columns, shipment, rowId, index) => {
  const { id, owner_id, shipper } = shipment;

  const quote = shipment.isActive
    ? shipment.activeQuote
    : shipment.pendingQuote;

  const thirdRow = [
    {
      label: "Comments",
      value: quote.comment,
      visibility: !!quote.comment,
      colspan: 3
    }
  ];

  const rows = [getFirstRow(shipment), getSecondRow(shipment), thirdRow];

  const openChangeStatusModalHandler = () => {
    ChangeStatusForm.setShipment(shipment);
  };

  const containerProps = {};

  if (!AuthStore.isTruckOwner) {
    containerProps["data-sc-index"] = index < 10 ? `0${index}` : index;
  }

  const isCollaboration = () => {
    const { id: user_id } = AuthStore.user;

    return shipper && owner_id !== user_id && shipper.id === user_id;
  };

  return (
    <div key={index} {...containerProps}>
      {shipment.isActive && (
        <>
          <ChangeableStatusesColumns
            shipment={shipment}
            rowId={rowId}
            index={index}
          />
          <Row style={{ marginLeft: 15 }} className="m-bottom-20">
            <Tooltip title={shipment.disabledStateMessage}>
              <Button
                type="secondary"
                onClick={openChangeStatusModalHandler}
                disabled={!shipment.isAbleToChangeStatus || isCollaboration()}
              >
                Change Delivery Status
              </Button>
            </Tooltip>
          </Row>
        </>
      )}

      <TableRowDescriptionRenderer
        key={`shipment-description-${id}`}
        rowId={rowId}
        rows={rows}
      />
    </div>
  );
};

const getDescriptionRender = columns => row => {
  const { is_shared_consignment, child_shipments } = row;

  if (is_shared_consignment && child_shipments) {
    return child_shipments.map((childShipment, i) =>
      renderSingleRow(columns, childShipment, row.id, i + 1)
    );
  }

  return renderSingleRow(columns, row, row.id);
};

export default getDescriptionRender;
