import React, { useState, useEffect } from "react";
import { Button, Card, Form, Icon, Input, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { history } from "../../contexts/history";
import Src from "../../styles/images/icon-mail.png";
import { ReactComponent as BackIcon } from "../../icons/back.svg";
import Field from "../../common/FieldContainer";
import ForgotPasswordForm from "../../models/forms/auth/forgot-password";
import FormContext from "../../models/forms/form-context";
import "./LoginForm.less";

const { Text, Title } = Typography;

const ForgotPassword = inject("AuthStore", "AppState")(
  observer(({ AuthStore, AppState, ...rest }) => {
    const [state, setState] = useState({
      checkEmail: false,
      submitDisabled: true
    });

    useEffect(() => {
      const params = new URLSearchParams(rest.location.search);
      const token = params.get("token");
      const email = params.get("email");
      if (token && email) {
        AuthStore.setResetPasswordToken(token);
        AuthStore.setEmail(email);
        history.push(`/reset/password${rest.location.search}`);
      }
    }, [AuthStore, rest.location.search]);

    useEffect(() => {
      AppState.addLayoutClass("login");

      return () => AppState.removeLayoutClass("login");
    }, [AppState]);

    const handleSubmit = e => {
      e.preventDefault();
      ForgotPasswordForm.validate({ showErrors: true }).then(({ isValid }) => {
        if (!isValid) {
          return;
        }
        AuthStore.forgotPassword(ForgotPasswordForm.values())
          .then(() => {
            setState({ ...state, checkEmail: !state.checkEmail });
            ForgotPasswordForm.reset();
          })
          .catch(({ response: { data, status } }) => {
            ForgotPasswordForm.$("email_request").invalidate(
              status === 401 ? "Email address not found." : data.message
            );
          });
      });
    };

    const handleBackToLogin = () => {
      history.push({
        pathname: "/login"
      });
    };

    const renderCheckEmail = () => {
      return (
        <>
          <Row type="flex" justify="center" align="top">
            <Col className="single-column">
              <div
                style={{ margin: 35, marginBottom: 20, textAlign: "center" }}
              >
                <img src={Src} alt="Email" />
              </div>
              <div style={{ textAlign: "center" }}>
                <Text className="email-row">
                  Check your email. <br />
                  We sent you a message with a link to reset password.
                </Text>
              </div>
            </Col>
          </Row>
        </>
      );
    };

    return (
      <FormContext.Provider value={ForgotPasswordForm}>
        <Card className="login-form-container" bordered={false}>
          <Link
            className="back-to-login m-top-30 fs-12"
            to="/login"
            onClick={handleBackToLogin}
          >
            <BackIcon />
            Back to Login
          </Link>
          <Title
            align="center"
            className="m-top-40 fs-24 font-bold"
            style={{
              margin: "10px"
            }}
          >
            Forgot Password
          </Title>
          {state.checkEmail && renderCheckEmail()}
          {!state.checkEmail && (
            <>
              <Text className="forgot-row m-top-10 m-bottom-30">
                Enter the email address for your account{" "}
              </Text>
              <Form hideRequiredMark onSubmit={handleSubmit}>
                <Field
                  name="email_request"
                  hasFeedback
                  label={
                    <span>
                      <Icon
                        style={{
                          color: "#A2A7B0"
                        }}
                        type="user"
                      />
                      Email Address
                    </span>
                  }
                >
                  <Input />
                </Field>
                <Button
                  size="large"
                  style={{ marginTop: 24 }}
                  disabled={AuthStore.isSubmitForgotPassword}
                  loading={AuthStore.isSubmitForgotPassword}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Continue
                </Button>
              </Form>
            </>
          )}
        </Card>
      </FormContext.Provider>
    );
  })
);

ForgotPassword.isAuthRoute = true;

export default ForgotPassword;
