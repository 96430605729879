import React from "react";
import { bool, element, objectOf, oneOfType, shape, string } from "prop-types";
import { Tooltip } from "antd";

import "./StatusRenderer.less";

const StatusRenderer = ({
  statuses,
  currentStatus,
  hasTooltip = false,
  tooltipText,
  tooltipIcon,
  style = {},
  color = false
}) => {
  const shouldRenderTooltip = hasTooltip && tooltipIcon && tooltipText;
  const statusDescriptor = statuses[currentStatus];

  if (!statusDescriptor) {
    return null;
  }

  const dotStyles = { backgroundColor: color || statusDescriptor.color };

  if (statusDescriptor.border) {
    dotStyles.backgroundColor = "#fff";
    dotStyles.border = `1px solid ${statusDescriptor.border}`;
  }

  return (
    <div className="status-name" style={style}>
      <div className="container">
        <div className="box">
          <div
            className={`status-indicator status-indicator-${currentStatus}`}
            style={dotStyles}
          />
          <div style={{ position: "relative", paddingRight: 21 }}>
            {statusDescriptor.name}
            {shouldRenderTooltip && (
              <Tooltip title={tooltipText}>
                {React.createElement(tooltipIcon, {
                  style: {
                    paddingLeft: 10,
                    width: 20,
                    height: 12,
                    position: "absolute",
                    top: 4
                  },
                  className: "status-icon"
                })}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StatusRenderer.defaultProps = {
  hasTooltip: false,
  tooltipText: false,
  tooltipIcon: false
};

StatusRenderer.propTypes = {
  statuses: objectOf(shape({ name: string, color: string })).isRequired,
  currentStatus: string.isRequired,
  hasTooltip: bool,
  tooltipText: oneOfType([string, bool, element]),
  tooltipIcon: oneOfType([shape({}), bool])
};

export default StatusRenderer;
