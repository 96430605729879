import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Input, Select } from "antd";
import Field from "../../../common/FieldContainer";
import FormContext from "../../../models/forms/form-context";

const { Option } = Select;

const AddressInput = observer(({ fieldName, options, ...props }) => {
  const formState = useContext(FormContext);
  if (formState.isDefaultCountry) {
    return (
      <Field name={fieldName}>
        <Select
          showSearch
          size="large"
          style={{ width: "100%" }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          {...props}
        >
          {options.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Field>
    );
  }
  const nameFormManualField = fieldName.replace("_id", "_manual");
  return (
    <Field name={nameFormManualField}>
      <Input {...props} />
    </Field>
  );
});

const LocationSection = observer(() => {
  const formState = useContext(FormContext);
  return (
    <>
      <Field name="signup.country_id" label="Country">
        <Select
          size="large"
          style={{ width: "100%" }}
          placeholder="Select Country"
        >
          {formState.countries.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Field>
      <Field name="signup.street_address">
        <Input size="large" />
      </Field>
      <Field name="signup.house_number" label="Building/House Number">
        <Input size="large" />
      </Field>
      <AddressInput fieldName="signup.state_id" options={formState.states} />
      <AddressInput fieldName="signup.city_id" options={formState.cities} />
      <AddressInput fieldName="signup.area_id" options={formState.areas} />
      <Field name="signup.postal_code" label="Postal Code">
        <Input size="large" />
      </Field>
    </>
  );
});

export default LocationSection;
