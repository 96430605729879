import React, { useCallback, useContext, useState } from "react";
import { Button, Checkbox, Icon, Input, Tooltip } from "antd";
import { observer } from "mobx-react";
import Field from "../../../common/FieldContainer";
import CaptchaComponent from "../Captcha";
import FormContext from "../../../models/forms/form-context";
import { ReactComponent as EyeOpen } from "../../../icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "../../../icons/eye-closed.svg";
import { history } from "../../../contexts/history";
import "./input.less";

const renderPasswordLabel = () => {
  return (
    <span>
      Password&nbsp;
      <Tooltip title="Password must be a minimum of 8 characters, must include letters, numbers and at least one special character.">
        <Icon type="question-circle-o" />
      </Tooltip>
    </span>
  );
};

const SecuritySection = observer(() => {
  const formState = useContext(FormContext);
  const handleCaptchaVerified = token => formState.setCaptchaToken(token);
  const [state, setState] = useState({
    passwordVisible: false,
    confirmationVisible: false
  });

  const handleCancelSignUp = useCallback(
    e => {
      formState.onReset(e);
      history.push("/");
    },
    [formState]
  );

  const setPasswordVisibility = key => () => {
    setState({ ...state, [key]: !state[key] });
  };

  const renderEyeForPassword = (stateLocal, value) => {
    return stateLocal ? (
      <Tooltip title="Hide Password">
        <EyeOpen
          style={{ color: "#A2A7B0" }}
          onClick={setPasswordVisibility(value)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Show Password">
        <EyeClosed
          style={{ color: "#A2A7B0" }}
          onClick={setPasswordVisibility(value)}
        />
      </Tooltip>
    );
  };

  return (
    <>
      <Field name="signup.email" disabled={formState.$("signup").submitting}>
        <Input />
      </Field>
      <Field
        name="signup.password"
        label={renderPasswordLabel()}
        extra={
          formState.$("signup.password").focused &&
          formState.$("signup.password").extra
        }
      >
        <Input
          autoComplete="new-password"
          type={state.passwordVisible ? "text" : "password"}
          suffix={renderEyeForPassword(
            state.passwordVisible,
            "passwordVisible"
          )}
        />
      </Field>
      <Field name="signup.password_confirmation">
        <Input
          type={state.confirmationVisible ? "text" : "password"}
          suffix={renderEyeForPassword(
            state.confirmationVisible,
            "confirmationVisible"
          )}
        />
      </Field>
      <CaptchaComponent onVerify={handleCaptchaVerified} />
      <div style={{ marginTop: "auto" }}>
        <Field name="signup.terms_of_service">
          <Checkbox>
            I agree with all{" "}
            <a href="/terms-and-services" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </Checkbox>
        </Field>
        <div
          className="button-container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="secondary"
            size="large"
            htmlType="reset"
            onClick={handleCancelSignUp}
          >
            Cancel
          </Button>
          <Button
            size="large"
            loading={formState.$("signup").submitting}
            type="primary"
            htmlType="submit"
            onClick={formState.$("signup").onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
});

export default SecuritySection;
