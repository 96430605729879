import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import {
  any,
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string
} from "prop-types";
import collect from "collect.js";
import stylePropType from "react-style-proptype";
import cx from "classnames";

const TableRowDescriptionRenderer = ({ rowId, rows, noPadding = false }) => {
  const [columnWidths, setColumnWidths] = useState([]);

  const updateColumnSizes = useCallback(() => {
    if (columnWidths.length > 0) {
      return;
    }

    /**
     * @type {NodeList}
     */
    const closestRow = document.querySelectorAll(
      `[data-row-key='${rowId}'] td`
    );
    let currentColumnWidths = Array.from(closestRow).map(td => td.clientWidth);

    currentColumnWidths.splice(0, 1);

    const totalWidth = currentColumnWidths.reduce(
      (sum, width) => sum + width,
      0
    );

    currentColumnWidths = currentColumnWidths.map(width => {
      return (width * 100) / totalWidth;
    });

    setColumnWidths(currentColumnWidths);
  }, [rowId, columnWidths]);

  const debouncedWidthUpdateFunction = debounce(50, updateColumnSizes);

  useEffect(() => {
    updateColumnSizes();
    window.addEventListener("resize", debouncedWidthUpdateFunction);

    return () => {
      window.removeEventListener("resize", debouncedWidthUpdateFunction);
    };
  }, [debouncedWidthUpdateFunction, updateColumnSizes]);

  if (!rowId || rows.length === 0) {
    return null;
  }

  const getColumnWidth = (index, span, row) => {
    if (typeof span === "object" && Object.hasOwnProperty.call(span, "width")) {
      return span.width;
    }

    if ([undefined, null, 1].includes(span)) {
      return columnWidths[index];
    }

    const offset = collect([...row].slice(0, index)).sum(
      ({ colspan }) => colspan || 1
    );

    return collect(columnWidths.slice(offset, offset + span)).sum();
  };

  return (
    <div className="embedded-description">
      {rows.map((row, i) => {
        const visibleCells = row.filter(
          ({ visibility }) => visibility !== false
        );

        return (
          <div key={`description-row-${i}`} className="d-flex">
            {visibleCells.map(
              (
                { label, value, colspan, visibility, styles = {}, ...rest },
                j
              ) => {
                return (
                  <div
                    key={`description-col-${i}-${j}-row-${row.id}`}
                    style={{
                      width: `${getColumnWidth(j, colspan, visibleCells) ||
                        columnWidths[j]}%`,
                      // maxWidth: `${getColumnWidth(j, colspan, visibleCells) ||
                      //   columnWidths[j]}%`,
                      ...styles
                    }}
                    className={cx("ant-table-row", {
                      "embedded-description-column": !noPadding
                    })}
                    {...rest}
                  >
                    <div
                      key={`description-col-${i}-${j}-row-${row.id}-label`}
                      className="embedded-description-label"
                    >
                      {label}
                    </div>
                    <div
                      key={`description-col-${i}-${j}-row-${row.id}-value`}
                      className="embedded-description-value"
                    >
                      {value}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

TableRowDescriptionRenderer.propTypes = {
  rowId: oneOfType([number, string]).isRequired,
  rows: arrayOf(
    arrayOf(
      shape({
        label: string,
        value: any,
        colspan: oneOfType([
          number,
          bool,
          shape({
            width: number
          })
        ]),
        visibility: bool,
        style: stylePropType
      })
    )
  ).isRequired
};

export default TableRowDescriptionRenderer;
