import { observable, computed, action, reaction } from "mobx";
import BaseStore from "./base-manager";
import TruckService from "../../../services/api/trucks";
import {
  STATUS_DEFAULT,
  TRUCK_STATUSES,
  LOG_TRUCK_MAX_TRUCKS,
  PAGE_DEFAULT
} from "../../../config/constants";
import { formatNumber } from "../../../utils/format";
import truckProxy from "../../proxies/truck-proxy";
import { pipeToArray } from "../../../utils/pipe";
import {
  getIsDriverHasWaitingStatus,
  getIsDriverLicenseExpired,
  getIsInspectorsApprovalDateEarlierThanMonth,
  getIsTruckHasExpiredDocuments,
  getIsTruckHasPendingAttributes,
  getIsTruckHasWaitingStatus,
  getIsTruckLinkedToDriver
} from "../../../components/items-managers/manage-trucks/list";

class TrucksStore extends BaseStore {
  loadFunction = TruckService.get;

  proxy = row => {
    const reasons = pipeToArray(
      getIsInspectorsApprovalDateEarlierThanMonth,
      getIsTruckHasPendingAttributes,
      getIsTruckHasExpiredDocuments,
      getIsTruckHasWaitingStatus,
      getIsTruckLinkedToDriver,
      getIsDriverHasWaitingStatus,
      getIsDriverLicenseExpired
      // getIsTruckLoggedAsAvailable,
      // getIsTruckHasWaitingContract,
      // getIsTruckHasActiveQuote
    )(row, { clearFalsy: true });

    row.truckIsNotAvailableForLog = reasons.length > 0;
    row.truckIsNotAvailableForLogReasons = reasons;

    return truckProxy(row);
  };

  @observable tag = STATUS_DEFAULT;

  constructor() {
    super();
    reaction(
      () => this.tag,
      () => {
        this.setSortedInfo();
      }
    );
  }

  @action
  setTag(tag) {
    this.tag = tag;
    this.setPage(PAGE_DEFAULT);
  }

  @computed
  get selectedTruckCouldBeLoggedAsAvailable() {
    const availableStatuses = [
      TRUCK_STATUSES.approved.code,
      TRUCK_STATUSES.pending_recertification.code
    ];

    if (!this.paginator.data) {
      return true;
    }

    return (
      this.paginator.data.filter(
        truck =>
          this.selectedRowKeys.includes(truck.id) &&
          availableStatuses.indexOf(truck.status) > -1
      ).length === this.selectedRowKeys.length
    );
  }

  @computed
  get canAddTrucks() {
    return this.selectedRowKeys.length <= LOG_TRUCK_MAX_TRUCKS;
  }

  @computed
  get specificArguments() {
    return {
      status: this.tag
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get tags() {
    return Object.entries(TRUCK_STATUSES).map(entry => {
      const [code, descriptor] = entry;

      return {
        code,
        name: descriptor.name
      };
    });
  }

  formatTruckDetail = ({
    manufacturer,
    model,
    vehicle_registration_number
  }) => {
    return [
      manufacturer && manufacturer.name,
      model && model.name,
      vehicle_registration_number && vehicle_registration_number
    ]
      .filter(Boolean)
      .join(" ");
  };

  formatTruckSpecifications = ({ weight, length, volume }, type) => {
    switch (type) {
      case "weight":
        return weight && weight;
      case "volume":
        return volume !== null ? `${formatNumber(volume)}` : "";
      case "length":
        return length && length;
      default:
        return "";
    }
  };
}

export default new TrucksStore();
