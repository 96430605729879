import { Base64 } from "js-base64";

export const persistData = (name, data) => {
  try {
    localStorage.setItem(name, Base64.encode(JSON.stringify(data)));
  } catch (e) {
    console.dir(e);
  }
};

export const loadDataFromStorage = name => {
  const data = localStorage.getItem(name);
  try {
    const decodedData = Base64.decode(data);
    return JSON.parse(decodedData);
  } catch (e) {
    return false;
  }
};

export const removeDataFromStorage = name => {
  localStorage.removeItem(name);
};

export default {
  persistData,
  loadDataFromStorage,
  removeDataFromStorage
};
