import { WARNING_MANDATORY } from "../config/constants";
import throttledMessage from "./throttled-message";

const showMessage = throttledMessage(WARNING_MANDATORY, "warning");

export const checkErrors = errors => {
  const hasEmptyMandatoryField = Object.values(errors).includes(
    "This field is required."
  );
  if (hasEmptyMandatoryField) {
    showMessage();
  }
};

export default {
  checkErrors
};
