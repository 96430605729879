/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Col, Row, Button, Input, Select } from "antd";

import Field from "../../common/FieldContainer";
import FormContext from "../../models/forms/form-context";
import BackIcon from "../../common/Icons/BackIcon";

const { Option } = Select;

const renderFieldSet = (bankOptions = []) => (
  <>
    <Field name="bank_account_details.bank_name_id">
      <Select>
        {bankOptions.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Field>
    <Field name="bank_account_details.nuban_bank_account_number">
      <Input />
    </Field>
    <Field name="bank_account_details.beneficiary_name">
      <Input />
    </Field>
  </>
);

const BankAccountDetails = observer(() => {
  const formState = useContext(FormContext);
  const fieldSet = formState.$("bank_account_details");
  const bankOptions = fieldSet.$("bank_name_id").extra || [];

  return (
    <>
      <Row className="title" type="flex" justify="center">
        <Col>
          <h1>Bank Account Details</h1>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="top">
        <Col lg={7} md={10} sm={24} className="single-column">
          {renderFieldSet(bankOptions)}
          <div
            className="button-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              size="large"
              type="default"
              htmlType="reset"
              onClick={() => formState.goBack()}
            >
              <BackIcon />
              {" Back"}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={fieldSet.submitting}
              disabled={fieldSet.submitting}
              onClick={fieldSet.onSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default BankAccountDetails;
