/* eslint-disable no-unused-vars */
import axios from "axios";
import { booleansToNumberEquals } from "../../utils/response-transformer";

const TruckService = {
  loadSingle: id => {
    return axios
      .get(`/trucks/${id}`)
      .then(({ data }) => booleansToNumberEquals(data));
  },

  save: (values, truckId) => {
    if (!truckId) {
      return axios.post("/trucks", values);
    }

    return axios.patch(`/trucks/reapply/${truckId}`, values.trucks[0]);
  },

  get: values => {
    const url = `/trucks/${values.status}/${values.size}/${values.sortField}/${values.sortType}`;

    return axios
      .get(url, {
        params: {
          id: values.id,
          page: values.page
        }
      })
      .then(({ data }) => data)
      .catch(() => []);
  },

  delete: ({ id, reason }) => {
    return axios
      .delete(`/trucks/${id}`, {
        data: {
          reason
        }
      })
      .then(({ data }) => data);
  },

  deleteAvailableTruck: id => {
    return axios
      .delete(`/trucks/available-route/${id}`)
      .then(({ data }) => data);
  },

  getAvailableToLogTrucks: () => {
    return axios.get("/trucks/available").then(({ data }) => data);
  },

  logAvailableRoute: values => {
    const { routeId } = values;

    if (!routeId) {
      return axios
        .post("/trucks/available-route", values)
        .then(({ data }) => data);
    }

    delete values.trucks;
    delete values.routeId;

    return axios
      .put(`/trucks/available-route/${routeId}`, values)
      .then(({ data }) => data);
  },

  getAvailableRoutes: values => {
    const url = `/trucks/available-route/${values.size}/${values.userId}`;

    return axios
      .get(url, {
        params: {
          page: values.page,
          originCity: values.originCity,
          originState: values.originState,
          destinationCity: values.destinationCity,
          destinationState: values.destinationState,
          startDate: values.startDate,
          endDate: values.endDate
        }
      })
      .then(({ data }) => data)
      .catch(() => []);
  },

  checkTruckCouldRemoved: id => {
    return axios.get(`/trucks/${id}/remove`).then(({ data }) => {
      console.log(data);
    });
  }
};

export default TruckService;
