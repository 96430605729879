/* eslint-disable react/no-danger */
import React from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import unescape from "voca/unescape_html";

import cx from "classnames";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";
import VideoBlock from "./video";
import { getAssetUrl } from "../../utils/url-utils";

const TYPE_HEADER = "header";
const TYPE_PARAGRAPH = "paragraph";
const TYPE_EMBED = "embed";
const TYPE_IMAGE = "image";
const TYPE_LIST = "list";
const TYPE_EMBED_LIST = "embed-list";

// eslint-disable-next-line no-useless-escape
const YT_VIDEO_URL_REGEX = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/;

const allowedTypes = [
  TYPE_EMBED,
  TYPE_HEADER,
  TYPE_PARAGRAPH,
  TYPE_IMAGE,
  TYPE_LIST,
  TYPE_EMBED_LIST
];

const fieldsToUnescape = ["text"];

const validateBlock = block => {
  if (!allowedTypes.includes(block.type)) {
    return false;
  }

  return true;
};

const renderParagraph = ({ text }) => {
  return (
    <Paragraph className="info-content m-bottom-30">
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </Paragraph>
  );
};

const renderHeader = ({ key, text, level = 3 }) => {
  return (
    <Title id={key || null} level={level} className="header">
      {text}
    </Title>
  );
};

const renderImage = ({ file: { url }, caption, withBorder, stretched }) => {
  if (!url) {
    return null;
  }

  const classes = cx("static-page-img m-bottom-50 m-top-30", {
    "static-page-img-with-shadow": withBorder,
    "static-page-img-full-width": stretched
  });

  return <img className={classes} alt={caption} src={getAssetUrl(url)} />;
};

const renderList = ({ style = "unordered", items = [] }) => {
  if (items.length === 0) {
    return null;
  }

  const itemClasses = cx({
    steps: style === "unordered",
    decimals: style === "ordered"
  });

  return (
    <Paragraph className="info-content m-bottom-30">
      <ul>
        {items.map((text, i) => {
          return (
            <li
              key={i}
              className={itemClasses}
              dangerouslySetInnerHTML={{
                __html: `${style === "unordered" ? "—" : ""} ${text}`
              }}
            />
          );
        })}
      </ul>
    </Paragraph>
  );
};

export const renderVideo = ({
  service,
  source,
  wrapToRow = true,
  useThumbnail = true,
  noShadow
}) => {
  if (service !== "youtube" || !source) {
    return "no video";
  }

  const matchedGroups = YT_VIDEO_URL_REGEX.exec(source);

  YT_VIDEO_URL_REGEX.lastIndex = 0;

  if (!matchedGroups) {
    return null;
  }

  if (!matchedGroups[5]) {
    return null;
  }

  return (
    <VideoBlock
      videoId={matchedGroups[5]}
      {...{
        useThumbnail,
        wrapToRow,
        noShadow
      }}
    />
  );
};

const renderVideoList = ({ items = [] }) => {
  console.warn(items);

  return (
    <div className="static-video-list">
      {items.map(({ data }) => (
        <div className="static-video-list-item m-bottom-30">
          {renderVideo({ ...data, wrapToRow: false })}
          <div className="static-video-list-item-caption">{data.caption}</div>
        </div>
      ))}
    </div>
  );
};

const renderBlockByType = ({ type, data }) => {
  switch (type) {
    case TYPE_PARAGRAPH:
      return renderParagraph(data);
    case TYPE_HEADER:
      return renderHeader(data);
    case TYPE_IMAGE:
      return renderImage(data);
    case TYPE_LIST:
      return renderList(data);
    case TYPE_EMBED:
      return renderVideo(data);
    case TYPE_EMBED_LIST:
      return renderVideoList(data);

    default:
      return null;
  }
};

export const prepareBlockData = block => {
  fieldsToUnescape.forEach(fieldName => {
    block.data[fieldName] = unescape(block.data[fieldName]);
  });

  return block;
};

export default function Block(block) {
  if (!validateBlock(block)) {
    return null;
  }

  const isEmbedList = block.type === TYPE_EMBED_LIST || block.fullWidth;

  return (
    <Row>
      <Col
        lg={{ span: isEmbedList ? 23 : 18, offset: 0 }}
        md={{ span: 21, offset: 0 }}
        sm={{ span: 21, offset: 0 }}
      >
        {renderBlockByType(prepareBlockData(block))}
      </Col>
    </Row>
  );
}
