/* eslint-disable no-unused-vars */
import axios from "axios";
import jsonToFormData from "json-form-data";
import { booleansToNumberEquals } from "../../utils/response-transformer";

const MAIN_ROUTE = "/drivers";

const DriverService = {
  loadSingle: id => {
    return axios
      .get(`${MAIN_ROUTE}/${id}`)
      .then(({ data }) => booleansToNumberEquals(data));
  },
  save: (values, driverId, mode) => {
    if (!driverId) {
      return axios.post(MAIN_ROUTE, jsonToFormData(values));
    }

    if (mode === "edit") {
      const { phone_number } = values.drivers[0];
      return axios.patch(`${MAIN_ROUTE}/${driverId}`, { phone_number });
    }

    if (mode === "reapply") {
      const driver = values.drivers[0];
      const { license } = driver;
      const license_new = license.filter(file => file instanceof File);
      const licenseUploaded = license
        .filter(file => !(file instanceof File))
        .map(file => ({ ...file, filename: file.name }));

      // eslint-disable-next-line no-underscore-dangle
      driver._method = "put";

      return axios.post(
        `${MAIN_ROUTE}/${driverId}/reapply`,
        jsonToFormData({ ...driver, license_new, license: licenseUploaded })
      );
    }

    return Promise.resolve();
  },
  get: values => {
    const url = `${MAIN_ROUTE}/${values.status}/${values.size}/${values.sortField}/${values.sortType}`;
    return axios
      .get(url, {
        params: {
          id: values.id,
          page: values.page
        }
      })
      .then(({ data }) => data)
      .catch(() => []);
  },
  delete: ({ id }) => {
    return axios.delete(`${MAIN_ROUTE}/${id}`).then(({ data }) => data);
  },
  getDriversAvailableToLink: () => {
    return axios
      .get(`${MAIN_ROUTE}/link-available`)
      .then(({ data }) => data)
      .catch(() => []);
  },
  getTrucksAvailableToLink: () => {
    const url = `/trucks/link-available`;
    return axios.get(url).then(({ data }) => data);
  },
  linkDriverToTruck: ({ driver_id, truck_id }) => {
    return axios
      .post(`${MAIN_ROUTE}/${driver_id}/link-truck/${truck_id}`)
      .then(({ data }) => {
        return data;
      });
  },
  unlinkTruckFromDriver: ({ id }) => {
    return axios
      .post(`${MAIN_ROUTE}/${id}/unlink-truck`)
      .then(({ data }) => data);
  },
  getTruckLinkedToDriver: id => {
    const driverUrl = `${MAIN_ROUTE}/${id}`;
    return axios.get(driverUrl).then(({ data }) => {
      const truckUrl = `/trucks/${data ? data.truck_id : 0}`;
      return axios.get(truckUrl).then(resp => resp.data);
    });
  },
  checkTruckLinkedToDriver: id => {
    const driverUrl = `${MAIN_ROUTE}/${id}`;
    return axios.get(driverUrl).then(({ data }) => {
      if (data.truck_id !== null) {
        return Promise.reject(
          new Error("The selected Driver has been already linked to Truck.")
        );
      }
      return "";
    });
  }
};

export default DriverService;
