import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Col, Radio, Row } from "antd";
import Tooltip from "antd/lib/tooltip";
import Field from "../../../common/FieldContainer";
import PersonalInfoSection from "./PersonalInformationSection";
import LocationSection from "./LocationSection";
import SecuritySection from "./SecuritySection";
import FormContext from "../../../models/forms/form-context";
import { SUPPORTED_ROLES } from "../../../config/constants";

const isRoleDisabledForSignup = role => {
  return !SUPPORTED_ROLES.includes(role);
};

const getTooltipLabelForRoleIfDisabled = (role, roleLabel) => {
  if (!isRoleDisabledForSignup(role)) {
    return null;
  }

  return `${roleLabel} registration is not available at the moment.`;
};

const roles = {
  truck_owner: "Truck Owner",
  agent: "Agent",
  shipper: "Shipper"
};

const SignUpSections = observer(() => {
  const formState = useContext(FormContext);
  const personalSectionTitle = formState.isPersonal
    ? "Personal Info"
    : "Contact Person";

  return (
    <>
      <Row className="title" type="flex" justify="center">
        <Col>
          <h1>Sign Up</h1>
        </Col>
      </Row>
      <Row gutter={10} type="flex" justify="center">
        <Col lg={24} md={20} xs={22} className="flex flex-j-center">
          <Field className="flex-xs-grow-1" name="signup.company_type">
            <Radio.Group size="large" buttonStyle="solid">
              {Object.entries(roles).map(entry => {
                const [roleKey, roleLabel] = entry;

                return (
                  <Tooltip
                    title={getTooltipLabelForRoleIfDisabled(roleKey, roleLabel)}
                  >
                    <Radio.Button
                      disabled={isRoleDisabledForSignup(roleKey)}
                      value={roleKey}
                    >
                      {roleLabel}
                    </Radio.Button>
                  </Tooltip>
                );
              })}
            </Radio.Group>
          </Field>
        </Col>
      </Row>
      <Row
        className="account-information-section"
        gutter={{ lg: 10, xs: 20 }}
        type="flex"
        justify="center"
        style={{ alignItems: "stretch" }}
      >
        <Col lg={{ span: 6 }} sm={{ span: 10 }} xs={20}>
          <div className="section-title">{personalSectionTitle}</div>
          <PersonalInfoSection />
        </Col>
        <Col lg={{ span: 6, offset: 1 }} sm={{ span: 10 }} xs={20}>
          <div className="section-title m-xs-top-50">Location</div>
          <LocationSection />
        </Col>
        <Col
          lg={{ span: 6, offset: 1 }}
          sm={{ span: 10 }}
          xs={20}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="section-title m-xs-top-50">Security</div>
          <SecuritySection />
        </Col>
      </Row>
    </>
  );
});

export default SignUpSections;
