import { Col, Input, Radio, Row, Select } from "antd";
import React, { useContext } from "react";
import { observer } from "mobx-react";
import FormContext from "../../../models/forms/form-context";
import Field from "../../../common/FieldContainer";
import PhonesForm from "./PhonesForm";

const { Option } = Select;

const renderCompanyNameField = () => (
  <Field name="signup.company_name">
    <Input size="large" />
  </Field>
);

const renderCompanyFieldsForShipper = () => (
  <>
    {renderCompanyNameField()}
    <Field name="signup.company_rc" label="Company RC">
      <Input size="large" />
    </Field>
  </>
);

const PersonalInfoSection = observer(() => {
  const formState = useContext(FormContext);

  return (
    <>
      {!formState.isTruckOwner && (
        <Field className="m-lg-top-40" name="signup.account_type">
          <Radio.Group className="bordered" size="large">
            <Radio value="individual">Individual</Radio>
            <Radio value="company">Company</Radio>
          </Radio.Group>
        </Field>
      )}
      {!formState.isPersonal && (
        <>
          {formState.isShipper && renderCompanyFieldsForShipper()}
          {formState.isAgent && renderCompanyNameField()}
        </>
      )}
      {formState.isTruckOwner && renderCompanyNameField()}
      <Row gutter={20}>
        <Col lg={8} xs={7}>
          <Field name="signup.title" label="Title">
            <Select
              size="large"
              placeholder="Mr."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="mr">Mr.</Option>
              <Option value="mrs">Mrs.</Option>
              <Option value="miss">Ms.</Option>
            </Select>
          </Field>
        </Col>
        <Col lg={16} xs={17}>
          <Field name="signup.first_name" label="First Name">
            <Input size="large" />
          </Field>
        </Col>
      </Row>
      <Field name="signup.middle_name" label="Middle Name">
        <Input size="large" />
      </Field>
      <Field name="signup.last_name" label="Last Name">
        <Input size="large" />
      </Field>
      <PhonesForm />
    </>
  );
});

export default PersonalInfoSection;
