import ZenDeskService from "../../../services/api/zen-desk";
import AuthStore from "../../auth";
import { formatNameFromProfile } from "../../../utils/format";

class CommentFactory {
  constructor(service) {
    this.service = service;
  }

  buildComment({ body, ...rest }, isNew = false) {
    const currentUserId = ZenDeskService.user.id;

    return {
      body,
      sent_by_current_user: rest.author_id === currentUserId,
      ...rest,
      ...(isNew
        ? {
            id: Date.now(),
            created_at: new Date(),
            sent_by_current_user: true,
            sender: {
              name: formatNameFromProfile(AuthStore.user.profile)
            }
          }
        : {})
    };
  }
}

export default new CommentFactory(ZenDeskService);
