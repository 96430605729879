import React from "react";
import moment from "moment";
import truckProxy from "../models/proxies/truck-proxy";
// eslint-disable-next-line import/no-cycle
import { DATE_PICKER_PROPS } from "../config/constants";

const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export const valueRenderer = value => {
  if (value === null || value === undefined) {
    return "—";
  }

  if (typeof value === "string" && dateRegex.test(value)) {
    const dateValue = moment(value, "YYYY-MM-DD");

    if (dateValue.isValid()) {
      return dateValue.format(DATE_PICKER_PROPS.format);
    }
  }

  return value;
};

/**
 * this function insert a space after index iteratively
 * example of usage - to format phone number such as 888 888 88
 * @param string
 * @param after
 * @returns {boolean|*}
 */
const insertSpaceAfterIndex = (string, after) => {
  if (!string) {
    return false;
  }

  after = after || 4;

  const v = string.replace(/[^\dA-Z]/g, "");
  const regex = new RegExp(`.{${after}}`, "g");

  return v.replace(regex, a => {
    return `${a} `;
  });
};

/**
 * format number
 * @param n
 * @param isDecimal
 * @returns {string|*}
 */
export const formatNumber = (n, isDecimal = false) => {
  const NumberFormatter = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 2,
    minimumFractionDigits: isDecimal ? 2 : 0
  });

  // * if value couldn't be converted to number then return a raw value
  if (Number.isNaN(+n)) {
    return n;
  }

  return NumberFormatter.format(Math.floor(n));
};

export const formatTimeRange = (
  { pickup_time_start, pickup_time_end },
  asString = false
) => {
  const formatter = time => moment.utc(time).format("LT");

  if (asString) {
    return `${formatter(pickup_time_start)} — ${formatter(pickup_time_end)}`;
  }

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {`${formatter(pickup_time_start)} —`}
      <br />
      {formatter(pickup_time_end)}
    </span>
  );
};

export const dateFormatHuman = d => {
  const date = moment(d);
  const startOfDay = moment().startOf("d");

  if (
    date
      .clone()
      .startOf("d")
      .isSame(startOfDay)
  ) {
    return date.fromNow();
  }

  return date.format("DD MMM YYYY");
};

export const dateFormatter = (d, template = "DD MMM YYYY") => {
  const momentDate = moment(d, "YYYY-MM-DD");

  if (momentDate.isValid()) {
    return momentDate.format(template);
  }

  return null;
};

export const formatPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    const [dialCode, number] = phoneNumber.split("-");

    const formattedNumber = insertSpaceAfterIndex(number, 3);

    if (!dialCode) {
      return formattedNumber;
    }

    return `(${dialCode}) ${formattedNumber}`;
  }

  return "-";
};

export const formatTruckLabel = truck => {
  const {
    vehicle_registration_number,
    manufacturer: { name: manufacturer_name } = {},
    model: { name: model_name } = {},
    type: { name: type_name } = {},
    weight,
    length,
    volume
  } = truckProxy(truck);
  let manufacturerName;

  if (!manufacturer_name) {
    const {
      model: { truck_manufacturer: { name: manufacturerNameLocal } } = {}
    } = truckProxy(truck);

    manufacturerName = manufacturerNameLocal;
  }

  return [
    vehicle_registration_number,
    [manufacturer_name, manufacturerName, model_name].filter(Boolean).join(" "),
    type_name,
    weight && `${weight} tons`,
    volume && `${formatNumber(volume)} lt`,
    length && `${length} ft`
  ]
    .filter(Boolean)
    .join(" — ");
};

export const formTruckMainInfo = truck => {
  const {
    manufacturer: { name: manufacturer_name } = {},
    model: { name: model_name } = {}
  } = truckProxy(truck);

  return [manufacturer_name, model_name].filter(Boolean).join(" — ");
};

export const formatLicenseClass = license_class => {
  if (!license_class) {
    return null;
  }

  const delimiter = "_";
  const indexOfDelimiter = license_class.toString().indexOf(delimiter);

  return (
    license_class &&
    license_class
      .toString()
      .substr(indexOfDelimiter + 1)
      .toUpperCase()
  );
};

/**
 * capitalize a given string
 * if passed as an argument variable is not string - returns empty string
 * @param string {string}
 * @returns {string}
 */
export const capitalize = string => {
  if (typeof string !== "string") {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatLocation = location => {
  const orderedKeys = Object.keys(location);

  return orderedKeys
    .map(key => capitalize(location[key].name))
    .filter(Boolean)
    .join(", ");
};

export const formatNameFromProfile = (
  { first_name, middle_name, last_name, title } = {},
  withTitle = false
) => {
  return [withTitle && title && `${title}.`, first_name, middle_name, last_name]
    .filter(Boolean)
    .join(" ");
};

export const toSnakeCase = (string, ignoreHyphen = false) => {
  if (typeof string !== "string") {
    string = `${string}`;
  }

  if (ignoreHyphen) {
    return string.toLocaleLowerCase().replace(/\s+/g, "_");
  }

  return string.toLocaleLowerCase().replace(/\s+|-/g, "_");
};

export const toKebabCase = string => {
  if (typeof string !== "string") {
    string = `${string}`;
  }

  return string
    .replace(/[0-9]/g, "")
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-");
};

export default {
  formatPhoneNumber,
  formatTruckLabel,
  formatNumber,
  dateFormatter,
  formatLocation,
  capitalize,
  formatNameFromProfile
};
