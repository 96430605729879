import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { PageHeader, Collapse, Icon, Affix } from "antd";
import Scrollspy from "react-scrollspy";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";

import { SUPPORT_ADDITIONAL_MAIL } from "../../config/constants";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { useScrollTop } from "../../utils/navigation";
import { getAssetUrl } from "../../utils/url-utils";
import { toKebabCase } from "../../utils/format";
import Helmet from "../../common/Helmet";
import StaticPagesService from "../../services/api/static-pages";
import Fallback from "./fallback";

import "./styles.less";

const gutter = { lg: 10, sm: 10, xs: 15 };
const { Panel } = Collapse;

const FAQ = inject("AppState")(
  observer(({ AppState, preloadedSchema }) => {
    const [schema, setSchema] = useState();
    const [hasError, setHasError] = useState(false);

    const loadSchema = async () => {
      try {
        const loadedSchema = await StaticPagesService.getFAQStaticPage();

        loadedSchema.sections.forEach(section => {
          section.key = toKebabCase(section.title);
        });
        setSchema(loadedSchema);
      } catch (e) {
        setHasError(true);
      }
    };

    useScrollTop();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      AppState.addLayoutClass("faq");
      if (preloadedSchema) {
        preloadedSchema.sections.forEach(section => {
          section.key = toKebabCase(section.title);
        });
        setSchema(preloadedSchema);

        return;
      }

      loadSchema();
    }, []);

    useEffect(() => {
      AppState.addLayoutClass("faq");

      return () => AppState.removeLayoutClass("faq");
    }, [AppState]);

    const handleSwitchSection = sectionName => e => {
      const element = document.querySelector(`#${sectionName}`);

      if (!element) {
        return;
      }

      e.preventDefault();

      element.scrollIntoView({ block: "start", behavior: "smooth" });
    };

    if (hasError) {
      return <Fallback />;
    }

    if (!schema) {
      return null;
    }

    const {
      hero: { title, image },
      sections = []
    } = schema;

    const renderPhoneLinks = (phonesString = "") => {
      return phonesString
        .split(",")
        .map(number => (
          <a
            href={`tel:${number}`}
            className="phone-number link-common font-bolder fs-16"
          >
            {number}
          </a>
        ))
        .reduce((links, item, index) => {
          links.push(item);

          if (index % 2 === 0) {
            links.push(", ");
          }

          return links;
        }, []);
    };

    const renderNavigation = () => {
      return sections.map(({ key, title: sectionTitle }) => (
        <a
          key={key}
          className="button buttons-wrapper"
          href={`#${key}`}
          onClick={handleSwitchSection(key)}
        >
          {sectionTitle}
        </a>
      ));
    };

    const renderSection = () => {
      return sections.map(({ title: sectionTitle, questions, key }) => {
        return (
          <div className="section-wrapper">
            <div className="vh-section font-bolder fs-24" id={key}>
              {sectionTitle}
            </div>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <Icon component={Arrow} rotate={isActive ? 180 : 0} />
              )}
            >
              {questions.map(({ question, answer }) => (
                <Panel header={question} key={toKebabCase(question)}>
                  <p
                    className="faq-content"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </Panel>
              ))}
            </Collapse>
          </div>
        );
      });
    };

    return (
      <>
        <Helmet title={title} />
        <Row
          className="faq-img"
          type="flex"
          justify="center"
          style={{ backgroundImage: `url(${getAssetUrl(image)})` }}
        >
          {" "}
        </Row>
        <Row gutter={gutter} className="header">
          <Col
            lg={{ span: 21, offset: 1 }}
            md={{ span: 21, offset: 0 }}
            sm={{ span: 21, offset: 0 }}
          >
            <PageHeader title={title} />
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col
            className="affix-wrapper"
            lg={{ span: 4, offset: 1 }}
            md={{ span: 6, offset: 1 }}
            sm={{ span: 6, offset: 0 }}
            xs={{ span: 24 }}
          >
            <Affix>
              <Scrollspy
                items={sections.map(({ key }) => key)}
                currentClassName="button active"
              >
                {renderNavigation()}
              </Scrollspy>
            </Affix>
          </Col>

          <Col
            lg={{ span: 18, offset: 1 }}
            md={{ span: 14, offset: 0 }}
            sm={{ span: 16, offset: 1 }}
            xs={{ span: 24, offset: 0 }}
          >
            {renderSection()}
            <div className="m-top-20 m-left-35 fs-16">
              <div>Do You Have Other Questions or Enquiries?</div>
              <div className="footer-content m-top-20">
                Please call our helpdesk{" "}
                {renderPhoneLinks(AppState.contactInfo.phone_number)} or send an
                email to{" "}
                <a
                  href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
                  className="email-link link-common font-bolder fs-16"
                >
                  {SUPPORT_ADDITIONAL_MAIL}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  })
);

export default FAQ;
