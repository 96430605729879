/* eslint-disable consistent-return */
import { isObservable, toJS } from "mobx";

const nameManualAttributes = ["manufacturer", "model", "type"];
const manualAttributes = ["length", "weight", "volume"];

const proxiedAttributes = [...manualAttributes, ...nameManualAttributes];

const fetchManualAttribute = (name, source) => {
  return source.find(attribute => attribute.name.includes(name));
};

const isAttributeRequireNamedObject = attribute => {
  return (
    nameManualAttributes.filter(name => attribute.name.includes(name)).length >
    0
  );
};

const truckProxy = obj => {
  return new Proxy(isObservable(obj) ? toJS(obj) : obj, {
    get(target, prop) {
      const value = target[prop];
      const awaitingAttributes = target.attributes_waiting_confirmation;
      const hasAwaitingAttributes = Array.isArray(awaitingAttributes);

      if (hasAwaitingAttributes && proxiedAttributes.includes(prop)) {
        const awaitingAttribute = fetchManualAttribute(
          prop,
          awaitingAttributes
        );
        if (!awaitingAttribute) {
          return value;
        }
        if (isAttributeRequireNamedObject(awaitingAttribute)) {
          return { name: awaitingAttribute.value };
        }
        return awaitingAttribute.value;
      }

      if (!(prop in target)) {
        return;
      }

      return value;
    }
  });
};

export default truckProxy;
