/* eslint-disable no-unused-vars */
import React, { useContext, useCallback } from "react";
import { observer } from "mobx-react";
import { Col, Row, Button, Checkbox, Input, Spin } from "antd";

import Field from "../../common/FieldContainer";
import FormContext from "../../models/forms/form-context";
import "./styles.less";

const renderContract = formState => {
  const handleDownloadContract = e => {
    e.preventDefault();
    formState.downloadContractExample();
  };

  return (
    <>
      <div style={{ marginBottom: 40 }}>
        <p>
          One of the greatest challenges for new owner-operators and small {""}
          fleet owners is finding loads. This task can be difficult and {""}
          represents one of the most common reasons why many small trucking {""}
          companies go out of business.
        </p>
        <p>
          Let me start by adjusting how you should think about this situation.
          You really shouldn’t be looking for loads. That sort of thinking is
          just a short-term mentality that won’t get you far. Instead, you
          should be looking for clients and relationships.
        </p>
        <p>
          (Note: we do not provide trucking contracts. This article is {""}
          provided for information purposes only)
        </p>
        <p>
          What not to do <br />
          If you are just looking for loads, you can simply go to any load {""}
          board along with thousands of other truckers. However, any {""}
          experienced trucker can tell you that you are not going to grow a {""}
          profitable trucking company with load boards, especially free ones.
        </p>
      </div>
      {formState.isDownloadingContract && (
        <Spin className="loader" size="large" />
      )}
      <Row gutter={{ lg: 10, xs: 20 }} type="flex" justify="center">
        <Col lg={12}>
          <Field name="contract.terms_of_contract">
            <Checkbox style={{ display: "flex", justifyContent: "center" }}>
              I accept {` `}
              <span
                onClick={handleDownloadContract}
                style={{ textDecorationLine: "underline" }}
              >
                Virtual Haulage Truck Owners Contract Terms
              </span>
            </Checkbox>
          </Field>
        </Col>
      </Row>
    </>
  );
};

const renderRejectMessage = (formState, cancelClickHandler) => {
  const { TextArea } = Input;

  const renderEditor = formStateEditor => (
    <Col lg={16} md={22} xs={22}>
      <Field name="comment.comment">
        <TextArea rows={9} />
      </Field>
      <Row className="button-container">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="large"
            htmlType="reset"
            type="default"
            onClick={cancelClickHandler}
          >
            Cancel
          </Button>
          <Button
            className="m-left-20"
            size="large"
            loading={formStateEditor.$("comment").submitting}
            type="primary"
            htmlType="submit"
            onClick={formStateEditor.$("comment").onSubmit}
          >
            Add Comment
          </Button>
        </Col>
      </Row>
    </Col>
  );

  return <>{renderEditor(formState)}</>;
};

const Contract = observer(() => {
  const formState = useContext(FormContext);
  const newCommentHandler = useCallback(() => {
    formState.addComment();
  }, [formState]);
  const cancelClickHandler = useCallback(() => {
    formState.toggleIsDeclineContractComment();
  }, [formState]);

  const render = () => {
    return (
      <Col lg={16} md={21} xs={21}>
        {renderContract(formState)}
        <Row
          gutter={{ lg: 10, xs: 20 }}
          className="button-container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col
            lg={9}
            md={24}
            xs={24}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              size="large"
              type="default"
              htmlType="reset"
              onClick={newCommentHandler}
            >
              Decline
            </Button>
            <Button
              size="large"
              loading={formState.$("contract").submitting}
              disabled={formState.$("contract").submitting}
              type="primary"
              htmlType="submit"
              onClick={formState.$("contract").onSubmit}
            >
              Accept
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <>
      <Row className="title" type="flex" justify="center">
        <Col>
          <h1>Contract</h1>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="top">
        {formState.isDeclineContractComment &&
          renderRejectMessage(formState, cancelClickHandler)}
        {!formState.isDeclineContractComment && render()}
      </Row>
    </>
  );
});

export default Contract;
