import { Component } from "react";

export default class StaticPageErrorBoundary extends Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, errorInfo) {
    console.log("%cStatic render error", "color: red");
    console.info({ error, errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError && typeof fallback === "function") {
      return fallback();
    }
    return children;
  }
}
