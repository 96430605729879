import { useEffect } from "react";
import { inject } from "mobx-react";

function ContactsPreview({ AppState, preloadedSchema }) {
  useEffect(() => {
    AppState.contactsPreview = preloadedSchema;
    return () => {
      AppState.contactsPreview = false;
    };
  }, [preloadedSchema, AppState]);

  return "Please, pay attantion to the contact information in the footer.";
}

export default inject("AppState")(ContactsPreview);
