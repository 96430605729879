import { formatNumber } from "../../../../utils/format";

const measurements = new Map([
  ["weight", "tons"],
  ["length", "feet"],
  ["volume", "lt"]
]);

const labels = new Map([
  ["weight", "Weight"],
  ["length", "Length"],
  ["volume", "Volume"]
]);

/**
 * @param shipment {Shipment}
 * @param options
 * @returns {{label: string, value: string}}
 */
export function getShipmentValue(
  shipment,
  options = { labelPrefix: false, includeMeasurements: false }
) {
  const { labelPrefix, includeMeasurements } = options;
  const { weight, length, volume } = shipment;
  const values = { weight, volume, length };

  const mergedLabel = [];
  const mergedValue = [];

  Object.entries(values)
    .filter(entry => {
      const [, value] = entry;

      return !!value;
    })
    .forEach(entry => {
      const [key, value] = entry;

      if (labelPrefix) {
        mergedLabel.push(labels.get(key));
      } else {
        mergedLabel.push(`${labels.get(key)}, ${measurements.get(key)}`);
      }

      const formattedValue = key === "volume" ? formatNumber(value) : value;

      mergedValue.push(
        includeMeasurements
          ? `${formattedValue} ${measurements.get(key)}`
          : formattedValue
      );
    });

  return {
    label: labelPrefix
      ? `${labelPrefix} ${mergedLabel.join(" / ")}`
      : mergedLabel.join(" / "),
    value: mergedValue.join(" / ")
  };
}
