import { computed, observable, action } from "mobx";
import { message } from "antd";
import BaseStore from "./base-manager";
import PaymentService from "../../../services/api/payments";
import {
  PAGE_DEFAULT,
  PAYMENT_METHODS,
  PAYMENT_STATUS_CODES,
  PAYMENT_STATUSES,
  STATUS_DEFAULT
} from "../../../config/constants";
import { history } from "../../../contexts/history";
import AuthStore from "../../auth";

class PaymentsStore extends BaseStore {
  loadFunction = PaymentService.get;

  @observable tag = STATUS_DEFAULT;

  @observable loading = false;

  @observable checkingInvoiceStatus = false;

  @observable invoice = {
    payment_details: {}
  };

  @observable isReadyToSubmit = false;

  @observable paymentInProgress = false;

  @observable paymentMethod = PAYMENT_METHODS.GTPAY;

  @action
  setPaymentMethod(method) {
    this.paymentMethod = method;
  }

  @action
  setTag(status) {
    this.tag = status;
    this.setPage(PAGE_DEFAULT);
  }

  @action
  async loadInvoice(id, withLoading = true) {
    this.loading = withLoading;
    await PaymentService.loadInvoice(id)
      .then(invoice => {
        this.invoice = invoice;
      })
      .catch(() => {
        message.warning("Something went wrong, please try again later.");
        history.replace("/payments");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  loadPaidInvoice = id => {
    return PaymentService.loadInvoice(id);
  };

  @action
  async pay(e) {
    e.preventDefault();
    try {
      await this.checkInvoiceStatus();
      if (!this.couldBePaid) {
        return false;
      }

      e.target.submit();
      this.isReadyToSubmit = true;
      this.paymentInProgress = true;

      return true;
    } catch (error) {
      message.warning(error.message);
      history.replace("/payments");

      return false;
    }
  }

  @action
  async checkInvoiceStatus() {
    this.checkingInvoiceStatus = true;
    try {
      await this.loadInvoice(this.invoice.id, false);

      if (!this.isPending) {
        const invoicePaidError = new Error(
          "The Invoice has been already paid."
        );

        invoicePaidError.shouldRedirect = true;
        throw invoicePaidError;
      }
    } finally {
      this.checkingInvoiceStatus = false;
    }
  }

  @computed
  // eslint-disable-next-line class-methods-use-this
  get tags() {
    return Object.entries(PAYMENT_STATUSES).map(entry => {
      const [code, descriptor] = entry;

      return { code, name: descriptor.name };
    });
  }

  @computed
  get specificArguments() {
    return {
      status: this.tag
    };
  }

  @computed
  get hasPaymentDetails() {
    return (
      this.invoice.payment_details &&
      Object.keys(this.invoice.payment_details).length > 0
    );
  }

  @computed
  get isPending() {
    return this.invoice.status === PAYMENT_STATUS_CODES.PENDING;
  }

  @computed
  get isAccountHasBankDetails() {
    if (!AuthStore.isShipper) {
      return true;
    }

    return AuthStore.isBankInfoFull;
  }

  @computed
  get couldBePaid() {
    return (
      this.isPending && this.hasPaymentDetails && this.isAccountHasBankDetails
    );
  }
}

export default new PaymentsStore();
