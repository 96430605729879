import React from "react";
import { Badge } from "antd";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const StyledBadge = styled(Badge)`
  font-size: 16px;
  .ant-badge-count {
    top: -10px;
    right: -10px;
    background: #ea5353;
    font-weight: 500;
  }
`;

function MessagesMenuItem({ children, ThreadsStore }) {
  return (
    <StyledBadge count={ThreadsStore.totalUnreadMessagedCount}>
      {children}
    </StyledBadge>
  );
}

export default inject("ThreadsStore")(observer(MessagesMenuItem));
