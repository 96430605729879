import { computed, observable, action, reaction } from "mobx";
import FileDownload from "js-file-download";
import { message } from "antd";
import BaseStore from "./base-manager";
import { history } from "../../../contexts/history";

import SelectQuoteService from "../../../services/api/select-quote";
import {
  MAX_QUOTES_TO_SELECT_SHARED,
  MAX_QUOTES_TO_SELECT_NON_SHARED,
  SHIPMENT_PENDING_STATUS_CODES
} from "../../../config/constants";
import throttledMessage from "../../../utils/throttled-message";
import Shipment from "../../shipment";
import PaginationItem from "../../../common/Pagination/Item";
import { validateSelectQuote } from "../../../utils/validation";

const PREFERENCES_LABELS = {
  1: "1st Preference",
  2: "2nd Preference",
  3: "3rd Preference"
};

class SelectQuoteManager extends BaseStore {
  @observable shipmentId = null;

  @observable isLoadingShipment = true;

  @observable insurancePercentage = 0;

  @observable rawData = {};

  @observable defaultSortKey = "price";

  @observable quotesMap = new Map();

  @observable companyNames = new Map();

  @observable isDownloadingContract = null;

  showInsuranceMessage = throttledMessage(
    "Insurance cost will be added into invoices.",
    "info",
    10000
  );

  constructor() {
    super();

    reaction(
      () => this.insurancePercentage,
      () => {
        this.paginator.data = this.applyInsurance(this.paginator.data);
      }
    );
  }

  loadFunction = (...args) => {
    if (!this.shipmentId) {
      this.isLoading = false;

      return Promise.resolve({});
    }

    return SelectQuoteService.load(...args).then(({ data }) => {
      const shipment = new Shipment(data);

      if (!shipment.isOwnedByCurrentUser) {
        this.reset();

        message.warning(`You are not able to select quote for this shipment.`);
        history.replace("/");

        return Promise.reject();
      }

      if (data.status === SHIPMENT_PENDING_STATUS_CODES.QUOTE_SELECTED) {
        this.reset();

        message.warning(
          `The Quote(-s) for Shipment ${this.shipmentId} has been already selected.`
        );
        history.replace("/");

        return Promise.reject();
      }

      this.isLoadingShipment = false;
      this.rawData = new Shipment(data);
      if (this.companyNames.size === 0) {
        this.rawData.quotes.forEach((quote, i) => {
          this.companyNames.set(quote.id, `Company ${i + 1}`);
        });
      }

      return {
        data: [...this.applyInsurance(data.quotes, false)]
      };
    });
  };

  @computed
  get specificArguments() {
    return {
      shipmentId: this.shipmentId
    };
  }

  @computed
  get quotePreference() {
    return quote => {
      if (this.isSharedConsignment) {
        return this.quotesMap.has(quote.id);
      }

      if (!this.quotesMap.has(quote.id)) {
        return null;
      }

      return this.quotesMap.get(quote.id).preference;
    };
  }

  @computed
  get canSelectQuote() {
    return this.quotesMap.size < this.maxQuotesToSelect;
  }

  @computed
  get selectedQuotes() {
    // return [...this.quotesMap.keys()].map(quoteId => {
    //   return this.tableData.find(quote => quote.id === quoteId);
    // });
    return [...this.quotesMap.values()];
  }

  @computed
  get companyName() {
    return quoteId => this.companyNames.get(quoteId);
  }

  @computed
  get isSharedConsignment() {
    return this.rawData.is_shared_consignment;
  }

  @computed
  get maxQuotesToSelect() {
    return this.isSharedConsignment
      ? MAX_QUOTES_TO_SELECT_SHARED
      : MAX_QUOTES_TO_SELECT_NON_SHARED;
  }

  @computed
  get selectedWeightVolume() {
    return this.selectedQuotes.reduce((sum, quote) => {
      return sum + +quote.chargeable;
    }, 0);
  }

  @computed
  get selectedCompanies() {
    const companyNames = [];

    this.quotesMap.forEach((_, quoteId) => {
      companyNames.push({
        quoteId,
        companyName: this.companyNames.get(quoteId)
      });
    });

    return companyNames;
  }

  @computed
  get isVolumeBased() {
    return !!this.rawData.volume;
  }

  @computed
  get value() {
    if (this.isVolumeBased) {
      return this.rawData.volume;
    }

    return this.rawData.weight;
  }

  @computed.struct
  get paginationSettings() {
    return {
      total: this.rawData.quotes ? this.rawData.quotes.length : 0,
      pageSize: 10,
      onChange: page => {
        console.warn({ page });
        this.disableReaction();
        this.setPage(page);
        setTimeout(this.enableReaction.bind(this), 100);
      },
      size: "small",
      itemRender: PaginationItem,
      hideOnSinglePage: true
    };
  }

  @action
  selectQuote(quote) {
    const validityState = this.validateQuote(quote);

    if (!validityState.valid) {
      return validityState;
    }

    const quotesSize = this.quotesMap.size;

    if (this.quotesMap.has(quote.id)) {
      this.quotesMap.delete(quote.id);

      [...this.quotesMap.keys()].forEach((newQuote, i) => {
        this.quotesMap.set(newQuote, {
          ...quote,
          preference: PREFERENCES_LABELS[i + 1]
        });
      });

      return validityState;
    }

    if (quotesSize === this.maxQuotesToSelect) {
      return validityState;
    }

    this.quotesMap.set(quote.id, {
      ...quote,
      preference: PREFERENCES_LABELS[quotesSize + 1]
    });

    return validityState;
  }

  @action
  reset() {
    super.reset();
    this.quotesMap.clear();
    this.companyNames.clear();
    this.shipmentId = null;
    this.rawData = {};
    this.insurancePercentage = 0;
    this.isLoadingShipment = true;
  }

  @action
  applyInsurance(source = [], showMessage = true) {
    if (this.insurancePercentage && showMessage === true) {
      this.showInsuranceMessage();
    }

    if (this.isSharedConsignment) {
      return source.map(quote => {
        if (!this.insurancePercentage) {
          quote.insurance = null;

          return quote;
        }

        const { cargo_value } = this.rawData;
        const { chargeable } = quote;
        const { value } = this;

        const insurance =
          ((+cargo_value * +chargeable) / value) * this.insurancePercentage;

        quote.insurance = Number.parseFloat(insurance).toFixed(2);

        return quote;
      });
    }

    return source.map(quote => {
      if (!this.insurancePercentage) {
        quote.insurance = null;

        return quote;
      }

      const { cargo_value } = this.rawData;

      const insurance = +cargo_value * this.insurancePercentage;

      quote.insurance = Number.parseFloat(insurance).toFixed(2);

      return quote;
    });
  }

  observeInsuranceChange({ field }) {
    const isInsuranceShouldBeCalculated = Boolean(+field.value);
    const { commodity_type: { insurance_percentage } = {} } = this.rawData;

    this.insurancePercentage = isInsuranceShouldBeCalculated
      ? insurance_percentage
      : 0;
  }

  downloadContract(quoteId) {
    this.isDownloadingContract = quoteId;
    SelectQuoteService.downloadContract({
      quoteId,
      shipmentId: this.shipmentId
    })
      .then(response => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `Contract-${this.shipmentId}-${quoteId}.pdf`
            : suggestedFileName;

        FileDownload(response.data, effectiveFileName);
      })
      .catch(() => {
        message.error(
          "Something went wrong, please try to download Contract later."
        );
      })
      .finally(() => {
        this.isDownloadingContract = null;
      });
  }

  validateQuote = quote => {
    return validateSelectQuote(quote);
  };
}

export default new SelectQuoteManager();
