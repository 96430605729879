import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute(props) {
  const {
    AuthStore: { isLoggedIn, authorizing, user },
    roles = [],
    ...restProps
  } = props;

  if (!isLoggedIn && authorizing) {
    return null;
  }

  if (isLoggedIn) {
    if (roles.length > 0 && !roles.includes(user.role)) {
      return null;
    }
    return <Route {...restProps} />;
  }
  return <Redirect to="/login" />;
}

export default inject("AuthStore")(observer(PrivateRoute));
