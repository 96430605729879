import { observable, computed, action, reaction, intercept } from "mobx";
import AuthStore from "../../auth";
import {
  SIZE_DEFAULT,
  PAGE_DEFAULT,
  SORT_FIELD_DEFAULT,
  SORT_TYPE_DEFAULT
} from "../../../config/constants";
import PaginationItem from "../../../common/Pagination/Item";

class BaseStore {
  authStore;

  @observable paginator = {};

  @observable selectedRowKeys = [];

  @observable isLoading = false;

  @observable size = SIZE_DEFAULT;

  @observable page = PAGE_DEFAULT;

  @observable defaultSortKey = SORT_FIELD_DEFAULT;

  @observable sortedInfo = {
    order: SORT_TYPE_DEFAULT,
    columnKey: this.defaultSortKey
  };

  @observable rowId = 0;

  @observable
  onlySingleSelection = false;

  @observable
  reactionEnabled = true;

  sortableFields = [];

  /**
   * @abstract
   * @returns {Promise<{}>}
   */
  loadFunction = () => Promise.resolve({});

  constructor(authStore) {
    this.authStore = authStore || AuthStore;
    // set up reactions to load
    const reactionSideEffect = this.load.bind(this);
    reaction(() => this.page, reactionSideEffect);
    reaction(() => this.size, reactionSideEffect);
    reaction(() => this.sortedInfo, reactionSideEffect);
    reaction(() => this.specificArguments, reactionSideEffect);

    intercept(this, "sortedInfo", change => {
      let {
        newValue: { order }
      } = change;
      if (order === "ascend") {
        order = "asc";
      } else if (order === "descend") {
        order = "desc";
      } else {
        order = false;
      }
      change.newValue.order = order;
      return change;
    });
  }

  @action
  setTag() {
    this.setPage(PAGE_DEFAULT);
  }

  @action setRowId(rowId) {
    this.rowId = rowId;
  }

  @action
  setSize(size) {
    this.size = size;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setSelectedRowKeys(row = []) {
    this.selectedRowKeys = row;
  }

  @action
  setSortField(field) {
    this.sortField = field;
  }

  @action
  setSortType(type) {
    this.sortType = type;
  }

  @action
  setSortedInfo(sorter = { columnKey: null, order: null }) {
    this.sortedInfo = sorter;
  }

  @action
  enableReaction() {
    this.reactionEnabled = true;
  }

  @action
  disableReaction() {
    this.reactionEnabled = false;
  }

  @action
  async load() {
    if (!this.reactionEnabled) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    const values = {
      size: this.size,
      page: this.page,
      sortField: this.sortedInfo.columnKey || this.defaultSortKey,
      sortType: this.sortedInfo.order || SORT_TYPE_DEFAULT,
      ...this.specificArguments
    };
    this.isLoading = true;

    const paginator = await this.loadFunction(values)
      .then(data => data)
      .finally(() => {
        window.scroll({
          behavior: "smooth",
          top: 0
        });
        this.isLoading = false;
      });
    if (this.page > paginator.last_page) {
      this.setPage(paginator.last_page);
      return;
    }

    this.paginator = paginator;
  }

  @action
  async loadAfterRemoval() {
    if (this.paginator.total % this.size === 1) {
      this.disableReaction();
      this.page = this.page === 1 ? 1 : this.page - 1;
      this.enableReaction();
    }

    this.load();
  }

  @computed
  get tableData() {
    let data = this.paginator.data ? [...this.paginator.data] : [];
    if (typeof this.proxy === "function") {
      data = data.map(entity => this.proxy(entity));
    }
    return data;
  }

  @computed
  get row() {
    return this.paginator.data.map(row => {
      if (row.id === this.rowId) {
        return row;
      }
      return null;
    });
  }

  @computed
  get getSize() {
    return this.paginator.per_page;
  }

  @computed
  get getPage() {
    return this.paginator.current_page;
  }

  @computed
  get getTotal() {
    return this.paginator.total;
  }

  @computed
  get sortOrder() {
    return columnKey => {
      return (
        this.sortedInfo.columnKey === columnKey &&
        this.transformSortDirection(this.sortedInfo.order)
      );
    };
  }

  @computed
  /**
   * @abstract
   */
  // eslint-disable-next-line class-methods-use-this
  get specificArguments() {
    return {};
  }

  @computed.struct
  get paginationSettings() {
    return {
      total: this.getTotal,
      current: this.getPage,
      pageSize: this.getSize,
      onChange: this.setPage.bind(this),
      size: "small",
      itemRender: PaginationItem,
      hideOnSinglePage: true
    };
  }

  transformSortDirection = order => {
    if (order === "asc") {
      return "ascend";
    }
    if (order === "desc") {
      return "descend";
    }

    return false;
  };

  reset() {
    this.disableReaction();

    this.paginator = {};
    this.page = 1;

    this.sortedInfo = {
      order: SORT_TYPE_DEFAULT,
      columnKey: this.defaultSortKey
    };

    if (typeof this.onTableReset === "function") {
      this.onTableReset();
    }

    this.enableReaction();
  }
}

export default BaseStore;
