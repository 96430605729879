/* eslint-disable class-methods-use-this */
import { observable, action, computed } from "mobx";
import { message } from "antd";
import ShipmentsService from "../../../../services/api/shipments";
import BaseForm from "../../base-form";

class ShipmentCancelForm extends BaseForm {
  @observable shippingId = null;

  @observable isCancelSuccess = false;

  @computed
  get shouldBeOpened() {
    return !!this.shippingId;
  }

  @action
  delete() {
    const values = {
      id: this.shippingId
    };
    return ShipmentsService.delete(values)
      .then(({ data }) => {
        this.isCancelSuccess = true;
        setTimeout(() => {
          message.success(`Shipment ${values.id} canceled successfully.`);
        }, 2000);
        this.resetState();
        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          message.warning(e.message);
        }
      })
      .finally(() => {
        this.isCancelSuccess = false;
      });
  }

  @action
  initState({ id }) {
    this.shippingId = id;
  }

  resetState() {
    this.shippingId = null;
  }

  hooks() {
    return {
      onSuccess: () => {
        return this.delete();
      }
    };
  }
}

export default new ShipmentCancelForm();
