/* eslint-disable import/no-cycle */
import { observable, extendObservable, computed } from "mobx";

import moment from "moment";
import {
  ISSUE_OR_COMPLAINT_CATEGORIES,
  ZEN_DESK_FIELDS,
  ZEN_DESK_SHIPMENT_ID_FIELD
} from "../../../config/constants";
import { getQuote } from "../../../components/items-managers/manage-shipments/common/active-shipments/description";
import ZenDeskService from "../../../services/api/zen-desk";
import AuthState from "../../auth";
import { dateFormatter } from "../../../utils/format";

class Thread {
  @observable latest_comment_from_form = null;

  constructor(attributes) {
    extendObservable(this, attributes);
  }

  getCustomFieldValue(fieldId) {
    const field = this.custom_fields.find(({ id }) => `${id}` === fieldId);

    if (!field) {
      return null;
    }

    return field.value;
  }

  updateCustomField(fieldId, value) {
    const field = this.custom_fields.find(({ id }) => `${id}` === fieldId);

    if (!field) {
      return;
    }

    field.value = value;
  }

  hasUnreadMessagesForUserById(id) {
    const unreadMessages = this.getCustomFieldValue(
      ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE
    );

    if (!unreadMessages) {
      return false;
    }

    try {
      const parsed = JSON.parse(unreadMessages);

      return parsed.participant_id.includes(id);
    } catch (e) {
      // indicates that there is an unread message from admin or agent
      if (["admin", "agent"].includes(unreadMessages)) {
        return true;
      }

      return false;
    }
  }

  @computed
  get hasUnreadMessagesForUser() {
    return this.hasUnreadMessagesForUserById(ZenDeskService.user.id);
  }

  isRelatedToShipment(shipment) {
    // todo check if has flag in field

    return (
      `${this.getCustomFieldValue(ZEN_DESK_SHIPMENT_ID_FIELD)}` ===
      `${shipment.id}`
    );
  }

  getLatestComment() {
    const lastComment =
      this.latest_comment_from_form ||
      this.getCustomFieldValue(ZEN_DESK_FIELDS.SYSTEM_LATEST_COMMENT);

    if (!lastComment || typeof lastComment !== "string") {
      return this.description;
    }

    if (lastComment === this.subject) {
      return null;
    }

    if (lastComment.length <= 90) {
      return lastComment;
    }

    return `${lastComment.slice(0, 90)}...`;
  }

  /**
   * @param shipment {Shipment}
   * @param quote
   * @param comment
   * @returns {Promise<void>}
   */
  async createThreadForParticipant(shipment, quote, comment) {
    try {
      const user = await this.getReceiverForShipment(shipment, quote);

      const newUnreadValue = JSON.stringify({
        participant_id: [user.id]
      });

      const unreadMessageEventFlag = [
        {
          id: ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE,
          value: newUnreadValue
        }
      ];

      const {
        data: { ticket }
      } = await ZenDeskService.createShipmentThread({
        subject: `Shipment ID ${shipment.id}`,
        description: comment,
        requester_id: ZenDeskService.user.id,
        author_id: ZenDeskService.user.id,
        submitter_id: ZenDeskService.user.id,
        collaborator_ids: user.id ? [user.id] : null,
        shipment_id: shipment.id,
        custom_fields: unreadMessageEventFlag
      });

      ticket.updated_at = moment(ticket.updated_at);

      return ticket;
    } catch (e) {
      return false;
    }
  }

  async createNewThreadForParticipant(shipment, quote) {
    try {
      const ticket = {
        id: "new",
        shipment,
        quote,
        subject: `Shipment ID ${shipment.id}`,
        requester_id: ZenDeskService.user.id,
        author_id: ZenDeskService.user.id,
        submitter_id: ZenDeskService.user.id,
        shipment_id: shipment.id,
        collaborator_ids: [],
        custom_fields: [],
        comments: []
      };

      ticket.updated_at = moment(ticket.updated_at);

      return ticket;
    } catch (e) {
      return false;
    }
  }

  async getReceiverForShipment(shipment, quoteFromAction) {
    const quote = quoteFromAction || getQuote(shipment);

    const { owner_id } = AuthState.isTruckOwner ? shipment : quote;
    let user = {};

    if (owner_id) {
      user = await ZenDeskService.getUserOrCreate({ id: owner_id });
    }

    return user;
  }

  async markAsRead() {
    try {
      const value = {
        participant_id: []
      };

      await ZenDeskService.updateTicketField(
        this.id,
        ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE,
        JSON.stringify(value)
      );

      this.updateCustomField(ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE, value);
    } catch (e) {
      console.warn("Unable to unread thread.");
    }
  }

  isOwned() {
    return this.requester_id === ZenDeskService.user.id;
  }

  async sync() {
    try {
      const { custom_fields } = await ZenDeskService.loadTicket(this.id);

      this.custom_fields = custom_fields;
    } catch (e) {
      console.warn(e);
    }
  }

  async getReceiversIds() {
    let currentIds = [];

    if (this.id !== "new") {
      await this.sync();
    }

    const unreadMessages = this.getCustomFieldValue(
      ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE
    );

    try {
      currentIds = JSON.parse(unreadMessages).participant_id;
    } catch (e) {
      currentIds = [];
    }

    if (this.isOwned()) {
      return [...this.collaborator_ids, ...currentIds];
    }

    return [this.requester_id, ...currentIds];
  }

  @computed
  get isSupportTicket() {
    return ["incident", "problem"].includes(this.type);
  }

  @computed
  get isContactPageTicket() {
    return ["question"].includes(this.type);
  }

  @computed
  get sortedComments() {
    return this.comments;
  }

  @computed
  get complaintDate() {
    const fieldValue = this.getCustomFieldValue(
      ZEN_DESK_FIELDS.ISSUE_OR_INCIDENT_DATE
    );

    const date = moment(fieldValue);

    if (!fieldValue || !date.isValid()) {
      return "—";
    }

    return dateFormatter(date);
  }

  @computed
  get firstComment() {
    return this.description;
  }

  @computed
  get hasComments() {
    return this.sortedComments.length;
  }

  @computed
  get complaintCategory() {
    const categoryKey = this.getCustomFieldValue(
      ZEN_DESK_FIELDS.ISSUE_OR_COMPLAINT_CATEGORY
    );

    const category = ISSUE_OR_COMPLAINT_CATEGORIES.find(
      ({ value }) => value === categoryKey
    );

    if (!category) {
      return "—";
    }

    return category.label;
  }

  @computed
  get ticketTitle() {
    if (this.isContactPageTicket) {
      return "Support";
    }

    if (this.isSupportTicket) {
      return "Support ID";
    }

    return "Shipment ID";
  }

  @computed
  get ticketId() {
    if (this.isSupportTicket) {
      return this.id;
    }

    if (this.id === "new") {
      return this.shipment.id;
    }

    return this.getCustomFieldValue(ZEN_DESK_SHIPMENT_ID_FIELD);
  }

  @computed
  get countOfUnreadMessages() {
    if (!this.hasUnreadMessagesForUser) {
      return 0;
    }

    try {
      const arrayOfIds = JSON.parse(
        this.getCustomFieldValue(ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE)
      ).participant_id;

      return arrayOfIds.filter(id => id === ZenDeskService.user.id).length;
    } catch (e) {
      console.warn(e);

      return 0;
    }
  }
}

export default Thread;
