/* eslint-disable import/no-cycle */
import { message, Modal } from "antd";
import { TRUCK_STATUSES_CODES } from "../../../config/constants";
import { history } from "../../../contexts/history";
import RemoveTruckForm from "../../../models/forms/truck/remove-truck";
import UnlinkTruckForm from "../../../models/forms/driver/unlink-truck";
import LinkDriverForm from "../../../models/forms/link-driver-to-truck";
import TruckService from "../../../services/api/trucks";
import { pipeToArray } from "../../../utils/pipe";
import {
  getIsTruckHasActiveQuote,
  getIsTruckHasWaitingContract,
  getIsTruckLoggedAsAvailable,
  getIsTruckHasPendingQuote
} from "./list";

const TRUCK_HAS_CONTRACT_MESSAGE =
  "You have pending Contract associated with this Truck. It will be canceled automatically. Confirm that you want to remove this Truck.";
const TRUCK_USED_IN_QUOTES =
  "You have used this Truck in a Shipment Quote(s). It will be canceled automatically.";

const handleRemoveAction = (row, postRemoveMessage) => {
  RemoveTruckForm.initState({
    id: row && row.id,
    manufacturer: `${row.manufacturer && row.manufacturer.name} ${row.model &&
      row.model.name}`,
    postRemoveMessage
  });
};

const showConfirmRemoveActionModal = (row, noticeMessage) => {
  Modal.confirm({
    icon: null,
    title: "Please, confirm you want to proceed.",
    content: noticeMessage,
    okButtonProps: {
      size: "large"
    },
    cancelButtonProps: {
      size: "large"
    },
    okText: "Confirm",
    width: 390,
    autoFocusButton: null,
    onOk: () => handleRemoveAction(row)
  });
};

const truckStatusCouldNotBeReapplied = ({ status }) => {
  return [
    TRUCK_STATUSES_CODES.REAPPLIED,
    TRUCK_STATUSES_CODES.PENDING_APPROVAL,
    TRUCK_STATUSES_CODES.PENDING_RECERTIFICATION
  ].includes(status);
};

const getIsTruckAvailableForReapply = truck => {
  return (
    pipeToArray(
      truckStatusCouldNotBeReapplied,
      getIsTruckHasWaitingContract,
      getIsTruckHasActiveQuote,
      getIsTruckLoggedAsAvailable,
      getIsTruckHasPendingQuote
    )(truck, { clearFalsy: true }).length > 0
  );
};

const ACTIONS = [
  {
    key: "reapply",
    label: "Reapply",
    disabled: getIsTruckAvailableForReapply,
    onClick: row => history.push(`/reapply-truck/${row.id}`),
    tooltipIfDisabled:
      'You cannot reapply if your truck status is still "Reapplied", or "Pending Approval", or "Pending Recertification" or it\'s logged as available or used in Accepted, Active, Truck at Origin, Picked Up, Delivered shipment.'
  },
  { key: "change_inspector", label: "Change Inspector" },
  { key: "reinstate", label: "Reinstate" },
  {
    key: "removed",
    label: "Remove",
    disabled: row => row.status === TRUCK_STATUSES_CODES.REMOVED,
    onClick: row => {
      return TruckService.checkTruckCouldRemoved(row.id)
        .then(() => {
          handleRemoveAction(row);
        })
        .catch(e => {
          const {
            response: { status, data: { errors = { truck: [] } } } = {}
          } = e;

          if (errors.truck.includes(TRUCK_HAS_CONTRACT_MESSAGE)) {
            showConfirmRemoveActionModal(row, TRUCK_HAS_CONTRACT_MESSAGE);

            return;
          }

          if (errors.truck.includes(TRUCK_USED_IN_QUOTES)) {
            showConfirmRemoveActionModal(row, TRUCK_USED_IN_QUOTES);

            return;
          }

          if (status === 422 && errors.truck.length > 0) {
            message.warning(errors.truck.join(" "));
          }
        });
    }
  },
  {
    key: "link_driver",
    label: "Link Driver",
    disabled: row =>
      ![
        TRUCK_STATUSES_CODES.APPROVED,
        TRUCK_STATUSES_CODES.PENDING_RECERTIFICATION
      ].includes(row.status),
    onClick: row => {
      LinkDriverForm.setLinkToUpdate(
        row.driver && row.driver.id,
        row && row.id
      );
      LinkDriverForm.openForm({
        truck: row,
        driver: row.driver,
        mode: "trucks"
      });
    }
  },
  {
    key: "unlink_driver",
    label: "Unlink Driver",
    disabled: row => !row.driver,
    onClick: row => {
      UnlinkTruckForm.initState({
        id: row.driver.id,
        driver: `${row.driver.first_name} ${
          row.driver.middle_name !== null ? row.driver.middle_name : ""
        } ${row.driver.last_name}`,
        truck: row
      });
    }
  },
  { key: "view_history", label: "View History" },
  {
    key: "view_details",
    label: "View Details",
    onClick: row => history.push(`/truck-info/${row.id}`)
  }
];

export default ACTIONS;
