import * as Sentry from "@sentry/browser";

(() => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  const sentryUrl = process.env.REACT_APP_SENTRY_API;
  const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED;
  if (sentryUrl && sentryEnabled) {
    Sentry.init({ dsn: sentryUrl });
  }
})();
