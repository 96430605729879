import { action, computed, observable } from "mobx";
import { message } from "antd";
import BaseForm from "../../base-form";
import AuthStore from "../../../auth";
import TrackShipmentService from "../../../../services/api/track-shipment";
import {
  ACTIVE_QUOTE_STATUS,
  SHIPMENT_ACTIVE_STATUS_CODES,
  SHIPMENT_ACTIVE_STATUSES
} from "../../../../config/constants";

class ChangeStatusForm extends BaseForm {
  @observable
  isFormVisible = false;

  /**
   * @type {Shipment|null}
   */
  @observable
  shipment = null;

  @observable
  statusesLoaded = false;

  @computed
  get shouldBeOpened() {
    return !!(this.shipment && this.shipment.id);
  }

  @computed
  get isStatusAvailable() {
    const sequence = new Map([
      [
        SHIPMENT_ACTIVE_STATUS_CODES.ACTIVE,
        SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN
      ],
      [
        SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN,
        SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP
      ],
      [
        SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP,
        SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED
      ]
    ]);

    return status => {
      if (!this.shipment) {
        return false;
      }

      return sequence.get(this.shipment.trackedEntity.status) === status;
    };
  }

  @action
  setShipment(shipment) {
    this.shipment = shipment;
  }

  @action
  closeForm() {
    this.shipment = null;
  }

  // eslint-disable-next-line class-methods-use-this
  setup() {
    return {
      fields: [
        {
          name: "status_id",
          label: "Change Shipment Status",
          rules: "required",
          type: "select",
          options: Object.entries(SHIPMENT_ACTIVE_STATUSES)
            .filter(entry => {
              const [status] = entry;

              return status !== ACTIVE_QUOTE_STATUS;
            })
            .map(entry => {
              const [status, descriptor] = entry;

              return {
                value: status,
                label: descriptor.name
              };
            })
        }
      ]
    };
  }

  resetState() {
    this.$("status_id").clear();
    this.$("status_id").resetValidation();
  }

  hooks() {
    return {
      onSuccess: async () => {
        try {
          const { status_id } = this.values();
          const updater = this.updaterFunction();

          await updater(status_id);

          this.gridStore.load();
          this.setShipment(null);
          this.resetState();
        } catch (e) {
          const {
            response: { status, data: { message: errorMessage } = {} } = {}
          } = e;

          if (status >= 500) {
            message.warning(
              "Unfortunately an error occurred, please try again."
            );

            return;
          }
          if (errorMessage) {
            this.$("status_id").invalidate(errorMessage);
          } else {
            message.warning("Something went wrong, please try again later.");
          }
        }
      }
    };
  }

  updaterFunction() {
    if (AuthStore.isTruckOwner) {
      return status => {
        return TrackShipmentService.changeQuoteStatus(
          this.shipment.activeQuote.id,
          status
        );
      };
    }

    return status => {
      return TrackShipmentService.changeShipmentStatus(
        this.shipment.id,
        status
      );
    };
  }
}

export default new ChangeStatusForm();
