export function pipe(...fns) {
  return arg => fns.reduce((prev, fn) => fn(prev), arg);
}

export function pipeToArray(...fns) {
  return (arg, options = { clearFalsy: false }) => {
    const pipelineResults = fns.reduce((prev, fn) => {
      prev.push(fn(arg));
      return prev;
    }, []);

    const transformers = [];

    if (options.clearFalsy) {
      transformers.push(arr => arr.filter(Boolean));
    }

    return pipe(...transformers)(pipelineResults);
  };
}
