import { computed, observable, action } from "mobx";
import { INFO_PHONE } from "../config/constants";

class AppState {
  @observable
  layoutClassList = new Set();

  @observable
  embeddableApp = false;

  @observable
  contactsPreview = false;

  @observable contactInfo = {
    phone_number: INFO_PHONE
  };

  @computed
  get layoutClassNames() {
    return [...this.layoutClassList];
  }

  @action
  addLayoutClass(className) {
    this.layoutClassList.add(className);
  }

  @action
  removeLayoutClass(className) {
    this.layoutClassList.delete(className);
  }

  /**
   * in some cases we have to know to render header menu or to do something else for specific users (inspectors)
   */
  @action
  makeAppEmbedded(embedded = true) {
    this.embeddableApp = embedded;
  }

  @action
  setContactInfo(info = {}) {
    this.contactInfo = info;
  }
}

export default new AppState();
