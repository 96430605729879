/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { Badge } from "antd";
import useToggle from "react-use-toggle";
import Tooltip from "antd/lib/tooltip";
import Icon from "antd/lib/icon";
import { inject, observer } from "mobx-react";
import { history } from "../contexts/history";
import { ReactComponent as MessageIcon } from "../icons/message.svg";
import Thread from "../models/forms/messaging/thread";
import "./MessagingActionButton.less";

const MessagingActionButton = inject("ThreadsStore")(
  observer(function MessagingActionButton({
    className,
    row,
    ThreadsStore,
    customGetRelatedThreadForShipment,
    quote
  }) {
    const [hasMessage, setHasMessage] = useState(false);
    const [thread, setThread] = useState(new Thread());
    const [loading, toggleLoading] = useToggle(false);

    useEffect(() => {
      let relatedThread;

      if (typeof customGetRelatedThreadForShipment === "function") {
        relatedThread = customGetRelatedThreadForShipment();
      } else {
        relatedThread = ThreadsStore.getRelatedThreadForShipment(row);
      }

      if (relatedThread) {
        setThread(relatedThread);
        setHasMessage(relatedThread.hasUnreadMessagesForUser);
      }
    }, [ThreadsStore.threads.length]);

    const handleSendMessage = () => {
      if (thread && thread.id) {
        history.push(`/messages/${thread.id}`);
      } else {
        if (loading) {
          return;
        }

        toggleLoading();
        thread
          .createNewThreadForParticipant(row, quote)
          .then(ticket => {
            if (!ticket) {
              return;
            }

            ThreadsStore.pushNewThread(new Thread(ticket));
            history.push(`/messages/${ticket.id}`);
          })
          .finally(() => {
            toggleLoading();
          });
      }
    };

    return (
      <Tooltip title={hasMessage ? "New Message" : null}>
        <Badge className={className} dot={hasMessage}>
          {loading && <Icon type="loading" style={{ color: "#CDD1D9" }} spin />}
          {!loading && (
            <MessageIcon
              disabled={loading}
              className="message-icon"
              onClick={handleSendMessage}
            />
          )}
        </Badge>
      </Tooltip>
    );
  })
);

export default MessagingActionButton;
