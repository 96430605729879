import moment from "moment";
import { URL_REGEX } from "../config/constants";

export const validateUrl = value => {
  return URL_REGEX.test(value);
};

export const validateSelectQuote = quote => {
  const validityState = {
    valid: true,
    errors: []
  };
  const now = moment();
  const invalidMessage =
    "You can select a Quote only when the difference between current date and time and Quote Pick Up Date is > 8 working hours";

  const getInvalidValidityState = () => {
    validityState.valid = false;
    validityState.errors.push(invalidMessage);
    return validityState;
  };

  const pickupDate = moment(quote.pickup_date || quote.pickup_date_time)
    .startOf("day")
    .add(9, "h");

  const isCurrentDateIsLastWorkingDay = now.isoWeekday() === 5;
  const isPickupDateIsWorkingDay = pickupDate.isWorkingDay();
  const startOfCurrentWorkingDay = now
    .clone()
    .startOf("day")
    .add(9, "h");

  if (
    isCurrentDateIsLastWorkingDay &&
    !isPickupDateIsWorkingDay &&
    now.isSameOrAfter(startOfCurrentWorkingDay)
  ) {
    return getInvalidValidityState();
  }

  const isNotInRangeOfWorkingHoursBeforePickupDate = now.isSameOrAfter(
    pickupDate.clone().subtract(1, "day")
  );

  if (isNotInRangeOfWorkingHoursBeforePickupDate) {
    return getInvalidValidityState();
  }

  return validityState;
};
