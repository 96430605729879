/* eslint-disable consistent-return */
import { computed, observable } from "mobx";
import { Field as BaseField } from "../../libs/mobx-react-form";

export const inputInterceptorsMap = new Map([
  ["numbers", /[0-9]+/gm],
  ["extra_spaces", /\s{2,}/gm],
  ["specials", /[^A-Za-z0-9\s]/gm],
  ["letters_digits", /[^A-Za-z0-9]/gm],
  ["specials_hyphenated", /[^A-Za-z0-9\s-]/gm],
  ["letters", /[a-zA-Z]+/gm],
  ["spaces", /\s/gm],
  [
    "name",
    v => {
      if (v === "") {
        return true;
      }

      if (!v || typeof v !== "string") {
        return false;
      }

      const regexToCheckDelimiters = /\s{2,}|-{2,}|-\s|\s-/gi;
      const regexToTestLettersAndDelimitersOnly = /^[a-zA-Z\s-]+$/gi;

      if (regexToCheckDelimiters.test(v)) {
        return false;
      }

      return regexToTestLettersAndDelimitersOnly.test(v);
    }
  ],
  ["starts_not_zero", /^0+/gm]
]);

const buildRule = rule => {
  if (!inputInterceptorsMap.has(rule)) {
    return;
  }

  return {
    key: "value",
    call: ({ change }) => {
      const regex = inputInterceptorsMap.get(rule);

      if (typeof regex === "function") {
        return regex(change.newValue) ? change : undefined;
      }

      if (change.newValue === null) {
        return change;
      }

      const matchToRule = new RegExp(regex).test(change.newValue);

      if (matchToRule) {
        return;
      }

      return change;
    }
  };
};

const buildInterceptors = (rules = "", config) => {
  const additionalInterceptors = [];

  if (["text", "textarea"].includes(config.data.type)) {
    additionalInterceptors.push(buildRule("extra_spaces"));
  }

  if (config.data.type === "integer") {
    additionalInterceptors.push(buildRule("letters"));
  }

  const rulesArray = rules.split("|");

  if (rulesArray.length) {
    rulesArray.forEach(rule => {
      const interceptor = buildRule(rule);

      if (interceptor) {
        additionalInterceptors.push(interceptor);
      }
    });
  }

  if (additionalInterceptors.length) {
    config.props.$interceptors = additionalInterceptors;
  }
};

const extendProps = config => {
  if (config.props.$type === "date") {
    config.props.$options = {
      validateOnChange: true,
      validateOnBlur: false
    };
  }
};

class Field extends BaseField {
  @observable
  hidden = false;

  @observable
  loading;

  constructor(config) {
    if (config.data) {
      buildInterceptors(config.data.guard || config.props.$guards, config);
    }

    if (config.props) {
      extendProps(config);
    }

    super(config);

    if (config.data && Object.hasOwnProperty.call(config.data, "hidden")) {
      this.hidden = config.data.hidden;
    }
  }

  @computed
  get isRequired() {
    if (typeof this.rules !== "string") {
      return false;
    }

    return this.rules.includes("required");
  }

  hooks = () => {
    return {
      onChange(field) {
        // * for the text fields do trim
        if (field.type === "text" && typeof field.value === "string") {
          field.value = field.value.trimStart();
        }
      },
      onBlur(field) {
        // * for the text fields do trim
        if (field.type === "text" && typeof field.value === "string") {
          field.value = field.value
            .replace(/((?!\b\s{2,}\b)\s{2,})/g, "")
            .trimRight();
        }
      }
    };
  };
}

export default Field;
