import React from "react";
import {
  SUPPORT_ADDITIONAL_MAIL,
  SUPPORT_MAIL,
  SUPPORT_PHONE
} from "../../config/constants";

export default function StaticInfoSectionFallback() {
  return (
    <div className="content-wrapper">
      <div className="intro-wrapper">
        <div className="truck-icon" />
        <span className="intro-text">
          Virtual Haulage seeks to provide Shippers, Agents and Truck Owners
          with a hassle free, reliable and cost effective trucking experience.
          Do send us a message if you have any comments or inquiries.
        </span>
      </div>
      <div className="phone-wrapper">
        <div className="phone-icon" />
        <span className="phone-text">Phone Call</span>
        <a
          href={`tel:${SUPPORT_PHONE}`}
          className="phone-number link-common font-bolder fs-16"
        >
          {SUPPORT_PHONE}
        </a>
      </div>
      <div className="email-wrapper">
        <div className="email-icon" />
        <span className="email-text">Email</span>
        <a
          href={`mailto:${SUPPORT_MAIL}`}
          className="email-link link-common font-bolder fs-16"
        >
          {SUPPORT_MAIL}
        </a>
      </div>
      <div className="support-wrapper">
        <div className="contact-us-icon" />
        <span className="support-text">Support</span>
        <a
          href={`mailto:${SUPPORT_ADDITIONAL_MAIL}`}
          className="support-email link-common font-bolder fs-16"
        >
          {SUPPORT_ADDITIONAL_MAIL}
        </a>
      </div>
    </div>
  );
}
