import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

import { inject, observer } from "mobx-react";
import ContactsSection from "./ContactsSection";

import "./Footer.less";

export default inject("AppState")(
  observer(({ AppState }) => {
    if (AppState.embeddableApp) {
      return (
        <Row gutter={{ lg: 10, md: 24 }} type="flex">
          <Col
            lg={{ span: 5, offset: 1 }}
            md={12}
            xs={12}
            className="footer-col"
          >
            <div className="footer-col-header">
              © Virtual Haulage, {new Date().getFullYear()}
            </div>
            <div className="footer-col-content">All rights Reserved</div>
          </Col>
          <ContactsSection />
        </Row>
      );
    }
    return (
      <Row gutter={{ lg: 10, md: 24 }} type="flex">
        <Col lg={{ span: 4, offset: 1 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">
            © Virtual Haulage, {new Date().getFullYear()}
          </div>
          <div className="footer-col-content">All rights Reserved</div>
        </Col>
        <Col lg={{ span: 3, offset: 9 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">Company</div>
          <div className="footer-col-content">
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={{ span: 3 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">Support</div>
          <div className="footer-col-content">
            <ul>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
              <li>
                <Link to="/terms-and-services">Terms & Services</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </Col>
        <ContactsSection />
      </Row>
    );
  })
);
