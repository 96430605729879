/* eslint-disable import/no-cycle */
import axios from "axios";

import moment from "moment";
import { reaction } from "mobx";
import AuthStore from "../../models/auth";
import { loadDataFromStorage, persistData } from "../../utils/storage-utils";
import { formatNameFromProfile } from "../../utils/format";
import { ZEN_DESK_SHIPMENT_ID_FIELD } from "../../config/constants";
import Thread from "../../models/forms/messaging/thread";
// import { mockPostTicket } from "../../data/create-ticket-response";
// import getComments from "../../data/comments";

const PERSIST_NAME = "zd_u";

class ZenDeskService {
  client;

  initialized = false;

  user = {};

  constructor(authStore) {
    this.authStore = authStore;

    this.client = axios.create({
      baseURL: process.env.REACT_APP_ZEN_DESK_API_URL,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ZEN_DESK_API_TOKEN}`,
        "Content-Type": "application/json"
      }
    });

    reaction(
      () => this.authStore.user.email,
      () => {
        if (this.authStore.user.email) {
          this.load();
        }
      }
    );

    reaction(
      () => this.authStore.isLoggedIn,
      () => {
        if (this.authStore.isLoggedIn === false) {
          this.initialized = false;
          this.user = {};
        }
      }
    );
  }

  async loadTicket(id) {
    const {
      data: { ticket }
    } = await this.client.get(`/tickets/${id}.json`);

    return ticket;
  }

  /**
   * used to load current signed in user from related zen desk account
   * @returns {null}
   */
  load() {
    if (this.initializerPromise) {
      return this.initializerPromise;
    }

    this.initializerPromise = new Promise(async resolve => {
      await this.getUser(this.authStore.user);

      if (!this.user.id) {
        await this.getUser(this.authStore.user, true);
        this.setUserPortalId();
      }

      if (!this.user.id) {
        const user = await this.createUser(this.authStore.user);

        this.user = user;
        persistData(PERSIST_NAME, user);
      }

      this.initialized = true;

      resolve();

      this.initializerPromise = null;
    });

    return this.initializerPromise;
  }

  /**
   * set user's id to the zen desk profile attributes
   * @returns {Promise<void|*>}
   */
  async setUserPortalId() {
    if (this.user.id) {
      return this.updateUser({
        user_fields: {
          portal_user_id: this.authStore.user.id
        }
      });
    }

    return Promise.resolve();
  }

  async updateUser(user) {
    return this.client.put(`/users/${this.user.id}.json`, {
      user: {
        ...user
      }
    });
  }

  /**
   * load user by attribute
   * - tries to load user by portal user if
   * - tries to load by email for created by email users which doesn't have portal id attribute
   * - if user created with temporary email then updates it identity to actual email
   * @param id
   * @param email
   * @param byEmail
   * @returns {Promise<{}|*>}
   */
  async getUser({ id, email }, byEmail = false) {
    const savedUser = loadDataFromStorage(PERSIST_NAME);

    if (savedUser) {
      this.user = savedUser;

      return Promise.resolve(this.user);
    }

    let url = `/users/search.json?query=portal_user_id:${id}`;

    if (byEmail) {
      url = `/users/search.json?query=${email}`;
    }

    return this.client.get(url).then(async ({ data }) => {
      if (data.count === 1) {
        const user = data.users[0];

        this.user = user;

        if (user.email !== AuthStore.user.email) {
          this.updateUsersPrimaryIdentity(AuthStore.user.email);
          this.updateUser({
            name: formatNameFromProfile(AuthStore.user.profile, false)
          });
        }

        persistData(PERSIST_NAME, data.users[0]);
      }

      return this.user;
    });
  }

  /**
   * format object with data of the user
   * @param userData
   * @returns {{role: string, user_fields: {portal_user_id: *}, name: (*|string), verified: boolean, email: (*|string)}}
   */
  getUsersDataForRegister(userData) {
    const { id, email, profile, full_name, ...rest } = userData;

    return {
      email: email || `portaluser${id}@virtualhaulage.com`,
      name:
        formatNameFromProfile(profile, false) ||
        full_name ||
        `Portal User ${id}`,
      verified: true,
      ...rest,
      role: "end-user",
      user_fields: {
        portal_user_id: id
      }
    };
  }

  async createUser(userData) {
    const payload = this.getUsersDataForRegister(userData);

    try {
      const {
        data: { user }
      } = await this.client.post("/users.json", { user: payload });

      return user;
    } catch (e) {
      console.dir(e);

      return false;
    }
  }

  async getUserOrCreate(userData) {
    const { id, email } = userData;
    let url = `/users/search.json?query=portal_user_id:${id}`;

    if (!id) {
      url = `/users/search.json?query=${email}`;
    }

    let user = await this.client.get(url).then(({ data }) => {
      if (data.count === 1) {
        return data.users[0];
      }

      return false;
    });

    if (!user) {
      user = await this.createUser(userData);
    }

    return user;
  }

  async loadThreads() {
    // return new Promise(resolve => {
    //   import("../../data/threads").then(module => {
    //     const tickets = module.default.tickets.map(t => new Thread(t));
    //     resolve({ tickets });
    //   });
    // });

    return Promise.all([
      this.client.get(
        `/users/${this.user.id}/tickets/ccd.json?sort_by=updated_at&sort_order=desc&include=users`
      ),
      this.client.get(
        `/users/${this.user.id}/tickets/requested.json?sort_by=updated_at&sort_order=desc&include=users`
      )
    ])
      .then(tickets => {
        const [
          {
            data: { tickets: ccd, users: ccd_users }
          },
          {
            data: { tickets: requested, users: requested_users }
          }
        ] = tickets;

        const users = [...ccd_users, ...requested_users];

        const findUser = user_id => users.find(({ id }) => id === user_id);

        return {
          tickets: [...ccd, ...requested]
            .map(item => {
              item.updated_at = moment(item.updated_at);
              item.requester_user = findUser(item.requester_id);
              item.collaborator_portal_ids = item.collaborator_ids.map(id => {
                const collaborator = findUser(id);

                return collaborator && collaborator.user_fields.portal_user_id;
              });

              return item;
            })
            .sort((a, b) => {
              if (a.updated_at.isSameOrAfter(b.updated_at)) {
                return -1;
              }

              return 1;
            })
            .map(ticket => new Thread(ticket))
        };
      })
      .catch(e => {
        console.warn(e);
      });
  }

  createShipmentThread(data) {
    data.custom_fields = [
      { id: ZEN_DESK_SHIPMENT_ID_FIELD, value: data.shipment_id },
      ...(data.custom_fields || [])
    ];
    const payload = { ticket: data };

    return this.createThread(payload);
  }

  createThread(payload) {
    // return mockPostTicket();
    return this.client.post("/tickets.json", payload);
  }

  postComment(ticketId, body, custom_fields = []) {
    // return Promise.resolve();
    return this.client.put(`/tickets/${ticketId}.json`, {
      ticket: {
        comment: {
          body,
          author_id: this.user.id
        },
        custom_fields
      }
    });
  }

  updateTicketField(ticketId, id, value) {
    return this.client.put(`/tickets/${ticketId}.json`, {
      ticket: {
        custom_fields: [{ id, value }]
      }
    });
  }

  async loadComments(ticketId) {
    // const {
    //   data: { comments, users }
    // } = await getComments();

    try {
      const {
        data: { comments, users }
      } = await this.client.get(
        `/tickets/${ticketId}/comments.json?include=users`
      );

      const findUser = userId => users.find(user => user.id === userId) || {};

      comments.forEach(comment => {
        comment.sender = findUser(comment.author_id);
      });

      return { comments };
    } catch (e) {
      return { comments: [] };
    }
  }

  /**
   * updates user's primary identity - set actual email instead of temporary email
   * @param emailToUpdate
   * @returns {Promise<void>}
   */
  async updateUsersPrimaryIdentity(emailToUpdate) {
    try {
      const {
        data: { identities }
      } = await this.client.get(`/users/${this.user.id}/identities.json`);

      const primaryIdentity = identities.find(
        ({ type, primary }) => type === "email" && primary
      );

      if (!primaryIdentity) {
        throw new Error("Identity not found.");
      }

      await this.client.put(
        `/users/${this.user.id}/identities/${primaryIdentity.id}.json`,
        {
          identity: { value: emailToUpdate }
        }
      );
    } catch (e) {
      console.warn(e);
    }
  }
}

export default new ZenDeskService(AuthStore);
