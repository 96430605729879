import React, { useContext } from "react";
import * as PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import FilterableTableContext from "../contexts/filterable-table";

const TaggedNavBar = observer(({ prefix = null, className = "", children }) => {
  const { gridStore } = useContext(FilterableTableContext);
  const { tags } = gridStore;

  if (!tags && children) {
    return children;
  }

  if (!tags) {
    return null;
  }

  const setTagBackground = tag =>
    tag === gridStore.tag ? "tag-nav-bar tag-nav-bar-selected" : "tag-nav-bar";
  const setStatusColor = tag =>
    tag === gridStore.tag ? "tag-color-selected" : "tag-color";

  const handleStatusClick = value => e => {
    e.preventDefault();
    if (gridStore.isLoading) {
      return;
    }

    gridStore.setTag(value);
  };

  return (
    <Row>
      <Col>
        <ul className={cx("hr", className)}>
          {prefix && <li>{prefix}:</li>}
          {tags.map(({ name, code }, index) => {
            return (
              <li key={index} className={setTagBackground(code)}>
                <Link
                  to="/#/"
                  onClick={handleStatusClick(code)}
                  className={setStatusColor(code)}
                >
                  {name}
                </Link>
              </li>
            );
          })}
          {children && <li className="extra-content">{children}</li>}
        </ul>
      </Col>
    </Row>
  );
});

TaggedNavBar.displayName = "TaggedNavBar";

TaggedNavBar.propTypes = {
  prefix: PropTypes.string
};

export default TaggedNavBar;
