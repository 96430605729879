/* eslint-disable import/no-cycle */
import { observable } from "mobx";
import { throttle } from "throttle-debounce";
import { REFRESH_TIMEOUT } from "./threads";

class ActivityTracker {
  @observable isActive = false;

  timeoutId = null;

  constructor() {
    this.resetTimer = throttle(
      REFRESH_TIMEOUT + 1,
      true,
      this.resetTimer.bind(this)
    );
    this.startTimer = this.startTimer.bind(this);
    this.goInactive = this.goInactive.bind(this);
    this.goActive = this.goActive.bind(this);
  }

  resetTimer() {
    window.clearTimeout(this.timeoutId);

    this.goActive();
  }

  startTimer() {
    this.timeoutId = window.setTimeout(this.goInactive, REFRESH_TIMEOUT + 1);
  }

  goInactive() {
    this.isActive = false;
  }

  goActive() {
    this.isActive = true;

    this.startTimer();
  }

  setup() {
    window.addEventListener("mousemove", this.resetTimer, false);
    window.addEventListener("mousedown", this.resetTimer, false);
    window.addEventListener("keypress", this.resetTimer, false);
    window.addEventListener("DOMMouseScroll", this.resetTimer, false);
    window.addEventListener("mousewheel", this.resetTimer, false);
    window.addEventListener("touchmove", this.resetTimer, false);
    window.addEventListener("MSPointerMove", this.resetTimer, false);

    this.startTimer();
  }

  destroy() {
    window.removeEventListener("mousemove", this.resetTimer, false);
    window.removeEventListener("mousedown", this.resetTimer, false);
    window.removeEventListener("keypress", this.resetTimer, false);
    window.removeEventListener("DOMMouseScroll", this.resetTimer, false);
    window.removeEventListener("mousewheel", this.resetTimer, false);
    window.removeEventListener("touchmove", this.resetTimer, false);
    window.removeEventListener("MSPointerMove", this.resetTimer, false);
  }
}

export default ActivityTracker;
