/* eslint-disable import/no-cycle */
import moment from "moment";
import { action, computed, observable, reaction } from "mobx";
import { throttle } from "throttle-debounce";
import Visibility from "visibilityjs";
import ZenDeskService from "../../../services/api/zen-desk";
import CommentFactory from "./comment-factory";
import { ZEN_DESK_SHIPMENT_ID_FIELD } from "../../../config/constants";
import Thread from "./thread";
import AuthState from "../../auth";

import ActivityTracker from "./activity-tracker";

const INITIAL_THREAD = { comments: [] };
const MINUTE = 60 * 1000;

export const REFRESH_TIMEOUT = 2 * MINUTE; // refresh messages each 2 minutes

class ThreadsStore {
  /**
   * @type {null|ActivityTracker}
   */
  activityTracker = null;

  @observable threads = [];

  @observable selectedThread = INITIAL_THREAD;

  @observable loadingComments = false;

  @observable loadingThreads = false;

  @observable initialLoadDone = false;

  @observable autoRefreshEnabled = false;

  constructor() {
    this.activityTracker = new ActivityTracker();

    reaction(
      () => AuthState.isLoggedIn,
      () => {
        if (AuthState.isLoggedIn === false) {
          this.reset();
          if (this.activityTracker) {
            this.activityTracker.destroy();
          }

          this.unregisterAutoRefresh();
        } else {
          this.autoRefreshEnabled = true;
          this.activityTracker.setup();
          this.loadAllThreads(true);
          this.registerAutoRefresh();
        }
      }
    );

    this.loadInitialThreads = throttle(
      REFRESH_TIMEOUT, // two minutes delay between requests
      true,
      this.loadInitialThreads.bind(this)
    );

    this.throttledLoadAllThreadForRefresh = throttle(
      REFRESH_TIMEOUT,
      true,
      this.loadAllThreads.bind(this)
    );
  }

  registerAutoRefresh() {
    if (this.autoRefreshEnabled) {
      this.refreshDisposer = Visibility.every(3000, () => {
        this.loadInitialThreads();
      });
    }
  }

  unregisterAutoRefresh() {
    if (Number.isInteger(this.refreshDisposer)) {
      Visibility.stop(this.refreshDisposer);
    }
  }

  @computed
  get sortedList() {
    return this.threads.sort((a, b) => {
      if (a.updated_at.isSameOrAfter(b.updated_at)) {
        return -1;
      }

      return 1;
    });
  }

  @computed
  get totalUnreadMessagedCount() {
    return this.threads.reduce((curry, thread) => {
      if (thread.hasUnreadMessagesForUser) {
        curry += thread.countOfUnreadMessages;
      }

      return curry;
    }, 0);
  }

  @action
  async loadUser() {
    if (!ZenDeskService.initialized) {
      await ZenDeskService.load();
    }
  }

  @action
  async loadInitialThreads() {
    if (this.activityTracker && !this.activityTracker.isActive) {
      return;
    }

    await this.loadAllThreads(false);
  }

  @action
  async loadAllThreads(skipLoadIfThreadLoaded) {
    // console.log("%cLoad all threads", "color: red");
    if (this.loadingThreads === true) {
      // console.log("%cSkip loading all thread", "color: orange");

      return;
    }

    this.loadingThreads = true;

    await this.loadUser();

    try {
      if (this.threads.length === 0 || !skipLoadIfThreadLoaded) {
        this.activePromise = ZenDeskService.loadThreads();
        const threads = await this.activePromise;

        const newThread = this.threads.find(({ id }) => id === "new");

        this.threads = newThread
          ? [newThread, ...threads.tickets]
          : threads.tickets;

        this.activePromise = null;
      }
    } catch (e) {
      console.warn(e);
    } finally {
      this.loadingThreads = false;
    }
  }

  @action
  async load(id) {
    const skipLoadIfThreadLoaded = !!id;

    if (!id) {
      this.setSelectedThread(INITIAL_THREAD);
    }

    if (this.activePromise) {
      await this.activePromise;
    } else {
      await this.loadAllThreads(skipLoadIfThreadLoaded);
    }

    if (id) {
      const threadWithIdFromUrl = this.threads.find(
        thread => thread.id === +id || (id === "new" && thread.id === id)
      );

      if (threadWithIdFromUrl) {
        this.setSelectedThread(threadWithIdFromUrl);
      }

      return;
    }

    const existedThread = this.threads.find(thread => {
      const shipmentField = thread.custom_fields.find(
        field => field.id === ZEN_DESK_SHIPMENT_ID_FIELD
      );

      if (!shipmentField) {
        return false;
      }

      return shipmentField.value === `${id}`;
    });

    if (existedThread && id !== "new") {
      const { comments } = await ZenDeskService.loadComments(existedThread.id);

      existedThread.comments = observable(comments);

      this.setSelectedThread(existedThread);
    }
  }

  @action
  async setSelectedThread(thread) {
    this.loadingComments = true;
    if (thread.id && thread.id !== "new") {
      try {
        const { comments } = await ZenDeskService.loadComments(thread.id);
        const normalizedComments = comments.map(comment =>
          CommentFactory.buildComment(comment)
        );

        thread.comments = observable(normalizedComments || []);

        if (thread.hasUnreadMessagesForUser) {
          thread.markAsRead();
        }
      } catch (e) {
        // todo
      } finally {
        this.loadingComments = false;
      }
    }

    this.loadingComments = false;

    this.selectedThread = thread;
  }

  @action
  unsetSelectedThread() {
    const indexOfNewThread = this.threads.findIndex(({ id }) => id === "new");

    if (indexOfNewThread >= 0) {
      this.threads.splice(indexOfNewThread, 1);
    }

    this.selectedThread = INITIAL_THREAD;
  }

  @action
  pushNewComment(comment) {
    this.selectedThread.latest_comment_from_form = comment.body;
    this.selectedThread.updated_at = moment();
    this.selectedThread.comments.push(
      CommentFactory.buildComment(comment, true)
    );
  }

  @action
  pushNewThread(thread) {
    if (!(thread instanceof Thread)) {
      throw new Error("Must be an instance of Thread");
    }

    this.threads.push(thread);
  }

  @action
  reset() {
    this.threads = [];
    this.selectedThread = INITIAL_THREAD;
    this.initialLoadDone = false;
  }

  @action
  swapNewThreadToCreated(ticket) {
    const indexOfNewThread = this.threads.findIndex(({ id }) => id === "new");

    if (indexOfNewThread < 0) {
      return;
    }

    if (!(ticket instanceof Thread)) {
      ticket = new Thread(ticket);
    }

    this.threads.splice(indexOfNewThread, 1, ticket);
  }

  getRelatedThreadForShipment = shipment => {
    return this.threads.find(item => {
      return item.isRelatedToShipment(shipment);
    });
  };
}

export default new ThreadsStore();
