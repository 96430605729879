import { computed } from "mobx";
import BaseForm from "../base-form";
import ThreadsStore from "./threads";
import ZenDeskService from "../../../services/api/zen-desk";
import {
  SEND_QUOTE_COMMENT_LENGTH,
  ZEN_DESK_FIELDS
} from "../../../config/constants";
import { history } from "../../../contexts/history";

class CommentForm extends BaseForm {
  threadsStore = ThreadsStore;

  @computed
  get commentRemainingChars() {
    const currentText = this.$("comment").value || "";

    if (currentText === "") {
      return null;
    }

    if (currentText.length > SEND_QUOTE_COMMENT_LENGTH) {
      return 0;
    }

    return SEND_QUOTE_COMMENT_LENGTH - currentText.length;
  }

  setup() {
    return {
      fields: [
        {
          name: "comment",
          placeholder: "Type a Message",
          rules: "communication_guard|max:500"
        }
      ]
    };
  }

  hooks() {
    return {
      onSuccess: async () => {
        return new Promise(async resolve => {
          const { comment } = this.values();
          const thread = this.threadsStore.selectedThread;

          if (!comment) {
            return Promise.resolve();
          }

          const receiversIds = await thread.getReceiversIds();

          const newUnreadValue = JSON.stringify({
            participant_id: receiversIds
          });

          const unreadMessageEventFlag = [
            {
              id: ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE,
              value: newUnreadValue
            }
          ];

          if (thread.id === "new") {
            return thread
              .createThreadForParticipant(
                thread.shipment,
                thread.quote,
                comment
              )
              .then(ticket => {
                this.threadsStore.swapNewThreadToCreated(ticket);
                history.replace(`/messages/${ticket.id}`);
                resolve();
              });
          }

          return ZenDeskService.postComment(
            thread.id,
            comment,
            unreadMessageEventFlag
          ).then(() => {
            thread.updateCustomField(
              ZEN_DESK_FIELDS.SYSTEM_UNREAD_MESSAGE,
              newUnreadValue
            );
            this.threadsStore.pushNewComment({ body: comment });
            resolve();
          });
        }).then(() => {
          this.clear();
        });
      }
    };
  }

  options() {
    return {
      validateOnReset: false,
      showErrorsOnReset: false,
      validateOnClear: false,
      showErrorsOnClear: false
    };
  }
}

export default new CommentForm();
