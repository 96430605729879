export const booleansToNumberEquals = json => {
  Object.keys(json).forEach(key => {
    if (typeof json[key] === "boolean") {
      json[key] = +json[key];
    }
  });

  return json;
};

export default {
  booleansToNumberEquals
};
