/* eslint-disable-next-line no-unused-vars */
import React from "react";
import { action, observable } from "mobx";
import { message } from "antd";
import { get } from "lodash";
import ShipmentsService from "../../../services/api/shipments";
import {
  DELIVERY_PLUGIN_KEY,
  SHIPMENT_ACTIVE_STATUS_CODES
} from "../../../config/constants";

const TRACKING_MAP_URL = `//tracking.sysserve.com/pro/applications/delivery/?key=${DELIVERY_PLUGIN_KEY}&panel_scale=big&map=roadmap`;

class TrackingMap {
  @observable shipmentId = null;

  @observable externalId = null;

  @observable shouldBeOpened = false;

  @observable trackingMapUrl = "";

  @action
  close() {
    this.shouldBeOpened = false;
    this.shipmentId = null;
    this.externalId = null;
    this.trackingMapUrl = "";
  }

  showWarningMessage() {
    message.warning(
      "You cannot monitor truck location anymore. The tracking is allowed only when both shipment statuses remain 'Picked Up'."
    );
  }

  checkIfMapShouldBeOpened(shipment) {
    if (shipment.status !== SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP) {
      this.showWarningMessage();

      return;
    }

    const quoteWithPickedUpStatus = shipment.quotes.find(
      quote => quote.status === SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP
    );

    if (quoteWithPickedUpStatus && quoteWithPickedUpStatus.trucks) {
      const trackWithTrackerDevice = quoteWithPickedUpStatus.trucks.find(
        track => track.tracker_device
      );

      this.externalId = get(
        trackWithTrackerDevice,
        "tracker_device.tasks[0].external_id",
        null
      );

      if (!this.externalId) {
        message.warning(
          "We're sorry, unfortunately we're unable to get Order ID. You cannot track the Shipment."
        );

        return;
      }
    }

    if (this.externalId) {
      this.shouldBeOpened = true;
      this.trackingMapUrl = TRACKING_MAP_URL;
    } else {
      this.showWarningMessage();
    }
  }

  @action
  showTrackerModal(shipmentId) {
    return ShipmentsService.load(shipmentId)
      .then(shipment => {
        this.shipmentId = shipment.id;
        this.checkIfMapShouldBeOpened(shipment);
      })
      .catch(e => {
        if (e.response && e.response.status === 403) {
          message.warning("Something went wrong, please try again later.");
        }
      });
  }
}

export default new TrackingMap();
