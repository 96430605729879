import axios from "axios";

class SelectQuoteService {
  load = ({ shipmentId, sortField, sortType }) => {
    if (!shipmentId) {
      return Promise.resolve({});
    }

    return axios.get(`shipments/${shipmentId}/quotes/${sortField}/${sortType}`);
  };

  save = ({ shipmentId, quotes, insurance }) => {
    return axios.post(`/shipments/${shipmentId}/quotes/select`, {
      quotes,
      insurance
    });
  };

  downloadContract = ({ quoteId, shipmentId }) => {
    return axios
      .get(
        `/shipments/${shipmentId}/quotes/${quoteId}/contract/withInsurance`,
        {
          responseType: "blob"
        }
      )
      .then(response => response);
  };

  repostSharedConsignment = ({ shipmentId, quotes, insurance, repost }) => {
    const url = `/shipments/${shipmentId}/quotes/select`;
    return axios.post(url, { quotes, insurance, repost });
  };
}

export default new SelectQuoteService();
