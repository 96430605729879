// import OptionsService from "../services/api/options";

import React from "react";
import { ReactComponent as CalendarIcon } from "../icons/calendar-purple.svg";
import { ReactComponent as ClockIcon } from "../icons/clock.svg";
// eslint-disable-next-line import/no-cycle
import { toSnakeCase } from "../utils/format";

export const SUPPORT_PHONE = process.env.REACT_APP_CONTACTS_PHONE;
export const SUPPORT_MAIL = process.env.REACT_APP_CONTACTS_MAIL;
export const SUPPORT_ADDITIONAL_MAIL =
  process.env.REACT_APP_CONTACTS_ADDITIONAL_MAIL;
export const INFO_MAIL = process.env.REACT_APP_CONTACTS_INFO_MAIL;
export const INFO_PHONE = process.env.REACT_APP_CONTACTS_INFO_PHONE;
export const SUPPORT_ADDRESS_FIRST_LINE =
  process.env.REACT_APP_CONTACTS_ADDRESS_FIRST_LINE;
export const SUPPORT_ADDRESS_SECOND_LINE =
  process.env.REACT_APP_CONTACTS_ADDRESS_SECOND_LINE;
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const APP_NAME = process.env.REACT_APP_NAME;

export const MANUFACTURER_YEAR_FIRST_OPTION = 1994;
export const MANUFACTURER_YEAR_LAST_OPTION = new Date().getFullYear();

export const MAX_ALLOWED_NUMBER_OF_TRUCKS_TO_ADD = 10;

export const SIZE_DEFAULT = 10;
export const SIZE_OPTIONS = ["10", "25", "50", "100"];
export const PAGE_DEFAULT = 1;
export const STATUS_DEFAULT = "all";
export const SORT_FIELD_DEFAULT = "created_at";
export const SORT_TYPE_DEFAULT = "desc"; // asc
export const SORT_DIRECTIONS = ["descend", "ascend"];
export const OTHER = other => {
  if (other === "other") {
    return true;
  }

  return false;
};

// const statusesFromDb = OptionsService.getTruckStatuses();

export const TRUCK_STATUSES_CODES = {
  ALL: "all",
  APPROVED: "approved",
  PENDING_APPROVAL: "pending_approval",
  DECLINED: "declined",
  REMOVED: "removed",
  REINSTATED: "reinstated",
  REAPPLIED: "reapplied",
  PENDING_RECERTIFICATION: "pending_recertification"
};

export const TRUCK_STATUSES = {
  [TRUCK_STATUSES_CODES.ALL]: { name: "All", color: "" },
  [TRUCK_STATUSES_CODES.APPROVED]: {
    name: "Approved",
    color: "#69E283",
    code: "approved"
  },
  [TRUCK_STATUSES_CODES.PENDING_APPROVAL]: {
    name: "Pending Approval",
    color: "#70BAFF"
  },
  [TRUCK_STATUSES_CODES.DECLINED]: { name: "Declined", color: "#FA888F" },
  [TRUCK_STATUSES_CODES.REMOVED]: { name: "Removed", color: "#CDD1D9" },
  // [TRUCK_STATUSES_CODES.REINSTATED]: { name: "Reinstated", color: "#696B6D" },
  [TRUCK_STATUSES_CODES.REAPPLIED]: { name: "Reapplied", color: "#FFD600" },
  [TRUCK_STATUSES_CODES.PENDING_RECERTIFICATION]: {
    name: "Pending Recertification",
    color: "#EA88FA",
    code: "pending_recertification"
  }
};

export const AVAILABLE_TRUCK_STATUSES_CODES = {
  ALL: "all",
  ONLY_MY_TRUCKS: "only_my_trucks"
};

export const AVAILABLE_TRUCK_STATUSES = {
  [TRUCK_STATUSES_CODES.ALL]: { name: "All", color: "" },
  [TRUCK_STATUSES_CODES.ONLY_MY_TRUCKS]: {
    name: "Only My Trucks",
    color: "",
    code: "only_my_trucks"
  }
};

export const PENDING_TOOLTIPS = {
  TRUCK_UNAVAILABLE_UNTIL_APPROVAL:
    "Truck is unavailable until 48 hours after approval elapse.",
  TRUCK_UNAVAILABLE_UNTIL_OTHER_TRUCK_TYPE_APPROVED:
    'Truck is unavailable until "Other" Truck Type, Weight or Volume will be replaced by the Super User to existing in the system values.',
  DRIVER_LICENSE_EXPIRED: "Drive License Date expired.",
  TRUCK_UNAVAILABLE_UNTIL_DOCUMENTS_RENEWED:
    "Truck is unavailable until Documents will be renewed.",
  TRUCK_UNAVAILABLE_UNTIL_WILL_BE_RECERTIFIED:
    "Truck is unavailable until it will be recertified.",
  BAN_POST_SHIPMENT_MESSAGE:
    "You're required to share bank account details on your profile prior to posting a Shipment so that we can refund you money in case of shipment cancellation."
};

export const PENDING_TIME = -48 * 60 * 60 * 1000; // milliseconds = 48 hours

export const DATE_PICKER_PROPS = {
  format: "DD/MM/YYYY",
  suffixIcon: (
    <CalendarIcon style={{ width: 19, height: 19, color: "#837AE9" }} />
  ),
  size: "large",
  placeholder: "dd/mm/yyyy",
  style: {
    width: "100%",
    minWidth: "100%"
  }
};

export const TIME_PICKER_PROPS = {
  format: "hh:mm A",
  use12Hours: true,
  suffixIcon: <ClockIcon style={{ width: 18, height: 18, color: "#837AE9" }} />,
  size: "large",
  placeholder: "xx:xx AM/PM",
  minuteStep: 15,
  style: {
    width: "100%"
  }
};

export const LOG_TRUCK_MAX_LOCATIONS = 7;
export const LOG_TRUCK_MAX_TRUCKS = 10;

export const LINK_DRIVER_MAX_TRUCKS = 1;

export const DRIVER_STATUSES_CODES = {
  ALL: "all",
  APPROVED: "approved",
  PENDING_APPROVAL: "pending_approval",
  DECLINED: "declined",
  REMOVED: "removed",
  REAPPLIED: "reapplied",
  PENDING_RECERTIFICATION: "pending_recertification"
};

export const DRIVER_STATUSES = {
  [DRIVER_STATUSES_CODES.ALL]: { name: "All", color: "" },
  [DRIVER_STATUSES_CODES.APPROVED]: {
    name: "Approved",
    color: "#69E283",
    code: "approved"
  },
  [DRIVER_STATUSES_CODES.PENDING_APPROVAL]: {
    name: "Pending Approval",
    color: "#70BAFF"
  },
  [DRIVER_STATUSES_CODES.DECLINED]: { name: "Declined", color: "#FA888F" },
  [DRIVER_STATUSES_CODES.REMOVED]: { name: "Removed", color: "#CDD1D9" },
  [DRIVER_STATUSES_CODES.REAPPLIED]: { name: "Reapplied", color: "#FFD600" },
  [DRIVER_STATUSES_CODES.PENDING_RECERTIFICATION]: {
    name: "Pending Recertification",
    color: "#EA88FA"
  }
};

export const SHIPMENT_PENDING_STATUS_CODES = {
  PENDING: "pending",
  QUOTE_SELECTED: "quote_selected",
  CONTRACT_SIGNED: "contract_signed"
};

export const SHIPMENT_ACTIVE_STATUS_CODES = {
  ACTIVE: "active",
  TRUCK_AT_ORIGIN: "truck_at_origin",
  PICKED_UP: "picked_up",
  DELIVERED: "delivered"
};

export const SHIPMENT_PAST_CODES = {
  CLOSED: "closed",
  UNSUCCESSFUL: "unsuccessful",
  CANCELED: "canceled",
  NON_PAYMENT: "non_payment",
  DECLINED: "declined"
};

export const PAYMENT_STATUS_CODES = {
  ALL: "all",
  PENDING: "pending",
  PAID: "paid",
  CANCELED: "canceled"
};

export const QUOTE_STATUS_CODES = {
  PENDING: "pending",
  QUOTE_SELECTED: "quote_selected",
  CONTRACT_SIGNED: "contract_signed",
  CANCELED: "canceled",
  ACCEPTED: "accepted",
  UNSUCCESSFUL: "unsuccessful",
  ACTIVE: "active"
};

export const CONTRACT_STATUS_CODES = {
  PENDING: "pending",
  ACCEPTED: "accepted"
};

export const SHIPMENT_PENDING_STATUSES = {
  [SHIPMENT_PENDING_STATUS_CODES.PENDING]: {
    name: "Pending",
    color: "#70BAFF"
  },
  [SHIPMENT_PENDING_STATUS_CODES.QUOTE_SELECTED]: {
    name: "Quote Selected",
    color: "#70BAFF"
  },
  [SHIPMENT_PENDING_STATUS_CODES.CONTRACT_SIGNED]: {
    name: "Contract Signed",
    color: "#70BAFF"
  }
};

export const SHIPMENT_ACTIVE_STATUSES = {
  [SHIPMENT_ACTIVE_STATUS_CODES.ACTIVE]: {
    name: "Active",
    color: "#69E283"
  },
  [SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN]: {
    name: "Truck at Origin",
    color: "#FFD600"
  },
  [SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP]: {
    name: "Picked Up",
    color: "#A688FA"
  },
  [SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED]: {
    name: "Delivered",
    color: "#EA88FA"
  }
};

export const SHIPMENT_AVAILABLE_TO_VIEW_CONTRACT = [
  SHIPMENT_ACTIVE_STATUS_CODES.ACTIVE,
  SHIPMENT_ACTIVE_STATUS_CODES.TRUCK_AT_ORIGIN,
  SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP,
  SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED
];

export const SHIPMENT_PAST_STATUSES = {
  [SHIPMENT_PAST_CODES.CANCELED]: {
    name: "Canceled",
    color: "#696B6D"
  },
  [SHIPMENT_PAST_CODES.CLOSED]: {
    name: "Closed",
    color: "#CDD1D9"
  },
  [SHIPMENT_PAST_CODES.UNSUCCESSFUL]: {
    name: "Unsuccessful",
    border: "#696B6D"
  },
  [SHIPMENT_PAST_CODES.NON_PAYMENT]: {
    name: "Non-Payment",
    border: "#CDD1D9"
  },
  [SHIPMENT_PAST_CODES.DECLINED]: {
    name: "Declined",
    border: "#FA888F"
  }
};

export const PAYMENT_STATUSES = {
  [PAYMENT_STATUS_CODES.ALL]: { name: "All", color: "" },
  [PAYMENT_STATUS_CODES.PENDING]: { name: "Pending", color: "#70BAFF" },
  [PAYMENT_STATUS_CODES.PAID]: { name: "Paid", color: "#69E283" },
  [PAYMENT_STATUS_CODES.CANCELED]: { name: "Canceled", color: "#696B6D" }
};

export const PAYMENT_METHODS = {
  GTPAY: "gtpay",
  PAYSTACK: "paystack"
};

export const MAX_ALLOWED_NUMBER_OF_DRIVERS_TO_ADD = 10;

export const SUPPORTED_FILE_TYPES = [
  "image/png",
  "image/jpg",
  "image/gif",
  "application/pdf",
  "image/jpeg",
  "image/bmp"
];

export const MAX_FILE_SIZE = {
  SIGNUP: 25,
  DRIVER: 3
};

export const REGEX = {
  DRIVER_LICENSE_NUMBER: /^[a-zA-Z]{3}[0-9]{5}[a-zA-Z]{2}[0-9]{2}$/g,
  NAMES: /^([a-zA-Z]+(([-\s])?([a-zA-Z]+)+)*)+$/g,
  PHONE_NUMBER: /^[0-9]+$/g,
  CARGO_VALUE: /^[1-9][0-9]{0,8}$/g
};

export const DEFAULT_DIAL_CODE = "+234";
export const WARNING_MANDATORY = "Please fill in all mandatory fields.";
export const FULL_TRUCK_LOADS = () => (
  <div className="text-normal">
    Virtual Haulage assumes <span className="font-bold">Full Truck Loads</span>{" "}
    for all trips
  </div>
);

export const INVALID_DATE_ERROR_MESSAGE = "Invalid Input.";

export const SUBMIT_SUCCESS_MESSAGE =
  "Your request is submitted and will be reviewed by Virtual Haulage team.";

export const SHIPMENT_STRING_TO_REPLACE = "[Unique Identifier]";

export const SHIPMENT_SUCCESS_MESSAGE = {
  SHIPPER: `Thank you for using the VH Portal. Your Shipment Request ${SHIPMENT_STRING_TO_REPLACE} is being processed.`,
  AGENT: `Thank you for using the VH Portal. Your Shipment Request ${SHIPMENT_STRING_TO_REPLACE} has been sent to Shipper for collaboration confirmation.`
};

export const MAX_PICKUP_DAYS = 7;

export const MAX_CARGO_VALUE = 999999999;

export const MAX_COMMODITY_WEIGHT = 2500;

export const MAX_COMMODITY_WEIGHT_SHARED = MAX_COMMODITY_WEIGHT * 10;

export const MAX_COMMODITY_LENGTH = 1000;

export const MAX_COMMODITY_LENGTH_SHARED = MAX_COMMODITY_LENGTH;

export const MAX_COMMODITY_VOLUME = 600000;

export const MAX_COMMODITY_VOLUME_SHARED = MAX_COMMODITY_VOLUME * 10;

export const HOURS_TO_SIGN_SHIPMENT_CONTRACT = 48;

export const SEND_QUOTE_COMMENT_LENGTH = 500;

export const STATUS_QUOTE_AVAILABLE_TO_CANCEL = [
  QUOTE_STATUS_CODES.PENDING,
  QUOTE_STATUS_CODES.ACCEPTED
];

export const ACTIVE_QUOTE_STATUS = "active";

export const RECAPTCHA_PERSISTENT_KEY = "__recaptcha_token__";

export const MAX_QUOTES_TO_SELECT_NON_SHARED = 3;
export const MAX_QUOTES_TO_SELECT_SHARED = 10;

export const INVOICE_VAT_PERCENT = 5;

export const ZEN_DESK_CONTACT_AS_METHOD_FIELD_ID =
  process.env.REACT_APP_ZEN_DESK_CONTACT_AS_METHOD_FIELD_ID;

export const ZEN_DESK_SHIPMENT_ID_FIELD =
  process.env.REACT_APP_ZEN_DESK_SHIPMENT_ID_FIELD;

export const ZEN_DESK_FIELDS = {
  CONTACT_AS_METHOD: process.env.REACT_APP_ZEN_DESK_CONTACT_AS_METHOD_FIELD_ID,
  SHIPMENT_ID: process.env.REACT_APP_ZEN_DESK_SHIPMENT_ID_FIELD,
  DATE_OF_COMPLAINT: process.env.REACT_APP_ZEN_DESK_DATE_OF_COMPLAINT_FIELD,
  SHIPMENT_STATUS: process.env.REACT_APP_ZEN_DESK_SHIPMENT_STATUS_FIELD,
  ISSUE_OR_INCIDENT_DATE:
    process.env.REACT_APP_ZEN_DESK_ISSUE_OR_INCIDENT_DATE_FIELD,
  ISSUE_OR_COMPLAINT_CATEGORY:
    process.env.REACT_APP_ZEN_DESK_ISSUE_OR_COMPLAINT_CATEGORY_FIELD,
  ORIGIN: process.env.REACT_APP_ZEN_DESK_ORIGIN_FIELD,
  DESTINATION: process.env.REACT_APP_ZEN_DESK_DESTINATION_FIELD,
  COMMODITY_TYPE: process.env.REACT_APP_ZEN_DESK_COMMODITY_TYPE_FIELD,
  TRUCK_OWNER_NAME: process.env.REACT_APP_ZEN_DESK_TRUCK_OWNER_NAME_FIELD,
  SHIPPER_OR_AGENT: process.env.REACT_APP_ZEN_DESK_SHIPPER_OR_AGENT_FIELD,
  SHIPMENT_PICKUP_TIME: process.env.REACT_APP_ZEN_DESK_SHIPMENT_PICKUP_TIME,
  QUOTE_STATUS: process.env.REACT_APP_ZEN_DESK_QUOTE_STATUS_FIELD,
  QUOTE_PICKUP_DATE: process.env.REACT_APP_ZEN_DESK_QUOTE_PICKUP_DATE_FIELD,
  SYSTEM_UNREAD_MESSAGE: process.env.REACT_APP_ZEN_DESK_UNREAD_MESSAGE_FIELD,
  SYSTEM_LATEST_COMMENT: process.env.REACT_APP_ZEN_DESK_LATEST_COMMENT_FIELD
};

export const IS_MESSAGING_ENABLED =
  process.env.REACT_APP_MESSAGING_ENABLED === "true";

export const COMMON_TITLE_OPTIONS = {
  mr: "Mr.",
  mrs: "Mrs.",
  miss: "Ms."
};

export const DELIVERY_PLUGIN_KEY = process.env.REACT_APP_DELIVERY_PLUGIN_KEY;

export const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_URL;

export const URL_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const ISSUE_OR_COMPLAINT_CATEGORIES = [
  "Communication challenges",
  "Contract terms",
  "Delivery or Post-Delivery Issues",
  "Vehicle Driver or Operator",
  "Fees or Payment ",
  "Pick up or pre-delivery",
  "Shipper or Agent",
  "Trip updates",
  "Truck owner",
  "Vehicle status",
  "Virtual Haulage platform",
  "Other"
].map(category => ({
  label: category,
  value: toSnakeCase(category, true)
}));

export const SUPPORTED_ROLES = (() => {
  const valueFromEnv = process.env.REACT_APP_SUPPORTED_ROLES;
  const defaultValue = ["shipper", "agent", "truck_owner"];

  try {
    return JSON.parse(valueFromEnv);
  } catch (e) {
    return defaultValue;
  }
})();
