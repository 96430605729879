import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import activeImg from "../../icons/tracking-device.svg";
import inactiveImg from "../../icons/tracking-device-inactive.svg";

const StyledIcon = styled.span`
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url(${activeImg});
  background-repeat: no-repeat;
  background-color: transparent;
  &.inactive {
    background-image: url(${inactiveImg});
    &:hover {
      cursor: pointer;
      transition: all 0.2s linear;
      background-image: url(${activeImg});
    }
  }
`;

export const TrackingIcon = ({ className }) => {
  return (
    <Tooltip
      title="This Truck has a tracking device that allows monitoring the shipment from pick up till delivery"
      placement="topLeft"
    >
      <StyledIcon className={`tracking-icon ${className || ""}`} />
    </Tooltip>
  );
};
