import axios from "axios";

class TrackShipmentService {
  changeShipmentStatus(shipmentId, status) {
    return axios.put(`shipments/${shipmentId}/status/${status}`);
  }

  changeQuoteStatus(quoteId, status) {
    return axios.put(`shipments/quotes/${quoteId}/status/${status}`);
  }
}

export default new TrackShipmentService();
