import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Button, Icon, Input, Upload } from "antd";
import { observer } from "mobx-react";

import "./styles.less";

const UploadComponent = forwardRef(
  (
    {
      id,
      value,
      multiple = false,
      accept = "*",
      disabled,
      onChange,
      fileName,
      placeholder
    },
    ref
  ) => {
    const initialState = {
      file: null,
      fileName: "",
      fileList: []
    };
    const [state, setState] = useState(initialState);

    /**
     * initial file list setting up
     */
    const updateState = useCallback(() => {
      let freshState = initialState;
      if (Array.isArray(value)) {
        const firstFile = value.reverse()[0];
        if (!firstFile) {
          return;
        }
        const name = firstFile.name || firstFile;

        freshState = {
          ...state,
          file: firstFile,
          fileList: value,
          fileName: name
        };
      }
      setState(freshState);
    }, [value, setState]);

    useEffect(() => {
      updateState();
    }, [id, value, updateState]);

    const getFilteredFileList = () => {
      const { file, fileList } = state;
      if (!file || fileList.length === 0) {
        return [];
      }

      return fileList.filter(fileItem => fileItem.uid !== file.uid).reverse();
    };

    const uploaderProps = {
      onRemove: file => {
        const filteredFileList = state.fileList.filter(
          fileItem => fileItem.uid !== file.uid
        );
        if (typeof onChange === "function") {
          onChange({
            target: { value: multiple ? filteredFileList : file }
          });
        }
        setState({
          ...state,
          fileList: filteredFileList,
          fileName: filteredFileList[0] ? filteredFileList.name : null
        });
      },
      beforeUpload: (file, fileList) => {
        if (typeof onChange === "function") {
          onChange({
            target: {
              value: multiple ? [...state.fileList, ...fileList] : file
            }
          });
        }
        setState({
          ...state,
          file,
          fileName: file.name,
          fileList: [...state.fileList, ...fileList]
        });
        return false;
      },
      multiple,
      accept,
      fileList: getFilteredFileList(),
      showUploadList: multiple
    };

    const handleFileDelete = e => {
      e.preventDefault();
      e.stopPropagation();

      uploaderProps.onRemove(state.fileList[0]);
    };

    const renderDeleteHandler = () => {
      if (state.fileList.length === 0) {
        return null;
      }
      return (
        <Icon
          className="file-uploader-delete-handler"
          type="close"
          onClick={handleFileDelete}
        />
      );
    };

    return (
      <Upload disabled={disabled} ref={ref} {...uploaderProps}>
        <Input
          size="large"
          disabled={disabled}
          value={fileName || state.fileName}
          placeholder={placeholder}
        />
        {renderDeleteHandler()}
        <Button
          disabled={disabled}
          size="large"
          type="ghost"
          style={{ height: 42 }}
        >
          Upload
        </Button>
      </Upload>
    );
  }
);

export default observer(UploadComponent);
