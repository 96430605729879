import React from "react";
import "./gried.less";

const isGridEnabled =
  !!localStorage.getItem("grid-enabled") &&
  process.env.NODE_ENV === "development";

export default ({ cols = 24 }) => {
  if (!isGridEnabled) {
    return null;
  }
  return (
    <div className="cb-grid-lines">
      <div className="grid-overlay-container">
        <div className="grid-overlay-row">
          {[...Array(cols)].map((_, i) => (
            <div key={`grid-col${i}`} className="grid-overlay-col" />
          ))}
        </div>
      </div>
    </div>
  );
};
