import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Col, Row, Button, Input } from "antd";

import Field from "../../common/FieldContainer";
import FormContext from "../../models/forms/form-context";
import BackIcon from "../../common/Icons/BackIcon";

const EmailVerification = observer(() => {
  const formState = useContext(FormContext);
  const fieldSet = formState.$("email_verification");

  const handleResendVerificationClick = e => {
    e.preventDefault();
    formState.resendVerificationCode();
  };

  return (
    <>
      <Row className="title" type="flex" justify="center">
        <Col>
          <h1>Email Verification</h1>
        </Col>
      </Row>
      <Row gutter={{ lg: 10, xs: 20 }} type="flex" justify="center">
        <Col className="text-center" lg={6} md={10} sm={24}>
          <div>
            <p style={{ marginBottom: "3em" }}>
              We sent you an email with a verification code. Please enter it
              into the field below to proceed with registration.
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={{ lg: 10, xs: 20 }} type="flex" justify="center" align="top">
        <Col lg={6} md={10} xs={21} className="single-column">
          <Field name="email_verification.code">
            <Input size="large" />
          </Field>
          <div
            className="resend-verification-link"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              loading={formState.resendingVerificationCode}
              disabled={formState.resendingVerificationCode}
              type="link"
              onClick={handleResendVerificationClick}
            >
              Resend Verification Email
            </Button>
          </div>
          <div
            className="button-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              size="large"
              type="default"
              htmlType="reset"
              onClick={() => formState.goBack()}
            >
              <BackIcon />
              {" Back"}
            </Button>
            <Button
              size="large"
              loading={fieldSet.submitting}
              disabled={fieldSet.submitting}
              type="primary"
              htmlType="submit"
              onClick={fieldSet.onSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default EmailVerification;
