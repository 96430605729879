import { action, computed, isObservableArray, observable } from "mobx";
import moment from "moment";
import { message, Modal } from "antd/lib";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import TruckService from "../../../services/api/trucks";
import OptionsService from "../../../services/api/options";
import {
  LOG_TRUCK_MAX_LOCATIONS,
  LOG_TRUCK_MAX_TRUCKS
} from "../../../config/constants";
import { formatTruckLabel } from "../../../utils/format";
import throttledMessage from "../../../utils/throttled-message";

const fields = [
  "trucks",
  "trucks[].id",
  "locations",
  "locations[].state_id",
  "locations[].city_id",
  "start_date",
  "end_date"
];

const labels = {
  "trucks[].id": "Select a Truck",
  start_date: "Start Date",
  end_date: "End Date",
  "locations[].state_id": "State",
  "locations[].city_id": "City"
};

const placeholders = {
  start_date: "dd/mm/yyyy",
  end_date: "dd/mm/yyyy",
  "locations[].state_id": "Select State",
  "locations[].city_id": "Select City"
};

const types = {
  "trucks[].id": "select",
  start_date: "date",
  end_date: "date",
  "locations[].state_id": "select",
  "locations[].city_id": "select"
};

const rules = {
  "trucks[].id": "required",
  start_date: "required",
  end_date:
    "required|truck_availability_cannot_exceed:7|truck_availability_cannot_exceed_one_month",
  "locations[].state_id": "required",
  "locations[].city_id": ""
};

const hooks = {
  "locations[].state_id": {
    onChange: async field => {
      const cityField = field.container().$("city_id");

      cityField.reset();
      cityField.resetValidation();
      cityField.set("options", []);
      cityField.loading = true;
      let cities = [];

      if (field.value) {
        cities = await OptionsService.getCitiesByState(field.value);
      }

      cityField.set(
        "options",
        cities.map(({ id, name }) => ({ value: id, label: name }))
      );
      cityField.loading = false;
    }
  }
};

const options = {
  "locations[].city_id": []
};

const input = {
  start_date: v => (v ? moment(v) : null),
  end_date: v => (v ? moment(v) : null)
};

const output = {
  start_date: v => v && moment(v).format("YYYY-MM-DD"),
  end_date: v => v && moment(v).format("YYYY-MM-DD")
};

class LogTruck extends BaseForm {
  @observable
  availableTrucks = [];

  @observable
  states = [];

  @observable
  isStatesLoaded = false;

  @observable
  isLoadingTrucks = false;

  @observable
  isFormVisible = false;

  @observable
  isMultipleMode = false;

  @observable
  route = null;

  @observable
  isRemovingLocation = false;

  @observable
  isFillingRoute = false;

  throttledNoChangeMessage = throttledMessage(
    "There is no changed to update.",
    "warning"
  );

  /**
   * * computed values
   */

  @computed
  get filteredAvailableTrucksForSingleDropDown() {
    return dropdownValue => {
      const selected = this.$("trucks")
        .values()
        .map(truck => truck.id)
        .filter(id => Boolean(id) && id !== dropdownValue.id);

      return this.availableTrucks.filter(
        truck => !selected.includes(truck.value)
      );
    };
  }

  @computed
  // eslint-disable-next-line class-methods-use-this
  get filteredCitiesForSingleDropDown() {
    return [];
  }

  @computed
  get canAddLocation() {
    return this.$("locations").fields.size < LOG_TRUCK_MAX_LOCATIONS;
  }

  @computed
  get canAddTruck() {
    return this.$("trucks").fields.size < LOG_TRUCK_MAX_TRUCKS;
  }

  /**
   * * actions
   */
  @action
  setIsRemovingLocation(flag = false) {
    this.isRemovingLocation = flag;
  }

  @action
  setRoute(route = null) {
    this.route = route;
    if (route) {
      this.availableTrucks = [
        {
          value: this.route.truck.id,
          label: formatTruckLabel(this.route.truck)
        }
      ];
    }

    this.setMultipleMode(false);
  }

  @action
  addTruck(predefined = []) {
    if (
      (Array.isArray(predefined) || isObservableArray(predefined)) &&
      predefined.length > 0
    ) {
      predefined.forEach((id, i) => {
        this.$("trucks").add();
        this.$(`trucks[${i}].id`).set("value", id);
      });
    } else {
      this.$("trucks").add();
    }
  }

  @action
  async fillRouteFields() {
    if (this.route) {
      this.isFillingRoute = true;
      this.$("trucks[0].id").set("disabled", true);

      const { start_date, end_date, routes } = this.route;

      const countLocations = routes.length;

      if (countLocations > 2) {
        let i;

        // eslint-disable-next-line no-plusplus
        for (i = 2; i < countLocations; i++) {
          this.addLocation();
        }
      }

      this.$("start_date").set("value", moment(start_date));
      this.$("end_date").set("value", moment(end_date));

      // eslint-disable-next-line no-restricted-syntax
      for (const route of routes) {
        const { state_id, city_id, order } = route;

        this.$(`locations[${order}].state_id`).set("value", state_id);

        // eslint-disable-next-line no-await-in-loop
        const cities = await OptionsService.getCitiesByState(state_id);
        const cityField = this.$(`locations[${order}].city_id`);

        cityField.loading = true;
        cityField.set(
          "options",
          cities.map(({ id, name }) => ({ value: id, label: name }))
        );
        cityField.loading = false;
        this.$(`locations[${order}].city_id`).set("value", city_id);
      }

      this.isFillingRoute = false;
    }
  }

  @action
  addLocation() {
    const fieldset = this.$("locations");

    fieldset.add();
    // if there are only two points skip next step
    if (fieldset.fields.size <= 2) {
      return;
    }

    // if there are more then two points - move last location into new added field
    const fieldsetKeys = Array.from(fieldset.fields.toJS().keys());
    const lastFieldset = fieldset.fields.get(
      fieldsetKeys[fieldset.fields.size - 1]
    );
    const penultimateFieldset = fieldset.fields.get(
      fieldsetKeys[fieldset.fields.size - 2]
    );

    const valuesToInsertIntoLastFieldSet = penultimateFieldset.values();

    lastFieldset
      .$("state_id")
      .set("value", valuesToInsertIntoLastFieldSet.state_id);
    lastFieldset
      .$("city_id")
      .set("options", penultimateFieldset.$("city_id").options);
    lastFieldset
      .$("city_id")
      .set("value", valuesToInsertIntoLastFieldSet.city_id);
    // clear intermediary point values
    penultimateFieldset.clear();
    penultimateFieldset.$("city_id").set("options", []);
  }

  @action
  removeLocation(index) {
    if (index) {
      this.$("locations").del(index);
      this.setIsRemovingLocation(true);
    }
  }

  @action
  loadAvailableTrucks(withLoadingIndicator) {
    this.isLoadingTrucks = withLoadingIndicator;
    if (!this.route) {
      return TruckService.getAvailableToLogTrucks()
        .then(trucks => {
          this.availableTrucks = trucks.map(truck => ({
            value: truck.id,
            label: formatTruckLabel(truck)
          }));
        })
        .finally(() => {
          this.isLoadingTrucks = false;
        });
    }

    return TruckService.loadSingle(this.route.truck_id)
      .then(v => {
        this.availableTrucks = [
          {
            value: v.id,
            label: formatTruckLabel(v)
          }
        ];
      })
      .finally(() => {
        this.isLoadingTrucks = false;
      });
  }

  @action
  loadStates() {
    if (this.isStatesLoaded) {
      return;
    }

    OptionsService.getStates()
      .then(states => {
        this.states = states.map(state => ({
          value: state.id,
          label: state.name
        }));
      })
      .finally(() => {
        this.isStatesLoaded = true;
      });
  }

  @action
  async openForm(predefinedTrucks = [], withLoadingIndicator) {
    if (!this.route) {
      await this.loadAvailableTrucks(withLoadingIndicator);
      if (this.availableTrucks.length === 0) {
        message.warning("There are no available to log trucks.");

        return;
      }

      const allSelectedTucksAreInListOfAvailable =
        predefinedTrucks.filter(selectedTruck => {
          return this.availableTrucks.find(
            availableTuck => availableTuck.value === selectedTruck
          );
        }).length === predefinedTrucks.length;

      if (!allSelectedTucksAreInListOfAvailable) {
        message.warning(
          "The selected truck(-s) is not available to log as available."
        );

        return;
      }
    }

    await this.initForm(predefinedTrucks);

    this.isFormVisible = true;
  }

  @action
  closeForm() {
    this.isFormVisible = false;
    this.execHook("onClear");
    this.setRoute();
    if (this.gridStore) {
      this.gridStore.load();
    }

    this.reset();
  }

  @action
  async initForm(predefinedTrucks = []) {
    // await this.loadAvailableTrucks(true);
    this.addTruck(predefinedTrucks);

    await this.loadStates();

    this.addLocation();
    this.addLocation();

    if (this.route) {
      this.fillRouteFields();
    }
  }

  @action
  preSubmit() {
    if (this.route) {
      if (!this.changed) {
        this.throttledNoChangeMessage();
      } else {
        this.saveWithConfirmation();
      }
    } else {
      return new Promise((resolve, reject) => {
        this.hooks()
          .onSuccess.call(this)
          .then(() => {
            if (this.gridStore) {
              this.gridStore.load();
            }

            resolve();

            setTimeout(() => {
              this.gridStore = null;
            }, 1000);
          })
          .catch(reject);
      });
    }

    return this.changed;
  }

  @action
  saveWithConfirmation() {
    const confirmMessage =
      "Confirm that you want to save the changes to the Truck Route. You will get notifications about new matching Shipments to the updated Route, but active Quotes/Shipments associated with this Truck will not be canceled.";

    Modal.confirm({
      icon: null,
      title: "Please, confirm you want to proceed.",
      content: confirmMessage,
      okButtonProps: {
        size: "large"
      },
      cancelButtonProps: {
        size: "large"
      },
      okText: "Confirm",
      width: 390,
      autoFocusButton: null,
      onOk: () =>
        new Promise((resolve, reject) => {
          this.hooks()
            .onSuccess.call(this)
            .then(() => {
              if (this.gridStore) {
                this.gridStore.load();
              }

              resolve();

              setTimeout(() => {
                this.gridStore = null;
              }, 1000);
            })
            .catch(reject);
        }),
      onCancel: () => {
        this.closeForm();
      }
    });
  }

  @action
  clearValues() {
    this.$("trucks").fields.clear();
    this.$("locations").fields.clear();
  }

  @action
  setMultipleMode(isMultiple) {
    this.isMultipleMode = isMultiple;
  }

  hooks() {
    return {
      onClear: () => {
        this.clearValues();
        this.clear();
      },
      onSuccess: () => {
        const values = this.values();
        const trucks = values.trucks.map(truck => truck.id);
        const locations = values.locations.map((location, i) => ({
          ...location,
          order: i
        }));

        return TruckService.logAvailableRoute({
          ...values,
          trucks,
          locations,
          routeId: this.route && this.route.id
        })
          .then(() => {
            if (!this.route) {
              setTimeout(() => {
                message.success(
                  "The selected truck(-s) is successfully logged as available."
                );
              }, 2000);
            } else {
              setTimeout(() => {
                message.success(
                  "The selected logged truck is successfully updated."
                );
              }, 2000);
            }

            if (this.isMultipleMode) {
              this.execHook("onClear");
              this.reset();
              this.initForm();
              this.setMultipleMode(false);
              this.loadAvailableTrucks();
            } else {
              this.closeForm();
            }
          })
          .catch(error => {
            console.dir(error);
            // handle validation errors
            if (error.response && error.response.status === 422) {
              const { message: errMessage, errors } = error.response.data;

              if (errors) {
                Object.entries(errors).forEach(entry => {
                  const [fieldName, errorsMessagesArray] = entry;
                  const allErrorsMessage = errorsMessagesArray.join(" ");

                  if (fieldName.includes("truck")) {
                    const [, index] = fieldName.split(".");

                    if (index) {
                      this.$("trucks")
                        .$(index)
                        .$("id")
                        .invalidate(allErrorsMessage);
                    }
                  } else if (fieldName.includes("state")) {
                    const [, index] = fieldName.split(".");

                    this.$("locations")
                      .$(index)
                      .$("state_id")
                      .invalidate(allErrorsMessage);
                  } else if (fieldName.includes("city")) {
                    const [, index] = fieldName.split(".");

                    this.$("locations")
                      .$(index)
                      .$("city_id")
                      .invalidate(allErrorsMessage);
                  } else if (this.has(fieldName)) {
                    this.$(fieldName).invalidate(allErrorsMessage);
                  }
                });
              } else {
                message.error(errMessage);
              }
            } else {
              message.warning(error.message);
            }
          });
      }
    };
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          validator.register(
            "truck_availability_cannot_exceed",
            (v, days) => {
              const start_date = this.$("start_date").value;

              if (!start_date) {
                return true;
              }

              const delta = moment(v)
                .startOf("day")
                .diff(start_date.startOf("day"), "days");

              return delta > 0 && delta <= +days;
            },
            "Truck availability cannot exceed :truck_availability_cannot_exceed days."
          );
          validator.register(
            "truck_availability_cannot_exceed_one_month",
            v => {
              const start_date = moment().add(1, "month");

              if (!start_date) {
                return true;
              }

              const delta = moment(v).diff(start_date, "days");

              return delta < 0;
            },
            "Last day of truck availability cannot exceed one month in advance."
          );
        }
      })
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }
}

export default new LogTruck(
  {
    fields,
    labels,
    types,
    rules,
    input,
    output,
    placeholders,
    hooks,
    options
  },
  { hasNestedFields: true }
);
