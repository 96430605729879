import AuthStore from "./auth";
import AppState from "./app";
import TrucksStore from "./forms/items-managers/trucks-manager";
import RemoveTruckForm from "./forms/truck/remove-truck";
import AvailableTrucksStoreInstance from "./forms/items-managers/available-trucks-manager";
import DriversStore from "./forms/items-managers/drivers-manager";
import RemoveDriverForm from "./forms/driver/remove-driver";
import CitiesOptionsStore from "./cities-options";
import TrucksInspectionStore from "./trucks-inspection";
import TruckInspectionDeclineForm from "./forms/truck-inspection/decline-form";
import PostShipmentForm from "./forms/post-shipment";
import UnlinkTruckForm from "./forms/driver/unlink-truck";
import LinkDriverForm from "./forms/link-driver-to-truck";
import SendQuoteForm from "./forms/send-quote";
import ViewContractForm from "./forms/contract";
import PaymentsStore from "./forms/items-managers/payments-manager";
import SelectQuoteStore from "./forms/items-managers/select-quote-manager";
import SelectQuoteForm from "./forms/select-quote";
import ThreadsStore from "./forms/messaging/threads";
import CommentForm from "./forms/messaging/form";
import ContactUsForm from "./forms/contact-us/contact-us-form";
import IssueFormInstance from "./forms/issue-form";
import EditProfileForm from "./forms/auth/edit-profile";
import ChangePasswordForm from "./forms/auth/change-password";
import LogTruckForm from "./forms/log-truck";
import TruckOwnerPendingShipmentStore from "./forms/items-managers/shipments/truck-owner-pending-shipments-manager";
import GetQuote from "./forms/get-quote";
import ShipmentDeliveryCodeForm from "./forms/shipment-delivery-code-form";
import TrackingMap from "./forms/tracking-map";

export default {
  AuthStore,
  AppState,
  TrucksStore,
  RemoveTruckForm,
  DriversStore,
  PostShipmentForm,
  RemoveDriverForm,
  CitiesOptionsStore,
  AvailableTrucksStore: AvailableTrucksStoreInstance,
  TrucksInspectionStore,
  TruckInspectionDeclineForm,
  UnlinkTruckForm,
  LinkDriverForm,
  SendQuoteForm,
  ViewContractForm,
  SelectQuoteStore,
  SelectQuoteForm,
  PaymentsStore,
  ThreadsStore,
  ContactUsForm,
  IssueForm: IssueFormInstance,
  CommentForm,
  EditProfileForm,
  ChangePasswordForm,
  LogTruckForm,
  TruckOwnerPendingShipmentStore,
  GetQuote,
  ShipmentDeliveryCodeForm,
  TrackingMap
};
