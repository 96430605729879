import React, { useContext } from "react";
import { observer } from "mobx-react";
import FormContext from "../../models/forms/form-context";
import SignUpSections from "./SignupSections";
import EmailVerification from "./EmailVerification";
import PreQualification from "./PreQualification";
import BankAccountDetails from "./BankAccountDetails";
import Contract from "./Contract";

export default observer(() => {
  const formState = useContext(FormContext);
  const { currentStep } = formState;

  switch (currentStep.key) {
    case "registration":
      return <SignUpSections />;
    case "email_verification":
      return <EmailVerification />;
    case "prequalification_checklist":
      return <PreQualification />;
    case "bank_account_details":
      return <BankAccountDetails />;
    case "contract":
      return <Contract />;
    default:
      return null;
  }
});
