import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Col, Row, Button, Input, Select, Checkbox, InputNumber } from "antd";

import Field from "../../common/FieldContainer";
import FormContext from "../../models/forms/form-context";
import UploadComponent from "../../common/Upload";
import BackIcon from "../../common/Icons/BackIcon";

const { Option } = Select;

const renderTruckOwnerFieldSet = () => (
  <>
    <Field name="prequalification_checklist.number_of_trucks">
      <InputNumber style={{ width: "100%" }} min={0} />
    </Field>
    <Row>
      <Col>
        Confirm that trucks have the following documents:
        <ul style={{ listStyle: "none" }}>
          <li>- Proof of Ownership,</li>
          <li>- Vehicle License,</li>
          <li>- Certificate of Road Worthiness,</li>
          <li>- Vehicle Insurance</li>
        </ul>
      </Col>
    </Row>
    <Field name="prequalification_checklist.all_trucks_have_all_documents">
      <Checkbox>I have all of them</Checkbox>
    </Field>
  </>
);

const renderAgentFieldSet = (fileName = null, userIdentityTypes = []) => (
  <>
    <Field name="prequalification_checklist.user_identification_type_id">
      <Select>
        {userIdentityTypes.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </Field>
    <Field name="prequalification_checklist.picture_identity">
      <UploadComponent fileName={fileName} accept="image/*" />
    </Field>
  </>
);

const PreQualification = observer(() => {
  const formState = useContext(FormContext);
  const fieldSet = formState.$("prequalification_checklist");
  const userIdentityTypes = formState.$(
    "prequalification_checklist.user_identification_type_id"
  ).extra;

  return (
    <>
      <Row className="title" type="flex" justify="center">
        <Col>
          <h1>Pre-Qualification Checklist</h1>
        </Col>
      </Row>
      <Row gutter={{ lg: 10, xs: 20 }} type="flex" justify="center" align="top">
        <Col lg={6} md={10} xs={21} className="single-column text-left">
          {(formState.isTruckOwner || formState.isCompany) && (
            <Field name="prequalification_checklist.company_rc">
              <Input />
            </Field>
          )}

          {formState.isAgent &&
            renderAgentFieldSet(
              fieldSet.$("picture_identity_name").value,
              userIdentityTypes
            )}
          {formState.isTruckOwner && renderTruckOwnerFieldSet()}
          <div
            className="button-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 35
            }}
          >
            <Button
              size="large"
              type="default"
              htmlType="reset"
              onClick={() => formState.goBack()}
            >
              <BackIcon />
              {" Back"}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={fieldSet.submitting}
              disabled={fieldSet.submitting}
              onClick={fieldSet.onSubmit}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
});

export default PreQualification;
