/* eslint-disable no-return-assign */
import _ from "lodash";
import { values as mobxValues, keys as mobxKeys } from "mobx";

import props from "./props";

export const getObservableMapValues = observableMap =>
  mobxValues ? mobxValues(observableMap) : observableMap.values();

export const getObservableMapKeys = observableMap =>
  mobxValues ? mobxKeys(observableMap) : observableMap.keys();

export const checkObserveItem = change => ({ key, to, type, exec }) =>
  change.type === type &&
  change.name === key &&
  change.newValue === to &&
  exec.apply(change, [change]);

export const checkObserve = collection => change =>
  collection.map(checkObserveItem(change));

export const checkPropType = ({ type, data }) => {
  let $check;
  switch (type) {
    case "some":
      $check = $data => _.some($data, Boolean);
      break;
    case "every":
      $check = $data => _.every($data, Boolean);
      break;
    default:
      $check = null;
  }
  return $check(data);
};

export const hasProps = ($type, $data) => {
  let $props;
  switch ($type) {
    case "booleans":
      $props = props.booleans;
      break;
    case "field":
      $props = [
        ...props.field,
        ...props.validation,
        ...props.function,
        ...props.handlers
      ];
      break;
    case "all":
      $props = [
        "id",
        ...props.booleans,
        ...props.field,
        ...props.validation,
        ...props.function,
        ...props.handlers
      ];
      break;
    default:
      $props = null;
  }

  return _.intersection($data, $props).length > 0;
};

/**
  Check Allowed Properties
*/
export const allowedProps = (type, data) => {
  if (hasProps(type, data)) return;
  const $msg = "The selected property is not allowed";
  throw new Error(`${$msg} (${JSON.stringify(data)})`);
};

/**
  Throw Error if undefined Fields
*/
export const throwError = (path, fields, msg = null) => {
  if (!_.isNil(fields)) return;
  const $msg = _.isNil(msg) ? "The selected field is not defined" : msg;
  throw new Error(`${$msg} (${path})`);
};

export const pathToStruct = path => {
  let struct;
  struct = _.replace(path, new RegExp("[.]\\d+($|.)", "g"), "[].");
  struct = _.replace(struct, "..", ".");
  struct = _.trim(struct, ".");
  return struct;
};

export const hasSome = (obj, keys) => _.some(keys, _.partial(_.has, obj));

export const isPromise = obj =>
  !!obj &&
  typeof obj.then === "function" &&
  (typeof obj === "object" || typeof obj === "function");

export const isStruct = struct =>
  _.isArray(struct) && _.every(struct, _.isString);

export const isEmptyArray = field => _.isEmpty(field) && _.isArray(field);

export const isArrayOfObjects = fields =>
  _.isArray(fields) && _.every(fields, _.isPlainObject);

export const $getKeys = fields =>
  _.union(_.map(_.values(fields), values => _.keys(values))[0]);

export const hasUnifiedProps = ({ fields }) =>
  !isStruct({ fields }) && hasProps("field", $getKeys(fields));

export const hasSeparatedProps = initial =>
  hasSome(initial, props.separated) || hasSome(initial, props.validation);

export const allowNested = (field, strictProps) =>
  _.isObject(field) &&
  !_.isDate(field) &&
  !_.has(field, "fields") &&
  (!hasSome(field, [
    ...props.field,
    ...props.validation,
    ...props.function,
    ...props.handlers
  ]) ||
    strictProps);

export const parseIntKeys = fields =>
  _.map(getObservableMapKeys(fields), _.ary(_.toNumber, 1));

export const hasIntKeys = fields => _.every(parseIntKeys(fields), _.isInteger);

export const maxKey = fields => {
  const max = _.max(parseIntKeys(fields));
  return _.isUndefined(max) ? 0 : max + 1;
};

export const uniqueId = field =>
  _.uniqueId(
    [_.replace(field.path, new RegExp("\\.", "g"), "-"), "--"].join("")
  );

export const $isEvent = obj => {
  if (_.isNil(obj) || typeof Event === "undefined") return false;
  return obj instanceof Event || !_.isNil(obj.target); // eslint-disable-line
};

export const $hasFiles = $ => $.target.files && $.target.files.length !== 0;

export const $isBool = ($, val) =>
  _.isBoolean(val) && _.isBoolean($.target.checked);

export const $try = (...args) => {
  let found = null;

  args.map(val => found === null && !_.isNil(val) && (found = val));

  return found;
};

export const utils = {
  props,
  checkObserve,
  checkPropType,
  hasProps,
  allowedProps,
  throwError,
  isPromise,
  isStruct,
  isEmptyArray,
  isArrayOfObjects,
  pathToStruct,
  hasUnifiedProps,
  hasSeparatedProps,
  allowNested,
  parseIntKeys,
  hasIntKeys,
  maxKey,
  uniqueId,
  $isEvent,
  $hasFiles,
  $isBool,
  $try,
  getObservableMapKeys,
  getObservableMapValues
};

export default utils;
