import { action, computed, observable } from "mobx";
import { message, Modal } from "antd/lib";
import BaseStore from "./base-manager";
import TruckService from "../../../services/api/trucks";
import { formatNumber, dateFormatter, capitalize } from "../../../utils/format";
import { PAGE_DEFAULT } from "../../../config/constants";
import { history } from "../../../contexts/history";

const INITIAL_FILTER = {
  originCity: null,
  originState: null,
  destinationCity: null,
  destinationState: null,
  startDate: null,
  endDate: null,
  trackerDevice: null
};

export class AvailableTrucksStore extends BaseStore {
  loadFunction = params =>
    TruckService.getAvailableRoutes({
      ...params,
      userId: this.authStore.userId
    });

  @observable tag = null;

  @observable isRemovingSuccess = false;

  @observable filter = INITIAL_FILTER;

  @observable trackerDevice = false;

  @computed.struct
  // eslint-disable-next-line class-methods-use-this
  get specificArguments() {
    return { ...this.filter, userId: this.tag };
  }

  resetState() {
    this.isRemovingSuccess = false;
    this.setFilter();
  }

  @action
  setFilterByTrackingDevice(value) {
    this.trackerDevice = value;
    this.filter.trackerDevice = Number(this.trackerDevice) || null;
  }

  @action setFilter({
    originCity,
    originState,
    destinationCity,
    destinationState,
    startDate,
    endDate
  } = {}) {
    this.filter = {
      originCity,
      originState,
      destinationCity,
      destinationState,
      startDate,
      endDate,
      trackerDevice: Number(this.trackerDevice) || null
    };
  }

  @action
  setTag(tag) {
    this.tag = tag;
    this.setPage(PAGE_DEFAULT);
  }

  @action
  onTableReset() {
    this.trackerDevice = false;
    this.filter = INITIAL_FILTER;
    this.tag = null;
  }

  @action
  removeWithConfirmation(row) {
    const confirmMessage =
      "Confirm that you want to remove the Truck Route. You will not get notifications about new matching Shipments anymore, but active Quotes/Shipments associated with this Truck will not be canceled.";

    Modal.confirm({
      icon: null,
      title: "Please, confirm you want to proceed.",
      content: confirmMessage,
      okButtonProps: {
        size: "large"
      },
      cancelButtonProps: {
        size: "large"
      },
      okText: "Confirm",
      width: 390,
      autoFocusButton: null,
      onOk: () =>
        new Promise((resolve, reject) => {
          this.hooks()
            .onSuccess.call(this, row)
            .then(() => {
              this.load();

              resolve();
            })
            .catch(reject);
        }),
      onCancel: () => {
        history.push("/available-trucks");
      }
    });
  }

  @action
  delete(row) {
    this.isLoading = true;

    return TruckService.deleteAvailableTruck(row.id)
      .then(data => {
        this.isLoading = false;
        this.isRemovingSuccess = true;
        setTimeout(() => {
          message.success("Logged as available Truck is successfully removed.");
        }, 2000);
        this.resetState();

        return data;
      })
      .catch(e => {
        if (e.response && e.response.status === 422) {
          const {
            data: { errors }
          } = e.response;

          Object.entries(errors).forEach(entry => {
            const [errorMessage] = entry;

            message.error(errorMessage);
          });
        } else {
          message.warning(e.message);
        }
      });
  }

  formatTruckDetail = ({ truck }) => {
    const {
      vehicle_registration_number,
      // manufacture_year,
      manufacturer: { name: manufacturer_name },
      model: { name: model_name },
      type: { name: type_name }
    } = truck;

    return [
      vehicle_registration_number,
      [manufacturer_name, model_name] // , manufacture_year]
        .filter(Boolean)
        .join(" "),
      type_name,
      ""
    ].join(" — ");
  };

  formatTruckSpecifications = ({ truck }) => {
    const { weight, length, volume } = truck;

    return [
      weight && `${weight} tons`,
      volume && `${formatNumber(volume)} lt`,
      length && `${length} ft`
    ]
      .filter(Boolean)
      .join(" — ");
  };

  formatRoutesOrigin = ({ routes }) => {
    const { city, state } = routes.filter(f => f.order === 0)[0];

    return [capitalize(city && city.name), capitalize(state && state.name)]
      .filter(Boolean)
      .join(", ");
  };

  formatRoutesDestinations = ({ routes }) => {
    const destination = routes.reverse()[0];

    if (!destination) {
      return "—";
    }

    const { city, state } = destination;

    return [capitalize(city && city.name), capitalize(state && state.name)]
      .filter(Boolean)
      .join(", ");
  };

  formatRoutesStartDate = ({ start_date }) =>
    start_date && `${dateFormatter(start_date)}  — `;

  formatRoutesEndDate = ({ end_date }) => end_date && dateFormatter(end_date);

  hooks() {
    return {
      onSuccess: row => {
        return this.delete(row);
      }
    };
  }
}

export default new AvailableTrucksStore();
