import { observable } from "mobx";
import BaseStore from "../base-manager";
import { STATUS_QUOTE_AVAILABLE_TO_CANCEL as statusAvailableToCancel } from "../../../../config/constants";
import ShipmentsService from "../../../../services/api/shipments";

export class PendingShipmentsStore extends BaseStore {
  @observable defaultSortKey = "pickup_date_start";

  sortableFields = ["pickup_date_start", "price"];

  loadFunction = (...args) => {
    return ShipmentsService.getTuckOwnerPendingShipments(...args).then(
      ({ data: shipments, ...rest }) => {
        const [shipmentPrototype] = shipments;

        if (!shipmentPrototype) {
          return { data: shipments, ...rest };
        }

        const { user } = this.authStore;

        shipments.forEach(shipment => {
          const { quotes } = shipment;
          const quotesAvailable = quotes.filter(
            v => statusAvailableToCancel.indexOf(v.status) > -1
          );
          if (quotesAvailable.length === 0) {
            return;
          }

          const quoteOwnedByCurrentUser = quotesAvailable.find(
            quote => quote.owner_id === user.id
          );

          if (!quoteOwnedByCurrentUser) {
            return;
          }

          shipment.price = quoteOwnedByCurrentUser.price;
        });

        return { data: shipments, ...rest };
      }
    );
  };

  getCurrentUserHasHisQuoteSent(quotes) {
    const quotesAvailable = quotes.filter(
      v => statusAvailableToCancel.indexOf(v.status) > -1
    );
    return quotesAvailable.find(
      quote => quote.owner_id === this.authStore.user.id
    );
  }
}

export default new PendingShipmentsStore();
