import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Menu, Drawer } from "antd";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import HamburgerMenu from "react-hamburger-menu";
import useToggle from "react-use-toggle";
import cx from "classnames";
import { withRouter } from "react-router";
import { ReactComponent as IconDown } from "../../icons/down.svg";
import { Desktop, NotDesktop } from "../../utils/responsive";
import LogTruckForm from "../../models/forms/log-truck";
import AuthStoreInstance from "../../models/auth";
import AvailableTrucksStoreInstance from "../../models/forms/items-managers/available-trucks-manager";
import MessagesMenuItem from "./messages-menu-item";

import "./HeaderMenu.less";

const { SubMenu, Item, Divider } = Menu;

const GUEST_MENU_ITEMS = [
  {
    title: "Shippers",
    path: "/shippers"
  },
  {
    title: "Agents",
    path: "/agents"
  },
  {
    title: "Truck Owners",
    path: "/truck-owners"
  },
  {
    title: "Get Quote",
    path: "/get-quote"
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    children: [
      {
        title: "Available Trucks",
        path: "/marketplace/trucks"
      },
      {
        title: "Shipment Requests",
        path: "/marketplace/shipments"
      }
    ]
  }
];

const COMMON_MENU_ITEMS = [
  {
    title: "Shipments",
    path: "/my-shipments",
    children: [
      {
        title: "Post Shipment",
        path: "/post-shipment",
        visible: () => AuthStoreInstance.isBankInfoFull
      }
    ]
  },
  {
    title: "Payments",
    path: "/payments"
  },
  {
    title: "Get Quote",
    path: "/get-quote"
  },
  {
    title: "Messages",
    path: "/messages",
    Component: MessagesMenuItem
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    children: [
      {
        title: "Available Trucks",
        path: "/marketplace/trucks"
      },
      {
        title: "Shipment Requests",
        path: "/marketplace/shipments"
      }
    ]
  }
  // {
  //   title: "Messages",
  //   path: "/messages"
  // }
];
const TRUCK_OWNER_MENU_ITEMS = [
  {
    title: "Trucks",
    path: "/trucks",
    children: [
      {
        title: "Register Truck",
        path: "/register-truck"
      },
      {
        title: "Manage Truck",
        key: "manage-trucks",
        children: [
          {
            title: "Log Available Trucks",
            onClick: () => {
              LogTruckForm.gridStore = AvailableTrucksStoreInstance;
              LogTruckForm.openForm([], false);
            },
            path: "/log-truck"
          },
          {
            title: "View Available Trucks",
            path: "/available-trucks"
          }
        ]
      }
    ]
  },
  {
    title: "Drivers",
    path: "/drivers",
    children: [
      {
        title: "Add Driver",
        path: "/add-driver"
      }
    ]
  },
  {
    title: "Shipments",
    path: "/my-shipments"
  },
  {
    title: "Messages",
    path: "/messages",
    Component: MessagesMenuItem
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    children: [
      {
        title: "Available Trucks",
        path: "/marketplace/trucks"
      },
      {
        title: "Shipment Requests",
        path: "/marketplace/shipments"
      }
    ]
  }
  // {
  //   title: "Messages",
  //   path: "/messages"
  // }
];

const AGENT_MENU_ITEMS = [...COMMON_MENU_ITEMS];
const SHIPPER_MENU_ITEMS = [...COMMON_MENU_ITEMS];

const renderMobileMenuDivider = () => (
  <Divider
    key="divider"
    className="m-top-30"
    style={{ marginLeft: -31, marginRight: -24 }}
  />
);

const renderGuideLink = AuthStore => {
  if (!AuthStore.isLoggedIn) {
    return null;
  }

  let url = "";

  switch (true) {
    case AuthStore.isTruckOwner:
      url = "/truck-owners";
      break;
    case AuthStore.isShipper:
      url = "/shippers";
      break;
    case AuthStore.isAgent:
      url = "/agents";
      break;
    default:
  }

  return (
    <Item className="m-top-20" key="view-profile">
      <Link to={url}>Training Guides</Link>
    </Item>
  );
};

const mobileAuthLinks = [
  renderMobileMenuDivider(),
  <Item className="m-top-30" key="login-link">
    <Link to="/login">Login</Link>
  </Item>,
  <Item className="m-top-20" key="register-link">
    <Link className="link link-primary" to="/registration">
      Sign Up
    </Link>
  </Item>
];

const mobileAccountLinks = AuthStore => {
  const handleClickLogout = () => {
    AuthStore.isLoggedIn = false;
  };

  return [
    renderMobileMenuDivider(),
    renderGuideLink(AuthStore),
    <Item className="m-top-20" key="view-profile">
      <Link to="/view-profile">View/Edit Profile</Link>
    </Item>,
    <Item className="m-top-20" key="change-password">
      <Link to="/change-password">Change Password</Link>
    </Item>,
    <Item className="m-top-20" key="logout-link">
      <span onClick={handleClickLogout} className="link">
        Logout
      </span>
    </Item>
  ];
};

const renderSingleMenuItem = ({
  title,
  path,
  onClick,
  key,
  visible,
  Component
}) => {
  if (typeof visible === "function" && !visible()) {
    return null;
  }

  let clickableChild;

  let element;

  if (Component) {
    element = <Component>{title}</Component>;
  } else {
    element = title;
  }

  if (typeof onClick === "function") {
    clickableChild = (
      <span key={title || key} className="m-md-top-40 m-xs-top-40">
        {element}
      </span>
    );
  } else {
    clickableChild = (
      <Link key={title || key} to={path}>
        {element}
      </Link>
    );
  }

  return (
    <Item
      key={path || key}
      onClick={onClick}
      className="m-md-top-40 m-xs-top-40"
    >
      {clickableChild}
    </Item>
  );
};

const renderMenuTree = (
  { title, path, children, onClick, Component },
  history
) => {
  if (Array.isArray(children)) {
    const handleTitleClick = () => {
      if (path) {
        history.push(path);
      }
    };

    return (
      <SubMenu
        className={cx("m-md-top-40 m-xs-top-40", {
          "ant-menu-item-selected": history.location.pathname.includes(path)
        })}
        onTitleClick={handleTitleClick}
        key={path || title}
        title={title}
      >
        {children.map(child => {
          if (Array.isArray(child.children)) {
            return renderMenuTree(child, history);
          }

          return renderSingleMenuItem(child);
        })}
      </SubMenu>
    );
  }

  return renderSingleMenuItem({ title, path, onClick, Component });
};

/**
 * @param handleClickLogout
 * @param profile
 * @param AuthStore
 * @returns {*}
 */
const renderUsersMenu = ({
  handleClickLogout,
  user: { profile } = {},
  AuthStore
}) => {
  const menu = (
    <Menu style={{ borderRadius: "4px", padding: "0px" }}>
      {renderGuideLink(AuthStore)}
      <Item>
        <Link to="/view-profile">View/Edit Profile</Link>
      </Item>
      <Item>
        <Link to="/change-password">Change Password</Link>
      </Item>
      <Item onClick={handleClickLogout}>
        <span className="logout-link on-hover-cursor-pointer">Logout</span>
      </Item>
    </Menu>
  );

  return (
    <Dropdown
      trigger={["click"]}
      className="m-left-auto d-xs-none d-md-none"
      overlay={menu}
    >
      <span className="on-hover-cursor-pointer">
        {profile && profile.first_name} {profile && profile.last_name}{" "}
        <IconDown className="anticon" />
      </span>
    </Dropdown>
  );
};

/**
 * todo implement role management for the menu
 * @returns {*[]}
 */
const AuthorizedMenuItems = withRouter(
  inject("AuthStore", "EditProfileForm")(
    observer(
      ({
        AuthStore,
        isMobile = false,
        location: { pathname },
        history,
        EditProfileForm
      }) => {
        const [state, setState] = useState({
          menuItems: [],
          shouldRenderAuthLinks: true
        });
        const [selectedKeys, setSelectedKeys] = useState(["/"]);

        const updateSelectedKeysOnHistoryChange = useCallback(
          location => {
            setSelectedKeys(
              state.menuItems
                .filter(({ path }) => location.pathname.includes(path))
                .map(matched => matched.path)
            );
          },
          [state.menuItems]
        );

        const historyListenerDisposer = useRef(() => {});

        useEffect(() => {
          historyListenerDisposer.current = history.listen(
            updateSelectedKeysOnHistoryChange
          );

          return () => {
            historyListenerDisposer.current();
          };
        }, [history, updateSelectedKeysOnHistoryChange]);

        useEffect(() => {
          if (!isMobile) {
            return;
          }

          const shouldRenderAuthLinks =
            !AuthStore.isLoggedIn && !AuthStore.authorizing;

          setState(s => ({ ...s, shouldRenderAuthLinks }));
        }, [AuthStore.isLoggedIn, AuthStore.authorizing, isMobile]);

        useEffect(() => {
          let menuItems = [];

          if (AuthStore.isGuest) {
            menuItems = GUEST_MENU_ITEMS;
          } else {
            if (AuthStore.isTruckOwner) {
              menuItems = TRUCK_OWNER_MENU_ITEMS;
            }

            if (AuthStore.isAgent) {
              menuItems = AGENT_MENU_ITEMS;
            }

            if (AuthStore.isShipper) {
              menuItems = SHIPPER_MENU_ITEMS;
            }
          }

          setState(s => ({ ...s, menuItems }));
        }, [
          AuthStore.user.role,
          AuthStore.isGuest,
          AuthStore.isTruckOwner,
          AuthStore.isShipper,
          AuthStore.isAgent,
          EditProfileForm
        ]);

        if (state.menuItems.length === 0) {
          return null;
        }

        return (
          <Menu
            selectedKeys={[pathname, ...selectedKeys]}
            mode={isMobile ? "inline" : "horizontal"}
            className={cx({ "d-none d-lg-block": !isMobile })}
          >
            {state.menuItems.map(item => renderMenuTree(item, history))}
            {isMobile && state.shouldRenderAuthLinks && mobileAuthLinks}
            {isMobile &&
              !state.shouldRenderAuthLinks &&
              mobileAccountLinks(AuthStore)}
          </Menu>
        );
      }
    )
  )
);

const MobileMenu = ({ opened, onClose }) => {
  return (
    <Drawer
      className="mobile-menu"
      visible={opened}
      placement="top"
      closable={false}
      onClose={onClose}
    >
      <AuthorizedMenuItems isMobile />
    </Drawer>
  );
};

const HeaderMenu = inject("AuthStore", "AppState")(
  observer(({ AuthStore, AppState }) => {
    const [menuOpened, toggleMenu] = useToggle(false);
    const { user } = AuthStore;

    const shouldRenderAuthLinks =
      !AuthStore.isLoggedIn && !AuthStore.authorizing;

    const handleClickLogout = () => {
      AuthStore.isLoggedIn = false;
    };

    if (AppState.embeddableApp) {
      return <Link className="brand-logo m-auto" to="/" />;
    }

    return (
      <>
        <NotDesktop>
          <div className="d-lg-none menu-trigger">
            <HamburgerMenu
              height={22}
              width={32}
              strokeWidth={2}
              borderRadius={2}
              color="#3D3D46"
              isOpen={menuOpened}
              menuClicked={toggleMenu}
            />
            <MobileMenu opened={menuOpened} onClose={toggleMenu} />
          </div>
        </NotDesktop>
        <Link className="brand-logo m-md-auto m-xs-auto m-lg-right-50" to="/" />
        <AuthorizedMenuItems />
        <Desktop>
          {shouldRenderAuthLinks && (
            <>
              <Link
                className="text-black d-md-none d-xs-none m-left-auto"
                to="/login"
              >
                Login
              </Link>
              <Link
                className="link link-primary d-md-none d-xs-none m-left-30"
                to="/registration"
              >
                Sign Up
              </Link>
            </>
          )}
          {AuthStore.isLoggedIn &&
            renderUsersMenu({
              handleClickLogout,
              user,
              AuthStore
            })}
        </Desktop>
      </>
    );
  })
);

export default HeaderMenu;
