import React, { useEffect, useState } from "react";
import useToggle from "react-use-toggle";

import StaticPagesService, {
  TYPE_VH_FOR_SHIPPERS,
  TYPE_VH_FOR_AGENTS,
  TYPE_VH_FOR_TRUCK_OWNERS,
  TYPE_PRIVACY_POLICY,
  TYPE_TERMS_AND_CONDITIONS,
  TYPE_ABOUT_US_AND_OUR_TEAM,
  TYPE_FAQ,
  TYPE_HOME_PAGE,
  TYPE_CONTACT_US,
  TYPE_CONTACTS
} from "../../services/api/static-pages";
import InfoPagePreview from "./info-page-preview";

import "../../common/StaticPage/styles.less";
import TermsAndConditions from "../terms-and-conditions";
import FAQ from "../faq-page";
import HomePage from "../home";
import ContactUs from "../contact-us/contact-us";
import ContactsPreview from "./contacts-preview";

const infoPageRender = (schema, withoutPostfix) => (
  <InfoPagePreview schema={schema} withoutPostfix={withoutPostfix} />
);

const PREVIEW_RENDER_FUNCTIONS = {
  [TYPE_VH_FOR_SHIPPERS]: infoPageRender,
  [TYPE_VH_FOR_AGENTS]: infoPageRender,
  [TYPE_VH_FOR_TRUCK_OWNERS]: infoPageRender,
  [TYPE_PRIVACY_POLICY]: schema => infoPageRender(schema, true),
  [TYPE_TERMS_AND_CONDITIONS]: schema => (
    <TermsAndConditions preloadedSchema={schema} />
  ),
  [TYPE_ABOUT_US_AND_OUR_TEAM]: schema => infoPageRender(schema, true),
  [TYPE_FAQ]: schema => <FAQ preloadedSchema={schema} />,
  [TYPE_HOME_PAGE]: schema => <HomePage preloadedSchema={schema} />,
  [TYPE_CONTACT_US]: schema => <ContactUs preloadedSchema={schema} />,
  [TYPE_CONTACTS]: schema => <ContactsPreview preloadedSchema={schema} />
};

export default function CmsPreview({
  match: {
    params: { type }
  }
}) {
  const [schema, setSchema] = useState();
  const [isPreviewLoaded, toggleIsLoaded] = useToggle();

  const loadPreview = async () => {
    const previewSchema = await StaticPagesService.loadPreview(type);
    setSchema(previewSchema);
    toggleIsLoaded();
  };

  const renderFunction = PREVIEW_RENDER_FUNCTIONS[type];

  useEffect(() => {
    if (!renderFunction) {
      return;
    }
    loadPreview();
  }, [type]);

  if (!renderFunction) {
    return "Preview is not available for this page.";
  }

  if (!isPreviewLoaded || !schema) {
    return null;
  }

  return renderFunction(schema);
}
