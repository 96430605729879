import autobind from "autobind-decorator";
import axios from "axios";

/**
 * @typedef {"contacts"|"contact-us"|"faq"|"home"|"vh-for-shippers"|"vh-for-agents"|"vh-for-truck-owners"|"terms-and-conditions"|"about-us-and-our-team"|"privacy-policy"} CmsResource
 */
export const TYPE_VH_FOR_SHIPPERS = "vh-for-shippers";
export const TYPE_VH_FOR_AGENTS = "vh-for-agents";
export const TYPE_VH_FOR_TRUCK_OWNERS = "vh-for-truck-owners";
export const TYPE_TERMS_AND_CONDITIONS = "terms-and-conditions";
export const TYPE_ABOUT_US_AND_OUR_TEAM = "about-us-and-our-team";
export const TYPE_PRIVACY_POLICY = "privacy-policy";
export const TYPE_HOME_PAGE = "home";
export const TYPE_FAQ = "faq";
export const TYPE_CONTACT_US = "contact-us";
export const TYPE_CONTACTS = "contacts";

export const CMS_RESOURCE_TYPES = [
  TYPE_PRIVACY_POLICY,
  TYPE_PRIVACY_POLICY,
  TYPE_VH_FOR_TRUCK_OWNERS,
  TYPE_VH_FOR_AGENTS,
  TYPE_VH_FOR_SHIPPERS,
  TYPE_ABOUT_US_AND_OUR_TEAM,
  TYPE_TERMS_AND_CONDITIONS,
  TYPE_HOME_PAGE,
  TYPE_FAQ,
  TYPE_CONTACT_US,
  TYPE_CONTACTS
];

class StaticPagesService {
  cache = new Map();

  loading = false;

  async fetchAll() {
    if (this.cache.size !== 0) {
      this.loading = false;

      return Promise.resolve();
    }
    try {
      this.loading = axios.get("/cms/pages/active");
      const { data: allPages } = await this.loading;

      allPages.forEach(page => {
        this.cache.set(`${page.type}-active`, page.body);
      });
    } catch (e) {
      console.warn(e);
    }

    this.loading = false;

    return Promise.resolve();
  }

  /**
   *
   * @param resource {CmsResource}
   * @param type {('active'|'draft')}
   * @returns {Promise<unknown>|any}
   */
  async fetch(resource, type = "active") {
    if (this.loading) {
      return new Promise(resolve => {
        this.loading.finally(async () => {
          const response = await this.loadFromCache(resource, type);

          resolve(response);
        });
      });
    }

    await this.fetchAll();

    return this.loadFromCache(resource, type);
  }

  loadFromCache(resource, type) {
    const cacheKey = `${resource}-${type}`;

    if (this.cache.has(cacheKey)) {
      return Promise.resolve(this.cache.get(cacheKey));
    }

    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { body }
        } = await axios.get(`/cms/pages/${type}/${resource}`);

        this.cache.set(cacheKey, body);
        resolve(body);
      } catch (e) {
        console.warn(e);
        reject();
      }
    });
  }

  @autobind
  getShipperPageContent() {
    return this.fetch(TYPE_VH_FOR_SHIPPERS);
  }

  @autobind
  getAgentPageContent() {
    return this.fetch(TYPE_VH_FOR_AGENTS);
  }

  @autobind
  getTruckOwnerPageContent() {
    return this.fetch(TYPE_VH_FOR_TRUCK_OWNERS);
  }

  @autobind
  getPrivacyPolicyPageContent() {
    return this.fetch(TYPE_PRIVACY_POLICY);
  }

  @autobind
  getTermsPageContent() {
    return this.fetch(TYPE_TERMS_AND_CONDITIONS);
  }

  @autobind
  getAboutUsPageContent() {
    return this.fetch(TYPE_ABOUT_US_AND_OUR_TEAM);
  }

  @autobind
  getHomeStaticPage() {
    return this.fetch(TYPE_HOME_PAGE);
  }

  @autobind
  getFAQStaticPage() {
    return this.fetch(TYPE_FAQ);
  }

  @autobind
  getContactUsStaticPage() {
    return this.fetch(TYPE_CONTACT_US);
  }

  @autobind
  getContacts() {
    return this.fetch(TYPE_CONTACTS);
  }

  loadPreview(resource) {
    if (!CMS_RESOURCE_TYPES.includes(resource)) {
      throw new Error(`CMS Type "${resource}" is not supported`);
    }

    return this.fetch(resource, "draft");
  }
}

export default new StaticPagesService();
