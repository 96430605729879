import { action, computed, observable } from "mobx";
import { message } from "antd";
import BaseStore from "./forms/items-managers/base-manager";
import AppState from "./app";
import TruckInspectionService from "../services/api/trucks-inspection";
import { history } from "../contexts/history";
import truckProxy from "./proxies/truck-proxy";

const TRUCK_STATUS_APPROVED = "approved";
const TRUCK_STATUS_DECLINED = "declined";

class TrucksInspectionStore extends BaseStore {
  @observable
  hasErrors = false;

  @observable
  isCompeted = true;

  @observable
  isSubmitting = false;

  @observable
  token = null;

  @observable
  trucksStatuses = new Map();

  proxy = truckProxy;

  constructor(appState) {
    super();

    this.appState = appState;
  }

  loadFunction = (...args) => {
    return TruckInspectionService.loadTrucksToInspect(...args)
      .then(({ data }) => {
        if (!["inspected", "completed"].includes(data.status)) {
          this.isCompeted = false;
        } else {
          data.inspections.forEach(inspection => {
            if (inspection.status === TRUCK_STATUS_APPROVED) {
              this.approveTruck({ id: inspection.truck_id });
            } else {
              this.declineTruck({
                id: inspection.truck_id,
                comment: inspection.comment,
                reason: inspection.truck_decline_decline_reason_id
              });
            }
          });
        }

        return data;
      })
      .then(({ trucks }) => ({ data: trucks }))
      .catch(error => {
        const { response: { status } = {} } = error;
        // assumes that the token is expired or invalid
        if (status === 401) {
          message.warning("The link is not valid anymore.");
        }

        history.replace("/");

        this.appState.makeAppEmbedded(false);

        return error;
      });
  };

  @computed
  get companyName() {
    const { tableData } = this;

    const [truck] = tableData;
    if (!truck) {
      return false;
    }

    const {
      user: {
        profile: { company_name }
      }
    } = truck;
    if (!company_name) {
      return false;
    }
    return company_name;
  }

  @computed
  get canSubmit() {
    return !this.isCompeted;
  }

  @computed
  get specificArguments() {
    return {
      token: this.token
    };
  }

  @computed
  get isTruckHasStatus() {
    return truckId => {
      return this.trucksStatuses.has(truckId);
    };
  }

  @computed
  get isTruckApproved() {
    return truckId => {
      if (!this.trucksStatuses.has(truckId)) {
        return false;
      }
      const truck = this.trucksStatuses.get(truckId);

      return truck.status === TRUCK_STATUS_APPROVED;
    };
  }

  @computed
  get isTruckDeclined() {
    return truckId => {
      if (!this.trucksStatuses.has(truckId)) {
        return false;
      }
      const truck = this.trucksStatuses.get(truckId);

      return truck.status === TRUCK_STATUS_DECLINED;
    };
  }

  @computed
  get truck() {
    return id => this.trucksStatuses.get(id);
  }

  @action
  setToken(token) {
    this.token = token;
  }

  @action
  approveTruck({ id: truck_id }) {
    this.trucksStatuses.set(truck_id, {
      status: TRUCK_STATUS_APPROVED,
      truck_id
    });
  }

  @action
  declineTruck({ id: truck_id, reason, comment }) {
    this.trucksStatuses.set(truck_id, {
      status: TRUCK_STATUS_DECLINED,
      truck_id,
      reason,
      comment
    });
  }

  @action
  submit() {
    const trucksLength = this.tableData.length;
    const processedTrucksLength = this.trucksStatuses.size;
    const isIncomplete = trucksLength !== processedTrucksLength;

    this.hasErrors = isIncomplete;

    if (isIncomplete) {
      return;
    }

    this.isSubmitting = true;

    TruckInspectionService.submit([...this.trucksStatuses.values()], this.token)
      .then(() => {
        message.success("Inspection has been successfully submitted.");
        this.isCompeted = true;
      })
      .catch(e => {
        if (e.response.status === 401) {
          message.warning("Your account is no longer available.");
          this.isCompeted = true;
        }
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }
}

export default new TrucksInspectionStore(AppState);
