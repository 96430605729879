import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";

function AuthRoute(props) {
  const lastLocation = useLastLocation();
  const {
    AuthStore: { isLoggedIn, authorizing, isAccountActive },
    ...rest
  } = props;

  if (authorizing) {
    return null;
  }

  if ((!isLoggedIn && !authorizing) || !isAccountActive) {
    return <Route {...rest} />;
  }

  const redirectTo =
    lastLocation &&
    (!rest.component.isAuthRoute && !lastLocation.pathname.includes("login"))
      ? `${lastLocation.pathname}${lastLocation.hash || ""}`
      : "/";

  return <Redirect to={redirectTo} />;
}

export default inject("AuthStore")(observer(AuthRoute));
