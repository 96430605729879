import React from "react";

const PaginationItem = (current, type, originalElement) => {
  if (type === "prev") {
    return <span>Previous</span>;
  }
  if (type === "next") {
    return <span>Next</span>;
  }
  return originalElement;
};

export default PaginationItem;
