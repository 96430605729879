/* eslint-disable class-methods-use-this */
import validatorjs from "validatorjs";
import Field from "../field";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";

class ForgotPasswordForm extends BaseForm {
  setup() {
    return {
      fields: [
        {
          name: "email_request",
          rules: "required|email_ext|string|max:255",
          guard: "spaces"
        }
      ]
    };
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");

          validator.setMessages("en", {
            ...messages,
            "required.email_request": "Please specify your email address."
          });
        }
      })
    };
  }

  makeField(props) {
    return new Field(props);
  }

  options() {
    return {
      validateOnReset: false,
      showErrorsOnReset: false
    };
  }
}

export default new ForgotPasswordForm();
