import React from "react";
import { Modal as BaseModal } from "antd";
import { observer } from "mobx-react";

const Modal = observer(
  ({ children, okButtonProps = {}, cancelButtonProps = {}, ...rest }) => {
    return (
      <BaseModal
        destroyOnClose={false}
        maskClosable={false}
        okButtonProps={{ ...okButtonProps, size: "large" }}
        cancelButtonProps={{ ...cancelButtonProps, size: "large" }}
        {...rest}
      >
        {children}
      </BaseModal>
    );
  }
);

export default Modal;
