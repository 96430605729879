import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Steps, Col, Row } from "antd";
import useMobileDetect from "use-mobile-detect-hook";
import FormContext from "../../models/forms/form-context";

const { Step } = Steps;

const RegistrationSteps = observer(() => {
  const detectMobile = useMobileDetect();
  const formState = useContext(FormContext);
  const { currentStep, steps } = formState;

  return (
    <Row gutter={10} type="flex" justify="center">
      <Col lg={formState.isShipper ? 10 : 22} xs={22}>
        <Steps
          status="process"
          labelPlacement={detectMobile.isMobile() ? "vertical" : "horizontal"}
          current={currentStep.index}
        >
          {steps.map((step, i) => (
            <Step
              className={`step-${i + 1}`}
              key={step.key}
              title={step.label}
            />
          ))}
        </Steps>
      </Col>
    </Row>
  );
});

export default RegistrationSteps;
