import React, { useContext, useEffect, useRef } from "react";
import moment from "moment";
import { Form } from "antd";
import { observer } from "mobx-react";
import cx from "classnames";
import FormContext from "../models/forms/form-context";

export const selectSearchExtraProps = {
  style: { width: "100%" },
  showSearch: true,
  allowClear: true,
  optionFilterProp: "children",
  filterOption: (input, option) =>
    `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
};

const isValidMaxNumber = value => {
  return Number.isSafeInteger(+value);
};

export const inputNumberProps = {
  type: "integer",
  onKeyDown: e => {
    const charCode = e.which ? e.which : e.keyCode;
    // char codes of backspace and tab
    const navigationChars = [8, 9];
    // char codes of e , . -
    const disabledChars = [69, 187, 188, 189, 190, 107, 109, 110];
    // if char code is disabled then prevent event
    if (disabledChars.includes(charCode)) {
      e.preventDefault();
      return false;
    }
    // if nav key is pressed the allow the event to do validation
    if (navigationChars.includes(charCode)) {
      return true;
    }
    // if entered number is not safe integer then prevent input
    if (!isValidMaxNumber(e.target.value)) {
      e.preventDefault();
      return false;
    }

    return true;
  }
};

const testIfNumberIsValid = number => {
  if (!isValidMaxNumber(number)) {
    return false;
  }

  if (typeof number === "string") {
    return /^\d+$/g.test(number);
  }

  return true;
};

const pasteHandlerForInputNumber = e => {
  if (e instanceof ClipboardEvent) {
    const clipboardData = e.clipboardData.getData("text/plain");

    if (!testIfNumberIsValid(clipboardData)) {
      e.preventDefault();
    }
  }
};

const assignPasteHandlerToInputIfNumber = (type, ref) => {
  if (type !== "integer") {
    return;
  }
  const { current: { inputNumberRef: { input } = {} } = {} } = ref;
  if (input) {
    input.addEventListener("paste", pasteHandlerForInputNumber);
  }
};

const appendOptionalMarkToLabel = (label, isRequired = false) => {
  if (isRequired || label === "") {
    return label;
  }

  return (
    <span>
      {label} <span className="font-italic">(optional)</span>
    </span>
  );
};

export default observer(
  ({
    className,
    name,
    children,
    withSearch = false,
    hasFeedback = false,
    hideOptionalMark,
    disabled: disabledFromProps,
    ...rest
  }) => {
    const formState = useContext(FormContext);
    const fieldState = formState.$(name);
    const {
      label,
      hasError,
      error,
      isValid,
      disabled: disabledFromState,
      type,
      hidden,
      loading
    } = fieldState;

    const ref = useRef(null);
    useEffect(() => {
      assignPasteHandlerToInputIfNumber(type, ref);
    }, [type, ref]);

    // if field is hidden do not render a component at all
    if (hidden) {
      return null;
    }

    let { value } = fieldState;
    const extra = fieldState.extra || {};
    let additionalProps = { size: "large" };

    if (type === "select" && !value) {
      value = undefined;
    }

    if (type === "integer") {
      additionalProps = {
        ...additionalProps,
        ...inputNumberProps
      };
      if (!Number.isInteger(+value)) {
        value = undefined;
      }
    }

    // we have to check if value is not array due to DateRange specific value of array of moment objects
    if (type === "date" && !moment.isMoment(value) && !Array.isArray(value)) {
      value = value ? moment(value) : undefined;
    }

    if (type === "select" && withSearch) {
      additionalProps = { ...additionalProps, ...selectSearchExtraProps };
    }

    const onChange = val => {
      fieldState.onChange(val);
    };

    let errorStatus = error && (hasError || !isValid) ? "error" : "";
    if (errorStatus === "") {
      errorStatus = hasFeedback && isValid ? "success" : "";
    }
    const disabled = disabledFromState || disabledFromProps;
    return (
      <Form.Item
        className={cx(className, {
          "ant-form-item-disabled": disabled
        })}
        colon={false}
        hasFeedback={hasFeedback}
        label={
          !extra.hideLabel &&
          appendOptionalMarkToLabel(
            rest.label || label,
            hideOptionalMark || fieldState.isRequired
          )
        }
        validateStatus={errorStatus}
        help={error}
        extra={rest.extra}
      >
        {React.Children.map(children, child => {
          let refProp = ref;

          // we have to check if child is a Input component, this is not reliable but working check
          if (typeof child.type !== "function") {
            refProp = null;
          }

          return React.cloneElement(child, {
            ...fieldState.bind(),
            ...child.props,
            disabled,
            loading,
            value,
            onChange,
            ...additionalProps,
            ref: refProp
          });
        })}
      </Form.Item>
    );
  }
);
