import IssueFormInstance, {
  IssueForm as IssueFormClass
} from "../../../../../models/forms/issue-form";
import AuthStore from "../../../../../models/auth";
import { openInNewTab } from "../../../../../utils/window";
import { SHIPMENT_PAST_CODES } from "../../../../../config/constants";
import { history } from "../../../../../contexts/history";

const PAST_SHIPMENTS_ACTIONS = [
  {
    key: "re_post_shipment",
    label: "Repost",
    /**
     * @param row {Shipment}
     * @returns {*}
     */
    visible: row =>
      [SHIPMENT_PAST_CODES.UNSUCCESSFUL, SHIPMENT_PAST_CODES.CANCELED].includes(
        row.status
      ) &&
      !AuthStore.isTruckOwner &&
      row.isOwnedByCurrentUser,
    onClick: row => {
      history.push(`/repost-shipment/${row.id}`);
    }
  },
  {
    key: "view_contract",
    label: "View Contract",
    visible: row => row.isClosed,
    onClick: row => {
      openInNewTab(`/view-contract/${row.id}`);
    }
  },
  {
    key: "rate_truck_owner",
    label: "Rate Truck Owner",
    visible: row => row.isClosed && !AuthStore.isTruckOwner
  },
  {
    key: "register_complaint",
    label: "Register Complaint",
    visible: row => row.isClosed,
    onClick: shipment =>
      IssueFormInstance.setType(IssueFormClass.FORM_TYPE_COMPLAINT).setShipment(
        shipment
      )
  }
];

export default PAST_SHIPMENTS_ACTIONS;
