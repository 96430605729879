import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import useToggle from "react-use-toggle";
import { inject, observer } from "mobx-react";

import StaticPagesService from "../../services/api/static-pages";

const renderPhoneLinks = (phonesString = "") => {
  return phonesString
    .split(",")
    .map(number => <a href={`tel:${number}`}>{number}</a>)
    .reduce((links, item, index) => {
      links.push(item);

      if (index % 2 === 0) {
        links.push(", ");
      }

      return links;
    }, []);
};

function ContactsSection({ AppState }) {
  const [loaded, toggleLoaded] = useToggle(false);
  const [contacts, setContacts] = useState({});
  const contactsFallbackAfterPreview = useRef(false);

  const asyncLoader = async () => {
    const response = await StaticPagesService.getContacts();

    toggleLoaded();
    setContacts(response);

    AppState.setContactInfo(response);
  };

  useEffect(() => {
    asyncLoader();
  }, []);

  useEffect(() => {
    if (AppState.contactsPreview !== false) {
      contactsFallbackAfterPreview.current = contacts;
      setContacts(AppState.contactsPreview);
    } else if (
      AppState.contactsPreview === false &&
      contactsFallbackAfterPreview.current !== false
    ) {
      setContacts(contactsFallbackAfterPreview.current);
    }
  }, [AppState.contactsPreview]);

  if (!loaded) {
    return null;
  }

  const { phone_number, email, address_line_1, address_line_2 } = contacts;

  if (AppState.embeddableApp) {
    return (
      <>
        <Col lg={{ span: 5, offset: 1 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">Phone</div>
          <div className="footer-col-content">
            {renderPhoneLinks(phone_number)}
          </div>
        </Col>
        <Col lg={{ span: 5, offset: 1 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">Email</div>
          <div className="footer-col-content">
            {" "}
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </Col>
        <Col lg={{ span: 5, offset: 1 }} md={12} xs={12} className="footer-col">
          <div className="footer-col-header">Address</div>
          <div className="footer-col-content">
            <address style={{ marginTop: 0 }}>
              {address_line_1}
              {address_line_2 && (
                <>
                  <br />
                  {address_line_2}
                </>
              )}
            </address>
          </div>
        </Col>
      </>
    );
  }

  return (
    <Col lg={{ span: 3 }} md={12} xs={12} className="footer-col">
      <div className="footer-col-header">Contacts</div>
      <div className="footer-col-content">
        <ul>
          <li>{renderPhoneLinks(phone_number)}</li>
          <li>
            <a href={`mailto:${email}`}>{email}</a>
          </li>
          <li>
            <address>
              {address_line_1}
              {address_line_2 && (
                <>
                  <br />
                  {address_line_2}
                </>
              )}
            </address>
          </li>
        </ul>
      </div>
    </Col>
  );
}

export default inject("AppState")(observer(ContactsSection));
