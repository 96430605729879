/* eslint-disable no-unused-vars */
import { RECAPTCHA_PERSISTENT_KEY } from "../config/constants";

export const keysToRestoreAfterClear = [RECAPTCHA_PERSISTENT_KEY];

const fetchKeysValuesBeforeClear = () => {
  const values = {};
  keysToRestoreAfterClear.forEach(key => {
    values[key] = localStorage.getItem(key);
  });

  return values;
};

const restoreValues = (values = {}) => {
  Object.entries(values).forEach(entry => {
    const [key, value] = entry;
    localStorage.setItem(key, value);
  });
};

export const addKeyToPersist = key => {
  keysToRestoreAfterClear.push(key);
};

export const removeKeyFromPersistent = key => {
  const indexToDelete = keysToRestoreAfterClear.findIndex(
    currentKey => currentKey === key
  );

  keysToRestoreAfterClear.splice(indexToDelete, 1);
};

export const clearStorage = () => {
  const restorableValues = fetchKeysValuesBeforeClear();
  localStorage.clear();
  restoreValues(restorableValues);
};
