import { throttle } from "throttle-debounce";
import message from "antd/lib/message";

/**
 * this function show message pop up in throttled mode to prevent multiple showing of the message
 * @param textMessage
 * @param type {('info' | 'success' | 'error' | 'warning' | 'loading')}
 * @param delay {number} milliseconds of the delay
 */
const throttledMessage = (textMessage, type = "success", delay = 5000) => {
  return throttle(delay, true, () => {
    message[type](textMessage);
  });
};

export default throttledMessage;
