/* eslint-disable import/no-cycle */
import { action, computed, observable } from "mobx";
import moment from "moment";
import { message } from "antd";
import BaseForm from "../base-form";
import AuthState from "../../auth";
import Shipment from "../../shipment";
import {
  ISSUE_OR_COMPLAINT_CATEGORIES,
  SEND_QUOTE_COMMENT_LENGTH,
  SHIPMENT_ACTIVE_STATUSES,
  SHIPMENT_PAST_STATUSES,
  ZEN_DESK_FIELDS
} from "../../../config/constants";
import ZenDeskService from "../../../services/api/zen-desk";
import {
  capitalize,
  dateFormatter,
  formatNameFromProfile,
  formatTimeRange,
  toSnakeCase
} from "../../../utils/format";

export class IssueForm extends BaseForm {
  static FORM_TYPE_ISSUE = "issue";

  static FORM_TYPE_COMPLAINT = "complaint";

  /**
   * @type {Shipment|null}
   */
  @observable shipment = null;

  @observable type = IssueForm.FORM_TYPE_ISSUE;

  @computed
  get shouldBeOpened() {
    return !!this.shipment && !!this.shipment.id;
  }

  @computed
  get commentRemainingChars() {
    const currentText = this.$("comment").value || "";

    if (currentText.length > SEND_QUOTE_COMMENT_LENGTH) {
      return 0;
    }

    return SEND_QUOTE_COMMENT_LENGTH - currentText.length;
  }

  @computed
  get isComplaint() {
    return this.type === IssueForm.FORM_TYPE_COMPLAINT;
  }

  @computed
  get quote() {
    let quote = this.shipment.activeQuote;

    if (this.isComplaint) {
      quote = this.shipment.pastQuote;
    }

    return quote;
  }

  @computed
  get truckOwnerName() {
    const owner = AuthState.isTruckOwner ? AuthState.user : this.quote.owner;

    if (!owner || !owner.profile) {
      return "";
    }

    const { profile } = owner;

    if (profile.company_name) {
      return profile.company_name;
    }

    return formatNameFromProfile(owner.profile);
  }

  @computed
  get shipmentPickupDate() {
    return dateFormatter(
      this.quote.pickup_date || this.quote.pickup_date_time,
      "YYYY-MM-DD"
    );
  }

  @computed
  get quoteStatus() {
    return capitalize(this.quote.status.replace("_", " "));
  }

  @computed
  get shipmentPickupTime() {
    return formatTimeRange(this.shipment, true);
  }

  @action
  setType(formType) {
    this.type = formType;

    return this;
  }

  @action
  setShipment(shipment) {
    this.shipment = new Shipment(shipment);

    return this;
  }

  @action
  closeForm() {
    this.reset();
    this.shipment = null;
    this.type = IssueForm.FORM_TYPE_ISSUE;
  }

  hooks() {
    return {
      onSuccess: () => {
        const { comment, issue_date, issue_category } = this.values();

        return new Promise(async (resolve, reject) => {
          try {
            const user = await ZenDeskService.getUserOrCreate(AuthState.user);

            if (!user) {
              throw new Error("Can't create user.");
            }

            const status = {
              ...SHIPMENT_ACTIVE_STATUSES,
              ...SHIPMENT_PAST_STATUSES
            }[this.shipment.status].name;

            const payload = {
              subject: this.isComplaint
                ? `Shipment ${this.shipment.id} Complaint`
                : `Shipment ${this.shipment.id} Issue`,
              description: comment,
              priority: this.isComplaint ? "high" : "urgent",
              type: this.isComplaint ? "problem" : "incident",
              submitter_id: user.id,
              requester_id: user.id,
              custom_fields: [
                {
                  id: ZEN_DESK_FIELDS.ISSUE_OR_COMPLAINT_CATEGORY,
                  value: issue_category
                },
                {
                  id: ZEN_DESK_FIELDS.ISSUE_OR_INCIDENT_DATE,
                  value: dateFormatter(issue_date, "YYYY-MM-DD")
                },
                {
                  id: ZEN_DESK_FIELDS.SHIPMENT_ID,
                  value: this.shipment.id
                },
                {
                  id: ZEN_DESK_FIELDS.DATE_OF_COMPLAINT,
                  value: dateFormatter(moment(), "YYYY-MM-DD")
                },
                {
                  id: ZEN_DESK_FIELDS.SHIPMENT_STATUS,
                  value: status
                },
                {
                  id: ZEN_DESK_FIELDS.COMMODITY_TYPE,
                  value: this.shipment.commodityTypeName
                },
                {
                  id: ZEN_DESK_FIELDS.DESTINATION,
                  value: this.shipment.destination
                },
                {
                  id: ZEN_DESK_FIELDS.ORIGIN,
                  value: this.shipment.origin
                },
                {
                  id: ZEN_DESK_FIELDS.TRUCK_OWNER_NAME,
                  value: this.truckOwnerName
                },
                {
                  id: ZEN_DESK_FIELDS.SHIPMENT_PICKUP_TIME,
                  value: this.shipmentPickupDate
                },
                {
                  id: ZEN_DESK_FIELDS.QUOTE_PICKUP_DATE,
                  value: this.shipmentPickupDate
                },
                {
                  id: ZEN_DESK_FIELDS.SHIPMENT_PICKUP_TIME,
                  value: this.shipmentPickupTime
                },
                {
                  id: ZEN_DESK_FIELDS.SHIPPER_OR_AGENT,
                  value: this.shipment.ownerName
                },
                {
                  id: ZEN_DESK_FIELDS.QUOTE_STATUS,
                  value: this.quoteStatus
                }
              ],
              tags: [
                toSnakeCase(
                  this.isComplaint ? "Past Shipment" : "Active Shipment"
                )
              ]
            };

            await ZenDeskService.createThread({ ticket: payload });

            message.success(
              `The ${
                this.isComplaint ? "Complaint" : "Issue"
              } has been successfully reported.`
            );
            this.closeForm();
            resolve();
          } catch (e) {
            message.warning("Something went wrong, please try again later.");
            reject();
          }
        });
      }
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "issue_date",
          type: "date",
          rules: "required",
          label: "Issue or Incident Date"
        },
        {
          name: "issue_category",
          type: "select",
          options: ISSUE_OR_COMPLAINT_CATEGORIES,
          rules: "required",
          label: "Issue or Complaint Category"
        },
        {
          name: "comment",
          type: "textarea",
          rules: "required|max:500",
          label: "Comment"
        }
      ]
    };
  }
}

export default new IssueForm();
