/* eslint-disable no-useless-escape */
import { REGEX, SUPPORTED_FILE_TYPES } from "../../../config/constants";

const MAX_EMAIL_LENGTH = 254;
const MAX_DOMAIN_LENGTH = 63;

const validationTypeFile = `Image is in wrong format. Acceptable formats '${SUPPORTED_FILE_TYPES.map(
  type => type.replace("image/", "").replace("application/", "")
).join("', '")}'.`;

export default function signupValidator({ validator }) {
  const messages = validator.getMessages("en");
  const newMessages = {
    ...messages,
    required: "This field is required.",
    required_without: "This field is required.",
    required_if: "This field is required.",
    required_unless: "This field is required.",
    email: "Wrong email format.",
    alpha_num: "Invalid input.",
    between: "This field must be between :min and :max.",
    max: "This field cannot be longer than :max characters.",
    "regex.bank_account_details.nuban_bank_account_number":
      "Invalid NUBAN Bank Account Number.",
    "max.prequalification_checklist.number_of_trucks":
      "Maximum number of trucks is :max."
  };

  validator.setMessages("en", newMessages);
  validator.register(
    "strong_password",
    value => {
      return value.match(
        /^(?=.*[!@#$&*%^&()_+-=])(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/g
      );
    },
    "Password must be a minimum of 8 characters, must include letters, numbers and at least one special character."
  );
  validator.register(
    "confirm_password",
    value => {
      return value === this.$("signup.password").value;
    },
    "Passwords are not identical, re-enter correct password."
  );
  validator.register(
    "phone_number",
    value => {
      return value.match(REGEX.PHONE_NUMBER);
    },
    "Invalid input."
  );
  validator.register(
    "email_ext",
    value => {
      if (!/^[^@]+@{1}[^@]+$/.test(value)) {
        return false;
      }
      if (value.length > MAX_EMAIL_LENGTH) {
        return false;
      }

      const [username, domain] = value.split("@");
      const getMaxLength = domainValue => {
        const nameLengths = domainValue.split(".").map(name => name.length);
        return Math.max(...nameLengths);
      };

      if (/\.{1,}/.test(domain)) {
        const maxLength = getMaxLength(domain);
        if (maxLength > MAX_DOMAIN_LENGTH) {
          return false;
        }
      }

      if (
        // IPv4 domain format
        /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?)$/.test(
          domain
        )
      ) {
        return true;
      }
      if (
        // IPv6 domain format
        /^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])/.test(
          domain
        )
      ) {
        return false;
      }
      if (
        // domain format
        !/^(?!\-.*\..*$)(?!.*\-\..*$)(.*)$/.test(domain)
      ) {
        return false;
      }
      if (username.length > MAX_DOMAIN_LENGTH) {
        return false;
      }

      return value.match(
        /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i
      );
    },
    "Wrong email format."
  );
  validator.register(
    "alphanumeric_ext",
    value => {
      return value.match(/^[A-Za-z0-9 ]*$/);
    },
    "Invalid input."
  );
  validator.register(
    "all_docs_truck_accepted",
    value => {
      if (this.$("signup.company_type").value === "truck_owner") {
        return value === true;
      }
      return value === false;
    },
    "Confirm that all trucks have all documents."
  );

  validator.register(
    "file_type",
    (fileOrArrayOfFiles, mask) => {
      const validatorFunction = value => {
        if (value && value.uploaded) {
          return true;
        }
        if (!value || !value.type) {
          return false;
        }
        const supportedMimes = mask.split(",");
        return supportedMimes.includes(value.type);
      };

      if (Array.isArray(fileOrArrayOfFiles)) {
        const errors = fileOrArrayOfFiles
          .map(validatorFunction)
          .filter(Boolean);
        return errors.length === fileOrArrayOfFiles.length;
      }
      return validatorFunction(fileOrArrayOfFiles);
    },
    validationTypeFile
  );
  validator.register(
    "file_size",
    (fileOrArrayOfFiles, sizeInMb) => {
      const validatorFunction = file => {
        if (!file) {
          return false;
        }
        if (file.uploaded) {
          return true;
        }
        if (file instanceof File) {
          const bytesInMb = 2 ** 20;
          const limitInBytes = sizeInMb * bytesInMb;
          return file.size <= limitInBytes;
        }
        return false;
      };

      if (Array.isArray(fileOrArrayOfFiles)) {
        const errors = fileOrArrayOfFiles
          .map(validatorFunction)
          .filter(Boolean);
        return errors.length === fileOrArrayOfFiles.length;
      }
      return validatorFunction(fileOrArrayOfFiles);
    },
    "Maximum file size is :file_sizeMB."
  );

  validator.register(
    "name",
    v => {
      const regexToTestLettersAndDelimitersOnly = /^[a-zA-Z\s-]+$/gi;

      const hasInvalidPartsInFullName =
        v.split(/\s/).filter(part => /-$/.test(part)).length !== 0;

      return (
        !hasInvalidPartsInFullName &&
        regexToTestLettersAndDelimitersOnly.test(v)
      );
    },
    "Invalid input."
  );
}
