import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import StatusRenderer from "../../../../common/StatusRenderer";
import { SHIPMENT_ACTIVE_STATUSES } from "../../../../config/constants";
import { dateFormatter } from "../../../../utils/format";

const gutter = { lg: 10, sm: 10, xs: 15 };

const ChangeableStatusesColumns = ({ shipment, rowId }) => {
  const [width, setWidth] = useState();
  useEffect(() => {
    /**
     * @type {NodeList}
     */
    const closestRow = document.querySelectorAll(
      `[data-row-key='${rowId}'] td`
    );
    const currentColumnWidths = Array.from(closestRow).map(
      td => td.clientWidth
    );
    currentColumnWidths.splice(0, 1);
    currentColumnWidths.splice(-1, 1);

    const totalWidth = currentColumnWidths.reduce(
      (sum, tdWidth) => sum + tdWidth,
      0
    );
    setWidth(totalWidth);
  }, []);
  const { deliveryHistory } = shipment;
  if (deliveryHistory) {
    return (
      <Row
        style={{
          marginRight: 0,
          marginTop: 33,
          maxWidth: width,
          width: "100%"
        }}
        className="m-bottom-20"
        gutter={gutter}
      >
        {deliveryHistory.map((item, i) => {
          const isLastColumn = i === deliveryHistory.length - 1;
          let span = 4;
          switch (i) {
            case 0:
              span = 11;
              break;
            case 1:
              span = 9;
              break;
            default:
              span = 4;
          }
          const className = isLastColumn
            ? "status-col"
            : "destination-arrow start end status-col";

          let customColor = false;

          if (!shipment.isQuoteAndShipmentStateIsSameForStatus(item.status)) {
            customColor = "#CDD1D9";
          }

          return (
            <Col
              className={className}
              xs={{ span }}
              key={`status-column-${i}`}
              style={{
                paddingLeft: 0
              }}
            >
              <StatusRenderer
                currentStatus={item.status}
                statuses={SHIPMENT_ACTIVE_STATUSES}
                color={customColor}
              />
              <div style={{ marginLeft: 20 }}>
                {dateFormatter(
                  shipment.dateForLastStateUpdateOfStatus(item.status)
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }
  return null;
};

export default observer(ChangeableStatusesColumns);
