/* eslint-disable jsx-a11y/media-has-caption,react/no-danger */

import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "antd";
import { history } from "../../contexts/history";
import Fallback from "./fallback";
import HeroImgFallback from "../../styles/images/home-page.jpg";
import StaticPagesService from "../../services/api/static-pages";

import "./styles.less";
import { getAssetUrl } from "../../utils/url-utils";
import Block from "../../common/StaticPage/block";

const Home = inject("AppState")(
  observer(({ AppState, preloadedSchema }) => {
    const [schema, setSchema] = useState();
    const [hasError, setHasError] = useState(false);
    const loadSchema = async () => {
      try {
        const loadedSchema = await StaticPagesService.getHomeStaticPage();

        setSchema(loadedSchema);
      } catch (e) {
        setHasError(true);
      }
    };

    useEffect(() => {
      if (preloadedSchema) {
        setSchema(preloadedSchema);

        return;
      }
      loadSchema();
    }, []);
    useEffect(() => {
      AppState.addLayoutClass("home");

      return () => AppState.removeLayoutClass("home");
    }, [AppState]);

    if (hasError) {
      return <Fallback />;
    }

    if (!schema) {
      return null;
    }

    const handleClick = () => {
      history.push("/get-quote");
    };

    const {
      hero: { title, description, image } = {},
      cards = [],
      get_quote,
      what_we_do
    } = schema;

    return (
      <>
        <Row
          className="main-img"
          type="flex"
          justify="start"
          style={{
            backgroundImage: `url(${getAssetUrl(
              image
            )}), url(${HeroImgFallback})`
          }}
        >
          <Col
            lg={{ span: 10, offset: 2 }}
            md={{ span: 18, offset: 2 }}
            sm={{ span: 18, offset: 2 }}
            xs={24}
          >
            <h1 className="h1-main">{title}</h1>
            <div
              className="p-main"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div>
              <Link
                className="link-primary btn-create-account"
                to="/registration"
              >
                Create Account
              </Link>
            </div>
          </Col>
        </Row>
        {cards.length > 0 && (
          <Row gutter={{ lg: 10, md: 35, xs: 10 }}>
            {cards.map((card, i) => (
              <Col
                key={i}
                lg={{ span: 6, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xs={{ span: 22, offset: 1 }}
              >
                <div
                  className="card-img"
                  style={{ backgroundImage: `url(${getAssetUrl(card.image)})` }}
                />
                <div className="section-title">{card.title}</div>
                <div>
                  <p
                    className="p-main"
                    dangerouslySetInnerHTML={{ __html: card.description }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
        <Row className="get-quote-bg" type="flex" justify="center">
          <Col lg={10} md={15} xs={24}>
            <Row gutter={10} className="m-xs-left-10 m-xs-right-10">
              <Col span={24}>
                <h1 className="h1-quote">{get_quote.title}</h1>
                <div
                  className="p-get-quote"
                  dangerouslySetInnerHTML={{ __html: get_quote.description }}
                />
                <div className="p-get-quote">
                  <Button
                    ghost
                    onClick={handleClick}
                    block
                    className="btn-get-quote"
                    size="large"
                  >
                    Get Quote
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          gutter={{ lg: 10, md: 34, xs: 10 }}
          className="m-lg-top-110 m-md-top-100 m-xs-top-100"
        >
          <Col lg={{ span: 14 }} md={24} xs={24}>
            <Block
              key="home-page-video"
              type="embed"
              fullWidth
              data={{
                service: "youtube",
                source: what_we_do.video_url,
                wrapToRow: false,
                noShadow: true
              }}
            />
          </Col>

          <Col lg={{ span: 9, offset: 1 }} md={24} xs={24}>
            <h1 className="h1-what-we-do">{what_we_do.title}</h1>
            <div
              className="p-main"
              dangerouslySetInnerHTML={{ __html: what_we_do.description }}
            />
          </Col>
        </Row>
      </>
    );
  })
);

export default Home;
