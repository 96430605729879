/* eslint-disable class-methods-use-this,import/no-cycle */
import { action, computed, observable } from "mobx";
import { message } from "antd";
import moment from "moment";
import collect from "collect.js";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import { history } from "../../../contexts/history";
import OptionsService from "../../../services/api/options";
import ShipmentsService from "../../../services/api/shipments";
import AuthStore from "../../auth";
import { checkErrors } from "../../../utils/check-errors";
import extendValidator from "./validator";
import {
  DEFAULT_DIAL_CODE,
  SHIPMENT_STRING_TO_REPLACE,
  SHIPMENT_SUCCESS_MESSAGE
} from "../../../config/constants";
import { mock } from "../../../data/shipment-request.mock";

const formatOptionsArrayFromSource = source => {
  return source.map(item => ({
    ...item,
    value: item.id ? item.id : item.value,
    label: item.name ? item.name : item.label
  }));
};

const fields = [
  "id",
  "origin_state_id",
  "origin_city_id",
  "origin_area_id",
  "origin_street_address",
  "origin_house_number",
  "destination_state_id",
  "destination_city_id",
  "destination_area_id",
  "destination_street_address",
  "destination_house_number",
  "commodity_type_id",
  "packaging_id",
  "weight",
  "length",
  "volume",
  "cargo_value",
  "pickup_date_range",
  "pickup_time_range",
  "pickup_time_end",
  "delivery_date",
  "shipper_contact_name",
  "dial_code",
  "contact_phone_number",
  "is_shared_consignment",
  "is_shipment_without_shipper",
  "shipper_id",
  "shipper_email"
];

const defaults = {
  is_shipment_without_shipper: false
};

const labels = {
  origin_state_id: "State",
  origin_city_id: "City",
  origin_area_id: "Area",
  origin_street_address: "Street Address",
  origin_house_number: "Building / House Number",
  destination_state_id: "State",
  destination_city_id: "City",
  destination_area_id: "Area",
  destination_street_address: "Street Address",
  destination_house_number: "Building / House Number",
  commodity_type_id: "Commodity Type",
  packaging_id: "Packaging",
  weight: "Total Commodity Weight, tons",
  length: "Total Commodity Length, feet",
  volume: "Total Commodity Volume, liters",
  cargo_value: "Cargo Value",
  pickup_date_range: "Pick Up Date Range",
  pickup_time_range: "Pick Up Time Range",
  shipper_contact_name: "Shipper’ s Representative Contact Name",
  contact_phone_number: "Contact Phone Number",
  shipper_id: "Select Shipper"
};

const placeholders = {
  origin_state_id: "Select State",
  origin_city_id: "Select City",
  origin_area_id: "Select Area",
  origin_street_address: "Street Name, Avenue, Boulevard etc",
  origin_house_number: "House Number, Apartment, Suite etc",
  destination_state_id: "Select State",
  destination_city_id: "Select City",
  destination_area_id: "Select Area",
  destination_street_address: "Street Name, Avenue, Boulevard etc",
  destination_house_number: "House Number, Apartment, Suite etc",
  commodity_type_id: "Select Commodity Type",
  packaging_id: "Select Packaging",
  delivery_date: "dd/mm/yyyy",
  shipper_id: "Type Company Name or Email",
  shipper_email: "Invite Shipper via Email"
};

const types = {
  origin_state_id: "select",
  origin_city_id: "select",
  origin_area_id: "select",
  origin_street_address: "text",
  origin_house_number: "text",
  destination_state_id: "select",
  destination_city_id: "select",
  destination_area_id: "select",
  destination_street_address: "text",
  destination_house_number: "text",
  commodity_type_id: "select",
  packaging_id: "select",
  weight: "text",
  length: "text",
  volume: "text",
  cargo_value: "integer",
  pickup_date_range: "date",
  pickup_time_range: "date",
  delivery_date: "date",
  shipper_contact_name: "text",
  dial_code: "select",
  contact_phone_number: "text",
  is_shared_consignment: "checkbox",
  is_shipment_without_shipper: "checkbox",
  shipper_id: "select"
};

const rules = {
  origin_state_id: "required",
  origin_city_id: "required",
  origin_area_id: "required",
  origin_street_address: "required|max:255",
  origin_house_number: "required|max:255",
  destination_state_id: "required",
  destination_city_id: "required",
  destination_area_id: "required",
  destination_street_address: "required|max:255",
  destination_house_number: "required|max:255",
  commodity_type_id: "required",
  packaging_id: "required",
  weight: "required|weight_rule",
  length: "required|length_rule",
  volume: "required|volume_rule",
  cargo_value: "required|cargo_value_rule",
  pickup_date_range: "required|date_range|pickup_dates_api", // |pickup_dates_api",
  pickup_time_range: "required|date_range|pickup_time_between_working_hours",
  delivery_date: "required|delivery_date_rule",
  shipper_contact_name: "required|regex_names|between:2,90",

  dial_code: "required",
  contact_phone_number: "required|string|between:8,10|phone_number",
  // shipper_id: "required",
  shipper_email: "email_ext"
};

const guards = {
  shipper_contact_name: "numbers|name",
  contact_phone_number: "letters|specials",
  weight: "letters|specials",
  length: "letters|specials",
  volume: "letters|specials",
  cargo_value: "letters|specials|starts_not_zero"
};

const updateFieldStateBasedOnUndefinedBasis = (
  field,
  fieldNameToMutate,
  baseRules = [],
  shouldBeCleared = false
) => {
  if (
    !field.state.form.isUndefinedBasis &&
    !field.state.form.isVolumeOrWeightBased
  ) {
    return;
  }

  const mutatedField = field.state.form.$(fieldNameToMutate);
  const rulesToMutate = baseRules;
  const fieldHasValue = Number.isInteger(Number.parseInt(field.value, 10));

  if (!fieldHasValue) {
    rulesToMutate.unshift("required");
  }

  mutatedField.set("rules", rulesToMutate.join("|"));
  mutatedField.set("disabled", fieldHasValue);

  if (shouldBeCleared) {
    mutatedField.set("value", null);
  }

  mutatedField.resetValidation();
};

const setRules = (field, resetValues = false) => {
  let weightRule = "";
  let lengthRule = "";
  let volumeRule = "";

  switch (true) {
    case field.state.form.isUndefinedBasis ||
      field.state.form.isVolumeOrWeightBased:
      weightRule = "required|weight_rule";
      lengthRule = "length_rule";
      volumeRule = "required|volume_rule";
      break;
    case field.state.form.isWeightBased:
      weightRule = "required|weight_rule";
      lengthRule = "length_rule";
      volumeRule = "volume_rule";
      break;
    case field.state.form.isWeightAndLengthBased:
      weightRule = "required|weight_rule";
      lengthRule = "required|length_rule";
      volumeRule = "volume_rule";
      break;
    case field.state.form.isVolumeBased:
      weightRule = "weight_rule";
      lengthRule = "length_rule";
      volumeRule = "required|volume_rule";
      break;
    default:
      weightRule = "required|weight_rule";
      lengthRule = "length_rule";
      volumeRule = "required|volume_rule";
  }

  const updatedRules = {
    weight: weightRule,
    volume: volumeRule,
    length: lengthRule
  };

  Object.entries(updatedRules).forEach(entry => {
    const [fieldName, rulesToUpdate] = entry;
    const fieldToUpdate = field.container().$(fieldName);

    fieldToUpdate.set("rules", rulesToUpdate);
    fieldToUpdate.set("disabled", false);
    if (resetValues) {
      fieldToUpdate.set("value", null);
    }
  });
};

const validateSpecifications = field => {
  const fieldsToValidate = ["weight", "length", "volume"];

  fieldsToValidate.forEach(fieldName => {
    field
      .container()
      .$(fieldName)
      .validate();
  });
};

const pluckValidationTypes = truckTypes => {
  if (truckTypes.length === 0) {
    return {
      isVolumeRequiredForAtLeastOne: true,
      isWeightRequiredForAtLeastOne: true,
      isLengthRequiredForAtLeastOne: true
    };
  }

  const calculationTypes = collect(truckTypes).pluck("calculation_type");

  const isVolumeRequiredForAtLeastOne =
    calculationTypes
      .pluck("is_required_volume")
      .filter(Boolean)
      .all().length > 0;

  const isWeightRequiredForAtLeastOne =
    calculationTypes
      .pluck("is_required_weight")
      .filter(Boolean)
      .all().length > 0;

  const isLengthRequiredForAtLeastOne =
    calculationTypes
      .pluck("is_required_length")
      .filter(Boolean)
      .all().length > 0;

  return {
    isVolumeRequiredForAtLeastOne,
    isWeightRequiredForAtLeastOne,
    isLengthRequiredForAtLeastOne
  };
};

const hooks = {
  origin_state_id: {
    onChange: async field => {
      const cityField = field.container().$("origin_city_id");
      const areaField = field.container().$("origin_area_id");

      cityField.loading = true;
      areaField.loading = true;

      const cities = await OptionsService.getCities(field.value);

      cityField.set("options", formatOptionsArrayFromSource(cities));
      areaField.set("options", []);
      cityField.set("value", null);
      areaField.set("value", null);

      cityField.loading = false;
      areaField.loading = false;
    }
  },

  origin_city_id: {
    onChange: async field => {
      const areaField = field.container().$("origin_area_id");

      areaField.loading = true;

      const areas = await OptionsService.getAreas(field.value);

      areaField.set("options", formatOptionsArrayFromSource(areas));
      areaField.set("value", null);

      areaField.loading = false;
    }
  },

  destination_state_id: {
    onChange: async field => {
      const cityField = field.container().$("destination_city_id");
      const areaField = field.container().$("destination_area_id");

      cityField.loading = true;
      areaField.loading = true;

      const cities = await OptionsService.getCities(field.value);

      cityField.set("options", formatOptionsArrayFromSource(cities));
      areaField.set("options", []);
      cityField.set("value", null);
      areaField.set("value", null);

      cityField.loading = false;
      areaField.loading = false;
    }
  },

  destination_city_id: {
    onChange: async field => {
      const areaField = field.container().$("destination_area_id");

      areaField.loading = true;

      const areas = await OptionsService.getAreas(field.value);

      areaField.set("options", formatOptionsArrayFromSource(areas));
      areaField.set("value", null);

      areaField.loading = false;
    }
  },

  commodity_type_id: {
    onChange: async field => {
      let truckTypes = [];

      if (field.value) {
        truckTypes = await OptionsService.getTruckTypesByCommodity(field.value);
      }

      field.state.form.truckTypeSpec = pluckValidationTypes(truckTypes);
      setRules(field, true);
    }
  },

  dial_code: {
    onChange: field => {
      field.set("extra", { manuallyChanged: true });
    }
  },

  is_shipment_without_shipper: {
    onChange: async field => {
      field.validate();
      if (AuthStore.isAgent) {
        const shipperField = field.container().$("shipper_id");

        shipperField.resetValidation();
        shipperField.set("value", null);
        shipperField.set("disabled", field.value);
        shipperField.set("rules", field.value ? "" : "required");
      }
    }
  },

  is_shared_consignment: {
    onChange: async field => {
      validateSpecifications(field);
      field.validate();
    }
  },
  weight: {
    onChange: field => {
      updateFieldStateBasedOnUndefinedBasis(
        field,
        "volume",
        ["volume_rule"],
        true
      );
    }
  },
  volume: {
    onChange: field => {
      updateFieldStateBasedOnUndefinedBasis(
        field,
        "weight",
        ["weight_rule"],
        true
      );
    }
  }
};

const input = {
  delivery_date: v => (v ? moment(v, "DD/MM/YYYY") : null),
  weight: v => v || undefined,
  length: v => v || undefined,
  volume: v => v || undefined,
  dial_code: v => v || undefined,
  cargo_value: v => v || undefined
};

const output = {
  delivery_date: v => v && moment(v).format("YYYY-MM-DD"),
  weight: v => v && parseInt(v, 10),
  length: v => v && parseInt(v, 10),
  volume: v => v && parseInt(v, 10),
  cargo_value: v => v && parseInt(v, 10)
};

const options = {
  origin_state_id: [],
  origin_city_id: [],
  origin_area_id: [],
  destination_state_id: [],
  destination_city_id: [],
  destination_area_id: [],
  commodity_type_id: [],
  packaging_id: [],
  shipper_id: []
};

const baseCalculationType = {
  isVolumeRequiredForAtLeastOne: false,
  isWeightRequiredForAtLeastOne: true,
  isLengthRequiredForAtLeastOne: false
};

class PostShipmentForm extends BaseForm {
  @observable truckTypeSpec = baseCalculationType;

  @observable mode = "add";

  @observable loading = false;

  @computed
  get isUndefinedBasis() {
    const {
      isLengthRequiredForAtLeastOne,
      isWeightRequiredForAtLeastOne,
      isVolumeRequiredForAtLeastOne
    } = this.truckTypeSpec;

    return (
      isWeightRequiredForAtLeastOne &&
      isLengthRequiredForAtLeastOne &&
      isVolumeRequiredForAtLeastOne
    );
  }

  @computed
  get isVolumeOrWeightBased() {
    return this.isWeightBased && this.isVolumeBased;
  }

  @computed
  get isVolumeBased() {
    return (
      this.isUndefinedBasis || this.truckTypeSpec.isVolumeRequiredForAtLeastOne
    );
  }

  @computed
  get isWeightAndLengthBased() {
    return (
      this.isUndefinedBasis ||
      (this.truckTypeSpec.isLengthRequiredForAtLeastOne &&
        this.truckTypeSpec.isWeightRequiredForAtLeastOne)
    );
  }

  @computed
  get isWeightBased() {
    return (
      this.isUndefinedBasis ||
      (this.truckTypeSpec.isWeightRequiredForAtLeastOne &&
        !this.truckTypeSpec.isLengthRequiredForAtLeastOne)
    );
  }

  @action
  resetForm() {
    this.reset();

    const fieldsToReset = ["weight", "length", "volume", "cargo_value"];

    fieldsToReset.forEach(fieldName => {
      this.$(fieldName).value = "";
    });

    this.truckTypeSpec = baseCalculationType;
    this.$("weight").set("rules", "required|weight_rule");
    this.$("length").set("rules", "length_rule");
    this.$("volume").set("rules", "required|volume_rule");

    this.$("dial_code").value = DEFAULT_DIAL_CODE;
    if (AuthStore.isAgent) {
      // this.$("shipper_id").set("rules", "required");
      this.$("shipper_contact_name").set("disabled", false);
      this.$("shipper_contact_name").set(
        "rules",
        "required|regex_names|between:2,90"
      );
      this.$("shipper_id").set("options", null);
    } else if (AuthStore.isShipper) {
      // this.$("shipper_id").set("rules", "");
    }

    this.mode = "add";
    this.set({});

    this.fields.forEach(field => field.set("disabled", false));

    history.push("/my-shipments/pending");
  }

  /**
   * set up the validator plugin
   * @returns {{dvr: *}}
   */
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: extendValidator.bind(this)
      })
    };
  }

  prepareValidator() {
    if (AuthStore.isAgent) {
      let shipperFieldRule = "required";

      if (
        ["edit", "repost"].includes(this.mode) &&
        this.loadedValues.id &&
        !this.loadedValues.collaboration
      ) {
        shipperFieldRule = "";
      }

      this.$("shipper_id").set("rules", shipperFieldRule);
    } else {
      this.$("shipper_id").set("rules", "");
    }
  }

  @action
  async loadOptions() {
    this.loadStatesOptions();
    this.loadCommodityTypesOptions();
    await this.loadPackagingOptions();

    mock.call(this);
    // todo
    // ! if this is edit page (id is present) then do not load all cities\areas options
    // ! they will be loaded by ids from initialization hook while developing edit story
    // ! in case of empty form - load all options at first
    if (!this.loadedValues.id) {
      this.loadCitiesOptions();
      this.loadAreasOptions();
    }
  }

  async setFieldsForLoadedShipment() {
    const shipment = this.loadedValues;

    if (!shipment.id) {
      return;
    }

    const { weight, volume } = shipment;

    this.truckTypeSpec.isWeightRequiredForAtLeastOne = !!weight;
    this.truckTypeSpec.isVolumeRequiredForAtLeastOne = !!volume;

    OptionsService.getTruckTypesByCommodity(
      this.loadedValues.commodity_type_id
    ).then(truckTypes => {
      this.truckTypeSpec = pluckValidationTypes(truckTypes);
      setRules(this.$("commodity_type_id"));
      if (weight) {
        this.$("volume").set("rules", "volume_rule");
      }

      if (volume) {
        this.$("weight").set("rules", "weight_rule");
      }
    });

    this.$("pickup_date_range").set("value", [
      moment(shipment.pickup_date_start, "YYYY-MM-DD"),
      moment(shipment.pickup_date_end, "YYYY-MM-DD")
    ]);

    const timeRange = [
      moment.utc(shipment.pickup_time_start),
      moment.utc(shipment.pickup_time_end)
    ];

    this.$("pickup_time_range").set("value", timeRange);

    const [dial_code, phone_number] = shipment.contact_phone_number.split("-");

    this.$("dial_code").set("value", dial_code);
    this.$("contact_phone_number").set("value", phone_number);

    if (AuthStore.isAgent) {
      if (shipment.shipper) {
        const {
          id,
          email,
          profile: { first_name, last_name }
        } = shipment.shipper;
        const shipperSavedValue = `${first_name} ${last_name}, ${email}`;

        if (this.mode === "edit") {
          this.$("shipper_id").set("value", shipperSavedValue);
        } else if (this.mode === "repost") {
          const preloadedOptions = [
            {
              value: id,
              name: `${first_name} ${last_name}`,
              email
            }
          ];

          this.$("shipper_id").set("options", preloadedOptions);
        }
      } else if (shipment.shipper_email) {
        this.$("shipper_id").set("value", shipment.shipper_email);
      }

      if (!shipment.shipper_id && !shipment.shipper_email) {
        this.$("is_shipment_without_shipper").set("value", true);
      }

      if (!this.isNew && this.mode !== "repost") {
        // this.$("origin_state_id").set("disabled", true);
        this.$("shipper_contact_name").set("disabled", true);
        this.$("is_shipment_without_shipper").set("disabled", true);
        this.$("is_shipment_without_shipper").set("rules", "");
        this.$("shipper_id").set("disabled", true);
        this.$("shipper_id").set(
          "rules",
          this.$("is_shipment_without_shipper").value ? "" : "required"
        );
        this.$("shipper_id").resetValidation();
        this.$("is_shipment_without_shipper").resetValidation();
      }
    }
  }

  async load(id) {
    if (id) {
      await ShipmentsService.load(id)
        .then(shipment => {
          this.set(shipment);
        })
        .catch(() => {
          message.warning("Unable to load shipment.");
        });
      await this.loadGeoOptions();
    }
  }

  async optionsLoader(fieldsToUpdate = [], loaderFunction, id) {
    fieldsToUpdate.forEach(fieldName => {
      if (!this.has(fieldName)) {
        return;
      }

      this.$(fieldName).loading = true;
    });

    const rawOptions = await loaderFunction(id);
    const formattedOptions = formatOptionsArrayFromSource(rawOptions);

    fieldsToUpdate.forEach(fieldName => {
      if (!this.has(fieldName)) {
        return;
      }

      const field = this.$(fieldName);

      field.set("options", formattedOptions);
      field.loading = false;
    });
  }

  async loadStatesOptions() {
    await this.optionsLoader(
      ["origin_state_id", "destination_state_id"],
      OptionsService.getStates
    );
  }

  async loadPackagingOptions() {
    await this.optionsLoader(["packaging_id"], OptionsService.getPackaging);
  }

  async loadCitiesOptions() {
    await this.optionsLoader(
      ["origin_city_id", "destination_city_id"],
      OptionsService.getCities
    );
  }

  async loadOriginCitiesOptions(stateId) {
    await this.optionsLoader(
      ["origin_city_id"],
      OptionsService.getCities,
      stateId
    );
  }

  async loadAreasOptions() {
    await this.optionsLoader(
      ["origin_area_id", "destination_area_id"],
      OptionsService.getAreas
    );
  }

  async loadCommodityTypesOptions() {
    await this.optionsLoader(
      ["commodity_type_id"],
      OptionsService.getCommodityTypes
    );
  }

  async loadShippersOptions() {
    await this.optionsLoader(["shipper_id"], OptionsService.getShippers);
  }

  @action
  async loadGeoOptions() {
    const {
      origin_state_id,
      destination_state_id,
      origin_city_id,
      destination_city_id
    } = this.loadedValues;

    this.optionsLoader(
      ["origin_city_id"],
      OptionsService.getCities,
      origin_state_id
    );
    this.optionsLoader(
      ["destination_city_id"],
      OptionsService.getCities,
      destination_state_id
    );
    this.optionsLoader(
      ["origin_area_id"],
      OptionsService.getAreas,
      origin_city_id
    );
    this.optionsLoader(
      ["destination_area_id"],
      OptionsService.getAreas,
      destination_city_id
    );

    await this.setFieldsForLoadedShipment();
  }

  hooks() {
    return {
      onInit() {
        Object.entries(guards).forEach(entry => {
          const [field, guard] = entry;

          this.$(field).guard = guard;
        });
        this.$("dial_code").value = DEFAULT_DIAL_CODE;
      },

      onSuccess: () => {
        const {
          is_shipment_without_shipper,
          pickup_date_range,
          pickup_time_range,
          dial_code,
          contact_phone_number,
          shipper_id,
          shipper_email,
          ...rest
        } = this.values();
        const [pickup_date_start, pickup_date_end] = pickup_date_range.map(
          date => date.format("YYYY-MM-DD")
        );
        const [pickup_time_start, pickup_time_end] = pickup_time_range.map(
          time => time.format("HH:mm:ss")
        );

        const values = {
          ...rest,
          pickup_date_start,
          pickup_date_end,
          pickup_time_start,
          pickup_time_end,
          is_shipment_without_shipper,
          ...(!is_shipment_without_shipper
            ? { shipper_id, shipper_email }
            : {}),
          contact_phone_number: [dial_code, contact_phone_number].join("-")
        };

        if (values.shipper_email) {
          delete values.shipper_id;
        }

        return ShipmentsService.save(
          values,
          values.id || this.loadedValues.id,
          this.mode
        )
          .then(({ data }) => {
            let successMessage = "Post Shipment Successful";

            switch (this.mode) {
              case "edit":
                successMessage = "Changes saved successfully";
                break;
              default:
                if (AuthStore.isShipper) {
                  successMessage = SHIPMENT_SUCCESS_MESSAGE.SHIPPER.replace(
                    SHIPMENT_STRING_TO_REPLACE,
                    data.id && data.id
                  );
                } else if (AuthStore.isAgent) {
                  successMessage = SHIPMENT_SUCCESS_MESSAGE.AGENT.replace(
                    SHIPMENT_STRING_TO_REPLACE,
                    data.id && data.id
                  );
                }
            }

            setTimeout(() => {
              message.success(successMessage);
            }, 2000);
            this.resetForm();

            return data;
          })
          .catch(error => {
            console.dir(error);
            // handle validation errors
            if (error.response && error.response.status === 422) {
              const messages = error.response.data.errors;

              Object.entries(messages).forEach(entry => {
                const [dbFieldName, errorMessage] = entry;
                const formFieldName = dbFieldName
                  .replace("start", "range")
                  .replace("end", "range");

                this.$(formFieldName).invalidate(errorMessage);
              });
            } else {
              message.warning(error.message);
            }

            return Promise.reject();
          });
      },
      onError() {
        checkErrors(this.errors());
      },
      onReset() {
        this.resetForm();
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false,
      validateOnInit: false,
      validateDisabledFields: true
    };
  }
}

export default new PostShipmentForm({
  fields,
  labels,
  types,
  rules,
  guards,
  hooks,
  placeholders,
  input,
  output,
  options,
  defaults
});
