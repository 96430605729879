import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, message, Row, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import Field from "../../common/FieldContainer";
import FormContext from "../../models/forms/form-context";
import { history } from "../../contexts/history";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeClosed } from "../../icons/eye-closed.svg";
import { ReactComponent as EyeOpen } from "../../icons/eye-open.svg";
import ResetPasswordForm from "../../models/forms/auth/reset-password";

import "./LoginForm.less";

const gutter = { lg: 10, sm: 10, xs: 15 };

const ResetPassword = inject("AuthStore", "AppState")(
  observer(({ AuthStore, AppState, ...rest }) => {
    const [state, setState] = useState({
      newPasswordVisible: false,
      confirmPasswordVisible: false,
      isLoggedIn: false
    });

    useEffect(() => {
      AppState.addLayoutClass("login");

      return () => AppState.removeLayoutClass("login");
    }, [AppState]);

    useEffect(() => {
      const params = new URLSearchParams(rest.location.search);
      const token = params.get("token");
      const email = params.get("email");
      if (token && email) {
        AuthStore.setResetPasswordToken(token);
        AuthStore.setEmail(email);
      }
      if (AuthStore.isLoggedIn) {
        history.go();
      }
    }, [state.isLoggedIn, rest.location.search, AuthStore]);

    const setNewPasswordVisibility = () =>
      setState({ ...state, newPasswordVisible: !state.newPasswordVisible });

    const setConfirmPasswordVisibility = () =>
      setState({
        ...state,
        confirmPasswordVisible: !state.confirmPasswordVisible
      });

    const handleSubmit = e => {
      e.preventDefault();
      e.preventDefault();
      ResetPasswordForm.validate({ showErrors: true }).then(({ isValid }) => {
        if (!isValid) {
          return;
        }
        AuthStore.resetPassword(ResetPasswordForm.values()).catch(
          loginError => {
            const { response: { data } = {} } = loginError;
            if (data.message) {
              message.error(data.message);
            }
          }
        );
      });
    };

    return (
      <FormContext.Provider value={ResetPasswordForm}>
        <Row gutter={gutter} type="flex" justify="center">
          <Col lg={8}>
            <Card
              title="Change Password"
              bordered={false}
              className="login-form-container"
            >
              <Form hideRequiredMark onSubmit={handleSubmit}>
                <Row gutter={gutter}>
                  <Col span={24}>
                    <Field
                      name="password"
                      hasFeedback
                      label={
                        <span>
                          <LockIcon
                            style={{ color: "#A2A7B0" }}
                            className="anticon"
                          />
                          New Password
                        </span>
                      }
                      extra={
                        ResetPasswordForm.$("password").focused &&
                        ResetPasswordForm.$("password").extra
                      }
                    >
                      <Input
                        type={state.newPasswordVisible ? "text" : "password"}
                        suffix={
                          state.newPasswordVisible ? (
                            <Tooltip title="Hide Password">
                              <EyeOpen
                                style={{ color: "#A2A7B0" }}
                                onClick={setNewPasswordVisibility}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Show Password">
                              <EyeClosed
                                style={{ color: "#A2A7B0" }}
                                onClick={setNewPasswordVisibility}
                              />
                            </Tooltip>
                          )
                        }
                      />
                    </Field>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col span={24}>
                    <Field
                      name="password_confirmation"
                      hasFeedback
                      label={
                        <span>
                          <LockIcon
                            style={{ color: "#A2A7B0" }}
                            className="anticon"
                          />
                          Confirm Password
                        </span>
                      }
                    >
                      <Input
                        type={
                          state.confirmPasswordVisible ? "text" : "password"
                        }
                        suffix={
                          state.confirmPasswordVisible ? (
                            <Tooltip title="Hide Password">
                              <EyeOpen
                                style={{ color: "#A2A7B0" }}
                                onClick={setConfirmPasswordVisibility}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Show Password">
                              <EyeClosed
                                style={{ color: "#A2A7B0" }}
                                onClick={setConfirmPasswordVisibility}
                              />
                            </Tooltip>
                          )
                        }
                      />
                    </Field>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col span={24}>
                    <Button
                      size="large"
                      disabled={AuthStore.isSubmitResetPassword}
                      loading={AuthStore.isSubmitResetPassword}
                      block
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </FormContext.Provider>
    );
  })
);

ResetPassword.isAuthRoute = true;

export default ResetPassword;
