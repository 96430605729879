import { history } from "../../../../../contexts/history";
import AutoStore from "../../../../../models/auth";
import ShipmentCancelForm from "../../../../../models/forms/items-managers/shipments/cancel-pending-shipment";
import {
  SHIPMENT_PENDING_STATUS_CODES,
  STATUS_QUOTE_AVAILABLE_TO_CANCEL as statusShipmentNotAvailableToChange
} from "../../../../../config/constants";

const isShipmentOwnedByCurrentUser = row => {
  return row.owner_id === AutoStore.userId;
};
const isShipmentNotAvailableToChange = row =>
  !isShipmentOwnedByCurrentUser(row) || row.status === "canceled";

/**
 * @param shipment {Shipment}
 * @returns {*|boolean}
 */
const isShipmentNotAvailableToChangeDueToQuotes = shipment => {
  if (shipment.status === SHIPMENT_PENDING_STATUS_CODES.QUOTE_SELECTED) {
    return true;
  }
  if (shipment.isShared && shipment.child_shipments.length !== 0) {
    return (
      shipment.child_shipments.filter(childShipment => {
        return (
          childShipment.quotes.filter(
            v => statusShipmentNotAvailableToChange.indexOf(v.status) > -1
          ).length > 0
        );
      }).length > 0
    );
  }
  return (
    shipment.quotes &&
    shipment.quotes.filter(
      v => statusShipmentNotAvailableToChange.indexOf(v.status) > -1
    ).length > 0
  );
};

export const isShipmentNotEditable = shipment =>
  isShipmentNotAvailableToChange(shipment) ||
  isShipmentNotAvailableToChangeDueToQuotes(shipment);

const SHIPPER_PENDING_SHIPMENTS_ACTIONS = [
  {
    key: "edit_shipment",
    label: "Edit Shipment",
    visible: row => !isShipmentNotEditable(row),
    onClick: row => history.push(`/edit-shipment/${row.id}`)
  },
  {
    key: "cancel_shipment",
    label: "Cancel Shipment",
    disabled: row => !isShipmentOwnedByCurrentUser(row),
    visible: row => row.couldBeCancelled,
    onClick: row => {
      ShipmentCancelForm.initState({
        id: row && row.id
      });
    }
  }
];

export const SHIPPER_PENDING_CHILD_ACTIONS = [
  {
    key: "cancel_shipment",
    label: "Cancel Shipment",
    disabled: row => isShipmentNotAvailableToChange(row),
    onClick: row => {
      ShipmentCancelForm.initState({
        id: row && row.id
      });
    }
  }
];

export default SHIPPER_PENDING_SHIPMENTS_ACTIONS;
