/* eslint-disable class-methods-use-this,import/no-cycle */
import { action, observable, computed } from "mobx";
import { message, Modal } from "antd";
import validatorjs from "validatorjs";
import moment from "moment";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import { history } from "../../../contexts/history";
import ShipmentsService from "../../../services/api/shipments";
import { checkErrors } from "../../../utils/check-errors";
import Shipment from "../../shipment";
import TruckOwnerPendingShipmentsStore from "../items-managers/shipments/truck-owner-pending-shipments-manager";
import AuthStore from "../../auth";
import {
  formatNameFromProfile,
  formatPhoneNumber,
  dateFormatter,
  formatNumber
} from "../../../utils/format";

const fields = ["is_contract_accepted"];

const defaults = {
  is_contract_accepted: false
};

const types = {
  is_contract_accepted: "checkbox"
};

const rules = {
  is_contract_accepted: "required|accepted"
};

class ViewContractForm extends BaseForm {
  /**
   * @type {Shipment}
   */
  @observable shipment = new Shipment();

  @observable truckOwner;

  @observable mode;

  @action
  setShipment(id) {
    this.truckOwner = AuthStore.user;

    ShipmentsService.load(id)
      .then(shipment => {
        this.shipment = new Shipment(shipment);
        if (!AuthStore.isTruckOwner) {
          switch (true) {
            case this.shipment.isPast:
              this.truckOwner = this.shipment.pastQuote.owner;
              break;
            case this.shipment.isActive:
              this.truckOwner = this.shipment.activeQuote.owner;
              break;
            default:
          }
        }
      })
      .catch(() => {
        message.warning("Something went wrong, please try again later.");
        history.replace("/my-shipments");
      });
  }

  @action
  setMode(mode) {
    this.mode = mode;
  }

  @action
  resetForm() {
    this.reset();
    this.shipment = null;
    this.mode = null;

    history.push("/my-shipments/pending");
  }

  @action
  cancelContract(shipment) {
    const shipmentModel = new Shipment(shipment);

    Modal.confirm({
      icon: null,
      title: "Confirm that you want to cancel this Contract.",
      okButtonProps: {
        size: "large"
      },
      cancelButtonProps: {
        size: "large"
      },
      okText: "Confirm",
      width: 390,
      autoFocusButton: null,
      onOk: () => this.submitCancellation(shipmentModel),
      onCancel: () => {
        this.reset();
        history.replace("/my-shipments/pending");
      }
    });
  }

  /**
   * @param shipment {Shipment}
   * @returns {Promise<AxiosResponse<T>>}
   */
  @action
  submitCancellation(shipment) {
    return ShipmentsService.cancelContract(shipment.signedQuote)
      .then(() => {
        message.success("The Contract canceled successfully.");
        TruckOwnerPendingShipmentsStore.load();
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          const messages = error.response.data.errors;

          Object.entries(messages).forEach(entry => {
            const [, errorMessage] = entry;

            message.error(errorMessage);
          });
        } else {
          message.warning(error.message);
        }

        return Promise.reject();
      });
  }

  getQuoteForContract() {
    let quote =
      this.mode === "accept"
        ? this.shipment.quoteToSignAsTruckOwner
        : this.shipment.signedQuote;

    if (this.shipment.isPast) {
      quote = this.shipment.closedQuote;
    }

    if (this.shipment.isActive) {
      quote = this.shipment.activeQuote;
    }

    return quote;
  }

  @computed
  get isAcceptMode() {
    return this.mode === "accept";
  }

  @computed
  get shipmentId() {
    return this.shipment.id;
  }

  @computed
  get contractId() {
    if (this.shipment.hasAcceptedContractForTruckOwner) {
      return this.shipment.acceptedContractForTruckOwner.id;
    }

    if (!this.shipment.hasPendingContractForTruckOwner) {
      return null;
    }

    return this.shipment.pendingContractForTruckOwner.id;
  }

  @computed
  get agreementDate() {
    const quote = this.getQuoteForContract();
    let signDate = moment();

    if (quote && quote.selected_quote) {
      const {
        selected_quote: { updated_at }
      } = quote;

      signDate = moment(updated_at);
    }

    return `${signDate.date()} day of ${signDate.format(
      "MMMM"
    )} ${signDate.year()}`;
  }

  @computed
  get shipperName() {
    if (!this.shipment.isPaid) {
      return "[Shipper Company Name (for company) or First Name, Middle Name and Last Name (for individual)]";
    }

    if (!this.shipment.ownerUser) {
      return this.shipment.shipper_contact_name;
    }

    const { profile } = this.shipment.ownerUser;

    if (profile.company_name) {
      return profile.company_name;
    }

    return formatNameFromProfile(profile);
  }

  @computed
  get shipperRespectiveName() {
    if (!this.shipment.isPaid) {
      return "[Shipper’s Representative Contact Name]";
    }

    return this.shipment.shipper_contact_name;
  }

  @computed
  get shipperAddress() {
    if (!this.shipment.isPaid) {
      return "[Building/House Number, Street Address]";
    }

    if (this.shipment.ownerUser) {
      const { street_address, house_number } = this.shipment.ownerUser.profile;

      return `${street_address && street_address}, ${house_number &&
        house_number}`;
    }

    return null;
  }

  @computed
  get shipperCity() {
    if (!this.shipment.isPaid) {
      return "[City]";
    }

    if (this.shipment.ownerUser) {
      const { city_manual, city } = this.shipment.ownerUser.profile;

      return city_manual !== null ? city_manual : city.name;
    }

    return null;
  }

  @computed
  get shipperState() {
    if (!this.shipment.isPaid) {
      return "[State]";
    }

    if (this.shipment.ownerUser) {
      const { state_manual, state } = this.shipment.ownerUser.profile;

      return state_manual !== null ? state_manual : state.name;
    }

    return null;
  }

  @computed
  get shipperPhone() {
    if (!this.shipment.isPaid) {
      return "[Contact Phone Number]";
    }

    if (this.shipment.ownerUser) {
      const { phone_number } = this.shipment.ownerUser.profile;

      return formatPhoneNumber(phone_number);
    }

    return formatPhoneNumber(
      this.shipment.contact_phone_number && this.shipment.contact_phone_number
    );
  }

  @computed
  get shipperEmail() {
    if (!this.shipment.isPaid) {
      return "[Email]";
    }

    if (this.shipment.ownerUser) {
      const { email } = this.shipment.ownerUser;

      return email;
    }

    return this.shipment.shipper_email && this.shipment.shipper_email;
  }

  @computed
  get truckOwnerName() {
    if (!this.shipment.isPaid) {
      return "[Truck Owner Company Name]";
    }

    const { company_name } = this.truckOwner.profile;

    if (company_name) {
      return company_name;
    }

    return formatNameFromProfile(this.truckOwner.profile);
  }

  @computed
  get truckOwnerAddress() {
    if (!this.shipment.isPaid) {
      return "[Building/House Number, Street Address]";
    }

    const { street_address, house_number } = this.truckOwner.profile;

    return `${street_address}, ${house_number}`;
  }

  @computed
  get truckOwnerCity() {
    if (!this.shipment.isPaid) {
      return "[City]";
    }

    const { city_manual, city } = this.truckOwner.profile;

    return city_manual !== null ? city_manual : city.name;
  }

  @computed
  get truckOwnerState() {
    if (!this.shipment.isPaid) {
      return "[State]";
    }

    const { state_manual, state } = this.truckOwner.profile;

    return state_manual !== null ? state_manual : state.name;
  }

  @computed
  get truckOwnerPhone() {
    if (!this.shipment.isPaid) {
      return "[Phone]";
    }

    const { phone_number } = this.truckOwner.profile;

    return formatPhoneNumber(phone_number);
  }

  @computed
  get truckOwnerEmail() {
    if (!this.shipment.isPaid) {
      return "[Email]";
    }

    const { email } = this.truckOwner;

    return email;
  }

  @computed
  get originAddress() {
    const { origin_street_address, origin_house_number } = this.shipment;

    return `${origin_street_address}, ${origin_house_number}`;
  }

  @computed
  get originArea() {
    const { name } = this.shipment.origin_area;

    return name && name;
  }

  @computed
  get originCity() {
    const { name } = this.shipment.origin_city;

    return name && name;
  }

  @computed
  get originState() {
    const { name } = this.shipment.origin_state;

    return name && name;
  }

  @computed
  get commodityType() {
    const { name } = this.shipment.commodity_type;

    return name && name;
  }

  @computed
  get packaging() {
    const { name } = this.shipment.packaging;

    return name && name;
  }

  @computed
  get specifications() {
    const { weight, length, volume } = this.shipment;

    if (volume) {
      return `${volume} lt`;
    }

    if (length) {
      return `${weight} tones, ${length} ft`;
    }

    return `${weight} tones`;
  }

  @computed
  get destinationAddress() {
    const {
      destination_street_address,
      destination_house_number
    } = this.shipment;

    return `${destination_street_address}, ${destination_house_number}`;
  }

  @computed
  get destinationArea() {
    const { name } = this.shipment.destination_area;

    return name && name;
  }

  @computed
  get destinationCity() {
    const { name } = this.shipment.destination_city;

    return name && name;
  }

  @computed
  get destinationState() {
    const { name } = this.shipment.destination_state;

    return name && name;
  }

  @computed
  get contractValue() {
    const { value } = this.getQuoteForContract().contract;

    return value && formatNumber(value, true);
  }

  @computed
  get upfrontContractValue() {
    const { totalAmount } = this.getQuoteForContract().contract;

    return totalAmount && formatNumber(totalAmount, true);
  }

  @computed
  get pickupDate() {
    const { pickup_date_time } = this.getQuoteForContract();

    return pickup_date_time && dateFormatter(pickup_date_time, "DD/MM/YYYY");
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");

          validator.setMessages("en", {
            ...messages,
            accepted: "The terms of contract must be accepted."
          });
        }
      })
    };
  }

  hooks() {
    return {
      onSuccess: () => {
        // const { quotes } = this.shipment;
        // const quotesAvailable = quotes.filter(
        //   v => statusAvailableToAccept.indexOf(v.status) > -1
        // );
        return ShipmentsService.acceptContract(
          this.shipment.quoteToSignAsTruckOwner
        )
          .then(({ data }) => {
            setTimeout(() => {
              message.success("Contract is signed.");
            }, 2000);
            this.resetForm();

            return data;
          })
          .catch(error => {
            console.dir(error);
            // handle validation errors
            if (error.response && error.response.status === 422) {
              const messages = error.response.data.errors;

              Object.entries(messages).forEach(entry => {
                const [dbFieldName, errorMessage] = entry;

                this.$(dbFieldName).invalidate(errorMessage);
              });
            } else {
              message.warning(error.message);
            }

            return Promise.reject();
          });
      },
      onError() {
        checkErrors(this.errors());
      },
      onReset() {
        this.resetForm();
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }
}

export default new ViewContractForm({
  fields,
  types,
  rules,
  defaults
});
