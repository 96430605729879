import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cx from "classnames";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import useToggle from "react-use-toggle";
import Title from "antd/lib/typography/Title";
import { Redirect } from "react-router-dom";
import Helmet from "../Helmet";
import SchemaRenderer from "./schema-renderer";
import StaticPageErrorBoundary from "./error-boundary";
import { getAssetUrl } from "../../utils/url-utils";

import "./styles.less";

const gutter = { lg: 10, sm: 10, xs: 15 };

export default inject("AppState")(
  observer(
    ({
      AppState,
      fallback,
      loader,
      prefix = () => null,
      postfix = () => null,
      preloadedSchema
    }) => {
      useEffect(() => {
        AppState.addLayoutClass("info-layout");

        return () => AppState.removeLayoutClass("info-layout");
      }, [AppState]);
      const [schema, setSchema] = useState({});
      const [isLoaded, toggleLoaded] = useToggle();
      const [hasError, toggleHasError] = useToggle();
      const loadSchema = async () => {
        try {
          const response = await loader();

          setSchema(response);
          toggleLoaded();
        } catch (e) {
          toggleLoaded();
          toggleHasError();
        }
      };

      useEffect(() => {
        if (preloadedSchema) {
          setSchema(preloadedSchema);
          toggleLoaded();

          return;
        }
        loadSchema();
      }, [preloadedSchema]);

      if (!isLoaded) {
        return null;
      }

      if (hasError && typeof fallback === "function") {
        return fallback();
      }

      if (hasError) {
        return <Redirect to="/" />;
      }

      const { hero: { title, image } = {}, rich_text } = schema;

      const renderBanner = () => {
        if (!image) {
          return null;
        }

        return (
          <Row
            className="static-page-hero-img"
            style={{ backgroundImage: `url(${getAssetUrl(image)})` }}
            type="flex"
            justify="center"
          >
            {" "}
          </Row>
        );
      };

      const titleClasses = cx("page-header", {
        "text-black": !image,
        "text-white": image,
        "m-top-40 m-bottom-40": !image
      });

      return (
        <StaticPageErrorBoundary fallback={fallback}>
          <Helmet title={title} />
          {renderBanner()}
          <Row gutter={gutter} className="header">
            <Col lg={{ span: 14 }} md={{ span: 21 }} sm={{ span: 21 }}>
              <Title level={1} className={titleClasses}>
                {title}
              </Title>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
              {typeof prefix === "function" && (
                <Row gutter={gutter}>
                  <Col lg={{ span: 23 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    {prefix()}
                  </Col>
                </Row>
              )}
              <SchemaRenderer schema={rich_text} />
              {typeof postfix === "function" && (
                <Row gutter={gutter}>
                  <Col lg={{ span: 23 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    {postfix()}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </StaticPageErrorBoundary>
      );
    }
  )
);
