/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Button, Card, Icon, Input, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { inject, observer, Observer } from "mobx-react";
import cx from "classnames";

import Field from "../../common/FieldContainer";
import LoginForm from "../../models/forms/auth/login";
import FormContext from "../../models/forms/form-context";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as EyeClosed } from "../../icons/eye-closed.svg";
import { ReactComponent as EyeOpen } from "../../icons/eye-open.svg";
import "./LoginForm.less";

export default inject("AuthStore", "AppState")(
  observer(({ AuthStore, AppState }) => {
    useEffect(() => {
      AppState.addLayoutClass("login");

      return () => AppState.removeLayoutClass("login");
    }, [AppState]);
    const [state, setState] = useState({
      passwordVisible: false
    });

    const handleReapplyError = () => {
      const sendReapplyRequest = () => {
        AuthStore.requestReapply(LoginForm.$("email").value).then(() => {});
      };
      LoginForm.$("email").invalidate(
        <Observer>
          {() => (
            <span
              className={cx("animated", {
                "text-disabled": AuthStore.isRequestingReapply
              })}
            >
              Your registration request has been rejected. Please, reapply it
              using a{" "}
              <span
                className="on-hover-cursor-pointer text-underlined text-black"
                onClick={sendReapplyRequest}
              >
                &quot;Re-apply link&quot;
              </span>
              .
              {AuthStore.isRequestingReapply && (
                <Icon className="m-left-10" type="loading" spin />
              )}
            </span>
          )}
        </Observer>
      );
    };

    const setPasswordVisibility = () =>
      setState({ passwordVisible: !state.passwordVisible });

    const handleSubmit = e => {
      e.preventDefault();
      LoginForm.validate({ showErrors: true }).then(({ isValid }) => {
        if (isValid) {
          AuthStore.login(LoginForm.values())
            .then(() => {
              LoginForm.reset();
            })
            .catch(loginError => {
              const { response: { data, status } = {} } = loginError;
              const loginFailedDueToRejectedSignUp = status === 423;

              if (loginFailedDueToRejectedSignUp) {
                handleReapplyError();
                return;
              }

              const errors = data && data.errors;
              if (errors) {
                Object.entries(errors).forEach(entry => {
                  const [fieldName, errorMessages] = entry;
                  LoginForm.$(fieldName).invalidate(errorMessages.join(" "));
                });
              }
            });
        }
      });
    };

    return (
      <FormContext.Provider value={LoginForm}>
        <Card title="Login" className="login-form-container">
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              hasFeedback
              label={
                <span>
                  <Icon style={{ color: "#A2A7B0" }} type="user" />
                  Email Address
                </span>
              }
            >
              <Input />
            </Field>
            <Field
              name="password"
              hasFeedback
              label={
                <span>
                  <LockIcon style={{ color: "#A2A7B0" }} className="anticon" />
                  Password
                </span>
              }
            >
              <Input
                type={state.passwordVisible ? "text" : "password"}
                suffix={
                  state.passwordVisible ? (
                    <Tooltip title="Hide Password">
                      <EyeOpen
                        style={{ color: "#A2A7B0" }}
                        onClick={setPasswordVisibility}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show Password">
                      <EyeClosed
                        style={{ color: "#A2A7B0" }}
                        onClick={setPasswordVisibility}
                      />
                    </Tooltip>
                  )
                }
              />
            </Field>
            <div className="forgot-password-row">
              <Link className="font-bolder" to="/forgot/password">
                Forgot Password?
              </Link>
            </div>
            <Button
              size="large"
              disabled={AuthStore.isSubmitting}
              loading={AuthStore.isSubmitting}
              block
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
            <div className="register-row">
              Don't have an account? Please,{" "}
              <Link className="font-bolder" to="/registration">
                Register
              </Link>
            </div>
          </form>
        </Card>
      </FormContext.Provider>
    );
  })
);
