import React from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import { observer } from "mobx-react";
import { CAPTCHA_SITE_KEY } from "../../config/constants";

const CaptchaComponent = observer(({ onVerify = () => {} }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITE_KEY}>
      <GoogleReCaptcha onVerify={onVerify} />
    </GoogleReCaptchaProvider>
  );
});

export default CaptchaComponent;
