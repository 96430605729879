import { validateUrl } from "./validation";
import { ASSETS_BASE_URL } from "../config/constants";

export const getAssetUrl = url => {
  if (validateUrl(url)) {
    return url;
  }

  return `${ASSETS_BASE_URL}${url}`;
};
