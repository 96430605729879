import React from "react";
import { observer } from "mobx-react";
import { Input } from "antd";
import Modal from "../../../common/Modal";
import Field from "../../../common/FieldContainer";
import FormContext from "../../../models/forms/form-context";
import RemoveTruckForm from "../../../models/forms/truck/remove-truck";
import "../styles.less";

const { TextArea } = Input;
const RemoveTruckModal = observer(() => {
  return (
    <FormContext.Provider value={RemoveTruckForm}>
      <Modal
        width={390}
        okButtonProps={{
          style: { width: "100%" },
          disabled: RemoveTruckForm.submitting,
          loading: RemoveTruckForm.submitting
        }}
        cancelButtonProps={{
          style: { width: "100%" },
          disabled: RemoveTruckForm.submitting
        }}
        title="Remove Truck"
        okText="Confirm"
        closable={false}
        visible={RemoveTruckForm.shouldBeOpened}
        onCancel={() => {
          RemoveTruckForm.resetState();
        }}
        onOk={RemoveTruckForm.onSubmit}
      >
        <div className="p-top-20">
          Confirm that you want to remove this truck from the system.
        </div>
        <div className="font-bold m-top-20 m-bottom-20">
          {RemoveTruckForm.manufacturer}
        </div>
        <Field name="reason" className="m-bottom-20">
          <TextArea rows={4} />
        </Field>
      </Modal>
    </FormContext.Provider>
  );
});

export default RemoveTruckModal;
