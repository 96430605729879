/* eslint-disable class-methods-use-this,no-plusplus */
import { computed, observable } from "mobx";
import validatorjs from "validatorjs";
import collect from "collect.js";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import BaseForm from "../base-form";
import { formatNumber } from "../../../utils/format";

const MAX_NUMBER_OF_TRUCK = 25;
const MIN_NUMBER_OF_TRUCK = 1;

class QuoteTruckForm extends BaseForm {
  @observable
  lengthOptions = [];

  @observable
  weightOptions = [];

  @computed
  get selectedWeights() {
    return this.$("truck")
      .values()
      .map(({ weight }) => weight);
  }

  @computed
  get selectedLengths() {
    return this.$("truck")
      .values()
      .map(({ length }) => length);
  }

  @computed
  get selectedDimensions() {
    const { selectedLengths } = this;

    return this.selectedWeights.map((weight, i) => {
      return [weight, selectedLengths[i]];
    });
  }

  @computed
  get total() {
    return measure => {
      let total = 0;

      this.$("truck").each(field => {
        if (field.has(measure)) {
          total += +field.$(measure).value * +field.$("number_of_trucks").value;
        }
      });

      return total;
    };
  }

  @observable
  truckSpecification = {
    weight: null,
    length: null,
    volume: null
  };

  convertSpecificationToCollections(truckSpecification) {
    if (!truckSpecification) {
      return this.specification;
    }

    truckSpecification = collect(truckSpecification);
    const groupedWeights = truckSpecification
      .groupBy("weight")
      .map(group => group.map(item => item.length));

    const groupedLengths = truckSpecification
      .groupBy("length")
      .map(group => group.map(item => item.weight));

    const groupedVolumes = truckSpecification
      .groupBy("volume")
      .map(group => group.map(item => item.volume));

    this.specification = { groupedWeights, groupedLengths, groupedVolumes };

    return this.specification;
  }

  applySpecification(truckSpecification, fieldset, parentForm) {
    if (!this.parentForm) {
      this.parentForm = parentForm;
    }

    const selectedValues = collect(this.$("truck").values());
    const setOptions = (fieldName, arrayOfValues, format = false) => {
      fieldset.$(fieldName).set(
        "options",
        arrayOfValues.map(v => ({
          value: v,
          label: format ? formatNumber(v) : v
        }))
      );
    };

    const {
      groupedLengths,
      groupedWeights,
      groupedVolumes
    } = this.convertSpecificationToCollections(truckSpecification);

    if (this.parentForm.isWeightLengthBased) {
      const fieldSetKey = fieldset.container().fields.size - 1;
      const selectedWeight = selectedValues.get(fieldSetKey).weight;
      const selectedLength = selectedValues.get(fieldSetKey).length;

      if (![null, ""].includes(selectedLength)) {
        setOptions(
          "weight",
          groupedLengths.get(selectedLength).except(
            selectedValues
              .filter(item => item.length === selectedLength)
              .pluck("weight")
              .filter()
              .all()
          )
        );
      } else {
        const exceededValues = selectedValues
          .pluck("weight")
          .countBy()
          .filter((value, key) => {
            if (!groupedWeights.get(key)) {
              return true;
            }

            return groupedWeights.get(key).count() === value;
          })
          .keys()
          .filter()
          .all();

        setOptions("weight", groupedWeights.keys().except(exceededValues));
      }

      if (![null, ""].includes(selectedWeight)) {
        setOptions(
          "length",
          groupedWeights.get(selectedWeight).except(
            selectedValues
              .filter(item => item.weight === selectedWeight)
              .pluck("length")
              .filter()
              .all()
          )
        );
      } else {
        const exceededValues = selectedValues
          .pluck("length")
          .countBy()
          .filter((value, key) => {
            if (!groupedLengths.get(key)) {
              return true;
            }

            return groupedLengths.get(key).count() === value;
          })
          .keys()
          .filter()
          .all();

        setOptions("length", groupedLengths.keys().except(exceededValues));
      }

      setOptions(
        "length",
        selectedWeight
          ? groupedWeights.get(selectedWeight).except(
              selectedValues
                .filter(item => item.weight === selectedWeight)
                .pluck("length")
                .filter()
                .all()
            )
          : groupedLengths.keys()
      );
    } else if (this.parentForm.isWeightBased) {
      setOptions(
        "weight",
        groupedWeights.keys().except(
          selectedValues
            .pluck("weight")
            .filter()
            .all()
        )
      );
    } else {
      setOptions(
        "volume",
        groupedVolumes.keys().except(
          selectedValues
            .pluck("volume")
            .filter()
            .all()
        ),
        true
      );
    }

    const fieldsToUpdateRules = ["weight", "volume", "length"];

    fieldsToUpdateRules.forEach(fieldName => {
      let rule = "";

      switch (fieldName) {
        case "length":
          rule = this.parentForm.isWeightLengthBased ? "required" : "";
          break;
        case "volume":
          rule = this.parentForm.isVolumeBased ? "required" : "";
          break;
        case "weight":
          rule =
            this.parentForm.isWeightBased || this.parentForm.isWeightLengthBased
              ? "required"
              : "";
          break;
        default:
          rule = "";
      }

      fieldset.$(fieldName).set("rules", rule);
    });
  }

  resetFields() {
    for (let i = 0; i < 3; i++) {
      if (this.$("truck").has(`${i}`)) {
        this.$("truck").del(`${i}`);
      }
    }
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          validator.register(
            "quote_max_trucks",
            v => {
              return v && Number.isInteger(v) && v <= MAX_NUMBER_OF_TRUCK;
            },
            `Maximum number of trucks is ${MAX_NUMBER_OF_TRUCK}.`
          );
          validator.register(
            "quote_min_trucks",
            v => {
              return v && Number.isInteger(v) && v >= MIN_NUMBER_OF_TRUCK;
            },
            `Minimum number of trucks is ${MIN_NUMBER_OF_TRUCK}.`
          );
        }
      })
    };
  }
}

const fields = [
  "truck",
  "truck[].weight",
  "truck[].length",
  "truck[].volume",
  "truck[].number_of_trucks"
];

const labels = {
  "truck[].weight": "Truck Weight, tons",
  "truck[].length": "Truck Length, ft",
  "truck[].volume": "Truck Volume, lt",
  "truck[].number_of_trucks": "Number of trucks"
};
const rules = {
  "truck[].weight": "",
  "truck[].length": "",
  "truck[].volume": "",
  "truck[].number_of_trucks": "required|quote_min_trucks|quote_max_trucks"
};
const options = {
  "truck[].weight": [],
  "truck[].length": [],
  "truck[].volume": []
};
const types = {
  "truck[].weight": "select",
  "truck[].length": "select",
  "truck[].volume": "select",
  "truck[].number_of_trucks": "integer"
};

const hooks = {
  "truck[].weight": {
    onChange: field => {
      field.state.form.applySpecification(null, field.container());
    }
  },
  "truck[].length": {
    onChange: field => {
      field.state.form.applySpecification(null, field.container());
    }
  }
};

// const tpl = {
//   "truck[].weight": "",
//   "truck[].length": "",
//   "truck[].volume": "",
//   "truck[].number_of_trucks": ""
// };

const form = new QuoteTruckForm({
  fields,
  labels,
  rules,
  options,
  types,
  hooks
});

export default form;
