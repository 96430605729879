import axios from "axios";
import deepCleaner from "deep-cleaner";
import Shipment from "../../models/shipment";

const MAIN_ROUTE = "/shipments";

class ShipmentsService {
  /**
   * common loader function
   * @param resourcePath
   * @returns {function({userId?: *, size: *, [p: string]: *}): Promise<AxiosResponse<T>>}
   */
  loaderFunction = resourcePath => ({
    userId,
    size,
    sortField,
    sortType,
    ...rest
  }) =>
    axios
      .get(
        `${resourcePath}/${size}/${
          Number.isInteger(userId) ? userId : ""
        }${sortField}/${sortType}`,
        {
          params: deepCleaner(rest)
        }
      )
      .then(({ data }) => {
        data.data = data.data.map(shipment => {
          if (Array.isArray(shipment.children)) {
            shipment.child_shipments = shipment.children.map(childShipment => {
              return new Shipment({
                ...childShipment
              });
            });

            shipment.child_shipments.forEach(child => {
              child.parent = new Shipment(shipment);
            });

            delete shipment.children;
          }

          return new Shipment(shipment);
        });

        return data;
      });

  getAgentPendingShipments = this.loaderFunction(`${MAIN_ROUTE}/agent/pending`);

  getTuckOwnerPendingShipments = this.loaderFunction(
    `${MAIN_ROUTE}/truck-owner/pending`
  );

  getShipperPendingShipments = this.loaderFunction(
    `${MAIN_ROUTE}/shipper/pending`
  );

  getShipperActiveShipments = this.loaderFunction(
    `${MAIN_ROUTE}/shipper/active`
  );

  getAgentActiveShipments = this.loaderFunction(`${MAIN_ROUTE}/agent/active`);

  getTruckOwnerActiveShipments = this.loaderFunction(
    `${MAIN_ROUTE}/truck-owner/active`
  );

  getShipperPastShipments = this.loaderFunction(`${MAIN_ROUTE}/shipper/past`);

  getAgentPastShipments = this.loaderFunction(`${MAIN_ROUTE}/agent/past`);

  getTruckOwnerPastShipments = this.loaderFunction(
    `${MAIN_ROUTE}/truck-owner/past`
  );

  getAmountCancelShipment = id => {
    if (id) {
      return axios
        .get(`${MAIN_ROUTE}/${id}/cancel/pre-calculation`)
        .then(data => data);
    }

    return null;
  };

  load = id => {
    return axios.get(`${MAIN_ROUTE}/${id}`).then(({ data }) => data);
  };

  save = (values, shipmentId, mode) => {
    if (!shipmentId || mode === "repost") {
      return axios.post(MAIN_ROUTE, values);
    }

    if (mode === "edit") {
      return axios.put(`${MAIN_ROUTE}/${shipmentId}`, values);
    }

    return Promise.resolve();
  };

  delete = values => {
    if (values.reason_id) {
      return axios.put(`${MAIN_ROUTE}/${values.id}/cancel`, values);
    }

    return axios.put(`${MAIN_ROUTE}/${values.id}/cancel`);
  };

  validatePickUpDates = values => {
    return axios.post(`${MAIN_ROUTE}/validate/dates`, values);
  };

  confirmCollaboration = id => {
    return axios.post(`${MAIN_ROUTE}/${id}/collaboration/confirm`);
  };

  acceptContract = ({ id }) => {
    return axios.put(`${MAIN_ROUTE}/quotes/${id}/contract/accept`);
  };

  declineContract = values => {
    const { id, decline_reason_id, comment } = values;

    return axios.put(`${MAIN_ROUTE}/quotes/${id}/contract/decline`, {
      decline_reason_id,
      comment
    });
  };

  /**
   * @param values
   * @returns {Promise<AxiosResponse<T>>}
   */
  cancelContract = values => {
    const { id } = values;

    return axios.put(`${MAIN_ROUTE}/quotes/${id}/contract/cancel`);
  };

  loadDeliveryCode = shipmentId => {
    return axios
      .get(`/shipments/${shipmentId}/delivery-code`)
      .then(({ data }) => data);
  };

  confirmDelivery = (shipmentId, values) => {
    return axios.post(`/shipments/${shipmentId}/confirm-delivery`, values);
  };
}

export default new ShipmentsService();
