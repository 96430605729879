/* eslint-disable class-methods-use-this */
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import Field from "../field";
import BaseForm from "../base-form";
import { passwordAnalyst } from "../../../utils/password-analyst";

class ForgotPasswordForm extends BaseForm {
  setup() {
    return {
      fields: [
        {
          name: "password",
          label: "New Password",
          rules: "required|string|strong_password|between:8,25",
          hooks: {
            onChange: field => {
              passwordAnalyst(field);
            }
          }
        },
        {
          name: "password_confirmation",
          label: "Re-enter Password",
          rules: "required|string|same:password|between:8,25"
        }
      ]
    };
  }

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          const messages = validator.getMessages("en");
          const newMessages = {
            ...messages,
            "same.password_confirmation":
              "Passwords are not identical, re-enter correct password."
          };

          validator.setMessages("en", newMessages);
        }
      })
    };
  }

  makeField(props) {
    return new Field(props);
  }
}

export default new ForgotPasswordForm();
