import React, { useContext } from "react";
import { Input } from "antd";
import { observer } from "mobx-react";
import Field from "../../../common/FieldContainer";
import FormContext from "../../../models/forms/form-context";
import DialCodeSelector from "../../../common/DialCodeSelector";

const PhonesForm = observer(() => {
  const formState = useContext(FormContext);
  const { additionalPhoneNumbers } = formState;

  const handleAddNewAdditionalNumber = e => {
    e.preventDefault();
    formState.addAdditionalPhoneNumber();
  };

  return (
    <>
      <Field name="signup.phone_number" label="Phone Number">
        <Input
          size="large"
          addonBefore={
            <DialCodeSelector
              codes={formState.dialCodes}
              {...formState.$("signup.phone_number_dial_code").bind()}
            />
          }
          style={{ width: "100%" }}
        />
      </Field>
      {additionalPhoneNumbers.map((fieldName, i) => {
        return (
          <Field
            key={fieldName}
            name={`signup.${fieldName}`}
            label={
              <span>
                {i === 0 ? "First" : "Second"} Additional Phone Number{" "}
              </span>
            }
          >
            <Input
              size="large"
              addonBefore={
                <DialCodeSelector
                  codes={formState.dialCodes}
                  {...formState.$(`signup.${fieldName}_dial_code`).bind()}
                />
              }
              style={{ width: "100%" }}
            />
          </Field>
        );
      })}
      {formState.canAddNewAdditionalNumber && (
        <a
          href="/#"
          onClick={handleAddNewAdditionalNumber}
          className="link-common add-phone-link font-bolder"
          title="+ Add Another Phone Number"
        >
          + Add Another Phone Number
        </a>
      )}
    </>
  );
});

export default PhonesForm;
