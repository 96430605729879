/* eslint-disable class-methods-use-this */
import { action, computed, observable } from "mobx";
import validatorjs from "validatorjs";
import deepCleaner from "deep-cleaner";
import Field from "./field";
import { Form as EnhancedForm } from "../../libs/mobx-react-form";

import dvr from "../../libs/mobx-react-form/validators/DVR";
import throttledMessage from "../../utils/throttled-message";
import { WARNING_MANDATORY } from "../../config/constants";

class BaseForm extends EnhancedForm {
  @observable
  loadedValues = {};

  showMandatoryErrorMessage = throttledMessage(WARNING_MANDATORY, "warning");

  plugins() {
    return {
      dvr: dvr({
        package: validatorjs
      })
    };
  }

  makeField(props) {
    return new Field(props);
  }

  options() {
    return {
      validateOnReset: false,
      showErrorsOnReset: false,
      validateOnInit: false,
      showErrorsOnInit: false
    };
  }

  @action
  set(...args) {
    super.set(...args);
    const [values] = args;

    this.loadedValues = values;
  }

  @action
  async getInvalidFormIndex() {
    const forms = await Promise.all(
      this.forms.map(form => form.validate({ showErrors: true }))
    ).then(validatedForms => validatedForms);

    return forms.findIndex(form => {
      return Object.keys(deepCleaner(form.errors())).length !== 0;
    });
  }

  @computed
  get isNew() {
    return !this.loadedValues.id;
  }

  @computed
  get hasValues() {
    return deepCleaner(this.values());
  }
}

export default BaseForm;
