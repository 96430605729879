/* eslint-disable class-methods-use-this */
import { message } from "antd";
import BaseForm from "../base-form";
import {
  DEFAULT_DIAL_CODE,
  ZEN_DESK_CONTACT_AS_METHOD_FIELD_ID
} from "../../../config/constants";
import ZenDeskService from "../../../services/api/zen-desk";
import AuthStore from "../../auth";
import {
  capitalize,
  formatNameFromProfile,
  formatPhoneNumber
} from "../../../utils/format";

class ContactUsForm extends BaseForm {
  authStore = AuthStore;

  setup() {
    return {
      fields: [
        {
          name: "full_name",
          type: "text",
          label: "Full name",
          rules: "required|string|between:1,30|name",
          guard: "name|numbers"
        },
        {
          name: "email",
          type: "text",
          label: "E-mail",
          rules: "required|email_ext|string|max:255",
          guard: "spaces"
        },
        {
          name: "phone_number",
          type: "text",
          label: "Phone number",
          rules: "required|string|between:8,10|phone_number",
          guard: "letters|specials"
        },
        {
          name: "dial_code",
          type: "select",
          default: DEFAULT_DIAL_CODE
        },
        {
          name: "subject",
          type: "text",
          label: "Subject",
          rules: "required|string|between:1,255"
        },
        {
          name: "description",
          type: "text",
          label: "Message",
          placeholder: "Type your message here",
          rules: "required|max:1000"
        },
        {
          name: "preferred_method_of_communication",
          type: "radio",
          value: "email",
          rules: "required"
        }
      ]
    };
  }

  options() {
    return {
      showErrorsOnReset: false,
      validateOnReset: false
    };
  }

  hooks() {
    return {
      onSuccess: () => {
        const request = { ...this.values(), id: this.authStore.user.id };
        const { dial_code, phone_number } = request;

        request.phone = formatPhoneNumber(`${dial_code}-${phone_number}`);

        return new Promise(async (resolve, reject) => {
          try {
            const user = await ZenDeskService.getUserOrCreate(request);

            if (!user) {
              throw new Error("Can't create user.");
            }

            const payload = {
              ...request,
              type: "question",
              submitter_id: user.id,
              requester_id: user.id,
              custom_fields: [
                {
                  id: ZEN_DESK_CONTACT_AS_METHOD_FIELD_ID,
                  value: capitalize(request.preferred_method_of_communication)
                }
              ]
            };
            const ticket = await ZenDeskService.createThread({
              ticket: payload
            });

            if (!ticket) {
              throw new Error("Can't create ticket.");
            }

            message.success("Message Sent");
            this.resetForm();
            resolve();
          } catch (e) {
            reject(e);
          }
        }).catch(() => {
          message.warning("Something went wrong, please try again later.");
        });
      }
    };
  }

  toggleFieldsVisibility(visible = true) {
    const fields = ["full_name", "email", "phone_number"];

    fields.forEach(fieldName => {
      this.$(fieldName).hidden = !visible;
    });
  }

  initForm() {
    if (!this.authStore.user.id) {
      this.toggleFieldsVisibility(true);

      return;
    }

    const { user } = this.authStore;
    const [dial_code, phone_number] = user.profile.phone_number.split("-");

    this.toggleFieldsVisibility(false);
    this.set({
      full_name: formatNameFromProfile(user.profile),
      email: user.email,
      phone_number,
      dial_code
    });
  }

  resetForm() {
    if (!this.authStore.user.id) {
      this.reset();

      return;
    }

    this.$("subject").reset();
    this.$("description").reset();
  }
}

export default new ContactUsForm();
