import React from "react";
import Block from "./block";

export default function SchemaRenderer({ schema: { blocks } = {} }) {
  if (!blocks) {
    return null;
  }

  const reduced = blocks
    .reduce((curry, current, i, array) => {
      const nextBlock = array[i + 1];
      const prevBlock = array[i - 1];

      if (
        current.type === "embed" &&
        ((nextBlock && nextBlock.type === "embed") ||
          (prevBlock && prevBlock.type === "embed"))
      ) {
        const isLastItemArrayOfEmbed = Array.isArray(curry[curry.length - 1]);
        const group = isLastItemArrayOfEmbed
          ? [...curry[curry.length - 1], current]
          : [current];

        if (isLastItemArrayOfEmbed) {
          curry[curry.length - 1] = group;
        } else {
          curry.push(group);
        }
      } else {
        curry.push(current);
      }

      return curry;
    }, [])
    .map(item => {
      if (Array.isArray(item)) {
        return {
          type: "embed-list",
          data: {
            items: item
          }
        };
      }

      return item;
    });

  return reduced.map((block, i) => (
    <Block key={`${block.type}-${i}`} {...block} />
  ));
}
