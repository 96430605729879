import React from "react";
import { observer } from "mobx-react";
import Modal from "../../../common/Modal";
import FormContext from "../../../models/forms/form-context";
import UnlinkTruckForm from "../../../models/forms/driver/unlink-truck";
import "../styles.less";

const UnlinkTruckModal = observer(() => {
  return (
    <FormContext.Provider value={UnlinkTruckForm}>
      <Modal
        width={390}
        okButtonProps={{
          style: { width: "100%" },
          disabled: UnlinkTruckForm.submitting,
          loading: UnlinkTruckForm.submitting
        }}
        cancelButtonProps={{
          style: { width: "100%" },
          disabled: UnlinkTruckForm.submitting
        }}
        title="Unlink Truck"
        okText="Confirm"
        closable={false}
        visible={UnlinkTruckForm.shouldBeOpened}
        onCancel={() => {
          UnlinkTruckForm.resetState();
        }}
        onOk={UnlinkTruckForm.onSubmit}
      >
        <div className="p-top-20">
          Confirm that you want to unlink the Truck attached to this Driver.
        </div>
        <div className="font-bold m-top-20 m-bottom-20">
          Truck: {UnlinkTruckForm.linkedTruckInfo}
          <br />
          <br />
          Driver: {UnlinkTruckForm.driver}
        </div>
      </Modal>
    </FormContext.Provider>
  );
});

export default UnlinkTruckModal;
