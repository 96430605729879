import React from "react";
import MediaQuery from "react-responsive";

const SCREEN_PHONE = 480;
const SCREEN_TABLET = 768;
const SCREEN_DESKTOP = 992;

export const Desktop = ({ children }) => {
  return <MediaQuery minWidth={SCREEN_DESKTOP}>{children}</MediaQuery>;
};

export const Tablet = ({ children }) => {
  return (
    <MediaQuery minWidth={SCREEN_PHONE + 1} maxWidth={SCREEN_DESKTOP - 1}>
      {children}
    </MediaQuery>
  );
};

export const Mobile = ({ children }) => {
  return <MediaQuery maxWidth={SCREEN_PHONE}>{children}</MediaQuery>;
};

export const NotDesktop = ({ children }) => {
  return <MediaQuery maxWidth={SCREEN_DESKTOP - 1}>{children}</MediaQuery>;
};

export const NotMobile = ({ children }) => {
  return <MediaQuery minWidth={SCREEN_PHONE + 1}>{children}</MediaQuery>;
};

export const LessThanTablet = ({ children }) => {
  return <MediaQuery maxWidth={SCREEN_TABLET - 1}>{children}</MediaQuery>;
};

export const GreaterThanPhone = ({ children }) => {
  return <MediaQuery minWidth={SCREEN_TABLET}>{children}</MediaQuery>;
};

export default {
  Desktop,
  Tablet,
  Mobile,
  NotMobile,
  NotDesktop
};
