import axios from "axios";

class OptionsService {
  cache = new Map();

  getBankNames = () => {
    return axios
      .get("/accounts/vocabulary/bank-names")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getUserIdentificationTypes = () => {
    return axios
      .get("/accounts/vocabulary/user-identification-types")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckInsuranceTypes = () => {
    return axios
      .get("/trucks/vocabulary/insurance-types")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getCountries = () => {
    return axios
      .get("/geo-location/countries")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getStates = (countryId = null) => {
    if (!countryId && this.cache.has("states")) {
      return new Promise(resolve => resolve(this.cache.get("states")));
    }

    if (countryId) {
      return this.getStatesByCountry(countryId);
    }

    return axios
      .get("/geo-location/states")
      .then(({ data }) => {
        this.cache.set("states", data);

        return data;
      })
      .catch(() => []);
  };

  getCities = (stateId = null) => {
    if (this.cache.has("cities")) {
      let cities = this.cache.get("cities");

      if (stateId) {
        cities = cities.filter(({ state_id }) => {
          return state_id === stateId;
        });
      }

      return new Promise(resolve => resolve(cities));
    }

    if (stateId) {
      return this.getCitiesByState(stateId);
    }

    return axios
      .get("/geo-location/cities", {
        cached: true
      })
      .then(({ data }) => {
        this.cache.set("cities", data);

        return data;
      })
      .catch(() => []);
  };

  getAreas = (cityId = null) => {
    if (this.cache.has("areas")) {
      let areas = this.cache.get("areas");

      if (cityId) {
        areas = areas.filter(({ city_id }) => {
          return city_id === cityId;
        });
      }

      return new Promise(resolve => resolve(areas));
    }

    if (cityId) {
      return this.getAreasByCity(cityId);
    }

    return axios
      .get("/geo-location/areas")
      .then(({ data }) => {
        this.cache.set("areas", data);

        return data;
      })
      .catch(() => []);
  };

  getStatesByCountry = countryId => {
    return axios
      .get(`/geo-location/states/by-country/${countryId}`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getCitiesByState = stateId => {
    return axios
      .get(`/geo-location/cities/by-state/${stateId}`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getAreasByCity = stateId => {
    return axios
      .get(`/geo-location/areas/by-city/${stateId}`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckManufacturers = () => {
    return axios
      .get("/trucks/vocabulary/truck-manufacturers")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckModelsByManufacturer = id => {
    return axios
      .get(`/trucks/vocabulary/truck-manufacturers/${id}/truck-models`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckTypes = () => {
    return axios
      .get("/trucks/vocabulary/truck-types")
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckSpecByType = typeId => {
    return axios
      .get(`/trucks/vocabulary/truck-types/${typeId}/specifications`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getInspectors = () => {
    return axios.get("/accounts/vocabulary/inspectors").then(({ data }) => {
      return data.map(({ id, email, profile }) => ({
        value: id,
        label: profile ? `${profile.first_name} ${profile.last_name}` : email
      }));
    });
  };

  getTruckStatuses = () => {
    const url = "/trucks/vocabulary/statuses";

    return axios
      .get(url)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getCommodityTypes = () => {
    if (this.cache.has("commodity_types")) {
      return new Promise(resolve => resolve(this.cache.get("commodity_types")));
    }

    return axios
      .get("/trucks/vocabulary/commodity-types")
      .then(({ data }) => {
        this.cache.set("commodity_types", data);

        return data;
      })
      .catch(() => []);
  };

  getQuoteCalculationTypes = truckTypeId => {
    return axios
      .get(`/quotes/truck-type/${truckTypeId}/calculation-type`)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getTruckTypesByCommodity = commodityId => {
    return axios
      .get(`/trucks/vocabulary/commodity-types/${commodityId}/truck-types`)
      .then(({ data }) =>
        data.map(({ id, name, calculation_type }) => ({
          value: id,
          label: name,
          calculation_type
        }))
      )
      .catch(() => []);
  };

  getLicenseClasses = () => {
    const url = `/drivers/vocabulary/license-classes/`;

    return axios
      .get(url)
      .then(({ data }) => data)
      .catch(() => []);
  };

  getPackaging = () => {
    if (this.cache.has("packaging")) {
      return new Promise(resolve => resolve(this.cache.get("packaging")));
    }

    return axios
      .get("/shipments/vocabulary/packaging")
      .then(({ data }) => {
        this.cache.set("packaging", data);

        return data;
      })
      .catch(() => []);
  };

  getShippers = query => {
    if (!query) {
      return Promise.resolve();
    }

    return axios
      .get(`/accounts/shippers/${query}`)
      .then(({ data }) => {
        return data.map(shipper => {
          const {
            id,
            email,
            profile: {
              account_type,
              company_name,
              last_name,
              first_name,
              middle_name
            }
          } = shipper;

          let name = "";

          if (middle_name === null) {
            name = `${first_name} ${last_name}`;
          } else {
            name = `${first_name} ${middle_name} ${last_name}`;
          }

          if (account_type === "company") {
            name = company_name;
          }

          return {
            value: id,
            email,
            name
          };
        });
      })
      .catch(() => []);
  };

  getContractDeclineReasons = () => {
    return axios
      .get("/shipments/vocabulary/contract/decline-reasons")
      .then(({ data }) =>
        data.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      );
  };

  getShipmentCancelReasons = () => {
    const url = "/shipments/vocabulary/shipments/cancellation-reasons";

    return axios.get(url).then(({ data }) =>
      data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
    );
  };

  getTrackerModels = () => {
    return axios
      .get("/tracking-system/vocabulary/device-models")
      .then(({ data: { list } }) => list);
  };
}

export default new OptionsService();
