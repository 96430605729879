/* eslint-disable class-methods-use-this */
import React from "react";
import { action, observable, computed } from "mobx";
import deepCleaner from "deep-cleaner";
import { message, Modal, Checkbox } from "antd";
import Field from "../../../common/FieldContainer";
import SelectQuoteService from "../../../services/api/select-quote";
import BaseForm from "../base-form";
import FormContext from "../form-context";
import throttledMessage from "../../../utils/throttled-message";
import { formatNumber } from "../../../utils/format";
import { history } from "../../../contexts/history";

const quoteSelectedSuccessMessage =
  "The Quotes were successfully selected. Contract(s) are sent to the preferred Truck Owner(s).";

class SelectQuoteForm extends BaseForm {
  @observable
  isReadyToSubmit = false;

  shipmentId = null;

  showQuotesValidationMessage = throttledMessage(
    "Please, select at least one Quote.",
    "warning"
  );

  @observable quotesErrorsMap = new Map();

  gridState;

  @computed
  get readyToSubmit() {
    const errors = deepCleaner(this.errors());

    if (errors.quotes) {
      this.showQuotesValidationMessage();
      return false;
    }

    return (
      (Object.keys(errors).length === 1 && !!errors.accepted) ||
      Object.keys(errors).length === 0
    );
  }

  @computed
  get quoteError() {
    return quoteId => this.quotesErrorsMap.get(quoteId);
  }

  setGridState(gridState) {
    if (!this.gridState) {
      this.gridState = gridState;
    }
  }

  catchHandler(e) {
    const { response: { data: { errors = {} } = {} } = {} } = e;
    Object.entries(errors).forEach(entry => {
      const [fieldName, errorMessages] = entry;
      if (this.has(fieldName)) {
        this.$(fieldName).invalidate(errorMessages);
      } else if (fieldName.includes("quotes")) {
        const quoteIds = this.$("quotes").value;
        const [, index] = fieldName.split(".");
        this.quotesErrorsMap.set(quoteIds[index], errorMessages);
      } else {
        message.warning(errorMessages.join(" "));
      }
    });
  }

  setup() {
    return {
      fields: [
        {
          name: "insurance",
          label: "Goods In Transit Insurance",
          placeholder: "Select Transit Insurance",
          type: "select",
          options: [
            {
              value: "0",
              label: "Not required"
            },
            {
              value: 1,
              label: "Percentage for Commodity Type"
            }
          ],
          rules: "required"
        },
        {
          name: "quotes",
          rules: "required",
          type: "array"
        },
        {
          name: "accepted",
          type: "checkbox",
          rules: "required|accepted"
        },
        {
          name: "repost",
          type: "checkbox"
        }
      ]
    };
  }

  hooks() {
    return {
      onClear: () => {
        this.isReadyToSubmit = false;
        this.shipmentId = null;
        this.quotesErrorsMap.clear();
        this.$("insurance").dispose({
          key: "value",
          type: "observe"
        });
      },
      onSuccess: () => {
        const { shipmentId } = this;
        const values = this.values();
        if (values.repost) {
          return SelectQuoteService.repostSharedConsignment({
            shipmentId,
            ...this.values()
          })
            .then(({ data }) => {
              if (!data.reposted_shipment_id) {
                message.success(quoteSelectedSuccessMessage);
              } else {
                message.success(
                  `The Shipment ${data.reposted_shipment_id} reposted successfully. Message are sent to the preferred Truck Owner(s).`
                );
              }

              this.$("repost").reset();
              this.reset();
              history.push("/");
            })
            .catch(e => {
              console.dir(e);
              this.catchHandler(e);
            })
            .finally(() => {
              this.isReadyToSubmit = false;
            });
        }

        return SelectQuoteService.save({ shipmentId, ...this.values() })
          .then(() => {
            message.success(quoteSelectedSuccessMessage);
            history.push("/");
            this.reset();
          })
          .catch(e => {
            this.catchHandler(e);
          })
          .finally(() => {
            this.isReadyToSubmit = false;
          });
      },
      onError: () => {
        console.log(this.errors());
      }
    };
  }

  @action
  preSubmit() {
    this.validate({ showErrors: true }).then(() => {
      this.$("accepted").invalidate();
      this.$("repost").invalidate();

      const isReadyToSubmit = this.readyToSubmit;
      if (!isReadyToSubmit) {
        return;
      }

      if (this.gridState.isSharedConsignment) {
        this.processSharedConsignmentConfirmations();
      } else {
        this.isReadyToSubmit = isReadyToSubmit;
      }
    });
  }

  @action
  setQuoteError(quote, errors = []) {
    const errorMessage = Array.isArray(errors) ? errors.join(" ") : errors;
    this.quotesErrorsMap.set(quote.id, errorMessage);
  }

  setShipmentId(shipmentId) {
    this.shipmentId = shipmentId;
  }

  observeInsuranceChange(callback = () => {}) {
    this.$("insurance").observe(callback);
  }

  processSharedConsignmentConfirmations() {
    const { selectedWeightVolume, value } = this.gridState;
    const isValueLessThenSelected = selectedWeightVolume < value;
    const isValueGreaterThenSelected = selectedWeightVolume > value;
    const renderRepostShipment = () => {
      return (
        <>
          Total Weight/Volume of Quotes selected is{" "}
          {formatNumber(value - selectedWeightVolume)} less than Shipment
          Weight/Volume.
          <div className="m-lg-top-30">
            <FormContext.Provider value={this}>
              <Field name="repost">
                <Checkbox>Repost Shipment</Checkbox>
              </Field>
            </FormContext.Provider>
          </div>
        </>
      );
    };
    if (isValueLessThenSelected) {
      return Modal.confirm({
        content: renderRepostShipment(),
        icon: null,
        okType: "primary",
        okText: "Confirm",
        title: "Difference in Weight",
        autoFocusButton: null,
        okButtonProps: {
          size: "large"
        },
        cancelButtonProps: {
          size: "large"
        },
        onCancel: () => {
          this.$("repost").reset();
        },
        onOk: () => {
          this.isReadyToSubmit = true;
        }
      });
    }

    if (isValueGreaterThenSelected) {
      return Modal.confirm({
        title: "Difference in Weight",
        content: `Total Weight/Volume of Quotes selected is ${formatNumber(
          selectedWeightVolume - value
        )} greater than Shipment Weight/Volume. Please, confirm you want to proceed. The price will not be recalculated.`,
        icon: null,
        okText: "Confirm",
        autoFocusButton: null,
        okButtonProps: {
          size: "large"
        },
        cancelButtonProps: {
          size: "large"
        },
        onOk: () => {
          this.isReadyToSubmit = true;
        }
      });
    }

    this.isReadyToSubmit = true;

    return true;
  }
}

export default new SelectQuoteForm();
