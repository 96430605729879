import axios from "axios";

const Auth = {
  login: params =>
    axios
      .post("/auth/login", params)
      .then(({ data: { accessToken, token: { user } } }) => ({
        user,
        accessToken
      })),

  logout: token => axios.post("/auth/logout", token),

  authorizeByToken: () => axios.get("/auth/user").then(({ data }) => data),

  resetPassword: (action, values) =>
    // action -> "forgot" or "reset"
    axios.post(`/auth/${action}/password`, values),

  requestReapply: email => axios.post(`/auth/reapply/code`, { email }),

  loadRejectedAccount: code =>
    axios.post(`/auth/reapply`, { code }).then(({ data }) => data),

  showProfile: () => axios.get("/accounts/profile").then(({ data }) => data),

  editProfile: values =>
    axios.put(`/accounts/profile/update`, values).then(({ data }) => data),

  changePassword: values =>
    axios
      .post(`/accounts/profile/change-password`, values)
      .then(({ data }) => data)
};

export default Auth;
