import React, { useContext, useEffect, useState } from "react";
import { Table as BaseTable } from "antd";
import { observer } from "mobx-react";
import cx from "classnames";
import Helmet from "./Helmet";
import { ReactComponent as DownIcon } from "../icons/expand.svg";

import Context from "../contexts/filterable-table";

const getExpandIcon = extra => ({ expanded, record, onExpand }) => {
  const renderedExtra = typeof extra === "function" && extra(record);

  return (
    <div className="d-flex flex-align-center">
      <DownIcon
        className={cx("table-expand-icon", {
          "table-expand-icon-opened": expanded
        })}
        onClick={e => onExpand(record, e)}
        style={{ cursor: "pointer" }}
      />
      {renderedExtra}
    </div>
  );
};

function ItemsTable({
  title,
  columns,
  rowClassName,
  onChange,
  compact,
  expandedRowRender,
  expandIconExtraRenderer,
  withoutSelection = false,
  preventInitialLoading = false,
  hideExpandIcon = false,
  rowSelectionOverrides = {},
  ...rest
}) {
  const { gridStore } = useContext(Context);
  const [selectionProps, setSelectionProps] = useState({
    onChange: gridStore.setSelectedRowKeys.bind(gridStore),
    ...rowSelectionOverrides
  });

  const getKey = record => {
    if (typeof rest.rowKey === "function") {
      return rest.rowKey(record);
    }

    return record[rest.rowKey || "id"];
  };

  const updateSelectionProps = () => {
    setSelectionProps({
      ...selectionProps,
      getCheckboxProps: row => {
        const shouldBeDisabled =
          gridStore.selectedRowKeys.length === 1 &&
          !gridStore.selectedRowKeys.includes(getKey(row));

        return {
          disabled: shouldBeDisabled
        };
      }
    });
  };

  useEffect(() => {
    if (!gridStore.onlySingleSelection) {
      return;
    }

    updateSelectionProps();
  }, [gridStore.selectedRowKeys]);

  useEffect(() => {
    return () => {
      // * reset selected keys
      gridStore.setSelectedRowKeys();
    };
  }, [gridStore]);

  useEffect(() => {
    if (!preventInitialLoading) {
      gridStore.load();
    }
  }, [gridStore, preventInitialLoading]);

  const handleRowExpand = (expanded, record) => {
    const row = document.querySelector(`tr[data-row-key='${getKey(record)}']`);

    if (!row) {
      return;
    }

    row.classList.toggle("ant-row-parent-expanded", expanded);
  };

  const handleSortChanges = (columnKey, order) => {
    gridStore.setSortedInfo({
      order,
      columnKey
    });
  };

  const handleOnChange = (...args) => {
    const [, , sorterInfo] = args;

    handleSortChanges(sorterInfo.field, sorterInfo.order);

    if (typeof onChange === "function") {
      onChange(...args);
    }
  };

  const extendedTableProps = {};

  if (expandedRowRender) {
    extendedTableProps.expandIconAsCell = !hideExpandIcon;

    extendedTableProps.expandIcon = expandedRowRender
      ? getExpandIcon(expandIconExtraRenderer)
      : null;

    extendedTableProps.expandedRowRender = expandedRowRender;
    extendedTableProps.onExpand = handleRowExpand;
  }

  extendedTableProps.scroll = { x: compact ? "max-content" : 1080 };

  return (
    <>
      <Helmet title={title} />
      <BaseTable
        className={cx({
          "table-compact": compact,
          "only-single-selection": gridStore.onlySingleSelection
        })}
        rowKey="id"
        loading={gridStore.isLoading}
        dataSource={gridStore.tableData}
        pagination={gridStore.paginationSettings}
        columns={columns}
        rowClassName={rowClassName}
        onChange={handleOnChange}
        rowSelection={withoutSelection ? undefined : selectionProps}
        {...extendedTableProps}
        {...rest}
      />
    </>
  );
}

export default observer(ItemsTable);
