import validatorjs from "validatorjs";
import findEmailInText from "find-emails-in-string";
import { findNumbers } from "libphonenumber-js";
import wordsToNumbers from "words-to-numbers";
import moment from "moment";
import { get } from "lodash";

validatorjs.useLang("en");

// eslint-disable-next-line no-useless-escape
const URL_REGEX = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gim;

const textHasEmails = text => {
  if (typeof text !== "string") {
    return false;
  }

  return text.includes("@") || findEmailInText(text).length > 0;
};

const smokePhoneCheck = text => {
  return text.replace(/[^A-Za-z0-9]/g, "").match(/\d{4,}/gim);
};

const textHasPhoneNumbers = text => {
  if (typeof text !== "string") {
    return false;
  }

  try {
    const smokeTestResult = smokePhoneCheck(text);

    if (Array.isArray(smokeTestResult)) {
      return true;
    }

    const numbers = findNumbers(text, "US");

    return Array.isArray(numbers) && numbers.length > 0;
  } catch (e) {
    console.log(e);

    return true;
  }
};

const textHasPhoneNumbersInWords = text => {
  if (typeof text !== "string") {
    return false;
  }

  const transformedText = text
    .split(" ")
    .map(word => wordsToNumbers(word))
    .join("");

  const smokeTestResult = smokePhoneCheck(transformedText);

  return Array.isArray(smokeTestResult);
};

const textHasUrls = text => {
  if (typeof text !== "string") {
    return false;
  }

  return text.match(URL_REGEX);
};

(() => {
  validatorjs.register(
    "confirmed_insensitive",
    function confirmedInsensitive(val, req, key) {
      const confirmedKey = key.replace("_confirmation", "");
      const inputValue = `${this.validator.input[confirmedKey]} || ""`.toLowerCase();
      const insensitiveCheckValue = `${val} || ""`.toLowerCase();

      return inputValue === insensitiveCheckValue;
    },
    "The :attribute confirmation does not match."
  );

  validatorjs.register(
    "communication_guard",
    value => {
      // use waterfall for rules to prevent heavy validations if previous step is truthy
      const hasEmails = textHasEmails(value);

      if (hasEmails) {
        return false;
      }

      const hasUrls = textHasUrls(value);

      if (hasUrls) {
        return false;
      }

      const hasDigitalPhoneNumbers = textHasPhoneNumbers(value);

      if (hasDigitalPhoneNumbers) {
        return false;
      }

      const hasPhoneNumbersInWords = textHasPhoneNumbersInWords(value);

      if (hasPhoneNumbersInWords) {
        return false;
      }

      return true;
    },
    "Due to confidentiality reasons, you cannot share contact details here."
  );

  validatorjs.registerAsync("date_range_rule", function(
    value,
    attributes,
    key,
    passes
  ) {
    let result;
    const [fromTo, relatedFieldName] = attributes.split(",");

    const val = get(this, `validator.input[${relatedFieldName}]`);

    if (val) {
      if (fromTo === "from") {
        result = moment(value)
          .startOf("d")
          .isSameOrBefore(moment(val).startOf("d"));

        return !result
          ? passes(false, "Start date cannot be after end date.")
          : passes();
      }

      if (fromTo === "to") {
        result = moment(value)
          .startOf("d")
          .isSameOrAfter(moment(val).startOf("d"));

        return !result
          ? passes(false, "End date cannot be before start date.")
          : passes();
      }
    }

    return passes();
  });
})();
