import React, { useEffect, useState } from "react";
import StaticPagesService from "../../services/api/static-pages";
import Fallback from "./static-info-fallback";

export default function StaticInfoSection({ preloadedSchema }) {
  const [schema, setSchema] = useState();
  const [hasError, setHasError] = useState(false);

  const loadSchema = async () => {
    try {
      const loadedSchema = await StaticPagesService.getContactUsStaticPage();
      setSchema(loadedSchema);
    } catch (e) {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (preloadedSchema) {
      setSchema(preloadedSchema);
      return;
    }
    loadSchema();
  }, []);

  if (hasError) {
    return <Fallback />;
  }

  if (!schema) {
    return null;
  }

  const { email, support, phone_call, description } = schema;

  return (
    <div className="content-wrapper">
      <div className="intro-wrapper">
        <div className="truck-icon" />
        <span
          className="intro-text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="phone-wrapper">
        <div className="phone-icon" />
        <span className="phone-text">Phone Call</span>
        <a
          href={`tel:${phone_call}`}
          className="phone-number link-common font-bolder fs-16"
        >
          {phone_call}
        </a>
      </div>
      <div className="email-wrapper">
        <div className="email-icon" />
        <span className="email-text">Email</span>
        <a
          href={`mailto:${email}`}
          className="email-link link-common font-bolder fs-16"
        >
          {email}
        </a>
      </div>
      <div className="support-wrapper">
        <div className="contact-us-icon" />
        <span className="support-text">Support</span>
        <a
          href={`mailto:${support}`}
          className="support-email link-common font-bolder fs-16"
        >
          {support}
        </a>
      </div>
    </div>
  );
}
