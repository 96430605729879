import axios from "axios";

const getHeaders = token => ({
  "X-Requested-With": "XMLHttpRequest",
  "X-Inspector-Code": token
});

export default {
  loadTrucksToInspect: ({ token }) => {
    return axios.get("/trucks/request/inspection", {
      headers: getHeaders(token)
    });
  },
  loadDeclineReasons: () =>
    axios
      .get("/trucks/vocabulary/decline-reasons")
      .then(({ data }) =>
        data.map(({ id, name }) => ({ value: id, label: name }))
      ),
  submit: (inspections, token) => {
    return axios.post(
      "/trucks/request/inspection",
      { inspections },
      {
        headers: getHeaders(token)
      }
    );
  }
};
