import React from "react";
import Modal from "antd/lib/modal";
import { message } from "antd";
// eslint-disable-next-line import/no-cycle
import IssueFormInstance from "../../../../../models/forms/issue-form";
import CancelActiveShipmentForm from "../../../../../models/forms/items-managers/shipments/cancel-active-shipment";
import ChangeStatusForm from "../../../../../models/forms/items-managers/shipments/change-status";
import { openInNewTab } from "../../../../../utils/window";
import AuthStore from "../../../../../models/auth";
import ShipmentDeliveryCodeForm from "../../../../../models/forms/shipment-delivery-code-form";
import ShipmentsService from "../../../../../services/api/shipments";
import { SHIPMENT_ACTIVE_STATUS_CODES } from "../../../../../config/constants";
import TrackingMap from "../../../../../models/forms/tracking-map";

const isCollaboration = row => {
  const { owner_id, shipper } = row;
  const { id: user_id } = AuthStore.user;

  return shipper && owner_id !== user_id && shipper.id === user_id;
};

const isTrackShipmentButtonVisible = row => {
  if (AuthStore.isShipper || AuthStore.isAgent) {
    return !!(
      row.activeQuote.trucks &&
      row.activeQuote.trucks.find(track => track.vehicle_have_trucker)
    );
  }

  return false;
};

const ACTIVE_SHIPMENTS_ACTIONS = [
  {
    key: "send_deliver_code",
    label: "Provide Delivery Code",
    /**
     * @param row {Shipment}
     */
    visible: row => {
      const isShipmentPickedUp =
        row.status === SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP;
      const isQuotePickedUpOrDelivered = [
        SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP,
        SHIPMENT_ACTIVE_STATUS_CODES.DELIVERED
      ].includes(row.activeQuote.status);

      return (
        AuthStore.isTruckOwner &&
        isShipmentPickedUp &&
        isQuotePickedUpOrDelivered
      );
    },
    /**
     * @param row {Shipment}
     */
    onClick(row) {
      ShipmentDeliveryCodeForm.shipment = row;
      ShipmentDeliveryCodeForm.callback = this.load.bind(this);
    }
  },
  {
    key: "view_deliver_code",
    label: "View Delivery Code",
    /**
     * @param row {Shipment}
     */
    visible: row => {
      return !AuthStore.isTruckOwner && row.isPaid;
    },
    /**
     * @param row {Shipment}
     */
    onClick: row => {
      return ShipmentsService.loadDeliveryCode(row.id).then(response => {
        const { code } = response;

        if (!code) {
          message.info("There is no delivery code for this shipment.");

          return;
        }

        const truckOwner = row.truckOwnerName;

        Modal.info({
          icon: null,
          className: "modal-no-title",
          content: (
            <span>
              Kindly share this <strong>{code}</strong> code with{" "}
              <strong>{truckOwner}</strong> ONLY when shipment was successfully
              delivered.
            </span>
          ),
          okButtonProps: {
            size: "large"
          },
          width: 390,
          autoFocusButton: null
        });
      });
    }
  },
  {
    key: "view_contract",
    label: "View Contract",
    onClick: row => {
      openInNewTab(`/view-contract/${row.id}`);
    }
  },
  {
    key: "cancel_shipment",
    label: "Cancel Shipment",
    /**
     * @param row {Shipment}
     */
    disabled: row => {
      if (row.isActive) {
        return (
          CancelActiveShipmentForm.isNotAvailableToCancel(row) ||
          (!AuthStore.isTruckOwner && isCollaboration(row))
        );
      }

      return true;
    },
    onClick: row => {
      CancelActiveShipmentForm.initState(row);
    }
  },
  {
    key: "change_delivery_status",
    label: "Change Delivery Status",
    disabled: row => !row.isAbleToChangeStatus || isCollaboration(row),
    onClick: shipment => {
      ChangeStatusForm.setShipment(shipment);
    }
  },
  {
    key: "report_issue",
    label: "Report Issue",
    onClick: row => IssueFormInstance.setShipment(row)
  },
  {
    key: "tracking_map",
    label: "Track Shipment",
    tooltipIfDisabled:
      'The tracking is allowed only if Track in Shipment has a Tracker and both shipment and quote statuses remain "Picked Up".',
    visible: row => {
      return isTrackShipmentButtonVisible(row);
    },
    disabled: row => {
      return (
        row.status !== SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP ||
        row.activeQuote.status !== SHIPMENT_ACTIVE_STATUS_CODES.PICKED_UP
      );
    },
    onClick: shipment => TrackingMap.showTrackerModal(shipment.id)
  }
];

export default ACTIVE_SHIPMENTS_ACTIONS;
