import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router";

const ReapplyHandler = inject("AuthStore")(
  observer(({ AuthStore, match: { params: { token } } }) => {
    useEffect(() => {
      AuthStore.loadRejectedAccount(token);
    }, [AuthStore, token]);

    if (!AuthStore.isLoggedIn) {
      return null;
    }

    return <Redirect to="/registration" push={false} />;
  })
);

export default ReapplyHandler;
