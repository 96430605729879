/* eslint-disable class-methods-use-this */
import { action, computed, observable } from "mobx";
import validatorjs from "validatorjs";
import dvr from "../../../libs/mobx-react-form/validators/DVR";
import Field from "../field";
import OptionService from "../../../services/api/options";
import QuoteTruckForm from "./quote-truck-form";
import BaseForm from "../base-form";
import GetQuoteService from "../../../services/api/get-quote";
import throttledMessage from "../../../utils/throttled-message";
import { WARNING_MANDATORY } from "../../../config/constants";

const MAX_TRUCK_TO_ADD = 3;
const MAX_COMMODITY_WEIGHT = 1000;
const MAX_COMMODITY_VOLUME = 500000;

class GetQuoteForm extends BaseForm {
  trucksForm = QuoteTruckForm;

  @observable
  submitting = false;

  @observable
  specificationSource = [];

  @observable
  calculationType = { is_required_weight: true };

  @observable
  quoteResults = false;
  // quoteResults = {
  //   lowerQuoteLimit: 141131900,
  //   upperQuoteLimit: 169358280
  // };

  @observable
  quotePayload = false;
  // quotePayload = {
  //   email: "danilovich.dev@gmail.com",
  //   pickup_location: "Unnamed Road, Abuja, Nigeria",
  //   destination_location: "Hadari, Nigeria",
  //   commodity_type_id: 44,
  //   commodity_weight: "",
  //   number_of_days: 7,
  //   truck_type_id: 8,
  //   commodity_name: "Lifting operation",
  //   truck_name: "Cranes",
  //   origin: {
  //     city: "Abuja",
  //     area: "Municipal Area Coun",
  //     state: "Federal Capital Territory",
  //     location: "Unnamed Road, Abuja, Nigeria",
  //     country: "Nigeria",
  //     latitude: 8.983657571538867,
  //     longitude: 7.4226030312499915
  //   },
  //   destination: {
  //     city: null,
  //     area: "Kokona",
  //     state: "Nasarawa",
  //     location: "Hadari, Nigeria",
  //     country: "Nigeria",
  //     latitude: 8.875125809396632,
  //     longitude: 7.9499467812499915
  //   },
  //   trucks: [
  //     {
  //       weight: "100",
  //       length: "",
  //       volume: "",
  //       number_of_trucks: 2,
  //       truck_specification_id: 65
  //     }
  //   ]
  // };

  showMandatoryFieldsMessage = throttledMessage(WARNING_MANDATORY, "warning");
  /**
   * * getters
   */

  @computed
  get isQuoteReady() {
    return !!this.quoteResults;
  }

  @computed
  get canSelectTruckType() {
    return this.$("truck_type_id").disabled;
  }

  @computed
  get isVolumeBased() {
    return this.calculationType.is_required_volume;
  }

  @computed
  get isWeightBased() {
    return (
      this.calculationType.is_required_weight &&
      !this.calculationType.is_required_length
    );
  }

  @computed
  get isWeightLengthBased() {
    return (
      this.calculationType.is_required_weight &&
      this.calculationType.is_required_length
    );
  }

  @computed
  get isLocationRequired() {
    return true;
    // return this.calculationType.includes("locations");
  }

  @computed
  get truckTypeName() {
    const truckTypeField = this.$("truck_type_id");
    const trucks = truckTypeField.options;

    if (!trucks) {
      return null;
    }

    const truckId = truckTypeField.value || this.quotePayload.truck_type_id;
    const truck = trucks.find(truckOption => truckOption.value === truckId);

    return truck ? truck.label : null;
  }

  @computed
  get commodityName() {
    const commodityTypeField = this.$("commodity_type_id");
    const commodities = commodityTypeField.options;

    if (!commodities) {
      return null;
    }

    const commodityId =
      commodityTypeField.value || this.quotePayload.commodity_type_id;
    const commodity = commodities.find(
      commodityOption => commodityOption.value === commodityId
    );

    return commodity ? commodity.label : null;
  }

  @computed
  get bulkLabel() {
    if (this.isVolumeBased) {
      return "Volume, lt";
    }

    if (this.isWeightBased || this.isWeightLengthBased) {
      return "Weight, tons";
    }

    return "Weight, tons / Volume, lt";
  }

  @computed
  get originCoordinates() {
    const address = this.$("pickup_location").extra;

    if (!address) {
      return undefined;
    }

    return { lat: address.latitude, lng: address.longitude };
  }

  @computed
  get destinationCoordinates() {
    const address = this.$("pickup_location").extra;

    if (!address) {
      return undefined;
    }

    return { lat: address.latitude, lng: address.longitude };
  }

  @computed
  get canAddTruck() {
    if (this.specificationSource.length === 0) {
      return false;
    }

    return this.trucksForm.$("truck").fields.size < MAX_TRUCK_TO_ADD;
  }

  @computed
  get commodityStep() {
    return this.isVolumeBased ? 100 : 1;
  }

  /**
   * * actions
   */
  @action
  setAddress(fieldName, address) {
    if (!address) {
      return;
    }

    this.$(fieldName).set("value", address.location);
    this.$(fieldName).set("extra", address);
    this.$(fieldName).resetValidation();
  }

  @action
  setOriginManualAddress(addressObject) {
    this.setAddress("pickup_location", addressObject);
  }

  @action
  setDestinationManualAddress(addressObject) {
    this.setAddress("destination_location", addressObject);
  }

  @action
  addTruck() {
    this.trucksForm
      .$("truck")
      .validate({ showErrors: true })
      .then(({ isValid }) => {
        if (isValid) {
          const newFieldSet = this.trucksForm.$("truck").add();

          this.trucksForm.applySpecification(
            this.specificationSource,
            newFieldSet,
            this
          );
        }
      });
  }

  @action
  switchLocations() {
    const originAddress = this.$("pickup_location").value;
    const originExtra = this.$("pickup_location").extra;
    const destinationAddress = this.$("destination_location").value;
    const destinationExtra = this.$("destination_location").extra;

    if (!originAddress && !destinationAddress) {
      return;
    }

    this.setAddress("pickup_location", {
      location: destinationAddress,
      ...destinationExtra
    });
    this.setAddress("destination_location", {
      location: originAddress,
      ...originExtra
    });
  }

  @action
  applySpecification(specification) {
    const basisFields = ["weight", "volume", "length"];
    const truckSpecification = {};

    basisFields.forEach(field => {
      truckSpecification[field] = [];
    });
    specification.forEach(s => {
      basisFields.forEach(name => {
        truckSpecification[name].push(s[name]);
      });
    });

    return truckSpecification;
  }

  @action
  unregisterUserEmailField(isLoggedIn) {
    const emailField = this.$("email");

    emailField.set("rules", isLoggedIn ? "" : "required|email_ext");
    emailField.hidden = isLoggedIn;
  }

  /**
   * set up the validator plugin
   * @returns {{dvr: *}}
   */
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }) => {
          validator.register(
            "commodity_weight",
            v => {
              if (this.isVolumeBased) {
                return true;
              }

              return Number.isInteger(v) && v <= MAX_COMMODITY_WEIGHT;
            },
            "Maximum weight is 1000 tons. Please split your request."
          );
          validator.register(
            "commodity_volume",
            v => {
              if (!this.isVolumeBased) {
                return true;
              }

              return Number.isInteger(v) && v <= MAX_COMMODITY_VOLUME;
            },
            "Maximum volume is 500,000 liters. Please split your request."
          );
          validator.register(
            "has_coordinates",
            (...args) => {
              const [, , fieldName] = args;

              if (!this.has(fieldName)) {
                return true;
              }

              const field = this.$(fieldName);

              if (!field.extra) {
                return false;
              }

              const { longitude, latitude } = field.extra;

              return !(!longitude || !latitude);
            },
            "Please, select address on the map or choose a place prediction."
          );
        }
      })
    };
  }

  hooks() {
    return {
      onInit: async () => {
        this.observe({
          path: "truck_type_id",
          key: "value",
          call: async ({ field }) => {
            if (!field.value) {
              return;
            }

            this.trucksForm.resetFields();

            // load calculation type to know what types of input required
            this.calculationType = field.options.find(
              ({ value }) => value === field.value
            ).calculation_type;

            // enable weight or days input because we know what type of calculation will be used
            this.$("commodity_weight").set("disabled", false);
            this.$("number_of_days").set("disabled", false);

            // load dropdown options
            this.specificationSource = await OptionService.getTruckSpecByType(
              field.value
            );

            this.addTruck();
          }
        });
      },
      onClear: () => {
        this.quoteResults = false;
        this.calculationType = { is_required_weight: true };
        this.specificationSource = [];
        this.trucksForm.resetFields();
        this.$("commodity_weight").set("disabled", true);
        this.$("truck_type_id").set("disabled", false);
        this.$("pickup_location").set("extra", null);
        this.$("destination_location").set("extra", null);
        this.reset();
      },
      onError() {
        const errors = this.errors();
        const hasEmptyMandatoryField = Object.values(errors).includes(
          "This field is required."
        );

        if (hasEmptyMandatoryField) {
          this.showMandatoryFieldsMessage();
        }
      }
    };
  }

  async loadInitialOptions() {
    const commodityTypes = (await OptionService.getCommodityTypes()).map(
      ({ id, name }) => ({ value: id, label: name })
    );

    this.$("commodity_type_id").set("options", commodityTypes);
  }

  submit() {
    Promise.all([
      this.validate({ showErrors: true }),
      this.trucksForm.validate({ showErrors: true })
    ]).then(validationResult => {
      const [quoteForm, trucksForm] = validationResult;
      const isValid = quoteForm.isValid && trucksForm.isValid;

      if (!isValid) {
        this.showMandatoryFieldsMessage();

        return;
      }

      this.submitting = true;

      const values = {
        ...quoteForm.values(),
        commodity_name: this.commodityName,
        truck_name: this.truckTypeName,
        origin: quoteForm.$("pickup_location").extra,
        destination: quoteForm.$("destination_location").extra,
        [`commodity_${this.isWeightBased ? "weight" : "volume"}`]: quoteForm.$(
          "commodity_weight"
        ).value
      };
      const trucks = trucksForm.values().truck;

      if (this.isWeightLengthBased) {
        values.trucks = trucks.map(truck => {
          const specification = this.specificationSource.find(spec => {
            return spec.weight === truck.weight && spec.length === truck.length;
          });

          truck.truck_specification_id = specification && specification.id;

          return truck;
        });
      } else if (this.isVolumeBased) {
        values.trucks = trucks.map(truck => {
          const specification = this.specificationSource.find(spec => {
            return spec.volume === truck.volume;
          });

          truck.truck_specification_id = specification && specification.id;

          return truck;
        });
      } else if (this.isWeightBased) {
        values.trucks = trucks.map(truck => {
          const specification = this.specificationSource.find(spec => {
            return spec.weight === truck.weight;
          });

          truck.truck_specification_id = specification && specification.id;

          return truck;
        });
      }

      if (this.isVolumeBased) {
        delete values.commodity_weight;
      } else {
        delete values.commodity_volume;
      }

      GetQuoteService.get(values)
        .then(({ lowerQuoteLimit, upperQuoteLimit }) => {
          this.quotePayload = values;
          this.quoteResults = { lowerQuoteLimit, upperQuoteLimit };
        })
        .catch(error => {
          const { response: { data: { errors } = {}, status } = {} } = error;

          if (errors && status === 422) {
            Object.entries(errors).forEach(entry => {
              const [fieldName, errorMessages] = entry;

              if (this.has(fieldName)) {
                this.$(fieldName).invalidate(errorMessages);
              }
            });
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    });
  }

  setup() {
    return {
      fields: [
        {
          name: "email",
          type: "text",
          label: "Email Address",
          rules: "required|email_ext"
        },
        {
          name: "pickup_location",
          type: "text",
          label: "Address",
          placeholder: "Select Origin",
          rules: "required|max:255|has_coordinates",
          hooks: {
            onChange: field => {
              field.set("extra", null);
            }
          }
        },
        {
          name: "destination_location",
          type: "text",
          label: "Address",
          placeholder: "Select Destination",
          rules: "required|max:255|has_coordinates",
          hooks: {
            onChange: field => {
              field.set("extra", null);
            }
          }
        },
        {
          label: "Commodity Type",
          placeholder: "Select Commodity Type",
          name: "commodity_type_id",
          type: "select",
          rules: "required",
          options: [],
          hooks: {
            onChange: async field => {
              const truckTypeField = field.container().$("truck_type_id");

              if (truckTypeField.disabled) {
                truckTypeField.set("disabled", false);
                truckTypeField.reset();
              }

              truckTypeField.clear();
              this.trucksForm.resetFields();
              this.specificationSource = [];
              this.calculationType = { is_required_weight: true };

              if (field.value) {
                const truckTypes = await OptionService.getTruckTypesByCommodity(
                  field.value
                );

                truckTypeField.set("options", truckTypes);
              } else {
                truckTypeField.set("options", null);
              }
            }
          }
        },
        {
          name: "commodity_weight",
          type: "integer",
          rules: "required|commodity_weight|commodity_volume",
          guard: "letters",
          related: ["truck_type_id"],
          disabled: true
        },
        {
          name: "number_of_days",
          type: "integer",
          disabled: true
        },
        {
          name: "truck_type_id",
          type: "select",
          rules: "required",
          label: "Truck Type",
          placeholder: "Select Truck Type",
          options: [],
          handlers: {
            onFocus: field => {
              const isCommodityTypeSelected = field
                .container()
                .$("commodity_type_id").value;

              if (isCommodityTypeSelected) {
                return;
              }

              field.set("disabled", true);
              field.invalidate("Please, select Commodity Type.");
            }
          },
          hooks: {
            onChange: field => {
              if (!field.value) {
                this.trucksForm.resetFields();
              }
            }
          }
        }
      ]
    };
  }

  options() {
    return {
      validateOnReset: false,
      showErrorsOnReset: false
    };
  }

  makeField(props) {
    return new Field(props);
  }
}

export default new GetQuoteForm();
