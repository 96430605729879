import React from "react";
import { Form, Row, Col, Card } from "antd";
import { observer } from "mobx-react";
import RegistrationSteps from "./RegistrationSteps";
import StepWrapper from "./StepWrapper";
import RegistrationSuccess from "./RegistrationSuccess";

const RegistrationForm = ({ formState }) => {
  const handleSubmit = e => {
    e.preventDefault();
  };

  if (formState.isRegistrationCompleted) {
    return <RegistrationSuccess />;
  }

  return (
    <Card className="sign-up-container">
      <Row
        gutter={10}
        className="auth-container"
        type="flex"
        justify="center"
        align="top"
      >
        <Col span={24}>
          <RegistrationSteps />
          <Form
            onSubmit={handleSubmit}
            layout="vertical"
            labelAlign="right"
            hideRequiredMark
          >
            <StepWrapper />
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default observer(RegistrationForm);
