let analystFunction = null;

export const passwordAnalyst = async field => {
  if (analystFunction === null) {
    const { default: moduleDefaultExport } = await import("zxcvbn");

    analystFunction = moduleDefaultExport;
  }

  // 0 1 are weak, 2-3 - medium, 4++ - strong
  const scores = ["Weak", "Weak", "Medium", "Medium", "Strong"];
  const passwordScore = analystFunction(field.value).score;
  const currentStrength = scores[passwordScore] || scores.pop();

  field.set("extra", `Password Strength: ${currentStrength}.`);
};
