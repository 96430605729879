/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Col, Row, Button, Card } from "antd";
import SignUpForm from "../../models/forms/auth/signup";
import DoneImage from "../../styles/images/done.svg";
import { INFO_PHONE } from "../../config/constants";

const RegistrationSuccess = withRouter(
  inject("AuthStore", "AppState")(
    observer(({ history, AuthStore, AppState }) => {
      const onClickButton = () => {
        history.push("/");
      };

      useEffect(() => {
        return () => {
          if (SignUpForm.isShipper) {
            AuthStore.activateUser();
          } else {
            SignUpForm.fullReset();
          }
        };
      });

      const renderPhoneLinks = (phonesString = INFO_PHONE) => {
        return phonesString
          .split(",")
          .map(number => (
            <a href={`tel:${number}`}>
              <span className="font-regular fs-21">{number}</span>
            </a>
          ))
          .reduce((links, item, index) => {
            links.push(item);

            if (index % 2 === 0) {
              links.push(", ");
            }

            return links;
          }, []);
      };

      return (
        <Row
          className="success-container"
          type="flex"
          justify="center"
          align="top"
        >
          <Col lg={12}>
            <Card>
              <Row
                className="auth-container"
                type="flex"
                justify="center"
                align="top"
              >
                <Col lg={16} md={16} sm={24}>
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    <img src={DoneImage} alt="Registration Success" />
                  </span>
                  <Row className="title" type="flex" justify="center">
                    <h1>Sign Up Request Complete</h1>
                  </Row>
                  {!AuthStore.isShipper && (
                    <p className="text-common text-center font-bolder m-bottom-40">
                      Virtual Haulage personnel will review your request and
                      provide feedback within <br />
                      24 hours.
                    </p>
                  )}
                  <p className="text-common text-center font-bolder m-bottom-40">
                    Call {renderPhoneLinks(AppState.contactInfo.phone_number)}{" "}
                    for further enquires.
                  </p>
                  <div
                    className="button-container"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      size="large"
                      type="secondary"
                      onClick={onClickButton}
                    >
                      Back to the Home Page
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      );
    })
  )
);

export default RegistrationSuccess;
