import React, { useEffect } from "react";
import Col from "antd/lib/grid/col";
import { Button, Input, PageHeader, Radio } from "antd";
import Row from "antd/lib/grid/row";
import { inject, observer } from "mobx-react";
import Form from "antd/lib/form";
import Helmet from "../../common/Helmet";
import FormContext from "../../models/forms/form-context";
import Field from "../../common/FieldContainer";
import DialCodeSelector from "../../common/DialCodeSelector";
import StaticInfoSection from "./static-info";

import "./styles.less";

const gutter = { lg: 10, sm: 10, xs: 15 };
const { TextArea } = Input;

const ContactUs = inject("ContactUsForm")(
  observer(({ ContactUsForm, preloadedSchema }) => {
    useEffect(() => {
      ContactUsForm.initForm();
      return () => ContactUsForm.reset();
    }, []);

    return (
      <div className="contact-us-form">
        <FormContext.Provider value={ContactUsForm}>
          <Helmet title="Contact Us" />
          <Row gutter={gutter}>
            <Col lg={{ span: 22, offset: 1 }} md={{ span: 21, offset: 0 }}>
              <PageHeader title="Contact Us" />
            </Col>
          </Row>
          <Row gutter={gutter} type="flex">
            <Col
              lg={{ span: 9, offset: 1, order: 1 }}
              md={{ span: 10, offset: 0, order: 1 }}
              sm={{ span: 24, offset: 0, order: 2 }}
              xs={{ span: 24, offset: 0, order: 2 }}
            >
              <Form onSubmit={ContactUsForm.onSubmit}>
                <Field name="full_name">
                  <Input />
                </Field>
                <Field name="email">
                  <Input />
                </Field>
                <Field name="phone_number">
                  <Input
                    addonBefore={
                      <DialCodeSelector
                        {...ContactUsForm.$(`dial_code`).bind()}
                      />
                    }
                  />
                </Field>
                <Field name="subject">
                  <Input />
                </Field>
                <Field name="description">
                  <TextArea rows={4} />
                </Field>
                <Field name="preferred_method_of_communication">
                  <Radio.Group size="large">
                    <div className="method-of-communication">
                      Preferred Method of Communication
                    </div>
                    <div className="buttons-wrapper">
                      <Radio value="email">Email</Radio>
                      <Radio value="phone">Phone</Radio>
                    </div>
                  </Radio.Group>
                </Field>
                <Row type="flex" className="button-wrapper">
                  <Col className="d-flex">
                    <Button
                      loading={ContactUsForm.submitting}
                      disabled={ContactUsForm.submitting}
                      size="large"
                      block
                      type="primary"
                      htmlType="submit"
                      className="send-button m-left-auto m-top-30"
                    >
                      Send
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col
              lg={{ span: 7, offset: 4, order: 2 }}
              md={{ span: 10, offset: 4, order: 2 }}
              sm={{ span: 20, offset: 4, order: 1 }}
              xs={{ span: 20, offset: 4, order: 1 }}
            >
              <StaticInfoSection preloadedSchema={preloadedSchema} />
            </Col>
          </Row>
        </FormContext.Provider>
      </div>
    );
  })
);

export default ContactUs;
