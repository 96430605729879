import axios from "axios";
import FileDownload from "js-file-download";

const MAIN_ROUTE = "/shipments/quotes/invoices";

const PaymentService = {
  get: values => {
    const url = `${MAIN_ROUTE}/my/${values.size}/${values.status}`;
    return axios
      .get(url, { params: { page: values.page } })
      .then(({ data }) => data);
  },
  loadInvoice: id => {
    const url = `${MAIN_ROUTE}/details/${id}`;
    return axios.get(url).then(({ data }) => data);
  },
  downloadInvoice: id => {
    return axios
      .get(`/shipments/quotes/invoices/${id}/download`, {
        responseType: "blob"
      })
      .then(response => {
        FileDownload(response.data, `invoice-${id}.pdf`, "application/pdf");
      });
  }
};

export default PaymentService;
