import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { observer } from "mobx-react";
import { DEFAULT_DIAL_CODE } from "../config/constants";

const { Option } = Select;

const DialCodeSelector = observer(({ value, ...props }) => {
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    import("../data/phones").then(phones => {
      setCodes(phones.default || []);
    });
  }, []);

  if (!value) {
    value = DEFAULT_DIAL_CODE;
  }

  return (
    <Select style={{ width: 80 }} {...props} value={value}>
      {codes.map(({ dial_code }) => {
        return (
          <Option key={dial_code} value={dial_code}>
            {dial_code}
          </Option>
        );
      })}
    </Select>
  );
});

export default DialCodeSelector;
