import "./config/bootstrap";
// import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { Router } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import HistoryContext from "./contexts/history";
import App from "./containers/App";
import stores from "./models";
import "./styles/index.less";

if (process.env.NODE_ENV === "development") {
  window.stores = stores;
}

ReactDOM.render(
  <Provider {...stores}>
    <HistoryContext.Consumer>
      {history => (
        <Router history={history}>
          <LastLocationProvider>
            <App />
          </LastLocationProvider>
        </Router>
      )}
    </HistoryContext.Consumer>
  </Provider>,
  document.getElementById("root")
);
