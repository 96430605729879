/* eslint-disable no-unused-vars */
import { AccessControlPlus } from "accesscontrol-plus";
import { observable } from "mobx";
// eslint-disable-next-line import/no-cycle

/**
 * example of use
 const canApproveContent = await AccessControl.can(
  "trucks:add",
 );
 */
class AccessControl {
  @observable
  user = {
    role: "guest"
  };

  acl;

  constructor() {
    this.acl = new AccessControlPlus();
    this.configure();
  }

  setUser(user) {
    this.user = user;
  }

  configure() {
    this.configureTruckOwnerRole();
    this.configureGuestRole();
  }

  configureTruckOwnerRole() {
    this.acl
      .grant("truck_owner")
      .resource("truck")
      .action("manage")
      .action("add");
  }

  configureGuestRole() {
    this.acl.grant("guest").scope("quote:get");
  }

  async can(...args) {
    const [permissionRequest, context] = args;
    const permission = await this.acl.can(this.user.role, permissionRequest, {
      ...context,
      user: this.user
    });
    return Boolean(permission.granted);
  }
}

export default new AccessControl();
